import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { getUserCourse } from "../../../store/features/UserSlice";

const UsersCourse = () => {
  const dispatch = useDispatch();

  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const userCourses = useSelector((state) => state.userSlice.usersCourse);
  useEffect(() => {
    dispatch(getUserCourse(JSON.parse(jwtTokenRedux)));
  }, [jwtTokenRedux, dispatch]);

  return (
    <>
      {userCourses.length === 0 ? (
        <div className="w-[calc(100%-3.73rem)] px-3 mb-3">
          <div className="2xl:container mx-auto space-y-3 py-3">
            <div className="grid content-center mx-auto  bg-white rounded-xl px-1.5">
              <div className="bg-white">
                <div className="w-full bg-[#142850] rounded-lg mb-6">
                  <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                    Kurslarım
                  </p>
                </div>
                <div className="flex mb-5 mt-5">
                  <NavLink
                    to={`/kullanicipaneli/anasayfa`}
                    className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
                  >
                    <lord-icon
                      src="https://cdn.lordicon.com/zfxxckbp.json"
                      trigger="loop"
                      colors="primary:#4be1ec,secondary:#cb5eee"
                    ></lord-icon>
                    Geri
                  </NavLink>
                </div>
                <div className="mx-auto max-w-7xl py-16 px-6 lg:px-8">
                  <div className="overflow-hidden rounded-lg bg-[#142850] shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
                    <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                      <div className="lg:self-center">
                        <h2 className="text-white">
                          <span className="block text-lg">
                            Henüz bir kurs satın almadınız.
                          </span>
                          <span className="block font-bold text-2xl">
                            1000'den fazla kursu şimdi inceleyin ve kendinize en
                            uygun olanı seçin!
                          </span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-indigo-200">
                          Yetkin olduğunuz alanlarda yeteneklerinizi
                          belgeleyerek kariyerinizi geliştirin!
                        </p>
                        <Link
                          to="/tumkurslar"
                          className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-[#142850] shadow hover:bg-blue-200"
                        >
                          Kurs Sayfasına Git
                        </Link>
                      </div>
                    </div>
                    <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
                      <img
                        className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                        src="https://media.istockphoto.com/id/1290864946/tr/foto%C4%9Fraf/e-%C3%B6%C4%9Frenme-e%C4%9Fitim-kavram%C4%B1-online-%C3%B6%C4%9Frenme.jpg?s=612x612&w=0&k=20&c=WBA9frmW_NMidVilMDtgQtumM71czh_ToTQq5V5O7EY="
                        alt="App screenshot"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[calc(100%-3.73rem)] px-3 mb-20">
          <div className="2xl:container mx-auto space-y-3 py-3">
            <div className="w-full bg-[#142850] rounded-lg mb-6">
              <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                Kurslarım
              </p>
            </div>
            <div className="flex mb-5 mt-5">
              <NavLink
                to={`/kullanicipaneli/anasayfa`}
                className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
              >
                <lord-icon
                  src="https://cdn.lordicon.com/zfxxckbp.json"
                  trigger="loop"
                  colors="primary:#4be1ec,secondary:#cb5eee"
                ></lord-icon>
                Geri
              </NavLink>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-10">
              {!!userCourses &&
                userCourses?.map((course) => (
                  <div 
                  key={course.id}
                  className="mx-auto mt-8 col-span-1 border border-gray-300 rounded-lg">
                    <div
                      className=" overflow-hidden shadow-lg duration-500 hover:scale-105 rounded-md"
                      style={{ borderRadius: "4  0px" }}
                    >
                      <Link
                        to={`/kullanicipaneli/kurslarim/${course.name
                          .split(" ")
                          .join("")}/${course.id}`}
                      >
                        <div>
                          <img
                            className="h-52 mx-auto w-80"
                            src={`${course.imagePath}`}
                            alt="kurs-görseli"
                          />
                        </div>
                      </Link>

                      <div className="flex-col justify-between bg-white p-6">
                        <div className="flex-1">
                          <a className="mt-2 block">
                            <p className="text-lg font-semibold text-gray-900 text-center">
                              {course.name}
                            </p>

                            <div class="shadow bg-grey-light text-center mt-3">
                              <div
                                class="bg-[#142850] text-xs leading-none py-0.5 text-center text-white"
                                style={{
                                  width: `${course.progressProcess.progressPercentage}%`,
                                }}
                              ></div>
                              {course.progressProcess.isItComplete ? (
                                <span className="grid mx-auto text-xs text-green-500 py-0.5">
                                  Bu kursu tamamladınız
                                </span>
                              ) : (
                                <span className="mx-auto text-xs	text-semibold">
                                  %{course.progressProcess.progressPercentage}{" "}
                                  tamamlandı
                                </span>
                              )}
                            </div>
                          </a>
                        </div>

                        <div className="mt-6 flex items-center w-full justify-center gap-x-5">
                          {course.progressProcess.progressPercentage === 100 ? (
                            <>
                              {course.certificateImagePath === null ? (
                                <></>
                              ) : (
                                <Link
                                  to={`/kullanicipaneli/sertifikalarim`}
                                  className="bg-[#142850] p-1 text-white rounded-md border border-gray-500"
                                >
                                  Sertifikayı Gör
                                </Link>
                              )}
                            </>
                          ) : (
                            <button className="bg-[#142850] p-1 text-white rounded-md border border-gray-500">
                              <NavLink
                                to={`/kullanicipaneli/kurslarim/${course.name
                                  .split(" ")
                                  .join("")}/${course.id}`}
                              >
                                {course.progressProcess.progressPercentage === 0
                                  ? "Başla"
                                  : "Devam Et"}
                              </NavLink>
                            </button>
                          )}
                          <Link
                            to={`/kullanicipaneli/kurslarim/yorumyap/${course.id}`}
                            className="bg-[#142850] p-1 text-white rounded-md border border-gray-500"
                          >
                            Yorum Yap
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UsersCourse;
