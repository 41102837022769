import { FcShipped } from "react-icons/fc";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { getCertificateShipping } from "../../../store/features/UserSlice";

function CertificateShipping({id, address, forceUpdate}) {
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const notifyError = () => toast.error("İşlem başarısız! Bilgilerinizi güncelleyin.");
  const notify = () => toast.success("İsteğiniz işleme alındı!");
  const data = {
    certificateId: id,
    shippingStatus: true,
    shippingAddress: address,
  }

  const dispatch = useDispatch()
  
  const handleShipping = (e) => {
   
    e.preventDefault();
    Swal.fire({
      title: "Sertifikanız adresinize kargolansın mı?",
      text: "(Adres bilgilerinizi profil sayfasından güncelleyebilirsiniz.)",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Gönder",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
          try {
            await dispatch(getCertificateShipping({jwt:JSON.parse(jwtTokenRedux), data}))
            notify();
            forceUpdate();
          } catch (error) {
            console.log(error);
            notifyError();
          }
        };
        response();
      }
    });
  };

  return (
    <div className="mt-8">
      <div className="flex justify-center text-center">
        <button
          onClick={(e) => handleShipping(e)}
          className="inline-flex items-center justify-center px-6 py-3 w-full text-base font-medium rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Sertifikayı Adresime Kargola
          <span className="p-2">
            {" "}
            <FcShipped className="h-5 w-5" />
          </span>
        </button>
        <ToastContainer/>
      </div>
    </div>
  );
}

export default CertificateShipping;
