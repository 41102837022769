import React ,{useEffect, useState } from "react";
//import { useDispatch, useSelector } from "react-redux";
import { Link ,NavLink} from "react-router-dom";

import user from "../../../../assets/user.png";
import group from "../../../../assets/group.png";
import classes from "../../../../assets/class.png";


const menuItems = [

    {
        title: "Eğitmenler",
        pageId: "egitmenler",
        src: user,
    },
    {
        title: "Eğitmen istekleri",
        pageId: "egitmen-istekleri",
        src: group,
    },
    {
        title: "Eğitmen Kursları",
        pageId: "egitmen-kurslari",
        src: classes,
    },
    
   
  ];

function InstructorOperations (){



  return (
    <>
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
           <div className="px-5 py-4 mx-auto">
        <div className="grid grid-cols-12 gap-x-10 gap-y-10 mt-10 ">
          {menuItems?.map((change, i) => (
            <div
              key={i}
              className="max-w-xs lg:col-span-3 md:col-span-4 col-span-12 ml-1 md:ml-0 sm:ml-20 border-2 border-opacity-20 shadow-lg rounded-lg cursor-pointer hover:shadow-blue-500 hover:scale-110"
            >
              <Link to={`/adminpanel/${change.pageId}`}>
                <img
                  src={change.src}
                  alt="icon"
                  className="w-24 h-24 m-auto pt-2"
                />
                <div className="p-2 text-black hover:text-blue-900 transition duration-300 ease-in text-center">
                  <h1 className="text-xl font-semibold mb-3">{change.title}</h1>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InstructorOperations;
