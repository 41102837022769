import React, { useState } from "react";
import menu from "../../../assets/menu.png";
import control from "../../../assets/before.png";
import { Link } from "react-router-dom";
import { AiOutlineDown } from "react-icons/ai";

const Menus = [
  {
    title: "Ana Sayfa",
    pageId: "",
  },
  // {
  //   title: "Admin",
  //   pageId: "admin",
  // },
  {
    title: "Alışveriş",
    pageId: "alisveris",
  },
  {
    title: "Dersler",
    pageId: "dersler",
  },
  {
    title: "Duyurular",
    pageId: "duyurular",
  },
  {
    title: "Evraklar",
    pageId: "evraklar",
    gap: true,
  },
  {
    title: "Kategoriler",
    pageId: "",
    submenu: true,
    children: true,
    submenuItems: [
      {
        title: "Ana Kategori",
        pageId: "anakategoriler",
      },
      {
        title: "Alt Kategori",
        pageId: "altkategoriler",
      },
      {
        title: "Kurslar",
        pageId: "kurslar",
      },
    ],
  },
  {
    title: "Kullanıcılar",
    pageId: "kullanicilar",
  },
  {
    title: "Roller",
    pageId: "roller",
    gap: true,
  },
  {
    title: "Sözleşmeler",
    pageId: "sozlesmeler",
  },
  {
    title: "Sıkça Sorulan Sorular",
    pageId: "sss",
  },

  {
    title: "Yorumlar",
    pageId: "yorumlar",
  },
  {
    title: "Ayarlar",
    pageId: "ayarlar",
    gap: true,
  },
];

function AdminNavbar({ move, setMove }) {
  const [open, setOpen] = useState(false);
  const [submenuOpen, setsubmenuOpen] = useState(null);

  function show() {
    setOpen(!open);
    setMove(!move);
  }

  return (
    <div className="flex sticky top-0 left-0">
      <div
        className={`${
          open ? "w-64" : "w-20"
        } duration-300 h-screen p-5 pt-8 border-r-2 border-gray-200 shadow-md relative bg-gray-100`}
      >
        
        <img
          src={control}
          alt="description"
          className={`absolute cursor-pointer -right-3 top-9 w-7
           rounded-full  ${!open && "rotate-180"}`}
          onClick={show}
        />

        <div
          onClick={show}
          className="flex gap-x-4 items-center cursor-pointer"
        >
          <img
            alt="description"
            src={menu}
            className={`h-7 w-7 cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />
          <h1
            className={`text-blue-900 origin-left font-large font-semibold text-lg duration-300 ${
              !open && "scale-0"
            }`}
          >
            Menü
          </h1>
        </div>
        {Menus.map((menu, i) => (
          <ul key={i} className="pt-2 ">
            <Link to={`/adminpanel/${menu.pageId}`}>
              <li
                onClick={() => {
                  submenuOpen === menu.title
                    ? setsubmenuOpen(null)
                    : setsubmenuOpen(menu.title);
                }}
                className={`text-gray-900 text-md flex items-center gap-x-4 cursor-pointer p-2 ${
                  open ? "hover:bg-[#142850] hover:text-white" : ""
                } rounded-md ${menu.gap ? "mt-9" : "mt-2"}`}
              >
                <span
                  className={`text-base flex-1 ${
                    !open && "hidden"
                  }`}
                >
                  {menu.title}
                </span>
                {menu.submenu && menu && (
                  <AiOutlineDown
                    className={`h-4 w-4 inline-block ease-in ${
                      !open && "hidden"
                    } ${submenuOpen === menu.title ? "rotate-180" : ""}`}
                  />
                )}
              </li>
            </Link>
            {menu.submenu && submenuOpen === menu.title && open && (
              <ul>
                {menu.submenuItems.map((submenuItem) => (
                  <li
                    key={submenuItem.title}
                    className="text-gray-700 text-sm flex flex-col cursor-pointer p-2 hover:bg-amber-100 hover:text-blue-900 rounded-md px-7 "
                  >
                    <Link to={`/adminpanel/${submenuItem.pageId}`}>
                      {submenuItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </ul>
        ))}
      </div>
    </div>
  );
}

export default AdminNavbar;
