import React from "react";
import img1 from "../../../assets/delivery-box.png";
import img2 from "../../../assets/megaphone.png";
import img4 from "../../../assets/user.png";
import img7 from "../../../assets/chat.png";
import img8 from "../../../assets/add.png";
import img9 from "../../../assets/student.png";
import { Link } from "react-router-dom";
import lessons from "../../../assets/class.png";

const data = [
  {
    title: "Kurslarım",
    pageId: "kurslarim",
    src: img1,
  },
  // {
  //   title: "Yeni Kurs Ekle",
  //   pageId: "kurslarim/yenikurstalebi",
  //   src: img8,
  // },
  {
    title: "Kurs Yorumları",
    pageId: "kursyorumlari",
    src: img7,
  },
  {
    title: "Öğrencilerim",
    pageId: "ogrencilerim",
    src: img9,
  },
  {
    title: "Duyurular",
    pageId: "duyurular",
    src: img2,
  },
  // {
  //   title: "Dersler",
  //   pageId: "dersler",
  //   src: lessons,
  // },

  {
    title: "Profil",
    pageId: "profil",
    src: img4,
  },
];

function EducatorMainPart() {
  return (
    <section className="md:h-full grids items-center text-gray-600">
      <div className=" px-5 py-4 mx-auto">
        <div className="text-center mb-20 border-b">
          <h1 className="text-2xl md:text-3xl lg:text-4xl text-black lg:font-bold font-semibold">
            HOŞGELDİNİZ
          </h1>
        </div>

        <div className="grid grid-cols-12 gap-x-10 gap-y-10 mt-10 ">
          {data?.map((change) => (
            <div
              key={change.title}
              className="max-w-xs lg:col-span-3 md:col-span-4 col-span-12 ml-1 md:ml-0 sm:ml-20 border-1 border-opacity-60 shadow-lg rounded-lg cursor-pointer border hover:shadow-blue-500 hover:scale-110"
            >
              <Link to={`/egitmenpanel/${change.pageId}`}>
                <img
                  src={change.src}
                  alt="icon"
                  className="w-24 h-24 m-auto pt-2"
                />
                <div className="p-2 text-black hover:text-blue-900 transition duration-300 ease-in text-center">
                  <h1 className="text-xl font-semibold mb-3">{change.title}</h1>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default EducatorMainPart;
