import React, { useEffect, useState } from "react";
import { TbFileCertificate } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2";
import {
  addCertificateToUser,
  checkCertificateStatus,
} from "../../../store/features/AdminSlice";
import { ToastContainer, toast } from "react-toastify";
import { errors } from "../../../data/errors";
import { ColorRing } from "react-loader-spinner";
import { getUserDetails } from "../../../store/features/UserSlice";

function UserCertificateDetails() {
  const courseId = useParams();
  const id = useParams();
  const dispatch = useDispatch();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [certificate, setCertificate] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  let bodyFormData = new FormData();
  bodyFormData.append("CourseId", courseId.courseId);
  bodyFormData.append("UserId", id.id);
  bodyFormData.append("Description", description);
  bodyFormData.append("PathFile", certificate);

  useEffect(() => {
    dispatch(getUserDetails({ jwt: JSON.parse(jwtTokenRedux), userId: id.id }));
  }, []);
  const userInfo = useSelector((state) => state.userSlice.getUserDetails);

  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Sertifika kullanıcıya eklensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          setLoading(true);
          try {
            await dispatch(
              addCertificateToUser({
                jwt: JSON.parse(jwtTokenRedux),
                bodyFormData: bodyFormData,
              })
            );
            setLoading(false);
          } catch (error) {
            setLoading(false);
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                let isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === 999) {
                let isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                let isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen hata. Bizimle iletişime geçiniz. ");
            }
          }
        };
        submit();
      }
    });
  };
  useEffect(() => {
    try {
      dispatch(
        checkCertificateStatus({
          jwt: JSON.parse(jwtTokenRedux),
          userId: id.id,
          courseId: courseId.courseId,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, []);
  const userCertificateStatus = useSelector(
    (status) => status.AdminSlice.checkCertificateStatus
  );
  console.log(userCertificateStatus);
  return (
    <>
      <div className="flex mb-5 mt-5">
        <NavLink
          to={`/adminpanel/kullanicilar/duzenle/kursekle/${id.id}`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-10 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-[#2196f3] font-bold">
          Sertifika Detayları
        </h1>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl divide-y divide-gray-200 lg:flex lg:justify-center lg:divide-y-0 lg:divide-x lg:py-8">
          <div className="py-8 lg:w-1/3 lg:flex-none lg:py-0 grid grid-cols content-around">
            <div className="mx-auto flex max-w-xs items-center px-4 lg:max-w-none lg:px-8">
              <lord-icon
                src="https://cdn.lordicon.com/dbkviqyk.json"
                trigger="hover"
                colors="primary:#0a2e5c,secondary:#1663c7"
                stroke="70"
                style={{ width: "80px", height: "80px" }}
              ></lord-icon>
              <div className="ml-4 flex flex-auto flex-col-reverse">
                <h3 className="font-medium text-gray-900">Gerekli Belgeler</h3>
                <p className="text-sm text-gray-500">
                  Kullanıcı ayarlarında belgeleri görüntüleyebilirsiniz.
                </p>
              </div>
            </div>
            {userCertificateStatus.requiredDocuments ? (
              <div className="flex justify-center align-items items-center mt-3">
                {" "}
                <span className="text-center text-md text-gray-800">
                  Yüklendi
                </span>
                <span>
                  {" "}
                  <lord-icon
                    src="https://cdn.lordicon.com/jvihlqtw.json"
                    trigger="loop"
                    colors="primary:#16c72e,secondary:#0008ff"
                    stroke="70"
                    style={{ width: "30px", height: "30px" }}
                  ></lord-icon>
                </span>
              </div>
            ) : (
              <div className="flex justify-center align-items items-center mt-3">
                {" "}
                <span className="text-center text-md text-red-500">
                  Tamamlanmadı
                </span>
                <span>
                  {" "}
                  <lord-icon
                    src="https://cdn.lordicon.com/zlhhhefv.json"
                    trigger="loop"
                    delay="1500"
                    stroke="70"
                    style={{ width: "30px", height: "30px" }}
                  ></lord-icon>
                </span>
              </div>
            )}
          </div>

          <div className="py-8 lg:w-1/3 lg:flex-none lg:py-0 grid grid-cols content-around">
            <div className="mx-auto flex max-w-xs items-center px-4 lg:max-w-none lg:px-8">
              <lord-icon
                src="https://cdn.lordicon.com/tdxypxgp.json"
                trigger="hover"
                colors="primary:#0a2e5c,secondary:#1663c7"
                stroke="70"
                style={{ width: "70px", height: "70px" }}
              ></lord-icon>
              <div className="ml-4 flex flex-auto flex-col-reverse">
                <h3 className="font-medium text-gray-900">Videolar</h3>
                <p className="text-sm text-gray-500">
                  Kurs videoları tamamlandı mı?
                </p>
              </div>
            </div>
            {userCertificateStatus.lessons ? (
              <div className="flex justify-center align-items items-center mt-3">
                {" "}
                <span className="text-center text-md text-gray-800">
                  İzlendi
                </span>
                <span>
                  {" "}
                  <lord-icon
                    src="https://cdn.lordicon.com/jvihlqtw.json"
                    trigger="loop"
                    colors="primary:#16c72e,secondary:#0008ff"
                    stroke="70"
                    style={{ width: "30px", height: "30px" }}
                  ></lord-icon>
                </span>
              </div>
            ) : (
              <div className="flex justify-center align-items items-center mt-3">
                {" "}
                <span className="text-center text-md text-red-500">
                  Tamamlanmadı
                </span>
                <span>
                  {" "}
                  <lord-icon
                    src="https://cdn.lordicon.com/zlhhhefv.json"
                    trigger="loop"
                    delay="1500"
                    stroke="70"
                    style={{ width: "30px", height: "30px" }}
                  ></lord-icon>
                </span>
              </div>
            )}
          </div>

          <div className="py-8 lg:w-1/3 lg:flex-none lg:py-0 grid grid-cols content-around">
            <div className="mx-auto flex max-w-xs items-center px-4 lg:max-w-none lg:px-8">
              <lord-icon
                src="https://cdn.lordicon.com/cfyppusy.json"
                trigger="hover"
                stroke="100"
                delay="1500"
                colors="outline:#1663c7,primary:#0a2e5c,secondary:#ffffff"
                style={{ width: "40px", height: "40px" }}
                id="props-basic"
              ></lord-icon>
              <div className="ml-4 flex flex-auto flex-col-reverse">
                <h3 className="font-medium text-gray-900">Profil Onayı</h3>
                <p className="text-sm text-gray-500">
                  Kimlik numarası ve profil bilgileri kontrolü
                </p>
              </div>
            </div>
            {userCertificateStatus.accountVerification ? (
              <div className="flex justify-center align-items items-center mt-5">
                {" "}
                <span className="text-center text-md text-gray-800">
                  Onaylandı
                </span>
                <span>
                  {" "}
                  <lord-icon
                    src="https://cdn.lordicon.com/jvihlqtw.json"
                    trigger="loop"
                    colors="primary:#16c72e,secondary:#0008ff"
                    stroke="70"
                    style={{ width: "30px", height: "30px" }}
                  ></lord-icon>
                </span>
              </div>
            ) : (
              <div className="flex justify-center align-items items-center mt-5">
                {" "}
                <span className="text-center text-md text-gray-800">
                  Tamamlanmadı
                </span>
                <span>
                  {" "}
                  <lord-icon
                    src="https://cdn.lordicon.com/zlhhhefv.json"
                    trigger="loop"
                    delay="1500"
                    stroke="70"
                    style={{ width: "30px", height: "30px" }}
                  ></lord-icon>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {userInfo &&
        userInfo?.map((user) => (
          <div key={user.id} className="max-w-3xl divide-y lg:flex mt-20">
            <h2 className="text-md font-semibold text-left underline underline-offset-1">
              <span className="text-[#2196f3]">
                {user.firstName} {user.lastName}{" "}
              </span>
              için sertifika yükleyin:
            </h2>
          </div>
        ))}

      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 mt-10">
        <label
          htmlFor="cover-photo"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Sertifika Görseli
        </label>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-medium text-gray-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-gray-500 focus-within:ring-offset-2 hover:text-blue-500"
                >
                  <span>Dosya yükle ya da sürükleyip bırakın</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="file:hidden text-xs pl-36"
                    onChange={(e) => setCertificate(e.target.files[0])}
                  />
                </label>
              </div>
              <p className="text-xs text-gray-500">
                PNG, JPG, GIF / 10MB'a kadar
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 mt-10">
        <label
          htmlFor="cover-photo"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Açıklama
        </label>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <div className="space-y-1 text-center">
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor=""
                className="relative cursor-pointer rounded-md bg-white font-medium text-gray-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-gray-500 focus-within:ring-offset-2 hover:text-blue-500"
              >
                <input
                  type="text"
                  name="description"
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  className="block w-full border p-3 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="grid place-content-center shadow bg-grey-light text-center mt-3 text-md">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
          Yükleniyor...
        </div>
      )}

      <div className="flex justify-center mt-20 mb-20">
        <button
          onClick={(e) => handleSubmit(e)}
          type="button"
          disabled={userCertificateStatus.certificateStatus ? false : true}
          className="items-center rounded-lg shadow-xl border-2 ml-3 border-gray-300 bg-[#2196f3] text-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 hover:text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none"
        >
          Sertifika Ekle
          <TbFileCertificate className="h-5 w-5 inline-flex ml-2" />
        </button>
        <ToastContainer />
      </div>
    </>
  );
}

export default UserCertificateDetails;
