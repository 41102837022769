import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";

function EducatorCourseUpdate() {
    const id = useParams();

    return (
      <>
        <div className="flex mb-3 mt-5">
          <NavLink
            to={`/egitmenpanel/kurslarim`}
            className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
          >
            <lord-icon
              src="https://cdn.lordicon.com/zfxxckbp.json"
              trigger="loop"
              colors="primary:#4be1ec,secondary:#cb5eee"
            ></lord-icon>
            Geri
          </NavLink>
        </div>
        <h2 className="text-[#2196f3] text-center font-semibold mb-5 mt-8 text-2xl border-b">
          KURS DÜZENLE
        </h2>
        <div className="grid grid-cols-12 md:px-8 md:py-5 px-5 py-3 gap-y-10 lg:gap-x-5 md:gap-x-10 lg:divide-x divide-gray-200 mt-10 divide-y lg:divide-y-0">
          
          
          <div className="md:col-span-6 lg:col-span-4 col-span-12 max-w-lg flex justify-center align-center items-center min-w-full">
            <div className="py-8 lg:flex-none lg:py-0 ">
              <Link to={`bilgiler`}>
              <div className="grid justify-items-center items-center gap-y-5 border-2 border-gray-300 shadow-xl bg-gray-100 px-4 py-2 rounded-lg hover:bg-blue-100 cursor-pointer">
                <lord-icon
                  src="https://cdn.lordicon.com/puvaffet.json"
                  stroke="90"
                  trigger="loop"
                  delay="500"
                  colors="primary:#0a2e5c,secondary:#848484"
                  style={{ width: "70px", height: "70px" }}
                ></lord-icon>
                <span className="font-bold text-md text-center text-gray-800">
                  Kurs Bilgilerini Düzenle
                </span>
              </div>
              </Link>
            </div>
          </div>

          {/* <div className="md:col-span-6 lg:col-span-4 col-span-12 max-w-lg flex justify-center align-center items-center min-w-full">
            <div className="py-8 lg:flex-none lg:py-0">
            <Link to={`/adminpanel/kurslar/duzenle/evrak-ekle/${id.id}`}>
            <div className="grid justify-items-center items-center gap-y-5 border-2 border-gray-300 shadow-xl bg-gray-100 px-4 py-2 rounded-lg hover:bg-blue-100 cursor-pointer">
                <lord-icon
                  src="https://cdn.lordicon.com/zmenvmel.json"
                  stroke="90"
                  trigger="loop"
                  delay="1000"
                  colors="primary:#0a2e5c,secondary:#848484"
                  style={{ width: "70px", height: "70px" }}
                ></lord-icon>
                <span className="font-bold text-md text-center text-gray-800">
                  Gerekli Evrakları Belirle
                </span>
              </div>
            </Link>
            
            </div>
          </div> */}
          <div className="md:col-span-6 lg:col-span-4 col-span-12 max-w-lg flex justify-center align-center items-center min-w-full">
            <div className="py-8 lg:flex-none lg:py-0">
              <Link to={`dokumanlar`}>
              <div className="grid justify-items-center gap-y-5 border-2 border-gray-300 shadow-xl bg-gray-100 px-4 py-2 rounded-lg hover:bg-blue-100 cursor-pointer">
                <lord-icon
                  src="https://cdn.lordicon.com/ybyshbog.json"
                  stroke="90"
                  trigger="loop"
                  colors="primary:#0a2e5c,secondary:#848484"
                  style={{ width: "70px", height: "70px" }}
                ></lord-icon>
                <span className="font-bold text-md text-center text-gray-800 tracking-wider">
                  Kurs Dökümanı Ekle
                </span>
              </div>
              </Link>
            </div>
          </div>


        </div>
      </>
    );
}

export default EducatorCourseUpdate