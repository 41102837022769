import React from "react";


function VideoModal({ setModalOn, selectedData }) {

  const handleOKClick = () => {
    setModalOn(false);
  };
  console.log(selectedData);
  return (
    <>
      <div className="opacity-100 absolute inset-0 z-50 border-4">
        <div className="m-16 p-10">
          <div className="flex w-100 flex-col border-2 border-gray-400 bg-white pt-6 pb-10 sm:rounded-lg sm:pb-12 lg:py-8">
            <div className="flex justify-center px-4 sm:px-6 lg:px-8">
              <h2 className="text-2xl font-semibold tracking-wide text-[#2196f3]">
                {selectedData.name} Ders Videosu Önizleme
              </h2>
            </div>

            <div className="flex mt-3 mx-auto">
              <div>
                <iframe
                  width="580"
                  height="400"
                  className="top-0 h-100"
                  src={`${selectedData.link}`}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title="Video"
                ></iframe>
              </div>
            </div>
            <div className="mt-8 flex justify-end px-4 sm:px-6 lg:px-8">
              <button
                onClick={handleOKClick}
                type="submit"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-[#2196f3] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
              >
                Tamam
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoModal;
