import React from "react";

function PostUserDocModal({ setModalOn, action, setDocument }) {
  const handleOKClick = () => {
    setModalOn(false);
    action();
  };
  const handleCancelClick = () => {
    setModalOn(false);
  };
  return (
    <div className="opacity-100 fixed inset-0 z-50 bg-gray-500 bg-opacity-40">
      <div className="flex h-screen justify-center items-center">
        <div className="p-20 border-2 bg-[#f2f2f2] rounded-md border-gray-300 shadow-lg">
          <div className="grid place-content-center mt-10">
            <input
            onChange={(e) => setDocument(e.target.files[0])}
              type="file"
              className="block w-full p-1 text-gray-900 sm:text-sm"
            />
          </div>
          <div className="flex justify-center mt-10">
            <button
              onClick={handleOKClick}
              className="inline-flex justify-center rounded-md border bg-green-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2"
            >
              Yükle
            </button>
            <button
              onClick={handleCancelClick}
              className="rounded px-4 py-2 ml-4 text-white bg-red-600 hover:ring-2 hover:ring-red-400"
            >
              İptal
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostUserDocModal;
