import React, { useEffect, useState } from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../data/errors";
import {
  postAnnouncement,
  getCourses,
} from "../../../store/features/AdminSlice";
import axios from "axios";

function AddAnnouncment() {
  const pageNumber = 0;
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const [courseName, setCourseName] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  let bodyFormData = new FormData();
  bodyFormData.append("Title", title);
  bodyFormData.append("SubTitle", subTitle);
  bodyFormData.append("Content", content);
  bodyFormData.append("image", image);
  bodyFormData.append("courseId", courseName);

  useEffect(() => {
    dispatch(getCourses({ data: JSON.parse(jwtTokenRedux), pageNumber }));
  }, []);
  const coursesData = useSelector((state) => state.AdminSlice.adminCourses);

  const handleSubmit = () => {
    Swal.fire({
      title: "Yeni Duyuru eklensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          setLoading(true);
          try {
            await axios(`${process.env.REACT_APP_BASE_URL}/Announcement`, {
                method: "post",
                headers: {
                  accept: "text/plain",
                  Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                },
                data: bodyFormData,
              }
            );
            setLoading(false);
            Swal.fire("Eklendi!");
            navigate("/adminpanel/duyurular");
            Swal.fire("Eklendi!");
            
          } catch (error) {
            Swal.fire("Tüm zorunlu alanları doldurunuz.")
            setLoading(false);
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
              isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                 isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen hata. Bizimle iletişime geçiniz. ");
            }
          }
        };
        submit();
      }
    });
  };

  return (
    <div className="mt-10 ">
      <div>
        {!loading && (
          <form>
            <h2 className="text-blue-900 mb-10 text-center font-semibold text-2xl">
              Yeni Duyuru
            </h2>
            <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-BLUE-600">
              <label
                htmlFor="name"
                className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
              >
                BAŞLIK
              </label>
              <input
                type="text"
                name="Tittle"
                id=""
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                className="block w-full p-1 text-gray-900 sm:text-sm"
              />
            </div>
            <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-BLUE-600">
              <label
                htmlFor="name"
                className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
              >
                ALT BAŞLIK
              </label>
              <input
                onChange={(e) => setSubTitle(e.target.value)}
                value={subTitle}
                type="text"
                name="SubTittle"
                id=""
                className="block w-full p-1 text-gray-900 sm:text-sm"
              />
            </div>
            <div className="relative rounded-md border mb-10 mt-20 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-BLUE-600">
              <label
                htmlFor="name"
                className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
              >
                KURS ADI
              </label>
              <select
                onChange={(e) => setCourseName(e.target.value)}
                value={courseName}
                className="form-select block w-full p-1 text-gray-900 sm:text-sm"
              >
                <option>Seçiniz</option>
                {coursesData && coursesData?.map((item) => (
                  <option key={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="mt-10 ">
              <h2 className="text-xs pl-2 font-medium text-gray-900 mb-2 mt-20 after:content-['*'] after:ml-0.5 after:text-red-500">
                İÇERİK
              </h2>
              <CKEditor
                value={content}
                editor={Editor}
                data=""
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                }}
              />
            </div>
            <div className="mt-10 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
              <div className="flex text-sm text-gray-600 after:content-['*'] after:ml-0.5 after:text-red-500">
                <label className="relative cursor-pointer rounded-md bg-white font-medium text-[#2196f3]  ">
                  <input
                    onChange={(e) => setImage(e.target.files[0])}
                    type="file"
                    name="image"
                    id=""
                    accept="image/*"
                  />
                </label>
              </div>
            </div>
          </form>
        )}
        {loading && (
          <div className="grid place-content-center shadow bg-grey-light text-center mt-3 text-md">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
            Yükleniyor...
          </div>
        )}

        <div className="flex justify-end">
          <button
            onClick={handleSubmit}
            type="submit"
            className="rounded-md border-2 bg-blue-100 
        px-4 py-2 m-5 text-sm font-medium text-blue-900 hover:bg-indigo-200 focus:outline-none 
        focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
          >
            Yayınla
          </button>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default AddAnnouncment;
