import axios from "axios";
import { useEffect, useState, useReducer } from "react";
import { ColorRing } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2";

function EditSeo() {
  const id = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [copyright, setCopyright] = useState("");
  const [keywords, setKeywords] = useState("");
  const [loading, setLoading] = useState(false);
  const jwtToken = useSelector((state) => state.login.accessToken);
  const [seoSettings, setSeoSettings] = useState("");
  const [seoId, setSeoId] = useState("");
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1);

  useEffect(() => {
    const fetchSeoSettings = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Course/GetSeoSettingsByCourseId/${id.id}`,
          {
            headers: {
              accept: "application/json",
              Authorization: ` Bearer ${JSON.parse(jwtToken)}`,
            },
          }
        );
        setSeoSettings(response.data.data);
        setSeoId(response.data.data[0].id);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSeoSettings();
  }, [jwtToken, reducerValue]);

  const handleSubmit = () => {
    Swal.fire({
      title: "SEO bilgileri kursa eklensin mi?",
      text: "Daha sonra güncelleyebilirsiniz.",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const postSeo = async () => {
          try {
            await axios(
              `${process.env.REACT_APP_BASE_URL}/Course/AddNewSeoSettings`,
              {
                method: "post",
                headers: {
                  ContentType: "application/json-patch+json",
                  accept: "*/*",
                  Authorization: `Bearer ${JSON.parse(jwtToken)}`,
                },
                data: {
                  courseId: id.id,
                  title: title,
                  description: description,
                  keywords: keywords,
                  copyright: copyright,
                },
              }
            );
            setLoading(false);
            Swal.fire("SEO Eklendi!");
            forceUpdate();
          } catch (error) {
            setLoading(false);
            Swal.fire("Hata!");
          }
        };
        postSeo();
      }
    });
  };

  const handleUpdate = () => {
    Swal.fire({
      title: "SEO bilgileri güncellensin mi?",
      text: "Daha sonra güncelleyebilirsiniz.",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        const updateSeo = async () => {
          try {
            await axios(
              `${process.env.REACT_APP_BASE_URL}/Course/UpdateCourseSeo?id=${seoId}`,
              {
                method: "patch",
                headers: {
                  ContentType: "application/json-patch+json",
                  accept: "application/json",
                  Authorization: ` Bearer ${JSON.parse(jwtToken)}`,
                },
                data: {
                  title: title,
                  description: description,
                  keywords: keywords,
                  copyright: copyright,
                },
              }
            );
            Swal.fire("Güncellendi!");
            forceUpdate();
          } catch (error) {
            console.log(error);
            Swal.fire("Hata!");
          }
        };
        updateSeo();
      }
    });
  };

  return (
    <>
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel/kurslar/duzenle/${id.id}`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>

      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          SEO Bilgilerini Düzenle
        </h1>
      </div>
      <div className="isolate -space-y-px rounded-md shadow-sm">
        <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-slate-400">
          <label
            htmlFor="name"
            className="block text-xs font-medium text-gray-900"
          >
            Başlık
          </label>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            name="name"
            id="name"
            className="block w-full p-2 border-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="title"
          />
        </div>
        <div className="relative rounded-md rounded-t-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-slate-400">
          <label
            htmlFor="job-title"
            className="block text-xs font-medium text-gray-900"
          >
            Açıklama
          </label>
          <input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            name="job-title"
            id="job-title"
            className="block w-full p-2 border-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="description"
          />
        </div>
      </div>
      <div className="isolate -space-y-px rounded-md shadow-sm mt-5">
        <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-slate-400">
          <label
            htmlFor="name"
            className="block text-xs font-medium text-gray-900"
          >
            Anahtar Kelimeler
          </label>
          <input
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            type="text"
            name="name"
            id="name"
            className="block w-full p-2 border-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="keywords"
          />
        </div>
        <div className="relative rounded-md rounded-t-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-slate-400">
          <label
            htmlFor="job-title"
            className="block text-xs font-medium text-gray-900"
          >
            Telif Hakkı
          </label>
          <input
            value={copyright}
            onChange={(e) => setCopyright(e.target.value)}
            type="text"
            name="job-title"
            id="job-title"
            className="block w-full p-2 border-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="copyright"
          />
        </div>
      </div>
      {loading ? (
        <div className="grid place-content-center shadow bg-grey-light text-center mt-3 text-md">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
          Yükleniyor...
        </div>
      ) : (
        <div className="flex justify-center">
          <button
            type="submit"
            onClick={handleSubmit}
            className="inline-flex shadow-md items-center mx-auto rounded-md border bg-blue-100 
      px-4 py-2 m-5 text-md font-medium text-blue-900 hover:bg-blue-300 focus:outline-none 
      focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
          >
            Ekle
          </button>
        </div>
      )}

      {seoSettings &&
        seoSettings?.map((item, i) => (
          <div key={i} className="mt-10 border-t-2 pt-20 space-y-5">
            <p className="mt-3 mb-8 text-sm text-gray-500">
              Kurs Seo Bilgileri aşağıdadır. Güncellemek için kutucuğun içini
              doldurup güncelle butanına tıklayınız.
            </p>
            <div className="grid grid-cols-12">
              <div className="grid col-span-6 font-semibold italic items-center">
                Başlık
              </div>
              <div className="grid col-span-6">
                <div>
                  <label className="sr-only">Email</label>
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    name="title"
                    id="title"
                    className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    placeholder={`${item.title}`}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="grid col-span-6 font-semibold italic items-center">
                Açıklama
              </div>
              <div className="grid col-span-6">
                <div>
                  <label className="sr-only">Email</label>
                  <input
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    type="text"
                    name="description"
                    id="description"
                    className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    placeholder={`${item.description}`}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="grid col-span-6 font-semibold italic items-center">
                Anahtar Sözcükler
              </div>
              <div className="grid col-span-6">
                <div>
                  <label className="sr-only">Email</label>
                  <input
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                    type="text"
                    name="keywords"
                    id="keywords"
                    className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    placeholder={`${item.keywords}`}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="grid col-span-6 font-semibold italic items-center">
                Telif Hakkı
              </div>
              <div className="grid col-span-6">
                <div>
                  <label className="sr-only">Copyright</label>
                  <input
                    value={copyright}
                    onChange={(e) => setCopyright(e.target.value)}
                    type="text"
                    name="copyright"
                    id="copyright"
                    className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    placeholder={`${item.copyright}`}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleUpdate}
                type="submit"
                className="inline-flex shadow-md items-center rounded-md border bg-blue-100 
    px-4 py-2 m-5 text-md font-medium text-blue-900 hover:bg-blue-300 focus:outline-none 
    focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
              >
                Güncelle
              </button>
            </div>
          </div>
        ))}
    </>
  );
}

export default EditSeo;
