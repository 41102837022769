import { useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getUserConfirmAccount,
  getUserProfile,
  patchUserProfile,
} from "../../../store/features/UserSlice";
import { errors } from "../../../data/errors";
import { useNavigate } from "react-router";
import { ulkeTest } from "../../../data/country";
import { cityTest } from "../../../data/city.js";
import { townInCity } from "../../../data/townInCity.js";
import { NavLink } from "react-router-dom";

const UsersSettings = () => {
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const notify = () =>
    toast.success("Bilgileriniz güncellendi! Lütfen tekrar giriş yapınız.");
  const notifyConfirm = () =>
    toast.success("Bilgileriniz onaylandı! Teşekkürler.");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [profilPicture, setProfilPicture] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [country, setCountry] = useState("");
  const [streetName, setStreetName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [door, setDoor] = useState("");
  const [neighborhood, setNeigborhood] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [town, setTown] = useState("");
  const firstNameInput = (e) => {
    setFirstName(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^A-Za-z-ıöçğİüş" "]/gi, "");
    setFirstName(value);
  };

  const lastNameInput = (e) => {
    setLastName(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^A-Za-z-ıöçğİüş" "]/gi, "");
    setLastName(value);
  };

  const phoneNumberInput = (e) => {
    setPhone(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^0-9""]/gi, "");
    setPhone(value);
  };

  const setIdNumberInput = (e) => {
    setIdNumber(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^0-9""]/gi, "");
    setIdNumber(value);
  };

  const setZipCodeInput = (e) => {
    setZipCode(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^0-9""]/gi, "");
    setZipCode(value);
  };

  let bodyFormData = new FormData();
  bodyFormData.append("PhoneNumber", phone);
  bodyFormData.append("City", city);
  bodyFormData.append("ProfilePicture", profilPicture);
  bodyFormData.append("BirthDate", birthDate);
  bodyFormData.append("Country", country);
  bodyFormData.append("StreetName", streetName);
  bodyFormData.append("ZipCode", zipCode);
  bodyFormData.append("BuildingNumber", buildingNumber);
  bodyFormData.append("DoorNumber", door);
  bodyFormData.append("Neighborhood", neighborhood);
  bodyFormData.append("ZipCode", zipCode);
  bodyFormData.append("FirstName", firstName);
  bodyFormData.append("LastName", lastName);
  bodyFormData.append("IdentityNumber", idNumber);
  bodyFormData.append("District", town);
  bodyFormData.append("Email", email);
  const data = JSON.stringify({
    email: email,
    phone: phone,
    firstName: firstName,
    lastName: lastName,
    city: city,
    profilePicturePath: "",
    ProfilePicture: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Kullanıcı bilgileri güncellensin mi?",
      text: "(Ad-Soyad, e-mail ve kimlik numarasını güncellemek için iletişime geçiniz.)",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Kaydet",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
          try {
            await dispatch(
              patchUserProfile({ bodyFormData, jwt: JSON.parse(jwtTokenRedux) })
            );
            notify();
            await setTimeout(() => {
              navigate(`/login`);
            }, 2500);
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        response();
      }
    });
  };

  useEffect(() => {
    dispatch(getUserProfile(JSON.parse(jwtTokenRedux), data));
  }, [jwtTokenRedux, data, dispatch]);
  const userInfo = useSelector((state) => state.userSlice.usersProfile);

  const confirmAccount = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Kullanıcı bilgilerinizi onaylamak istiyor musunuz?",
      text: "(Hesabınız onaylandıktan sonra ad, soyad, tc bilgileriniz değiştirilemez!)",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Kaydet",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
          try {
            await dispatch(
              getUserConfirmAccount({
                jwt: JSON.parse(jwtTokenRedux),
                userId: userInfo[0]?.id,
              })
            );
            notifyConfirm();
          } catch (error) {
            console.log(error);
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen hata. Bizimle iletişime geçiniz.");
            }
          }
        };
        response();
      }
    });
  };
  return (
    <>
      <div className="w-[calc(100%-3.73rem)] ">
        <div className="2xl:container mx-auto space-y-3 py-3">
          <div className="mx-auto rounded-xl px-5 m-3 py-5">
            <div className="flex h-full">
              {/* Static sidebar for desktop */}

              <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
                <main className="flex flex-1 overflow-hidden">
                  <div className="flex flex-1 flex-col overflow-y-auto xl:overflow-hidden">
                    {/* Breadcrumb */}

                    <div className="flex flex-1 xl:overflow-hidden">
                      {/* Secondary sidebar */}

                      {/* Main content */}
                      <div className="flex-1 xl:overflow-y-auto ">
                        <div className="mx-auto py-1 px-3 sm:px-3 lg:py-1">
                          <div className="w-full bg-[#142850] rounded-lg">
                            <p className="text-white pl-5 text-3xl font-bold text-center">
                              Kullanıcı Ayarları
                            </p>
                          </div>
                          <div className="flex mb-5 mt-5">
                            <NavLink
                              to={`/kullanicipaneli/anasayfa`}
                              className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
                            >
                              <lord-icon
                                src="https://cdn.lordicon.com/zfxxckbp.json"
                                trigger="loop"
                                colors="primary:#4be1ec,secondary:#cb5eee"
                              ></lord-icon>
                              Geri
                            </NavLink>
                          </div>
                          <div className="flex justify-end pt-8">
                            <button
                              onClick={handleSubmit}
                              type="button"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-[#142850] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              Profilimi Güncelle
                            </button>
                            <ToastContainer />
                          </div>

                          <form className="divide-y-blue-gray-200 mt-6 space-y-8 divide-y">
                            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                              <div className="sm:col-span-6">
                                <h2 className="text-xl font-medium text-blue-gray-900">
                                  Profil
                                </h2>
                                <p className="mt-1 text-sm text-blue-gray-500">
                                  Profil bilgilerinizi güncellemek için
                                  kurumumuzla iletişime geçebilirsiniz.
                                </p>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="first-name"
                                  className="block text-sm font-medium text-blue-gray-900"
                                >
                                  Ad
                                </label>
                                <input
                                  placeholder={userInfo[0]?.firstName}
                                  value={firstName}
                                  onChange={firstNameInput}
                                  type="text"
                                  name="first-name"
                                  id="first-name"
                                  autoComplete="name"
                                  disabled={userInfo[0]?.isConfirmed}
                                  className="mt-1 px-3 block w-full rounded-md py-2 border-2 border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="last-name"
                                  className="block text-sm font-medium text-blue-gray-900"
                                >
                                  Soyad
                                </label>
                                <input
                                  placeholder={userInfo[0]?.lastName}
                                  value={lastName}
                                  onChange={lastNameInput}
                                  type="text"
                                  name="last-name"
                                  id="last-name"
                                  autoComplete="family-name"
                                  disabled={userInfo[0]?.isConfirmed}
                                  className="mt-1 block w-full px-3 rounded-md py-2 border-2 border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="last-name"
                                  className="block text-sm font-medium text-blue-gray-900"
                                >
                                  Kimlik Numarası
                                </label>
                                <input
                                  placeholder={userInfo[0]?.identityNumber}
                                  value={idNumber}
                                  onChange={setIdNumberInput}
                                  type="text"
                                  name="identity"
                                  id="identity"
                                  autoComplete="faidentity"
                                  disabled={userInfo[0]?.isConfirmed}
                                  className="mt-1 block w-full px-3 rounded-md py-2 border-2 border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="email-address"
                                  className="block text-sm font-medium text-blue-gray-900"
                                >
                                  Email adresi
                                </label>
                                <input
                                  placeholder={userInfo[0]?.email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  type="text"
                                  name="email-address"
                                  id="email-address"
                                  autoComplete="email"
                                  disabled={userInfo[0]?.isConfirmed}
                                  className="mt-1 px-3 block w-full py-2 border-2 rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>

                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="photo"
                                  className="block text-sm font-medium text-blue-gray-900"
                                >
                                  Fotoğraf
                                </label>
                                <div className="mt-1 flex items-center">
                                  <img
                                    className="inline-block h-20 w-20 rounded-full"
                                    src={userInfo[0]?.profilePicturePath}
                                    alt="profil-fotografı"
                                  />
                                  <div className="ml-4 flex">
                                    <div className="relative flex cursor-pointer shadow-xl items-center rounded-md border border-blue-gray-300 bg-white py-2 px-3 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 hover:bg-blue-gray-50">
                                      <label
                                        htmlFor="user-photo"
                                        className="pointer-events-none relative text-sm font-medium text-blue-gray-900"
                                      >
                                        <span>Değiştir </span>
                                        <span className="sr-only">
                                          {" "}
                                          Kullanıcı Fotoğraf
                                        </span>
                                      </label>
                                      <input
                                        id="user-photo"
                                        name="user-photo"
                                        onChange={(e) =>
                                          setProfilPicture(e.target.files[0])
                                        }
                                        type="file"
                                        className="absolute inset-0 h-full w-full  cursor-pointer rounded-md border-gray-300 opacity-0"
                                      />
                                    </div>
                                    <button
                                      type="button"
                                      className="ml-3 rounded-md border shadow-xl py-2 px-3 text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:border-blue-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-gray-50"
                                    >
                                      Sil
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {userInfo[0]?.isConfirmed ? (
                                <div className="flex items-center align-center text-md col-span-3 text-gray-500 font-semibold">
                                  <lord-icon
                                    src="https://cdn.lordicon.com/cfyppusy.json"
                                    trigger="hover"
                                    stroke="90"
                                    colors="outline:#1b1091,primary:#1b1091,secondary:#ffffff"
                                    style={{ width: "40px", height: "40px" }}
                                  ></lord-icon>
                                  Onaylanmış hesap.
                                </div>
                              ) : (
                                <div className="col-span-6 flex-col md:flex-col">
                                  <button
                                    id="confirmAccount"
                                    onClick={confirmAccount}
                                    type="button"
                                    className="ml-3 col-span-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Hesabı Onayla
                                  </button>
                                  <div className="text-sm italic text-gray-500 mt-2 px-1 col-span-3">
                                    Sertifikanızı alabilmek için önce kimlik
                                    bilgilerini kaydedip sonra hesabınızı
                                    onaylamanız gerekmektedir!
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
                              <div className="sm:col-span-6">
                                <h2 className="text-xl font-medium text-blue-gray-900">
                                  Kişisel Bilgiler
                                </h2>
                                <p className="mt-1 text-sm text-blue-gray-500">
                                  Kişisel bilgilerinizi bu bölümden
                                  düzenleyebilirsiniz.
                                </p>
                              </div>

                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="phone-number"
                                  className="block text-sm  font-medium text-blue-gray-900"
                                >
                                  Telefon Numarası
                                </label>
                                <input
                                  placeholder={userInfo[0]?.phoneNumber}
                                  value={phone}
                                  onChange={phoneNumberInput}
                                  maxLength={10}
                                  type="text"
                                  name="phone-number"
                                  id="phone-number"
                                  autoComplete="tel"
                                  className="mt-1 block w-full px-3 py-2 border-2 rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>

                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="email-address"
                                  className="block text-sm font-medium text-blue-gray-900"
                                >
                                  Doğum Tarihi
                                </label>
                                <span className="mt-1 block w-full px-3 py-2 border-2 rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm text-gray-400 sm:text-sm">
                                  {userInfo[0]?.birthDate?.slice(8, 10)}.
                                  {userInfo[0]?.birthDate?.slice(5, 7)}.
                                  {userInfo[0]?.birthDate?.slice(0, 4)}
                                </span>
                                <input
                                  onChange={(e) => setBirthDate(e.target.value)}
                                  min="1923-01-01"
                                  max="2022-12-31"
                                  type="date"
                                  name="birthday"
                                  id="birthday"
                                  autoComplete="date"
                                  className="mt-1 px-3 block w-full py-2 border-2 rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="country"
                                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                  Ülke
                                </label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0 w-full">
                                  <select
                                    onChange={(e) => setCountry(e.target.value)}
                                    id="country"
                                    name="country"
                                    required
                                    autoComplete="country-name"
                                    className="block w-full rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                                  >
                                    <option className="bg-blue-200">
                                      {userInfo[0]?.country}
                                    </option>
                                    {ulkeTest?.map((country) => (
                                      <option>{country.ulke}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="city"
                                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                >
                                  Şehir
                                </label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <select
                                    onChange={(e) => setCity(e.target.value)}
                                    id="city"
                                    name="city"
                                    required
                                    autoComplete="country-name"
                                    className={
                                      country === "Diğerleri"
                                        ? "hidden w-full rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                                        : "block w-full rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                                    }
                                  >
                                    <option className="bg-blue-200">
                                      {userInfo[0]?.city}
                                    </option>
                                    {cityTest
                                      ?.filter((city) =>
                                        city.ulke.includes(country)
                                      )
                                      .map((city) => (
                                        <option>{city.il}</option>
                                      ))}
                                  </select>
                                  {country === "Diğerleri" ? (
                                    <label
                                      className="text-gray-400 text-base"
                                      value
                                    >
                                      Ülke olarak "Diğerleri" seçtiğiniz için
                                      şehir seçmenize gerek yoktur.
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="town"
                                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                >
                                  İlçe
                                </label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <select
                                    onChange={(e) => setTown(e.target.value)}
                                    required
                                    id="town"
                                    name="town"
                                    autoComplete="town-name"
                                    className={
                                      country === "K.K.T.C." ||
                                      country === "Diğerleri"
                                        ? " w-full  rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1 hidden"
                                        : "block w-full  rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                                    }
                                  >
                                    <option className="bg-blue-200">
                                      {userInfo[0]?.district}
                                    </option>
                                    {townInCity
                                      ?.filter((town) => town.il.includes(city))
                                      .map((town) => (
                                        <option>{town.ilce}</option>
                                      ))}
                                  </select>
                                  {country === "Diğerleri" ? (
                                    <label
                                      className="text-gray-400 text-base"
                                      value
                                    >
                                      Ülke olarak "Diğerleri" seçtiğiniz için
                                      ilçe seçmenize gerek yoktur.
                                    </label>
                                  ) : null}
                                  {country === "K.K.T.C." ? (
                                    <label
                                      className="text-gray-400 text-base"
                                      value
                                    >
                                      Ülke olarak "K.K.T.C." seçtiğiniz için
                                      ilçe seçmenize gerek yoktur.
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="country"
                                  className="block text-sm font-medium text-blue-gray-900"
                                >
                                  Mahalle
                                </label>
                                <input
                                  placeholder={userInfo[0]?.neighborhood}
                                  onChange={(e) =>
                                    setNeigborhood(e.target.value)
                                  }
                                  id="neighborhood"
                                  name="neighborhood"
                                  autoComplete="neighborhood"
                                  className="mt-1 py-2 border-2 px-3 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                ></input>
                              </div>
                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="city"
                                  className="block text-sm font-medium text-blue-gray-900"
                                >
                                  Sokak
                                </label>
                                <input
                                  placeholder={userInfo[0]?.streetName}
                                  onChange={(e) =>
                                    setStreetName(e.target.value)
                                  }
                                  id="street"
                                  name="street"
                                  autoComplete="street"
                                  className="mt-1 py-2 border-2 px-3 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                ></input>
                              </div>
                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="street"
                                  className="block text-sm font-medium text-blue-gray-900"
                                >
                                  Apartman No
                                </label>
                                <input
                                  placeholder={userInfo[0]?.buildingNumber}
                                  onChange={(e) =>
                                    setBuildingNumber(e.target.value)
                                  }
                                  id="building"
                                  name="building"
                                  autoComplete="building-number"
                                  className="mt-1 py-2 border-2 px-3 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                ></input>
                              </div>
                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="country"
                                  className="block text-sm font-medium text-blue-gray-900"
                                >
                                  Kapı No
                                </label>
                                <input
                                  placeholder={userInfo[0]?.doorNumber}
                                  onChange={(e) => setDoor(e.target.value)}
                                  id="door-number"
                                  name="door-number"
                                  autoComplete="door-number"
                                  className="mt-1 py-2 border-2 px-3 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                ></input>
                              </div>
                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="country"
                                  className="block text-sm font-medium text-blue-gray-900"
                                >
                                  Posta Kodu
                                </label>
                                <input
                                  placeholder={userInfo[0]?.zipCode}
                                  value={zipCode}
                                  onChange={setZipCodeInput}
                                  maxLength={5}
                                  id="zip-code"
                                  name="zip-code"
                                  autoComplete="zip-code"
                                  className="mt-1 py-2 border-2 px-3 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                ></input>
                              </div>

                              <p className="text-sm text-blue-gray-500 sm:col-span-6">
                                Bu hesap{" "}
                                <time dateTime="2017-01-05T20:35:40">
                                  {userInfo[0]?.created.slice(5, 7)}.{" "}
                                  {userInfo[0]?.created.slice(8, 10)}.{" "}
                                  {userInfo[0]?.created.slice(0, 4)} tarihinde
                                  oluşturuldu.
                                </time>
                                .
                              </p>
                            </div>

                            <div className="flex justify-end pt-8">
                              <button
                                onClick={handleSubmit}
                                type="button"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-[#142850] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                              >
                                Profilimi Güncelle
                              </button>
                              <ToastContainer />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersSettings;
