import { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { getUserCourse } from "../../../store/features/UserSlice";

function UserExams() {
  const dispatch = useDispatch();
  const jwtToken = useSelector((state) => state.login.accessToken);
  const userCourses = useSelector((state) => state.userSlice.usersCourse);
  useEffect(() => {
    dispatch(getUserCourse(JSON.parse(jwtToken)));
  }, [jwtToken, dispatch]);

  return (
    <div className="w-[calc(100%-3.73rem)] px-3">
      <div className="2xl:container mx-auto space-y-3 py-3">
        <div className="grid content-center mx-auto  bg-white rounded-xl px-1.5 mb-20">
          <div className="bg-white">
            <div className="w-full bg-[#142850] rounded-lg mb-6">
              <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                Sınavlarım
              </p>
            </div>
            <div className="flex mb-5 mt-5">
              <NavLink
                to={`/kullanicipaneli/anasayfa`}
                className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
              >
                <lord-icon
                  src="https://cdn.lordicon.com/zfxxckbp.json"
                  trigger="loop"
                  colors="primary:#4be1ec,secondary:#cb5eee"
                ></lord-icon>
                Geri
              </NavLink>
            </div>
            <div class="bg-white">
              <div class="relative mx-auto max-w-7xl px-3">
                <div class="relative overflow-hidden rounded-xl bg-blue-200 py-24 px-8 shadow-2xl lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-16">
                  <div class="absolute inset-0 opacity-50 mix-blend-multiply saturate-0 filter">
                    <img
                      src="https://images.pexels.com/photos/238118/pexels-photo-238118.jpeg?auto=compress&cs=tinysrgb&w=1600"
                      alt="bilgilendirme"
                      class="h-full w-full object-cover"
                    />
                  </div>
                  <div class="relative lg:col-span-1">
                    <h2 className="text-2xl font-semibold text-[#142850]">
                      Bilgilendirme
                    </h2>
                    <blockquote class="mt-6 text-gray-900">
                      <p class="text-md sm:text-lg">
                        This app has completely transformed how we interact with
                        customers. We've seen record bookings, higher customer
                        satisfaction, and reduced churn.
                      </p>
                      <footer class="mt-6">
                        <p class="flex flex-col font-medium">
                          <span>Marie Chilvers</span>
                          <span>CEO, Workcation</span>
                        </p>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto mt-10 border-t">
              <h2 class="text-2xl font-semibold py-3 text-[#142850] text-center">
                Kurslar
              </h2>
              <ul class="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
                {userCourses &&
                  userCourses?.map((course) => (
                    <li
                      key={course.id}
                      class="relative flex space-x-6 py-6 xl:static hover:bg-blue-200 rounded-md"
                    >
                      <img
                        src="https://images.pexels.com/photos/5797903/pexels-photo-5797903.jpeg?auto=compress&cs=tinysrgb&w=1600"
                        alt=""
                        class="h-14 w-14 flex-none rounded-full"
                      />
                      <div class="flex-auto justify-between">
                        <h3 class="pr-10 font-semibold text-gray-900 xl:pr-0 cursor-pointer">
                          {course.name}
                        </h3>
                        <dl class="mt-2 flex flex-col text-gray-500 xl:flex-row">
                          <div class="flex items-start space-x-3">
                            <dt class="mt-0.5">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                              </svg>
                            </dt>
                            <dd>{course.mainCategoryName}</dd>
                          </div>
                          <div class="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                            <dt class="mt-0.5">
                            </dt>
                            <dd>{course.subCategoryName}</dd>
                          </div>
                        </dl>
                      </div>
                      <Link to={`/kullanicipaneli/sinav/${course.name.split(" ").join("")}/${course.id}`}>
                        <div className="flex justify-end items-center cursor-pointer">
                          <p className="hover:underline hover:text-blue-700">
                            Sınavlara Git
                          </p>
                          <svg
                            class="h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserExams;
