import React from "react";
import { NavLink } from "react-router-dom";
import AdminMainCat from "./AdminMainCat";
import AdminSubCats from "./AdminSubCats";

function AdminCategories() {
  return (
    <div className="mb-10">
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-2 text-black font-bold">
          Kategoriler
        </h1>
      </div>

      <AdminMainCat />
      <div className="mt-20 mb-20" aria-hidden="true"></div>
      <AdminSubCats />
    </div>
  );
}

export default AdminCategories;
