import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import useAxios from "../../../hooks/useAxios";
import axiosmain from "../../../apis/GetMainCategory";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../data/errors";
import ReactPaginate from "react-paginate";


function AdminSubCats() {
  const [subCategories, setSubCategories] = useState("");
  const [search, setSearch] = useState("");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [mainFilter, setMainFilter] = useState("");
  const [pageNumber, setPageNumber]= useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [perPage,setPerPage] = useState();
  const pageCount = Math.ceil(dataCount/perPage)



  const [main] = useAxios({
    axiosInstance: axiosmain,
    method: "GET",
  });
  const mainCategories = main.data;
  const handleClear = (e) => {
    setMainFilter("");
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };  

  const setFilter = (data) => 
  {
    setMainFilter(data);
    setPageNumber(0);
    //dispatch()
   
  };
  const setNameSearch = (data) => 
  {
    setSearch(data);
    setPageNumber(0);
      //dispatch()
     
  };

   useEffect(() => {
    const fetchSubCats = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/SubCategory?PageNumber=${pageNumber+1}&Name=${search}&MainCategoryId=${mainFilter}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            },
          }
        );
        setPerPage(10);
        
        setDataCount(response.data.metaData.dataCount);
     
        setSubCategories(response.data.data);
        
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubCats();
  }, [pageNumber,search,mainFilter]);
 
  const deleteSub = (id) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            axios
              .delete(
                `${process.env.REACT_APP_BASE_URL}/SubCategory?id=${id}`,
                {
                  headers: {
                    Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                  },
                }
              )
              .then((res) => {
                const newSubs = subCategories.filter((sub) => sub.id !== id);
                setSubCategories(newSubs);
              });
            Swal.fire("Silindi!");
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (
                error.response.data.details.errorCodes === errors[index].id
              ) {
                let isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === 999) {
                let isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                let isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        submit();
      }
    });
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 ">
        <div className="text-center">
          <h1 className="text-2xl text-gray-800 font-bold">Alt Kategoriler</h1>
        </div>
        <div className="grid grid-cols-2 gap-y-3 gap-x-7 mt-5 w-full shadow-sm">
          <div className="cols-span-1 mt-1 flex items-center border border-gray-300 rounded-lg">
            <input
              onChange={(e) => setNameSearch(e.target.value)}
              placeholder="Alt Kategori Ara..."
              type="text"
              name="search"
              id="search"
              className="block w-full h-8 p-3 rounded-md border-gray-500 pr-12 shadow-sm sm:text-sm"
            />
          </div>
          <div className="cols-span-1 border border-gray-300 rounded-lg">
            <select
              onChange={(e) => setFilter(e.target.value)}
              value={mainFilter}
              className="text-black p-1 w-full"
              placeholder="asd"
            >
              <option value="">Ana Kategori Seçin</option>
              {mainCategories?.map((main) => (
                <option key={main.id} value={main.id}>{main.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex gap-x-5 mt-2 justify-end">
          <div className="sm:items-center mt-4">
            <button
               className="items-center rounded-lg shadow-xl border-2 border-gray-200 bg-white px-4 py-2 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              onClick={(e) => handleClear(e)}
            >
              Temizle
            </button>
          </div>
          <div className="sm:flex mt-4">
            <Link to={"/adminpanel/altkategoriler/ekle"}>
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border border-gray-200 bg-[#142850] px-4 py-2 text-md font-medium hover:bg-blue-700 text-white focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-700"
              >
                Yeni Ekle +
              </button>
            </Link>
          </div>
        </div>
        <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 border border-gray-200 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Ana Kategori
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 border-r text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Alt Kategori
                </th>
              </tr>
            </thead>
            <tbody>
              {!!subCategories &&
                subCategories
                  // ?.filter((sub) => {
                  //   return search.toLowerCase() === ""
                  //     ? sub
                  //     : sub.name.toLowerCase().includes(search.toLowerCase());
                  // })
                  // .filter((sub) =>
                  //   sub.mainCategoryName
                  //     .toLocaleLowerCase("tr-TR")
                  //     .includes(mainFilter.toLocaleLowerCase("tr-TR"))
                  // )
                  .map((sub) => (
                    <tr key={sub.id}>
                      <td
                        className="border-t
                    relative py-4 pl-4 sm:pl-6 pr-3 text-sm"
                      >
                        <div className="font-medium text-gray-900">
                          {sub.mainCategoryName}
                        </div>
                      </td>
                      <td
                        className="border
                    relative py-4 pl-4 sm:pl-6 pr-3 text-sm"
                      >
                        <div className="font-medium text-gray-900">
                          {sub.name}
                        </div>
                      </td>

                      <td className="border-t space-x-6 space-y-5 relative sm:pr-12 text-right text-sm font-medium">
                        <Link
                          to={`/adminpanel/altkategoriler/duzenle/${sub.id}`}
                        >
                          <button
                            type="button"
                            className="rounded-md text-white font-light bg-[#142850] hover:bg-blue-700 px-2 py-1"
                          >
                            Düzenle
                          </button>
                        </Link>
                        <button
                          onClick={() => deleteSub(sub.id)}
                          type="button"
                          className="rounded-md text-white font-light bg-[#142850] hover:bg-blue-700 px-2 py-1"
                        >
                          Sil
                        </button>
                        <ToastContainer />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
       <div className="mt-5">
        <ReactPaginate
          forcePage = {pageNumber}
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
        />
      </div>
    </>
  );
}

export default AdminSubCats;
