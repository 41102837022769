import React, { useEffect, useState } from "react";

function Countdown({ time , action}) {
  const [timeLeft, setTimeLeft] = useState(time * 60 * 1000);

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(timeLeft - 1000);
    }, 1000);
  }, [timeLeft]);

  const getTimeLeft = (miliseconds) => {
    let total_seconds = parseInt(Math.floor(miliseconds / 1000));
    let total_minutes = parseInt(Math.floor(total_seconds / 60));

    let seconds = parseInt(total_seconds % 60);
    let minutes = parseInt(total_minutes % 60);

    if (minutes === 0 && seconds === 0) {
      action();
      return ``;
    } else {
      return `${minutes}  : ${seconds}`;
    }
  };

  return (
    <p className="text-lg italic mt-1 font-bold text-red-500">
      <strong>Kalan süre:</strong> {getTimeLeft(timeLeft)}
    </p>
  );
}

export default Countdown;
