import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import  Swal  from "sweetalert2/dist/sweetalert2";
import {BsFillArrowUpRightSquareFill} from "react-icons/bs"
import { errors } from "../../../data/errors";
import { toast } from "react-toastify";

function UserPostComment() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [rate, setRate] = useState(0);
  const [course, setCourse] = useState("");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Course/${id}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            },
          }
        );
        setCourse(response.data.data);
        console.log(course);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourse();
  }, []);

  const addComment = async (e) => {
     e.preventDefault();
     try {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/Course/Comment/AddNewCommentToCourse`,
            data: {
                courseId: id,
                comment: comment,
                rate: rate,
            },
            headers: {
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                ContentType: 'application/json-patch+json',
                accept: 'application/json',
            },
        });
        Swal.fire("Yorumunuz eklendi, teşekkürler!");
       navigate("/kullanicipaneli/yorumlarim");
     } catch (error) {
      let isEqual = false;
      for (let index = 0; index < errors.length; index++) {
        if (error.response.data.details.errorCodes === errors[index].id) {
          return toast.error(errors[index].content);
          isEqual = true;
        } else if (error.response.data.details.errorCodes === "999") {
          return toast.error(error.response.data.details.message);
          isEqual= true;
        } else {
         isEqual = false;
        }
        if (!isEqual) {
          return toast.error("Bilinmeyen bir hata! Bizimle iletişime geçiniz. ");
        }
      }

     }
  };

  return (
    <div className="w-[calc(100%-3.73rem)] ">
      <div className="2xl:container mx-auto space-y-3 py-3 ">
        <div className="mx-auto  bg-white rounded-xl px-1.5">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="w-full bg-[#142850] rounded-lg mb-6">
              <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                Yorum Yap
              </p>
            </div>
            <div className="flex mb-5 mt-5">
                <NavLink
                  to={`/kullanicipaneli/kurslarim`}
                  className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/zfxxckbp.json"
                    trigger="loop"
                    colors="primary:#4be1ec,secondary:#cb5eee"
                  ></lord-icon>
                  Geri
                </NavLink>
              </div>
            <div className="bg-white">
              <div className="mx-auto max-w-2xl py-8 px-4 sm:py-10 sm:px-6 lg:max-w-7xl">
                {!!course &&
                  course?.map((item) => (
                    <div
                      key={item.id}
                      className="relative overflow-hidden rounded-lg lg:h-96"
                    >
                      <div className="absolute inset-0">
                        <img
                          src={`${item?.courseBannerPath}`}
                          alt="kurs-görseli"
                          className="h-full w-full object-cover object-center"
                        />
                      </div>
                      <div
                        aria-hidden="true"
                        className="relative h-96 w-full lg:hidden"
                      />
                      <div
                        aria-hidden="true"
                        className="relative h-32 w-full lg:hidden"
                      />
                      <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-y-0 lg:inset-x-auto lg:w-96 lg:flex-col lg:items-start lg:rounded-tl-lg lg:rounded-br-none">
                        <div>
                          <h2 className="text-xl font-bold text-white">
                            {item?.name}
                          </h2>
                          <p className="mt-1 text-sm text-gray-300 tracking-wide">
                            Ana Kategori:{" "}
                            <strong>{item?.mainCategoryName}</strong> <br />
                            Alt KAtegori:
                            <strong> {item?.subCategoryName} </strong>
                          </p>
                        </div>
                        <NavLink
                          to={`/${item.mainCategoryName
                            .split(" ")
                            .join("-")}/${item.subCategoryName
                            .split(" ")
                            .join("-")}/${item.name.split(" ").join("-")}/${
                            item.id
                          }`}
                          state={{ id: item.id }}
                          className="mt-6 flex flex-shrink-0 items-center justify-center rounded-md border border-white border-opacity-25 bg-white bg-opacity-0 py-3 px-4 text-base font-medium text-white hover:bg-opacity-10 sm:mt-0 sm:ml-8 lg:ml-0 lg:w-full"
                        >
                          Kurs Sayfasına Git
                        </NavLink>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex mt-2 underline text-blue-700">
              <Link 
              className="flex gap-x-2"
              to="/kullanicipaneli/yorumlarim">
                Yorumlarıma Git <BsFillArrowUpRightSquareFill/>
              </Link>
            </div>

            <div className="mt-10">
              <label
                htmlFor="comment"
                className="block text-sm font-medium text-gray-700"
              >
                Deneyiminizi başkalarıyla paylaşın!
              </label>
              <ReactStars
                count={5}
                size={24}
                onChange={(e) => setRate(e)}
                value={rate}
                edit={true}
                activeColor="#ffd700"
              />
              <div className="mt-1 shadow-xl border border-gray-300 rounded-md">
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  rows={4}
                  name="comment"
                  id="comment"
                  className="p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  defaultValue={""}
                />
              </div>
            </div>
            <button
             onClick={(e) => addComment(e)}
              className="bg-[#142850] p-1 mt-5 text-white rounded-md border border-gray-500 hover:bg-blue-700 hover:ring-2 ring-offset-2"
            >
              Yorum Yap
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserPostComment;
