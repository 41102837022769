import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import UserSidebar from "../components/Dashboards/UserDashboard/UserSidebar";
import { logout } from "../store/features/LoginSlice";
import { Helmet } from "react-helmet-async";

const UserDashboard = ({ setCheckAdmin, setCheckUser }) => {
  const dispatch = useDispatch();
  const logoutHandle = async () => {
    await dispatch(logout());
    setCheckAdmin("");
    setCheckUser("");
  };
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [checkA, setCheckA] = useState();
  const checkAuth = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Auth/CheckIfAuthorized`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          },
        }
      );
      setCheckA(response.data.data);
    } catch (error) {
      logoutHandle();
    }
  };
  useEffect(() => {
    checkAuth();
  }, []);
  return (
    <>
      <Helmet>
        <title>Tüm Kullanıcı Paneli| Unvan Eğitim Kurumları</title>
        <meta
          data-react-helmet="true"
          name="description"
          content="Üniversite veya MEB onaylı sertifikalı eğitimlerimizle ihtiyaç duyduğunuz becerileri öğrenin ve kariyerinizi ilerletin. Kurslarımız, iş dünyasının taleplerine uygun olarak tasarlanmıştır ve sektördeki uzmanlar tarafından verilmektedir. İşletme, dijital pazarlama, programlama ve daha birçok alanda sertifika programlarımıza göz atın ve kariyerinizi güçlendirin."
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content="kullanıcı paneli, öğrenci paneli"
        />
        <meta
          data-react-helmet="true"
          name="copyright"
          content="© 2023 Unvan Eğitim Kurumları. Tüm hakları saklıdır. Web sitemizde yer alan tüm içerikler, telif hakkı kanunlarıyla korunmaktadır. İzinsiz kullanımı yasaktır."
        />
      </Helmet>
      <div className="bg-gray-10 dar:bg-gray-900 flex">
        <UserSidebar />
        <Outlet />
      </div>
    </>
  );
};

export default UserDashboard;
