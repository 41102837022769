import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../data/errors";
import { postAgreement } from "../../../store/features/AdminSlice";

function AddAgreements() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [info, setInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState("");
  const [IsMandatory, setMandatory] = useState();

  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const postingData = {
    name: name,
    data: info,
    version: version,
    isMandatory: IsMandatory,
  };

  const handleSubmit = () => {
    Swal.fire({
      title: "Yeni sözleşme eklensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          setLoading(true);
          try {
            await dispatch(
              postAgreement({
                jwt: JSON.parse(jwtTokenRedux),
                newData: postingData,
              })
            );
            setLoading(false);
            Swal.fire("Eklendi!");
            navigate("/adminpanel/sozlesmeler");
          } catch (response) {
            setLoading(true);
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (response.response.data.details.errorCodes === errors[index].id) {
               isEqual = true;
                return toast.error(errors[index].content);
              } else if (response.response.data.details.errorCodes === "999") {
               isEqual = true;
                return toast.error(response.response.data.details.message);
              } else {
               isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
            }
          }
        };
        submit();
      }
    });
  };

  return (
    <div>
      <div className="w-full min-h-[90vh] grid gap-x-20 gap-y-20 justify-items-center">
        <div className="w-2/3 items-start m-10 shadow-lg border-2 rounded-lg p-5">
          {!loading && (
            <form>
              <h2 className="text-blue-900 text-left font-semibold mb-5 text-2xl">
                Yeni Sözleşme
              </h2>

              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                <label
                  htmlFor="name"
                  className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900  after:content-['*'] after:ml-0.5 after:text-red-500"
                >
                  BAŞLIK
                </label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text"
                  name="Tittle"
                  id=""
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                />
              </div>
              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                <label
                  htmlFor="name"
                  className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900  after:content-['*'] after:ml-0.5 after:text-red-500"
                >
                  VERSİYON
                </label>
                <input
                  onChange={(e) => setVersion(e.target.value)}
                  value={version}
                  type="text"
                  name="Tittle"
                  id=""
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                />
              </div>

              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                <h2 className="text-base font-medium tracking-tight text-gray-600 mb-2  after:content-['*'] after:ml-0.5 after:text-red-500">
                  İçerik
                </h2>
                <CKEditor
                  data={info}
                  value={info}
                  editor={Editor}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setInfo(data);
                  }}
                />
              </div>

              <div className="mt-10 mb-5 space-y-5">
                    <h2 className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
                      Sözleşme türü
                    </h2>
                    <div>
                      <select
                        onChange={(e) => setMandatory(e.target.value)}
                        value={IsMandatory}
                        required
                        id="certificate"
                        name="certificate"
                        className="mt-1 w-48 block border rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      >
                        <option>Seçiniz</option>
                        <option value={true}>Zorunlu</option>
                        <option value={false}>İsteğe Bağlı</option>
                      </select>
                    </div>
                  </div>


            </form>
          )}
          {loading && (
            <>
              <div className="grid place-content-center shadow bg-grey-light text-center mt-3 text-md">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
                Yükleniyorr
              </div>
            </>
          )}

          <div className="flex justify-center">
            <button
              onClick={handleSubmit}
              type="submit"
              className="inline-flex shadow-xl rounded-md border-2 bg-blue-900 
            px-4 py-2 m-5 text-sm font-medium text-white hover:text-blue-900 hover:bg-blue-300 focus:outline-none 
            focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
            >
              Ekle
            </button>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAgreements;
