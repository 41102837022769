import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../../../store/features/AdminSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function AdminGroups() {
  const pageNumber = 0;
  const jwtToken = useSelector((state) => state.login.accessToken);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCourses({ data: JSON.parse(jwtToken), pageNumber }));
  }, []);
  const courses = useSelector((state) => state.AdminSlice.adminCourses);
  return (
    <>
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Gruplar
        </h1>
      </div>

      <div className="sm:flex-auto mb-5">
        <h1 className="text-xl font-semibold text-gray-900">Kurslar</h1>
        <p className="mt-2 text-sm text-gray-700">
          Grupları incelemek için bir kurs seçniz.
        </p>
      </div>

      <ul
        role="list"
        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 xl:grid-cols-3"
      >
        {courses && courses?.map((course) => (
          <NavLink to={`/adminpanel/gruplar/${course.name.split(" ").join("")}/${course.id}`}>
            <li
              key={course.id}
              className="col-span-1 flex rounded-md shadow-lg border-gray-300"
            >
              <div className="flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md">
                <img src={`${course.imagePath}`} alt="" className="h-16 w-16" />
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <div className="font-medium text-gray-900 hover:text-gray-600 truncate">
                    {course.name}
                  </div>
                  <p className="text-gray-500 text-xs truncate">
                    {course.mainCategoryName}
                  </p>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <lord-icon
                    src="https://cdn.lordicon.com/zpcieyfp.json"
                    trigger="hover"
                    stroke="100"
                    colors="primary:#121331,secondary:#1663c7"
                    style={{ width: "20px", height: "20px" }}
                  ></lord-icon>
                </div>
              </div>
            </li>
          </NavLink>
        ))}
      </ul>
    </>
  );
}

export default AdminGroups;
