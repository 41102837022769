import axios from "axios";
import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserComment } from "../../../store/features/UserSlice";
import { errors } from "../../../data/errors";
import { toast, ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const UsersComment = () => {
  const commentItems = useSelector((state) => state.userSlice.usersComment);
  const dispatch = useDispatch();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  const deleteComment = (id, e) => {
    e.preventDefault();
    Swal.fire({
      title: "Yorumunuzu silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            await axios(
              `${process.env.REACT_APP_BASE_URL}/User/Comment/RemoveCourseComment?courseCommentId=${id}`,
              {
                headers: {
                  Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                },
              }
            );
            Swal.fire("Yorumunuz silindi!");
            forceUpdate();
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
              if (!isEqual) {
                return toast.error(
                  "Bilinmeyen bir hata! Bizimle iletişime geçiniz. "
                )}
            }
          }
        };
        submit();
      }
    });
  };

  useEffect(() => {
    dispatch(getUserComment(JSON.parse(jwtTokenRedux)));
  }, [reducerValue, jwtTokenRedux, dispatch]);

  return (
    <>
      <ToastContainer />
      <div className="w-[calc(100%-3.73rem)] ">
        <div className="2xl:container mx-auto space-y-3 py-3 ">
          <div className="mx-auto  bg-white rounded-xl px-1.5">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="w-full bg-[#142850] rounded-lg mb-6">
                <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                  Yorumlarım
                </p>
              </div>
              <div className="flex mb-5 mt-5">
                <NavLink
                  to={`/kullanicipaneli/anasayfa`}
                  className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/zfxxckbp.json"
                    trigger="loop"
                    colors="primary:#4be1ec,secondary:#cb5eee"
                  ></lord-icon>
                  Geri
                </NavLink>
              </div>
              <div className="sm:flex sm:items-center"></div>
              <div className="-mx-4 mt-0 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Tarih
                      </th>

                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                      >
                        Kurs Adı
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Yorum
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Select</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {!!commentItems &&
                      commentItems?.map((plan, planIdx) => (
                        <tr key={plan?.id}>
                          <td
                            className={classNames(
                              planIdx === 0
                                ? ""
                                : "border-t border-transparent",
                              "relative py-4 pl-4 sm:pl-6  text-sm"
                            )}
                          >
                            <div className="font-medium text-gray-900">
                              {plan?.created.slice(8, 10)}.
                              {plan?.created.slice(6, 7)}.
                              {plan?.created.slice(0, 4)}
                            </div>
                            <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                              <span>{plan?.courseName}</span>
                            </div>
                            {planIdx !== 0 ? (
                              <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" />
                            ) : null}
                          </td>

                          <td
                            className={classNames(
                              planIdx === 0 ? "" : "border-t border-gray-200",
                              "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                            )}
                          >
                            {plan?.courseName}
                          </td>
                          <td
                            className={classNames(
                              planIdx === 0 ? "" : "border-t border-gray-200",
                              "px-3 py-3.5 text-sm text-gray-500"
                            )}
                          >
                            <div className="sm:hidden">{plan?.comment}</div>
                            <div className="hidden sm:block">
                              {plan?.comment}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              planIdx === 0
                                ? ""
                                : "border-t border-transparent",
                              "relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium"
                            )}
                          >
                            <button
                              type="button"
                              onClick={(e) => deleteComment(plan?.id, e)}
                              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                              disabled={plan?.isCurrent}
                            >
                              Sil<span className="sr-only">, {plan?.name}</span>
                            </button>
                            {planIdx !== 0 ? (
                              <div className="absolute right-6 left-0 -top-px h-px bg-gray-200" />
                            ) : null}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {commentItems.length === 0 ? (
                  <div>
                    <hr className="w-full" />
                    <h1 className="text-2xl tracking-tight text-[#142850] sm:text-3xl my-3 pt-2 py-4 text-center">
                      Henüz yorum yapmadınız!
                    </h1>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersComment;
