import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

function UserCourseExams() {
  const jwtToken = useSelector((state) => state.login.accessToken);
  const id = useParams();
  const [courseExam, setCourseExam] = useState("");
  useEffect(() => {
    const fetchCourseExams = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Exam/GetByCourseId/${id.courseId}`,
          {
            headers: {
              accept: "text/plain",
              Authorization: `Bearer ${JSON.parse(jwtToken)}`,
            },
          }
        );
        setCourseExam(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourseExams();
  }, [jwtToken]);

  return (
    <div className="w-[calc(100%-3.73rem)] px-3">
      <div className="2xl:container mx-auto space-y-3 py-3">
        <div className="grid content-center mx-auto  bg-white rounded-xl px-1.5 mb-20">
          <div className="bg-white">
            <div className="w-full bg-[#142850] rounded-lg mb-6">
              <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                {id.name}
              </p>
            </div>
          </div>
          <div className="flex mb-5 mt-5">
            <NavLink
              to={`/kullanicipaneli/sinavlar`}
              className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
            >
              <lord-icon
                src="https://cdn.lordicon.com/zfxxckbp.json"
                trigger="loop"
                colors="primary:#4be1ec,secondary:#cb5eee"
              ></lord-icon>
              Geri
            </NavLink>
          </div>
          <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul>
              {courseExam &&
                courseExam?.map((item) => (
                  <Link
                    target="_blank"
                    to={`/kullanicipaneli/sinav/${id.name}/${id.courseId}/${item.id}`}
                  >
                    <li
                      key={item.id}
                      className="hover:bg-slate-200 bg-slate-100"
                    >
                      <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="flex min-w-0 flex-1 items-center">
                          <div className="flex-shrink-0">
                            <img
                              className="h-12 w-12 rounded-full object-cover"
                              src="https://images.pexels.com/photos/5905522/pexels-photo-5905522.jpeg?auto=compress&cs=tinysrgb&w=1600"
                              alt="gorsel"
                            />
                          </div>
                          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                            <div>
                              <p className="truncate text-sm font-medium text-gray-600">
                                {item.name}
                              </p>
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                  />
                                </svg>

                                <span className="truncate">
                                  {item.description}
                                </span>
                              </p>
                            </div>
                            <div>
                              <p className="flex text-sm font-medium text-gray-500">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                  />
                                </svg>
                                Geçme Notu: {item.passingScore}
                              </p>
                              <p className="mt-2 font-medium flex items-center text-sm text-gray-500">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                Zaman: {item.duration} dk
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </Link>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCourseExams;
