import { Link, NavLink,useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";
import React, { useState,useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { errors } from "../../../data/errors";

function AdminUserRoles()
{
    const jwtTokenRedux = useSelector((state) => state.login.accessToken);
    const { id } = useParams();
    const [userRole, setUserRole] = useState([]);
    const [roleId ,setRoleId] = useState("");

    //const navigate = useNavigate();

    // const deleteRoleModel = {
    //   roleId: roleId,
    //   userId : id
    // };
 
    const handleClick = (roleId) => {
      console.log(roleId);
     
        Swal.fire({
          title: "Kullanıcının rolünü silmek üzeresiniz.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Vazgeç",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Evet!",
        }).then((result) => {
          if (result.isConfirmed) {
            const submit = async () => {
              try {
                //todo: bir parça yavaş
                let deleteRoleModel = {
                  roleId: roleId,
                  userId : id
                }

                 await axios({
                  method: "delete",
                  url: `${process.env.REACT_APP_BASE_URL}/Admin/User/Role/DeleteToUser`,
                  data: deleteRoleModel,
                  headers: {
                    ContentType: "application/json-patch+json",
                    accept: "text/plain",
                    Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
                  },
                });

                Swal.fire("Silindi!");

                const response = await axios(
                  `${process.env.REACT_APP_BASE_URL}/User/UserRoles?userId=${id}`,
                  {
                  headers: {
                      ContentType: "application/json-patch+json",
                      accept: "text/plain",
                      Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
                    },
                  }
                );
                setUserRole(response.data.data);
                //window.location.reload();
                //navigate(`/adminpanel/kullanicilar/duzenle/${id}/roller`);
              } 
              catch (error) {
                //todo: daha iyi bir algoritma düşünülebilir
                let isEqual = false;
                for (let index = 0; index <= errors.length; index++) {
                  if (error.response.data.details.errorCodes === errors[index].id) {
                    isEqual = true;
                    return toast.error(errors[index].content);
                  } else if (error.response.data.details.errorCodes == 999) {
                    isEqual = true;
                    return toast.error(error.response.data.details.message);
                  } else {
                    isEqual = false;
                  }
                }
                if (!isEqual) {
                  toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
                }
              }
            };
            submit();
          }
        });
      };

      useEffect(() => {
        const fetchTodos = async () => {
          const response = await axios(
            `${process.env.REACT_APP_BASE_URL}/User/UserRoles?userId=${id}`,
            {
            headers: {
                ContentType: "application/json-patch+json",
                accept: "text/plain",
                Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
              },
            }
          );
          setUserRole(response.data.data);
        };
        fetchTodos();
    },[]);


    
    return(
        <>
        <div className="flex mb-5 mt-5 ml-8 md:ml-0">
           <NavLink
             to={`/adminpanel/kullanicilar/duzenle/${id}`}
             className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
           >
             <lord-icon
               src="https://cdn.lordicon.com/zfxxckbp.json"
               trigger="loop"
               colors="primary:#4be1ec,secondary:#cb5eee"
             ></lord-icon>
             Geri
           </NavLink>
         </div>
         <div className="text-center mb-5 border-b">
         <h1 className="text-3xl md:text-4xl px-5 py-4 text-[#2196f3] font-bold">
         Kullanıcı Rolleri
        </h1>
         </div>
         <div className="sm:flex sm:items-center">
           <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none">
             <Link to={`/adminpanel/kullanicilar/duzenle/${id}/roller/add`}>
               <button
                 type="button"
                 className="items-center rounded-md border border-gray-200 px-2 py-2 text-md font-medium text-gray-700 hover:bg-blue-100 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
               >
                 Kullanıcıya Rol Ata
               </button>
             </Link>
           </div>
         </div>
         <ul
           role="list"
           className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-5"
         >
       {!!userRole &&
             userRole?.map((role) => ( 
               <li
                 key={role?.id}
                 className="col-span-1 border flex flex-col divide-y divide-gray-300 rounded-lg bg-white text-center shadow-lg"
               >
                 <div className="flex flex-1 flex-col p-8 border">
                   <img
                     className="mx-auto w-32 flex-shrink-0 rounded-full"
                     //todo: this picture have to moved assets
                     src="https://www.nicepng.com/png/detail/136-1366211_group-of-10-guys-login-user-icon-png.png"
                     alt="user"
                   />
                   <h3 className="mt-6 text-sm font-medium text-gray-900">
                     {role?.name} 
                   </h3>
                   <dl className="mt-1 flex flex-grow flex-col justify-between">
                     <dt className="sr-only">Title</dt>
                     <dt className="sr-only">Role</dt>
                     <dd className="mt-3">
                       <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                         {role?.name} 
                        
                       </span>
                     </dd>
                   </dl>
                 </div>
                 <div>
                   <div className="flex justify-center space-x-8 shadow-md">
                     <div className="flex">
                       <button
                         onClick={() => handleClick(role.id)}
                         className="rounded-md border bg-white font-medium text-[#2196f3] hover:text-blue-900 hover:scale-110"
                       >
                         Sil
                       </button>
                       <ToastContainer />
                     </div>
                   </div>
                 </div>
               </li>
             ))} 
         </ul>
       </>
    );
}
export default AdminUserRoles;