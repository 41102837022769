import React ,{useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link ,NavLink} from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../../data/errors";




function InstructorRequests (){

  const [instructorRequest, setInstructorRequest] = useState();
  const [dataCount,setDataCount] = useState();
  const [perPage,setPerPage] = useState();
  const [pageNumber,setPageNumber] = useState(0);
  const pageCount = Math.ceil(dataCount / perPage);
  const [errorMessage, setErrorMessage] = useState("Bilinmeyen bir hata oluştu. Lütfen bizimle iletisime geçiniz");

  const jwtTokenRedux = useSelector((state) => state.login.accessToken);

  useEffect(() => {
    const fetchTodos = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/Admin/Instructor/Requests?&PageNumber=${
            pageNumber + 1
          }`,
          {
          headers: {
              ContentType: "application/json-patch+json",
              accept: "text/plain",
              Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
            },
          }
        );
        setInstructorRequest(response.data.data);
        setDataCount(response.data.data.length);
        setPerPage(10);

      };
      fetchTodos();
   
   
  }, [pageNumber]);

  const confirmRequest = (e,requestId) =>
  {
    
    e.preventDefault();
    Swal.fire({
      title: "Dikkat",
      text: "Kullanıcının eğitmen talebini onaylamak üzeresiniz",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Onayla",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
                const response = await axios.post(
                  `${process.env.REACT_APP_BASE_URL}/Admin/Instructor/Requests/ChangeStatus?id=${requestId}`,
                  {
                    status : true,
                    statusMessage: "Tebrikler."
                  },
                  {
                  headers: {
                      ContentType: "application/json-patch+json",
                      accept: "text/plain",
                      Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
                    },
                  }
                )
                .then((data) => {
                    
                    Swal.fire("İşlem başarılı!")
                })
                .catch((error) => {
                    for (let index = 0; index < errors.length; index++) {
                        if (error.response.data.details.errorCodes === errors[index].id) 
                            setErrorMessage(errors[index].content.toString());
                      }
                   let message = errorMessage;
                    Swal.fire({
                        title : "İşlem Başarısız!",
                        text : message,
                        confirmButtonText: "Tamam",
                    });
                });  
        };
        response();
      }
    });
    

  
    //alert(userId);
  }


  const data =
  instructorRequest &&
  instructorRequest.map((request) => {
      return (
        <>
          <li 
          key={request.id}
          >
             {/* <Link
              to={`/adminpanel/kullanicilar/duzenle/${request.id}`}
              className="block hover:bg-gray-50"
            > */}
              <div
              
                className={`flex items-center px-4 py-4 sm:px-6 ${
                  request.status ? "bg-green-200" : "bg-red-200"
                }`}
              >
                <div className="flex min-w-0 flex-1 items-center">
              
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="truncate text-sm font-medium text-black">
                       {request.user.firstName} {request.user.lastName}
                      </p>
                      <br/>
                      <p className="text-sm text-gray-900">
                          Kullanıcı Mesajı: {request.userMessage}
                      </p>
                   
                    </div>
                    {/* <div className="hidden md:block">
                      <div>
                        
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          {request.phoneNumber}
                        </p>
                      </div>
                    </div> */}
                  </div>
                  {request.status ? <></> :
                   <div
                   className="flex mb-5 mt-5 ml-8 md:ml-0"
                   >
                     <button
                      className=" bg-red-200 flex text-lg items-center text-white hover:bg-green-900  shadow-sm rounded-full  mx-1 "
                      onClick={((e) => confirmRequest(e,request.id))}
                      >
                     <lord-icon
                       src="https://cdn.lordicon.com/sxddhwcs.json"
                       trigger="click"
                       colors="primary:#ffffff,secondary:#0a5c49"
                       style={{ width: "50px", height: "50px" }}
                       >
                     </lord-icon>
                   </button>
                   </div>
                } 
                </div>
                <div>
                </div>
              </div>
            {/* </Link>  */}
           
            <div>
             
            </div>
          </li>
        </>
      );
    });
const changePage = ({ selected }) => {
  setPageNumber(selected);
};

  return (
    <>
    <div className="flex mb-5 mt-5 ml-8 md:ml-0">
    <NavLink
      to={`/adminpanel/egitmen-islemleri`}
      className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
    >
      <lord-icon
        src="https://cdn.lordicon.com/zfxxckbp.json"
        trigger="loop"
        colors="primary:#4be1ec,secondary:#cb5eee"
      ></lord-icon>
      Geri
    </NavLink>
    </div>   

    <div className="mb-10">
        <div className="text-center mb-5 border-b">
          <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
            Eğitmen İstekleri
          </h1>
        </div>
        <div>
              <ul class="ml-3 py-2">
                <li class ="py-2 mt-1 bg-green-200">Onaylı eğitmenler</li>
                <li class ="py-2 mt-1 bg-red-200">Onay bekleyen eğitmenler</li>
              </ul>
        </div>
        <div className=" sm:justify-between grid grid-cols-4 gap-4 px-10 mb-5">
          <div className="sm:items-center  mb-5 col-span-12 md:col-span-6 mx-auto sm:mx-0">
            {/*
             <div className="mt-4 sm:mt-0 flex sm:ml-auto ">
              <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
               
                <input
                  className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md w-64"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={"Mail adresine göre ara"}
                />
              </div>
              
              <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
                <input
                  className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md w-64"
                  type="text"
                  value={identityNumber}
                  onChange={(e) => setIdentityNumber(e.target.value)}
                  placeholder={"Kimlik numarasına göre ara"}
                />
                </div>

              <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
              <input
                  className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md w-64"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder={"İsme göre ara"}
                />
                </div>
                
                <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
                <input
                  className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md w-64"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder={"Soyada göre ara"}
                />
                </div>

               

            </div> 
            */}
          </div>
         
        </div>

        <div className="overflow-hidden bg-white shadow sm:rounded-md mb-10 mx-10 md:mx-3">
          <ul role="list" className="divide-y divide-gray-200">
            {data}
          </ul>
        </div>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
        />
      </div>
      
    </>
  );
};

export default InstructorRequests;
