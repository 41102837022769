export const ulkeTest = [
  {
    ulke: "Türkiye",
    id: "1",
  },
  {
    ulke: "K.K.T.C.",
    id: "1",
  },
  {
    ulke: "Diğerleri",
    id: "1",
  },
];
