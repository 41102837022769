import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { deleteUser } from "../../../store/features/AdminSlice";

function EditUserPageButtons({ userData }) {
  const email = (userData[0]?.email)
  const { id } = useParams();
  const navigate = useNavigate();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const dispatch = useDispatch();

  const freezeUser = () => {
    Swal.fire({
      title: "Bu işlemi yapmak istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const freezeIt = async () => {
          try {
            await axios({
              method: "post",
              url: `${process.env.REACT_APP_BASE_URL}/Admin/User/ChangeUserStatus`,
              data: {
                userId: id,
              },
              headers: {
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                ContentType: "application/json-patch+json",
              },
            }).then(Swal.fire("İşlem başarılı!"));
          } catch (error) {
            console.log(error);
          }
          navigate("/adminpanel/kullanicilar");
        };
        freezeIt();
      }
    });
  };

  const handleClick = () => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu işlemin geri dönüşü yoktur!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Kullanıcıyı Sil!",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteIt = async () => {
          try {
          await dispatch(deleteUser({data: JSON.parse(jwtTokenRedux), id}))
          .then(
            Swal.fire("Silindi!", "Kullanıcı başarıyla silindi!", "success")
          );
          } catch (error) {
            console.log(error);
          }
          navigate("/adminpanel/kullanicilar");
        };
        deleteIt();
      }
    });
  };

  const mailtoUser = () => {
    Swal.fire({
      title: "Kullanıcı bilgileri mail olarak gönderilsin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const mailInfo = async () => {
          try {
            await axios({
              method: "GET",
              url: `${process.env.REACT_APP_BASE_URL}/Admin/User/SendUserDetailToUser/${email}`,
              data: {
                email: email,
              },
              headers: {
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                accept: "*/*",
              },
            }).then(Swal.fire("Gönderildi!"));
          } catch (error) {
            console.log(error);
          }
        };
        mailInfo();
      }
    });
  };

  return (
    <div className="-z-50 mb-20">
      <div className="flex justify-center mt-10">
        <span className="isolate inline-flex rounded-md shadow-lg">
          <button
            type="button"
            className="relative inline-flex items-center rounded-l-md border bg-gray-500 text-white hover:bg-blue-200 hover:text-gray-800  border-gray-300  px-4 py-2 text-sm font-medium focus:z-10 focus:outline-none"
          >
            Kullanıcı Bilgilerini SMS ile Yolla
          </button>
          <button
            onClick={freezeUser}
            type="button"
            className="relative -ml-px inline-flex items-center border border-gray-300 bg-gray-500 text-white hover:bg-blue-200 hover:text-gray-800 px-4 py-2 text-sm font-medium focus:z-10 focus:outline-none"
          >
            {userData[0]?.isActive ? "Hesabı dondur" : "Hesabı Aktifleştir"}
          </button>
          <button
            onClick={handleClick}
            type="button"
            className="relative -ml-px inline-flex items-center border border-gray-300 bg-gray-500 text-white hover:bg-blue-200 hover:text-gray-800 px-4 py-2 text-sm font-medium  focus:z-10 focus:outline-none"
          >
            Kullanıcıyı Sil
          </button>
          <button
            onClick={mailtoUser}
            type="button"
            className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-500 text-white hover:bg-blue-200 hover:text-gray-800 px-4 py-2 text-sm font-medium focus:z-10 focus:outline-none"
          >
            Kullanıcı Bilgilerini Mail ile Yolla
          </button>
        </span>
      </div>
    </div>
  );
}

export default EditUserPageButtons;
