import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../data/errors";
import {
  adminAnnouncements,
  deleteAnnouncement,
} from "../../../store/features/AdminSlice";
import ReactPaginate from "react-paginate";

function AdminAnnouncement() {
  const [pageNumber, setPageNumber] = useState(1);
  const announcementsPerPage = 10;
  const dataCount = useSelector((state) => state.AdminSlice.pageCount);
  const pageCount = Math.ceil(dataCount?.dataCount / announcementsPerPage);
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [courseName, setCourseName] = useState("");

  useEffect(() => {
    dispatch(
      adminAnnouncements({
        data: JSON.parse(jwtTokenRedux),
        pageNumber,
        search,
        courseName,
      })
    );
  }, [pageNumber, search, courseName, dispatch, jwtTokenRedux]);

  const announces = useSelector((state) => state.AdminSlice.announcements);

  const handleClick = (id) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            await dispatch(
              deleteAnnouncement({ data: JSON.parse(jwtTokenRedux), id })
            );
            dispatch(
              adminAnnouncements({
                data: JSON.parse(jwtTokenRedux),
                pageNumber,
                search,
                courseName,
              })
            );
            Swal.fire("Silindi!");
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        submit();
      }
    });
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };
  return (
    <div className="mb-20">
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Duyurular
        </h1>
      </div>

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto md:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none ">
            <Link to={"/adminpanel/duyurular/ekle"}>
              <button
                type="button"
                className="items-center rounded-lg shadow-lg border text-white border-gray-200 px-2 py-2 text-md bg-[#142850] hover:bg-blue-700 focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Yeni Ekle +
              </button>
            </Link>
          </div>
        </div>

        <div className="p-3 flex justify-around">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            placeholder="Ara..."
            className="py-2 px-4 border rounded-lg"
          />
          <input
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
            type="text"
            placeholder="Kursa göre ara..."
            className="py-2 px-4 border rounded-lg"
          />
        </div>

        <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Başlık
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Kurs
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Tarih
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Select</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {announces &&
                announces?.map((item) => (
                  <tr key={item.id}>
                    <td className="border-t relative py-4 pl-4 sm:pl-6 pr-3 text-sm">
                      <div className="font-medium text-gray-900">
                        {item.title}
                      </div>
                      <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                        <span>{item.created.slice(0, 10)}</span>
                      </div>
                    </td>

                    <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                      {item?.course?.name}
                    </td>

                    <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                      {item.created.slice(8, 10)}.{item.created.slice(5, 7)}.
                      {item.created.slice(0, 4)}
                    </td>
                    <td className="border-t relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-light">
                      <div>
                        <div className="m-2">
                          <Link to={`/adminpanel/duyurular/duzenle/${item.id}`}>
                            <button
                              type="button"
                              className="rounded-md text-white px-2 py-1 bg-[#142850] hover:bg-blue-700"
                            >
                              Düzenle
                            </button>
                          </Link>
                          <button
                            onClick={() => handleClick(item.id)}
                            type="button"
                            className="rounded-md ml-4 text-white px-2 py-1 bg-[#142850] hover:bg-blue-700"
                          >
                            Sil
                          </button>
                          <ToastContainer />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="mt-5">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminAnnouncement;
