import { useRef, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { googleAuth, signInUser } from "../../store/features/LoginSlice";
import jwt_decode from "jwt-decode";
import { getCart } from "../../store/features/denemeSlice";
import { BsLinkedin } from "react-icons/bs";
import { ColorRing } from "react-loader-spinner";
import { errors } from "../../data/errors";

import showPwdImg from "../Helper/show-password.svg";
import hidePwdImg from "../Helper/hide-password.svg";

const LoginComp = ({ checkAdmin, checkUser }) => {
  const [loading, setLoading] = useState(false);
  const notify = () => toast.success("Giriş başarılı!");
  const dispatch = useDispatch();
  const userRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [remember, setRemember] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const google = window.google;
  const [userGoogle, setUserGoogle] = useState({});
  function handleCallbackResponse(response) {
    try {
      setLoading(true);
      let userObject = jwt_decode(response.credential);
      setUserGoogle(userObject);
      dispatch(googleAuth(response.credential));
    } catch (response) {
      console.log(response);
      for (let index = 0; index < errors.length; index++) {
        if (response.response.data.details.errorCodes === errors[index].id) {
          return toast.error(errors[index].content);
        } else if (response.response.data.details.errorCodes === "999") {
          return toast.error(response.response.data.details.message);
        } else {
          return toast.error("Bilinmeyen hata. Bizimle iletişime geçiniz.");
        }
      }
    }
  }

  useEffect(() => {
    google?.accounts.id.initialize({
      client_id:
        "1046159213695-9u49fbn6b42loa1a5q7t981qbm26v6pi.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    google?.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
      text: "continue_with",
      logo_alignment: "center",
    });
  }, []);

  const userCode = useSelector((state) => state.login.error);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(
        signInUser({ password: pwd, email: user, rememberMe: remember })
      );
      await dispatch(getCart(JSON.parse(jwtTokenRedux)));
      const checkCode = userCode;
      await notify();
    } catch (error) {
      let isEqual = false;
      for (let index = 0; index < errors.length; index++) {
        isEqual = true;
        if (userCode.errorCodes === errors[index].id) {
          return toast.error(errors[index].content);
        } else if (userCode.errorCodes === "999") {
          isEqual = true;
          return toast.error(userCode.message);
        } else {
          isEqual = false;
        }
      }
      if (!isEqual) {
        toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
      }
    } finally {
      setLoading(false);
    }
  };
  let navigate = useNavigate();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);

  const navigateLogin = async () => {
    if (checkAdmin == "Admin") {
      return navigate("/adminpanel");
    } else if (checkUser == "User") {
      return navigate("/kullanicipaneli/anasayfa");
    } else {
      return navigate(1);
    }
  };
  useEffect(() => {
    navigateLogin();
  }, [navigateLogin]);

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 mb-20">
        <ToastContainer />
        <div className="w-full max-w-md space-y-8">
          <div className="mb-10">
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-[#142850] ">
              Panallere giriş yapın
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
         
              <a
                href="https://unvanakademi.com/Identity/Account/Register"
                className="nav-link font-medium text-red-500 hover:text-blue-500"
                // aria-current="page"
               
              >
                Yeni hesap oluşturun
              </a> 
            </p>
          </div>
          <div></div>

          <div className="mt-1 grid grid-cols-2 gap-5">
            <div id="signInDiv" className="col-span-1"></div>
            <div className="col-span-1">
              <div>
                <a
                  href=""
                  className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                >
                  <span className="sr-only">LinkedIn ile kayıt ol</span>
                  <BsLinkedin className="h-5 w-5" />
                </a>
              </div>
            </div>
          </div>
          <div className="relative mt-6">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-white px-2 text-gray-500">
                Ya da devam edin
              </span>
            </div>
          </div>

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email adresi
                </label>
                <input
                  id="email"
                  type="email"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  placeholder="Email adresi"
                />
              </div>
              <div class="relative flex items-center">
                <img
                  title={isRevealPwd ? "Hide password" : "Show password"}
                  src={isRevealPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  class="w-6 h-6 absolute flex right-4 mt-1.5 items-center ml-2 "
                />
                <label htmlFor="password" className="sr-only">
                  Şifre
                </label>

                <input
                  id="password"
                  type={isRevealPwd ? "text" : "password"}
                  autoComplete="off"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  minLength={6}
                  required
                  className="w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 pl-2  py-2 text-gray-900 placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm "
                  placeholder="Şifre"
                />
              </div>

              <div className=" items-center pt-5 hidden">
                <input
                  id="remember"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  onChange={() => setRemember(true)}
                  value={remember}
                />
                <label
                  htmlFor="remember"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Beni Hatırla
                </label>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                {/* <NavLink
                  to="/sifremiunuttum"
                  className="font-medium text-[#142850] hover:text-blue-500"
                >
                  Şifreni mi unuttun?
                </NavLink> */}
              </div>
            </div>
            {loading ? (
              <div className="text-center">
                <span>Giriş yapılıyor...</span>
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{ margin: "0 auto" }}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
              </div>
            ) : null}

            <div >
              <button
                type="submit"
                aria-current="page"
                className="nav-link group relative flex w-full justify-center rounded-md border border-transparent bg-[#142850] py-2 my-2 px-4 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Giriş
              </button>

               <a
                 href ="https://unvanakademi.com/"
                aria-current="page"
                className="nav-link group relative flex w-full justify-center rounded-md border border-transparent bg-[#142850] py-2 px-4 my-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Siteye Dön
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginComp;
