import React, { useEffect, useReducer } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CertificateShipping from "./CertificateShipping";
import {
  InformationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";
import { getSingleCertificate } from "../../../store/features/UserSlice";

function SingleCerificate() {
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const { id } = useParams();
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleCertificate({ jwt: JSON.parse(jwtTokenRedux), id }));
  }, [reducerValue, dispatch, jwtTokenRedux, id]);
  const certificateInfo = useSelector(
    (state) => state.userSlice.usersSingleCertificate
  );
  return (
    <div className="mx-auto flex content-center mb-20 bg-white rounded-xl px-1.5">
      <div className="m-5 flex items-center">
        {!!certificateInfo &&
          certificateInfo?.map((item) => (
            <div key={item.id} className="relative bg-white">
              <div className="flex mb-5">
                <NavLink
                  to={`/kullanicipaneli/sertifikalarim`}
                  className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/zfxxckbp.json"
                    trigger="loop"
                    colors="primary:#4be1ec,secondary:#cb5eee"
                  ></lord-icon>
                  Geri
                </NavLink>
              </div>
              <div className="bg-blue-600 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2 z-10">
                <embed
                  className="h-full w-full object-cover"
                  src={`${item.path}`}
                  alt="Sertifika"
                />
              </div>
              <div className="relative mx-auto max-w-7xl py-8 px-6 sm:py-12 lg:py-16">
                <div className="mx-auto max-w-2xl lg:mr-0 lg:ml-auto lg:w-1/2 lg:max-w-none lg:pl-10">
                  <div>
                    <div className="flex h-12 w-12 items-center justify-center rounded-md text-white">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/8681/8681457.png"
                        alt=""
                        className="mobileimage hover:scale-110"
                      />
                    </div>
                  </div>
                  <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {item.course.name}
                  </h2>
                  <p className="mt-6 text-lg text-gray-500">
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias.
                  </p>
                  <p className="text-red-500 italic mt-5 mb-3">
                    {" "}
                    Admin Mesajı: {item.description}
                  </p>
                  <div className="mt-8 overflow-hidden">
                    <dl className="-mx-8 -mt-8 flex flex-wrap">
                      <div className="flex flex-col px-8 pt-8">
                        <dt className="order-2 text-base font-medium text-gray-500">
                          Saat
                          
                        </dt>
                        <dd className="order-1 text-2xl font-bold text-[#142850] sm:text-3xl sm:tracking-tight">
                          {item.course.duration}
                        </dd>
                      </div>
                      <div className="flex flex-col px-8 pt-8">
                        <dt className="order-2 text-base font-medium text-gray-500">
                          Eğitim şekli
                        </dt>
                        <dd className="order-1 text-2xl font-bold text-[#142850] sm:text-3xl sm:tracking-tight">
                          {item.course.isRemote ? "Uzaktan" : "Yüzyüze"}
                        </dd>
                      </div>
                      <div className="flex flex-col px-8 pt-8">
                        <dt className="order-2 text-base font-medium text-gray-500">
                          Kurs Kodu
                        </dt>
                        <dd className="order-1 text-2xl font-bold text-[#142850] sm:text-3xl sm:tracking-tight">
                          {item.course.code}
                        </dd>
                      </div>
                    </dl>
                  </div>
                  {item.path == null || item.path === "" ? (
                    <>
                     <div className="flex pt-6">
                        <div className="flex-shrink-0">
                          <InformationCircleIcon
                            className="h-5 w-5 text-blue-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 flex-1 md:flex">
                          <p className="text-sm text-blue-700">
                            Daha sertifikanız çıkmadı. Sertifanız çıktıktan sonra kargo talebinde bulunabilirsiniz.
                          </p>
                        </div>
                      </div>
                    </>
                  ) :(
                    <>
                  
                    {item.isSend === false && item.isShipping ? (
                    <div className="rounded-md bg-blue-100 p-2 mt-20">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <InformationCircleIcon
                            className="h-5 w-5 text-blue-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 flex-1 md:flex">
                          <p className="text-sm text-blue-700">
                            Talebiniz alınmıştır. Sertifikanız adresinize
                            kargolanacaktır.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <CertificateShipping
                      forceUpdate={forceUpdate}
                      id={item.id}
                      address={item.shippingAddress}
                    />
                  )}
                    
                    </>
                  )}
                  
                  {item.isSend ? (
                    <div className="rounded-md bg-green-100 p-2 mt-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-5 w-5 text-green-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 flex-1">
                          <p className="text-sm font-medium text-green-800">
                            Sertifika teslim edildi.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default SingleCerificate;
