import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AddModal from "./AddModal";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../../data/errors";
import { ColorRing } from "react-loader-spinner";
import { MdOutlineClass } from "react-icons/md";



function EducatorUpdateCourseAddLesson() {
    const [lessonName, setLessonName] = useState("");
    const [lessonLink, setLessonLink] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();
    const [modalOn, setModalOn] = useState(false);
    const [choice, setChoice] = useState(false);
    const [documentType, setDocumentType] = useState("Döküman");
    const jwtTokenRedux = useSelector((state) => state.login.accessToken);
    const [loading, setLoading] = useState(false);
    let bodyFormData = new FormData();
    bodyFormData.append("Name", lessonName);
    bodyFormData.append("Link", lessonLink);
    bodyFormData.append("CourseId", id);
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/Lesson`,
          data: bodyFormData,
          headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            accept: "text/plain",
          },
        });
        Swal.fire("Eklendi!");
        navigate(`/egitmenpanel/kurslarim/${id}/dersler`);
      } catch (response) {
        setLoading(true);
        let isEqual = false;
        for (let index = 0; index < errors.length; index++) {
          if (response.response.data.details.errorCodes === errors[index].id) {
            let isEqual = true;
            return toast.error(errors[index].content);
          } else if (response.response.data.details.errorCodes === "999") {
            let isEqual = true;
            return toast.error(response.response.data.details.message);
          } else {
            let isEqual = false;
          }
        }
        if (!isEqual) {
          toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
        }
      } finally {
        setLoading(false);
      }
    };
  
    const sweetAlert = (e) => {
      e.preventDefault();
      // const swalWithBootstrapButtons = Swal.mixin({
      //   customClass: {
      //     confirmButton: "bg-green-400 px-8 py-3 rounded-md ml-3",
      //     cancelButton: "bg-red-400 px-8 py-3 rounded-md",
      //   },
      //   buttonsStyling: false,
      // });
  
      Swal.fire({
          title: "İşlemi gerçekleştirmek istediğinizden emin misin?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            handleSubmit(e);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire({
              title: "İşlem iptal edildi!",
              icon: "error",
            });
          }
        });
    };
    return (
      <>
      
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/kurslarim/dersler/${id}`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
        <ToastContainer />
        <div className="items-start m-10">
          <form onSubmit={(e) => sweetAlert(e)}>
            <h2 className="text-green-500 text-center font-semibold mb-5 text-3xl">
              Yeni Ders Ekle
            </h2>
            <div className="flex justify-center mb-8">
              <MdOutlineClass className="w-10 h-10" />
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 after:content-['*'] after:ml-0.5 after:text-red-500"
              >
                Ders Adı :
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    value={lessonName}
                    onChange={(e) => setLessonName(e.target.value)}
                    type="text"
                    name="lesson-name"
                    id="lesson-name"
                    required
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="lastname"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 after:content-['*'] after:ml-0.5 after:text-red-500"
              >
                Link :
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <textarea
                    value={lessonLink}
                    onChange={(e) => setLessonLink(e.target.value)}
                    rows={8}
                    required
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
  
            {loading ? (
              <div className="text-center">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{ margin: "0 auto" }}
                  wrapperClass="blocks-wrapper"
                  colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                />
                Ekleniyor...
              </div>
            ) : (
              <div className="pt-5">
                <div className="flex justify-end">
                  <Link
                    to={`/egitmenpanel/kurslarim/${id}/dersler`}
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Geri Dön
                  </Link>
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
        {modalOn && (
          <AddModal
            setModalOn={setModalOn}
            setChoice={setChoice}
            setType={setDocumentType}
            action={(e) => handleSubmit(e)}
          />
        )}
      </>
    );
}

export default EducatorUpdateCourseAddLesson