import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams} from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ExamResults from "./ExamResults";
import { ColorRing } from "react-loader-spinner";
import Countdown from "./Countdown";

function ExamPage() {
  const [exam, setExam] = useState("");
  const id = useParams();
  const jwtToken = useSelector((state) => state.login.accessToken);
  const [answers, setAnswers] = useState({});
  const [result, setResult] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChangeInput = (e, id) => {
    let temp = answers;
    temp[e.target.value] = id;
    setAnswers(temp);
  };

  const handleUserAnswers = () => {
    const finalAnswers = Object.entries(answers).map((row) => {
      return { questionId: row[0], answerId: row[1] };
    });
    Swal.fire({
      title: "Cevaplar gönderilsin mi?",
      text: "Bu işlemi onayladıktan sonra geri dönemezsiniz.",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Gönder ve Sınavı Bitir",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const response = async () => {
          try {
            const response = await axios(
              `${process.env.REACT_APP_BASE_URL}/Exam/PostUserAnswers`,
              {
                method: "post",
                headers: {
                  "Content-Type": "application/json-patch+json",
                  accept: "text/plain",
                  Authorization: `Bearer ${JSON.parse(jwtToken)}`,
                },
                data: JSON.stringify(finalAnswers),
              }
            );
            setResult(response.data.data);
            setSuccess(response.data.details.success);
            setLoading(false);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Cevaplarınız gönderildi",
              showConfirmButton: true,
              timer: 1500,
            });
          } catch (error) {
            console.log(error);
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Hata!",
            });
          }
        };
        response();
      }
    });
  };

  const handleUserAnswersTimeOut = () => {
    const finalAnswers = Object.entries(answers).map((row) => {
      return { questionId: row[0], answerId: row[1] };
    });
    setLoading(true);
    const response = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Exam/PostUserAnswers`,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json-patch+json",
              accept: "text/plain",
              Authorization: `Bearer ${JSON.parse(jwtToken)}`,
            },
            data: JSON.stringify(finalAnswers),
          }
        );
        setResult(response.data.data);
        setSuccess(response.data.details.success);
        setLoading(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Cevaplarınız gönderildi",
          showConfirmButton: true,
          timer: 1500,
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Hata!",
        });
      }
    };
    response();
  };

  useEffect((e) => {
    setLoading(true);
    const fetchExamQuestions = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Exam/GetQuestionsByExamId?examId=${id.examId}`,
          {
            headers: {
              accept: "text/plain",
              Authorization: `Bearer ${JSON.parse(jwtToken)}`,
            },
          }
        );
        setExam(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchExamQuestions();
  }, []);

  return (
    <div className="w-[calc(100%-3.73rem)] px-3">
      {loading ? (
        <div className="text-center m-20">
          <span>Yükleniyor...</span>
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{ margin: "0 auto" }}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      ) : (
        <div className="2xl:container mx-auto space-y-3 py-3">
          {success ? (
            <ExamResults result={result} />
          ) : (
            <div className="grid content-center mx-auto  bg-white rounded-xl px-1.5">
              {exam &&
                exam?.map((item) => (
                  <div key={item.id} className="bg-white">
                    <div className="w-full bg-[#142850] rounded-lg mb-6">
                      <p className="text-white pl-5 text-4xl py-1  font-bold	text-center">
                        {item.name}
                      </p>
                    </div>
                    <h1 className="text-center text-2xl sm:text-4xl font-bold text-[#142850]">
                      Sorular
                    </h1>
                    <p className="italic text-lg font-medium px-2 text-gray-500 mt-2 mb-2">
                      <strong> Not:</strong> Yanlış cevaplar doğru cevapları
                      götürmeyecektir. Boş bırakmak istediğiniz sorulara hiç
                      işaretleme yapmayınız.
                    </p>
                    <div className="px-3 py-1 mb-5 mt-3">
                      <p className="text-md italic">
                        <strong>Sınav Süresi:</strong> {item.duration} dakika
                      </p>
                      <p className="text-md italic">
                        <strong>Açıklamalar:</strong> {item.description}
                      </p>
                      <Countdown
                        time={item.duration}
                        action={handleUserAnswersTimeOut}
                      />
                    </div>
                    {item.questions?.map((question) => (
                      <div
                        key={question.id}
                        className="flex justify-start px-5 py-2 mb-10 bg-blue-100 rounded-lg"
                      >
                        <div className="gap-y-5">
                          <p className="font-medium text-md">
                            <strong>Soru:</strong> {question.text}
                          </p>
                          <div className="mt-4 mb-20">
                            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-20">
                              {question.answers?.map((answer) => (
                                <div
                                  key={answer.id}
                                  className="flex items-center"
                                >
                                  <input
                                    name={question.id}
                                    id={answer.id}
                                    value={question.id}
                                    onChange={(e) => {
                                      onChangeInput(e, answer.id);
                                    }}
                                    type="radio"
                                    className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500"
                                  />
                                  <label
                                    htmlFor="answertext"
                                    className="ml-3 block text-sm font-medium text-gray-700"
                                  >
                                    {answer.text}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="flex justify-end mb-10">
                      <button
                        onClick={handleUserAnswers}
                        className="inline-flex shadow-xl items-center mx-auto rounded-md border-2 bg-[#142850]
                   px-4 py-2 m-5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none"
                      >
                        Gönder
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ExamPage;
