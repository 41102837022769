import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  agreements: "",
  deleteAgreements: "",
  postAgreement: "",
  announcements: "",
  deleteAnnouncement: "",
  adminCourses: "",
  postCourse: "",
  pageCount: 0,
  deleteCourse: "",
  users: "",
  deleteUser: "",
  shopping: "",
  payment: "",
  documents: "",
  postDocument: "",
  roles: "",
  deleteRole: "",
  fAQ: "",
  postFAQ: "",
  lessons: "",
  lesson: "",
  patchLesson: "",
  deleteLesson: "",
  getMainCategories: "",
  getSubCategoriesByMainId: "",
  getCourseDocuments: "",
  addCertificateToUser: "",
  addCourseDocument: "",
  getUserDocuments: "",
  postUserDocument: "",
  checkCertificateStatus: "",
  getAllGroup: "",
};
export const getAgreement = createAsyncThunk("getAgreements", async (data) => {
  try {
    const response = await axios(
      `${process.env.REACT_APP_BASE_URL}/Agreement`,
      {
        headers: {
          accept: "text/plain",
          Authorization: `Bearer ${data}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
});

export const deleteAgreement = createAsyncThunk(
  "deleteAgreement",
  async ({ data, id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/Agreement?id=${id}`,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const postAgreement = createAsyncThunk(
  "postAgreement",
  async ({ jwt, newData }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Agreement`,
        {
          method: "post",
          headers: {
            ContentType: "application/json-patch+json",
            accept: "text/plain",
            Authorization: `Bearer ${jwt}`,
          },
          data: newData,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const adminAnnouncements = createAsyncThunk(
  "adminAnnouncements",
  async ({ data, pageNumber , search, courseName}) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Announcement?Title=${search}&?&?CourseName=${courseName}&PageNumber=${
          pageNumber}`,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteAnnouncement = createAsyncThunk(
  "deleteAnnouncement",
  async ({ data, id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/Announcement?id=${id}`,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);



export const getCourses = createAsyncThunk(
  "getCourses",
  async ({ data, pageNumber, search }) => {
    try {
   
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Admin/Course/GetAll?Name=${search}&PageNumber=${pageNumber + 1}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteCourse = createAsyncThunk(
  "deleteCourse",
  async ({ data, id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/Course?id=${id}`,
        {
          headers: {
            ContentType: "application/json-patch+json",
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
export const postCourse = createAsyncThunk(
  "postCourse",
  async ({ jwt, bodyFormData }) => {
    try {
      const response = await axios(`${process.env.REACT_APP_BASE_URL}/Course`, {
        method: "post",
        headers: {
          Authorization: `Bearer ${jwt}`,
          accept: "application/json",
          ContentType: "multipart/form-data",
        },
        data: bodyFormData,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUsers = createAsyncThunk(
  "getUsers",
  async ({ data, pageNumber }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Admin/User/GetAllUser?pageNumber=${
          pageNumber + 1
        }`,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
export const deleteUser = createAsyncThunk(
  "deleteUsers",
  async ({ data, id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/Admin/DeleteUser`,
        {
          data: {
            userId: id,
          },
          headers: {
            ContentType: "application/json-patch+json",
            accept: "*/*",
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const getShopping = createAsyncThunk(
  "getShopping",
  async ({ data, pageNumber }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Shopping?pageNumber=${
          pageNumber + 1
        }`,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
export const getPayment = createAsyncThunk(
  "getPayment",
  async ({ data, pageNumber }) => {
    try {
      const response = await axios(
        `${
          process.env.REACT_APP_BASE_URL
        }/Admin/Payment/GetAllPayments?PageNumber=${pageNumber + 1}`,
        {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
export const getDocuments = createAsyncThunk(
  "getDocuments",
  async ({ data, pageNumber }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Document?pageNumber=${
          pageNumber + 1
        }`,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const postDocument = createAsyncThunk(
  "postDocument",
  async ({ jwt, bodyFormData }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Document`,
        {
          method: "post",
          headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
            accept: "text/plain",
          },
          data: bodyFormData,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const getRoles = createAsyncThunk("getRoles", async (data) => {
  try {
    const response = await axios(
      `${process.env.REACT_APP_BASE_URL}/Admin/Role/GetAll`,
      {
        headers: {
          accept: "text/plain",
          Authorization: `Bearer ${data}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
});
export const deleteRole = createAsyncThunk(
  "deleteRole",
  async ({ data, id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/Admin/Role/Delete?id=${id}`,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
export const getFAQ = createAsyncThunk(
  "getFAQ",
  async ({ jwt, pageNumber }) => {
    try {
      const response = await axios(
        `${
          process.env.REACT_APP_BASE_URL
        }/FrequentlyAskedQuestions?PageNumber=${pageNumber + 1}`,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const postFAQ = createAsyncThunk(
  "postFaQ",
  async ({ data, question, answer, contentTypes }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/FrequentlyAskedQuestions?Question=${question}&Answer=${answer}&ContentTypes=${contentTypes}`,
        {
          method: "post",
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${data}`,
          },
          data: {
            question: question,
            answer: answer,
            contentTypes: contentTypes,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error)
      return error;
    }
  }
);

export const getLessons = createAsyncThunk(
  "getLessons",
  async ({ jwt, pageNumber }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Lesson?PageNumber=${pageNumber + 1}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${jwt}`,
            accept: "text/plain",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const lessonGetById = createAsyncThunk(
  "lessonGetById",
  async ({ jwt, id }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Lesson/${id}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${jwt}`,
            accept: "text/plain",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const patchLesson = createAsyncThunk(
  "patchLesson",
  async ({ jwt, bodyFormData, id }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Lesson?id=${id}`,
        {
          method: "patch",
          headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
            accept: "text/plain",
          },
          data: bodyFormData,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteLesson = createAsyncThunk(
  "deleteLesson",
  async ({ jwt, id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/Lesson?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            accept: "text/plain",
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const getMainCategories = createAsyncThunk(
  "getMainCategories",
  async (jwt) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/MainCategory`,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const getSubCategoriesByMainId = createAsyncThunk(
  "getSubCategoriesByMainId",
  async ({ jwt, mainCategoryId }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/SubCategory?MainCategoryId=${mainCategoryId}`,
        {
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const getCourseDocuments = createAsyncThunk(
  "getCourseDocuments",
  async ({ jwt, courseId }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Course/Document/GetByCourseId?courseId=${courseId}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const addCertificateToUser = createAsyncThunk(
  "addCertificateToUser",
  async ({ jwt, bodyFormData }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Admin/User/Certificate/AddToUser`,
        {
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
          data: bodyFormData,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addCourseDocument = createAsyncThunk(
  "addCourseDocument",
  async ({ jwt, bodyFormData }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Course/Document/AddToCourse`,
        {
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
            accept: "application/json",
          },
          data: bodyFormData,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserDocuments = createAsyncThunk(
  "getUserDocuments",
  async ({ jwt, userId }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Admin/User/GetUserDocuments?userId=${userId}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${jwt}`,
            accept: "*/*",
          },
          data: {
            userId: userId,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const postUserDocument = createAsyncThunk(
  "postUserDocument",
  async ({ jwt, bodyFormData }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Admin/User/RegisterDocument`,
        {
          method: "post",
          headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
            accept: "*/*",
          },
          data: bodyFormData,
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const checkCertificateStatus = createAsyncThunk(
  "checkCertificateStatus",
  async ({ jwt, userId, courseId }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Admin/User/CheckCertificateStatus?userId=${userId}&courseId=${courseId}`,
        {
          method: "get",
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const getAllGroup = createAsyncThunk(
  "getAllGroup",
  async ({ jwt, courseId }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Group?CourseId=${courseId}`,
        {
          method: "get",
          headers: {
            accept: "text/plain",
            Authorization: `Bearer  ${jwt}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const AdminSlice = createSlice({
  name: "adminPanelData",
  initialState,
  reducers: {},
  extraReducers: {
    [getAgreement.pending]: (state, action) => {},
    [getAgreement.fulfilled]: (state, { payload: { data } }) => {
      state.agreements = data;
    },
    [getAgreement.rejected]: (state, action) => {},

    [adminAnnouncements.pending]: (state, action) => {},
    [adminAnnouncements.fulfilled]: (
      state,
      { payload: { data, metaData } }
    ) => {
      state.announcements = data;
      state.pageCount = metaData;
    },
    [adminAnnouncements.rejected]: (state, action) => {},

    [getCourses.pending]: (state, action) => {},
    [getCourses.fulfilled]: (state, { payload: { data, metaData } }) => {
      state.adminCourses = data;
      state.pageCount = metaData;
    },
    [getCourses.rejected]: (state, action) => {},

    [postCourse.pending]: (state, action) => {
      state.loading = false;
    },
    [postCourse.fulfilled]: (state, { payload: { data, details } }) => {
      state.loading = true;
      state.error = details.success;

      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.postFAQ = data;
      }
    },
    [postCourse.rejected]: (state, action) => {
      state.loading = true;
    },

    [getUsers.pending]: (state, action) => {},
    [getUsers.fulfilled]: (state, { payload: { data, metaData } }) => {
      state.users = data;
      state.pageCount = metaData;
    },
    [getUsers.rejected]: (state, action) => {},

    [getShopping.pending]: (state, action) => {},
    [getShopping.fulfilled]: (state, { payload: { data, metaData } }) => {
      state.shopping = data;
      state.pageCount = metaData;
    },
    [getShopping.rejected]: (state, action) => {},

    [getPayment.pending]: (state, action) => {},
    [getPayment.fulfilled]: (state, { payload: { data, metaData } }) => {
      state.payment = data;
      state.pageCount = metaData;
    },
    [getPayment.rejected]: (state, action) => {},

    [getDocuments.pending]: (state, action) => {},
    [getDocuments.fulfilled]: (state, { payload: { data, metaData } }) => {
      state.documents = data;
      state.pageCount = metaData;
    },
    [getDocuments.rejected]: (state, action) => {},

    [postDocument.pending]: (state, action) => {
      state.loading = false;
    },
    [postDocument.fulfilled]: (state, { payload: { data, details } }) => {
      state.loading = true;
      state.error = details.success;

      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.postDocument = data;
      }
    },
    [postDocument.rejected]: (state, action) => {
      state.loading = true;
    },

    [getRoles.pending]: (state, action) => {},
    [getRoles.fulfilled]: (state, { payload: { data, metaData } }) => {
      state.roles = data;
      state.pageCount = metaData;
    },
    [getRoles.rejected]: (state, action) => {},

    [getFAQ.pending]: (state, action) => {},
    [getFAQ.fulfilled]: (state, { payload: { data, metaData } }) => {
      state.fAQ = data;
      state.pageCount = metaData;
    },
    [getFAQ.rejected]: (state, action) => {},

    [postFAQ.pending]: (state, action) => {
      state.loading = false;
    },
    [postFAQ.fulfilled]: (state, { payload: { data, details } }) => {
      state.loading = true;
      state.error = details.success;
      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.postFAQ = data;
      }
    },
    [postFAQ.rejected]: (state, action) => {
      state.loading = true;
    },

    [getLessons.pending]: (state, action) => {},
    [getLessons.fulfilled]: (state, { payload: { data, metaData } }) => {
      state.lessons = data;
      state.pageCount = metaData;
    },
    [getLessons.rejected]: (state, action) => {},

    [lessonGetById.pending]: (state, action) => {},
    [lessonGetById.fulfilled]: (state, { payload: { data } }) => {
      state.lesson = data;
    },
    [lessonGetById.rejected]: (state, action) => {},

    [patchLesson.pending]: (state, action) => {
      state.loading = false;
    },
    [patchLesson.fulfilled]: (state, { payload: { details, data } }) => {
      state.loading = true;
      state.error = details.success;

      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.patchLesson = data;
      }
    },
    [patchLesson.rejected]: (state, action) => {
      state.loading = true;
    },

    [postAgreement.pending]: (state, action) => {
      state.loading = false;
    },
    [postAgreement.fulfilled]: (state, { payload: { data, details } }) => {
      state.loading = true;
      state.error = details.success;

      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.postAgreement = data;
      }
    },
    [postAgreement.rejected]: (state, action) => {
      state.loading = true;
    },

    [getMainCategories.pending]: (state, action) => {},
    [getMainCategories.fulfilled]: (state, { payload: { data } }) => {
      state.getMainCategories = data;
    },
    [getMainCategories.rejected]: (state, action) => {},

    [getSubCategoriesByMainId.pending]: (state, action) => {},
    [getSubCategoriesByMainId.fulfilled]: (state, { payload: { data } }) => {
      state.getSubCategoriesByMainId = data;
    },
    [getSubCategoriesByMainId.rejected]: (state, action) => {},

    [getCourseDocuments.pending]: (state, action) => {},
    [getCourseDocuments.fulfilled]: (state, { payload: { data } }) => {
      state.getCourseDocuments = data;
    },
    [getCourseDocuments.rejected]: (state, action) => {},

    [addCertificateToUser.pending]: (state, action) => {},
    [addCertificateToUser.fulfilled]: (state, { payload: { data } }) => {
      state.addCertificateToUser = data;
    },
    [addCertificateToUser.rejected]: (state, action) => {},

    [addCourseDocument.pending]: (state, action) => {},
    [addCourseDocument.fulfilled]: (state, { payload: { data } }) => {
      state.addCourseDocument = data;
    },
    [addCourseDocument.rejected]: (state, action) => {},

    [getUserDocuments.pending]: (state, action) => {},
    [getUserDocuments.fulfilled]: (state, { payload: { data } }) => {
      state.getUserDocuments = data;
    },
    [getUserDocuments.rejected]: (state, action) => {},

    [postUserDocument.pending]: (state, action) => {},
    [postUserDocument.fulfilled]: (state, { payload: { data } }) => {
      state.postUserDocument = data;
    },
    [postUserDocument.rejected]: (state, action) => {},

    [checkCertificateStatus.pending]: (state, action) => {},
    [checkCertificateStatus.fulfilled]: (state, { payload: { data } }) => {
      state.checkCertificateStatus = data;
    },
    [checkCertificateStatus.rejected]: (state, action) => {},

    [getAllGroup.pending]: (state, action) => {},
    [getAllGroup.fulfilled]: (state, { payload: { data } }) => {
      state.getAllGroup = data;
    },
    [getAllGroup.rejected]: (state, action) => {},
  },
});

export default AdminSlice.reducer;
