import React from "react";
import { Link, NavLink } from "react-router-dom";
import { BsLink45Deg } from "react-icons/bs";

function EducatorLessons() {
  return (
    <div className="mb-20">
    <div className="flex mb-5 mt-5 ml-8 md:ml-0">
      <NavLink
        to={`/egitmenpanel`}
        className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
      >
        <lord-icon
          src="https://cdn.lordicon.com/zfxxckbp.json"
          trigger="loop"
          colors="primary:#4be1ec,secondary:#cb5eee"
        ></lord-icon>
        Geri
      </NavLink>
    </div>

    <div className="text-center mb-5 border-b">
      <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
        Dersler
      </h1>
    </div>

    <div className="flex mx-auto px-4 sm:px-6 lg:px-8 mt-3 rounded-lg items-center w-full">
      <input
        placeholder="Kursa göre ara..."
        type="text"
        name="search"
        id="search"
        className="block w-full h-8 p-3 rounded-md border border-gray-500 pr-12 shadow-sm sm:text-sm"
      />
    </div>

    <div className="px-4 sm:px-6 lg:px-8">
      <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Kurs Adı
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Ders
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell gap-x-1"
              >
                <div className="flex items-center">
                  Link <BsLink45Deg />
                </div>
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Seç</span>
              </th>
            </tr>
          </thead>
          <tbody>
                  <tr>
                    <td className="border-t relative py-4 pl-4 sm:pl-6 pr-3 text-sm">
                      <div className="font-medium text-gray-900">
                       Kurs 1
                      </div>
                      <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                        <span>Ders 1</span>
                      </div>
                    </td>

                    <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                      Ders Adı
                    </td>

                    <td
                      className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell hover:cursor-pointer hover:text-blue-700"
                    >
                      Link
                    </td>
                    <td className="border-t relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-light">
                      <div>
                        <div className="m-2">
                          <Link
                            to={`/egitmenpanel`}
                          >
                            <button
                              type="button"
                              className="rounded-md text-white bg-[#142850]  hover:bg-blue-600 px-2 py-1"
                            >
                              Düzenle
                            </button>
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default EducatorLessons