import { configureStore } from "@reduxjs/toolkit";
import LoginSlice from "./features/LoginSlice";
import CartSlice from "./features/CartSlice";
import denemeSlice from "./features/denemeSlice";
import UserSlice from "./features/UserSlice";
import AdminSlice from "./features/AdminSlice";

const store = configureStore({
  reducer: {
    login: LoginSlice,
    cart: CartSlice,
    deneme: denemeSlice,
    userSlice: UserSlice,
    AdminSlice: AdminSlice,
  },
});

export default store;
