import React, { useState, useEffect } from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../data/errors";

function AddAnnouncment() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  let bodyFormData = new FormData();
  bodyFormData.append("Title", title);
  bodyFormData.append("SubTitle", subTitle);
  bodyFormData.append("Content", content);
  bodyFormData.append("Image", image);

  useEffect(() => {
    const fetchAnnounces = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Announcement/${id}`,
          {
            headers: {
              accept: "text/plain",
            },
          }
        );
        setTitle(response.data.data[0].title);
        setSubTitle(response.data.data[0].subTitle);
        setContent(response.data.data[0].content);
        setImage(response.data.data[0].imagePath);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAnnounces();
  }, []);

  const handleSubmit = () => {
    Swal.fire({
      title: "Duyuru güncellensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          setLoading(true);
          try {
            await axios({
              method: "patch",
              url: `${process.env.REACT_APP_BASE_URL}/Announcement?id=${id}`,
              data: bodyFormData,
              headers: {
                accept: "text/plain",
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                ContentType: "multipart/form-data",
              },
            });
            setLoading(false);
            Swal.fire("Güncellendi!");
            navigate("/adminpanel/duyurular");
          } catch (error) {
            setLoading(false);
            let isEqual =false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
               let isEqual = true;
                return toast.error(errors[index].content);
              } else if(error.response.data.details.errorCodes === 999) {
                let isEqual=true;
                return toast.error(error.response.data.details.message);
              } 
              else {
                let isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
            }
          }
        };
        submit();
      }
    });
  };

  return (
    <div className="w-full grid gap-x-20 gap-y-20 justify-items-center">
      <div className="items-start m-10 shadow-lg border-2 rounded-lg p-5">
        {!loading && (
          <form>
            <h2 className="text-blue-900 text-left font-semibold mb-5 text-2xl">
              Duyuru Düzenle
            </h2>
            <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-BLUE-600">
              <label
                htmlFor="name"
                className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
              >
                BAŞLIK
              </label>
              <input
                type="text"
                name="Tittle"
                id=""
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                className="block w-full p-1 text-gray-900 sm:text-sm"
              />
            </div>
            <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-BLUE-600">
              <label
                htmlFor="name"
                className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
              >
                ALT BAŞLIK
              </label>
              <input
                onChange={(e) => setSubTitle(e.target.value)}
                value={subTitle}
                type="text"
                name="SubTittle"
                id=""
                className="block w-full p-1 text-gray-900 sm:text-sm"
              />
            </div>

            <CKEditor
              data={content}
              value={content}
              editor={Editor}
              onReady={(editor) => {}}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
            <div className="relative rounded-md mb-10 border-gray-300 px-3 py-2">
              <img src={image} alt="Duyuru-görseli" className="h-96" />
              <input
                onChange={(e) => setImage(e.target.files[0])}
                type="file"
                name="image"
                id=""
                className="block w-full text-gray-900 sm:text-sm mt-2"
              />
            </div>
          </form>
        )}
        {loading && (
          <div className="grid place-content-center shadow bg-grey-light text-center mt-3 text-md">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
            Yükleniyor...
          </div>
        )}

        <button
          onClick={handleSubmit}
          type="submit"
          className="inline-flex items-center mx-auto rounded-md border border-transparent bg-blue-100 
        px-4 py-2 m-5 text-sm font-medium text-blue-900 hover:bg-indigo-200 focus:outline-none 
        focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          Paylaş
        </button>
        <ToastContainer />
      </div>
    </div>
  );
}

export default AddAnnouncment;
