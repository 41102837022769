import React , {Fragment} from "react";
import { NavLink } from "react-router-dom";
import { GrMoney } from "react-icons/gr";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";

function AdminShoppingModal({  setisOpen, isOpen, selectedData }) {
  const handleOKClick = () => {
    setisOpen(false);
  };
  
  return (
     <>
       <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={handleOKClick}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg text-center font-medium leading-6 text-gray-900"
              >
                Döküman İncele
              </Dialog.Title>
              <ul className="grid grid-cols-1 px-4 mt-5 mb-5">
          <h2 className="text-md font-medium tracking-wide text-gray-600 px-4 mb-2">
            Kullanıcı Bilgileri
          </h2>
          {selectedData.userDetails?.map((person) => (
            <li
              key={person.email}
              className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
            >
              <div className="flex w-full items-center justify-between space-x-6 p-6 border">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="truncate text-sm font-medium text-gray-900">
                      {person.firstName}
                      {person.lastName}
                    </h3>
                  </div>
                  <p className="mt-1 truncate text-sm text-gray-500">
                    {person.city}
                  </p>
                </div>
                <img
                  className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                  src={`${person.profilePicturePath}`}
                  alt="profil-fotoğrafı"
                />
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200 border">
                  <div className="flex w-0 flex-1">
                    <a
                      href={`mailto:${person.email}`}
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                    >
                      <EnvelopeIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">{person.email}</span>
                    </a>
                  </div>
                  <div className="-ml-px flex w-0 flex-1">
                    <a
                      href={`tel:${person.telephone}`}
                      className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                    >
                      <PhoneIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">{person.phoneNumber}</span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <ul
              className="divide-y divide-gray-200 px-4 sm:px-6 mt-5"
            >
                {selectedData.shoppingDetails?.map((item) => (
              <li key={item.id} className="flex py-4 text-sm sm:items-center">
                <img
                  src={item.course.imagePath}
                  alt="kurs-görseli"
                  className="h-24 w-24 flex-none rounded-lg border border-gray-200 sm:h-32 sm:w-32"
                />
                <div className="ml-4 grid flex-auto grid-cols-1 grid-rows-1 items-start gap-y-3 gap-x-5 sm:ml-6 sm:flex sm:items-center sm:gap-0">
                  <div className="row-end-1 flex-auto sm:pr-6">
                    <h3 className="font-medium text-gray-900">
                      <NavLink
                        to={`/${item.course.mainCategoryName
                          .split(" ")
                          .join("")}/${item.course.subCategoryName
                          .split(" ")
                          .join("")}/${item.course.name.split(" ").join("")}/${
                          item.course.id
                        }`}
                        state={{ id: item.course.id }}
                      >
                        {item.course.name}
                      </NavLink>
                    </h3>
                    <p className="mt-1 text-gray-500 text-sm font-small">
                      Ana Kategori: {item.course.mainCategoryName}
                    </p>
                    <p className="mt-1 text-gray-500 text-sm font-small">
                      Alt Kategori: {item.course.subCategoryName}
                    </p>
                  </div>

                  <p className="row-span-2 font-medium text-gray-900 sm:order-1 sm:ml-6 sm:text-right">
                  {`${item?.course.price}`*18/100 + item.course.price}  TL
                  </p>
                  <GrMoney className="sr-only sm:not-sr-only" />
                </div>
              </li>
                ))}
            </ul>

              <div className="mt-10 flex justify-end space-x-5">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={handleOKClick}
                >
                  Kapat
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
     </>
  );
}

export default AdminShoppingModal;
