import React, { useEffect } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import "./Header.module.css";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToken, logout } from "../../store/features/LoginSlice";
import { BiLogOutCircle } from "react-icons/bi";
import { getCart } from "../../store/features/denemeSlice";

const Header = ({ checkAdmin, setCheckAdmin, setCheckUser, checkInstructor, setCheckInstructor }) => {
  const dispatch = useDispatch();
  const logoutHandle = async () => {
    await dispatch(logout());
    setCheckAdmin("");
    setCheckUser("");
    setCheckInstructor("");

  };
  dispatch(addToken());
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const userName = useSelector((state) => state.login.firstName);
  useEffect(() => {
    dispatch(getCart(JSON.parse(jwtTokenRedux)));
  }, []);

  return (
    <>
      <div>
        <div
          className=" mx-auto max-w-screen py-2 px-3 sm:px-6 lg:px-8 "
          style={{ background: "#142850", color: "#fff" }}
        >
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center ">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://tr-tr.facebook.com/UnvanMeslekEgitimKurslari/"
              >
                <FacebookIcon className="mr-3" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/unvan/?originalSubdomain=tr"
              >
                <LinkedInIcon className="mr-3" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/+905077656423"
              >
                <WhatsAppIcon />
              </a>
              <span>
                <p className="ml-1 truncate mt-1">
                  <span className="hidden xl:inline text-white text-m">
                    0312 433 66 70
                  </span>
                </p>
              </span>
              <a href="mailto:info@unvanakademi.com">
                <MailOutlineIcon className="ml-3" />
              </a>

              <p className="ml-1 truncate text-white">
                <a href="mailto:info@unvanakademi.com">
                  <span className="hidden xl:inline text-white text-m mt-1">
                    info@unvanakademi.com
                  </span>
                </a>
              </p>
            </div>
            {jwtTokenRedux == null ? (
              <div className="order-3 mt-1 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                {/* <NavLink
                  className="nav-link hidden md:inline font-light hover:font-normal hover:text-[#142850] hover:bg-white px-3 py-1 rounded-lg mr-10 border"
                  aria-current="page"
                  to="/iletisim"
                >
                  Bilgi Al
                </NavLink> */}
                 <a
                  href ="https://unvanakademi.com/"
                  className=" nav-link hidden md:inline whitespace-nowrap text-base font-medium hover:text-[#142850]  hover:bg-white px-3 py-1 rounded-lg mr-5 "
                  aria-current="page"
                 
                >
                  Siteye Dön
                </a>
               
                <NavLink
                  className=" nav-link hidden md:inline whitespace-nowrap text-base font-medium hover:text-[#142850]  hover:bg-white px-3 py-1 rounded-lg mr-5 "
                  aria-current="page"
                  to="/login"
                >
                  Giriş
                </NavLink>
               
              </div>
            ) : (
              <div className="flex order-3 mt-1 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto button">
                <p className="my-auto  invisible lg:visible mr-8">
                  {`Merhaba ${JSON.parse(userName)}`}!
                </p>
                {checkAdmin == "Admin" ? (
                  <>
                    <NavLink
                      className="nav-link hidden md:inline font-light hover:font-normal hover:text-[#142850] hover:bg-white px-3 py-2 rounded-lg mr-10 border"
                      aria-current="page"
                      to="/adminpanel"
                    >
                      Admin Paneli
                    </NavLink>
                    <NavLink
                      className="nav-link hidden md:inline font-light hover:font-normal hover:text-[#142850] hover:bg-white px-3 py-2 rounded-lg mr-10 border"
                      aria-current="page"
                      to="/kullanicipaneli/anasayfa"
                    >
                      Kullanıcı Paneli
                    </NavLink>
                    
                    {checkInstructor == "Eğitmen" ? (<>
                      <NavLink
                      className="nav-link hidden md:inline font-light hover:font-normal hover:text-[#142850] hover:bg-white px-3 py-2 rounded-lg mr-10 border"
                      aria-current="page"
                      to="/egitmenpanel"
                    >
                      Eğitmen Paneli
                    </NavLink>

                    </>) : (<>
                    </>)}
                   
                  </>
                ) : (
                  <>
                  <NavLink
                      className="nav-link hidden md:inline font-light hover:font-normal hover:text-[#142850] hover:bg-white px-3 py-2 rounded-lg mr-10 border"
                      aria-current="page"
                      to="/kullanicipaneli/anasayfa"
                    >
                      Kullanıcı Paneli
                    </NavLink>

                    {checkInstructor == "Eğitmen" ? (<>
                      <NavLink
                      className="nav-link hidden md:inline font-light hover:font-normal hover:text-[#142850] hover:bg-white px-3 py-2 rounded-lg mr-10 border"
                      aria-current="page"
                      to="/egitmenpanel"
                    >
                      Eğitmen Paneli
                    </NavLink>

                    </>) : (<>
                    </>)}
                  </>
                )}

                <NavLink
                  className="nav-link hidden md:inline whitespace-nowrap text-base hover:text-[#142850] hover:bg-white p-1 rounded-lg  border"
                  aria-current="page"
                  to="/"
                  onClick={logoutHandle}
                >
                  <BiLogOutCircle className="mx-3" size={26} />
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
