import React ,{useEffect, useState } from "react";

import { Link ,NavLink} from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../../data/errors";
import ReactPaginate from "react-paginate";



function InstructorCourses (){

  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [dataCount,setDataCount] = useState();
  const [perPage,setPerPage] = useState();
  const [pageNumber,setPageNumber] = useState(0);
  const pageCount = Math.ceil(dataCount / perPage);
  const [errorMessage, setErrorMessage] = useState("Bilinmeyen bir hata oluştu. Lütfen bizimle iletisime geçiniz");
  const [instructorCourse,setInstructorCourse] = useState([]);
  const dispatch = useDispatch();

  const getInstructorCourses = () =>{

    const fetchTodos = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/Admin/Instructor/Courses`,

        {
        headers: {
            ContentType: "application/json-patch+json",
            accept: "text/plain",
            Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
          },
        }
      );
      setInstructorCourse(response.data.data);
      setDataCount(response.data.data.length);
      setPerPage(10);

    };
     fetchTodos();
  }
  useEffect(() => {
    const fetchTodos = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/Admin/Instructor/Courses`,

        {
        headers: {
            ContentType: "application/json-patch+json",
            accept: "text/plain",
            Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
          },
        }
      );
      setInstructorCourse(response.data.data);
      setDataCount(response.data.data.length);
      setPerPage(100);

    };
     fetchTodos();
  }, [pageNumber]);

  const appreveCourse = (e, courseId) =>
  {
    e.preventDefault();
    Swal.fire({
      title: "Dikkat",
      text: "Kursu onaylamak üzeresiniz!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Onayla",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
                const response = await axios.post(
                  `${process.env.REACT_APP_BASE_URL}/Admin/Instructor/ApproveCourse`,
                  {
                    courseId: courseId,
                    approveStatus: true
                  },
                  {
                  headers: {
                      ContentType: "application/json-patch+json",
                      accept: "text/plain",
                      Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
                    },
                  }
                )
                .then((data) => {
                    dispatch(getInstructorCourses());
                    Swal.fire("İşlem başarılı!")
                })
                .catch((error) => {
                    for (let index = 0; index < errors.length; index++) {
                        if (error.response.data.details.errorCodes === errors[index].id) 
                            setErrorMessage(errors[index].content.toString());
                      }
                   let message = errorMessage;
                    Swal.fire({
                        title : "İşlem Başarısız!",
                        text : message,
                        confirmButtonText: "Tamam",
                    });
                });  
        };
        response();
      }
    });
    

  }

  const deleteCourse = (e,courseId) => {
    e.preventDefault();
    Swal.fire({
      title: "Dikkat",
      text: "Kursu silmek üzeresiniz!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Onayla",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
                await axios.delete(
                  `${process.env.REACT_APP_BASE_URL}/Course?id=${courseId}`,
          
                  {
                  headers: {
                      ContentType: "application/json-patch+json",
                      accept: "text/plain",
                      Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
                    },
                  }
                )
                .then((data) => {
                    dispatch(getInstructorCourses());
                    Swal.fire("Başarı ile silindi!")
                })
                .catch((error) => {
                    for (let index = 0; index < errors.length; index++) {
                        if (error.response.data.details.errorCodes === errors[index].id) 
                            setErrorMessage(errors[index].content.toString());
                      }
                   let message = errorMessage;
                    Swal.fire({
                        title : "İşlem Başarısız!",
                        text : message,
                        confirmButtonText: "Tamam",
                    });
                });  
        };
        response();
      }
    });
  }

  const instructorCourses =Object.values(instructorCourse)
   .map((data) => {
    return (
      <tr key={data.course.id}>
        <td className="border-t relative py-4 pl-4 sm:pl-6 pr-3 text-sm  ">
          <div className="font-medium text-gray-900">{data.course.name}</div>

          <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
            <span>
              {data.course.mainCategoryName} / {data.course.subCategoryName}
            </span>
          </div>
        </td>
        <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-800 lg:table-cell">
          {data.course.mainCategoryName}
        </td>
        <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
          {data.course.subCategoryName}
        </td>
        <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
          {data.user.firstName} {data.user.lastName}
        </td>

        <td className="border-t space-x-3 space-y-2 flex lg:flex-none relative sm:pr-4 pr-2 text-right text-sm font-medium">
          <NavLink
            to={`/${data.course.mainCategoryName
              .split(" ")
              .join("")}/${data.course.subCategoryName
              .split(" ")
              .join("")}/${data.course.name.split(" ").join("")}/${data.course.id}`}
            state={{ id: data.course.id }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button target="_blank" className="px-2 py-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4 text-gray-500"
              >
                <path
                  fillRule="evenodd"
                  d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </NavLink>
          {data.course.isApproved ? (
            <div
            className="pl-5 pr-5"
            >
            <lord-icon
              src="https://cdn.lordicon.com/sxddhwcs.json"
              trigger="hover"
              colors="primary:#0a5c15,secondary:#ffffff"
              style={{width:"25px",height:"25px"}}>
            </lord-icon>

            </div>
            
          ) :(
          
            <button
              onClick={(e)=> appreveCourse(e,data.course.id)}
              type="button"
              className="rounded-md bg-red-600 text-white font-light bg-[#142850] hover:bg-green-600 px-2 py-1"
            >
              Onayla
            </button>
        
          )}
        
          <Link to={`/adminpanel/kurslar/incele/${data.course.id}`}>
            <button
              type="button"
              className="rounded-md text-white font-light bg-[#142850] hover:bg-blue-700 px-2 py-1"
            >
              Dersler
            </button>
          </Link>
          <Link to={`/adminpanel/kurslar/duzenle/${data.course.id}`}>
            <button
              type="button"
              className="rounded-md text-white font-light bg-[#142850] hover:bg-blue-700 px-2 py-1"
            >
              Düzenle
            </button>
          </Link>
          <button
            onClick={(e) => deleteCourse(e,data.course.id)}
            type="button"
            className="rounded-md text-white font-light bg-[#142850] hover:bg-blue-700 px-2 py-1"
          >
            Sil
            <span className="sr-only">, {data.course.name}</span>
          </button>
          <ToastContainer />
        </td>
      </tr>
    );
  });
const changePage = ({ selected }) => {
  setPageNumber(selected);
};



  return (
    <>

<div>
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel/egitmen-islemleri`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Eğitmen Kursları
        </h1>
      </div>
      <div className="px-1">
        <div className="-mx-4 mt-5 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Kurs Adı
                </th>
                <th
                  scope="col"
                  className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:table-cell"
                >
                  Ana Kategorisi
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Alt Kategorisi
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Eğitmen
                </th>


                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Seç</span>
                </th>
              </tr>
            </thead>
            <tbody> 
              {instructorCourses}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-5">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
        />
      </div>
    </div>



    </>
  );
};

export default InstructorCourses;
