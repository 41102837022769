import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { errors } from "../../../data/errors";
import { ColorRing } from "react-loader-spinner";
import { MdOutlineClass } from "react-icons/md";

const AddLesson = () => {
  const [lessonName, setLessonName] = useState("");
  const [lessonLink, setLessonLink] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [loading, setLoading] = useState(false);
  let bodyFormData = new FormData();
  bodyFormData.append("Name", lessonName);
  bodyFormData.append("Link", lessonLink);
  bodyFormData.append("CourseId", id);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/Lesson`,
        data: bodyFormData,
        headers: {
          ContentType: "multipart/form-data",
          Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          accept: "text/plain",
        },
      });
      Swal.fire("Eklendi!");
      navigate(`/adminpanel/kurslar/incele/${id}`);
    } catch (response) {
      setLoading(true);
      let isEqual = false;
      for (let index = 0; index < errors.length; index++) {
        if (response.response.data.details.errorCodes === errors[index].id) {
         isEqual = true;
          return toast.error(errors[index].content);
        } else if (response.response.data.details.errorCodes === "999") {
         isEqual = true;
          return toast.error(response.response.data.details.message);
        } else {
         isEqual = false;
        }
      }
      if (!isEqual) {
        toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
      }
    } finally {
      setLoading(false);
    }
  };

  const sweetAlert = (e) => {
    e.preventDefault();
    Swal.fire({
        title: "İşlemi gerçekleştirmek istediğinizden emin misin?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleSubmit(e);
        }
      });
  };
  return (
    <>
      <ToastContainer />
      <div className="items-start m-10">
        <form onSubmit={(e) => sweetAlert(e)}>
          <h2 className="text-green-500 text-center font-semibold mb-5 text-3xl">
            Yeni Ders Ekle
          </h2>
          <div className="flex justify-center mb-8">
            <MdOutlineClass className="w-10 h-10" />
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Ders Adı :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                <input
                  value={lessonName}
                  onChange={(e) => setLessonName(e.target.value)}
                  type="text"
                  name="lesson-name"
                  id="lesson-name"
                  required
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="lastname"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Link :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                <textarea
                  value={lessonLink}
                  onChange={(e) => setLessonLink(e.target.value)}
                  rows={8}
                  required
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                />
              </div>
            </div>
          </div>

          {loading ? (
            <div className="text-center">
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{ margin: "0 auto" }}
                wrapperClass="blocks-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
              Ekleniyor...
            </div>
          ) : (
            <div className="pt-5">
              <div className="flex justify-end">
                <Link
                  to={`/adminpanel/kurslar/incele/${id}`}
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Geri Dön
                </Link>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Kaydet
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default AddLesson;
