
import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { errors } from "../../../data/errors";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate,NavLink,useParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";


function AdminAddRoleToUser()
{
    const { id } = useParams();
    const navigate = useNavigate();
    const jwtTokenRedux = useSelector((state) => state.login.accessToken);
    const [roles, setRoles] = useState([]); 
    const [roleId, setRoleId] = useState("");
    const addRoleModel = {
        userId: id,
        roleId: roleId
      };

    const addRole = async (e) => {
        e.preventDefault();
        console.log(roleId);
        console.log(id);
        Swal.fire({
            title: "Kullanıcıya rol eklemek üzeresiniz!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Vazgeç",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Evet!",
          }).then((result) => {
            if (result.isConfirmed) {
              const submit = async () => {
        
        try {
           await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/Admin/User/Role/AssignToUser`,
            data: addRoleModel,
            headers: {
              ContentType: "application/json-patch+json",
              accept: "text/plain",
              Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
            },
          });
          Swal.fire("Eklendi!");
          navigate(`/adminpanel/kullanicilar/duzenle/${id}/roller`);
        } 
        catch (error) {
          let isEqual = false;
          for (let index = 0; index <= errors.length; index++) {
            if (error.response.data.details.errorCodes === errors[index].id) {
              isEqual = true;
              return toast.error(errors[index].content);
            } else if (error.response.data.details.errorCodes == 999) {
              isEqual = true;
              return toast.error(error.response.data.details.message);
            } else {
              isEqual = false;
            }
          }
          if (!isEqual) {
            toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
          }
        }
    };
    submit();
  }
});
    };
    const handleMain = (event) => {
        const getRoleId = event.target.value;
        setRoleId(getRoleId);
    };

    //   const [main] = useAxios({
    //     axiosInstance: axiosmain,
    //     method: "GET",
    //   });
      
    useEffect(() => {
        const fetchTodos = async () => {
          const response = await axios(
            `${process.env.REACT_APP_BASE_URL}/Admin/Role/GetAll`,
            {
            headers: {
                ContentType: "application/json-patch+json",
                accept: "text/plain",
                Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
              },
            }
          );
          setRoles(response.data.data);
        };
        fetchTodos();
    },[]);

    return(
        <>
        <div className="flex mb-5 mt-5">
        <NavLink
          to={`/adminpanel/kullanicilar/duzenle/${id}/roller`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
        <div className="mt-20">
      <h2 className="text-blue-900 text-left font-semibold mb-5 text-2xl">
        Roller
      </h2>
      
        <div className="border border-gray-300 rounded-lg md:col-span-1 col-span-4 mx-auto mt-3 items-center w-full">
            <select
              onChange={(e) => handleMain(e)}
              value={roleId}
              className="text-black p-1 w-full"
              placeholder="asd"
            >
              <option>Role Seçiniz</option>
              {!!roles && roles?.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
             ))}
            </select>
        </div>
       


      {/* <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
        <label
          htmlFor="name"
          className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
        >
         Rol
        </label>
        <input
          onChange={(e) => setName(e.target.value)}
          value={name}
          type="text"
          name="Tittle"
          id="1"
          className="block w-full p-1 text-gray-900 sm:text-sm"
        />
      </div> */}
      <button
        onClick={addRole}
        type="submit"
        className="inline-flex items-center mx-auto rounded-md border border-transparent bg-blue-100 
      px-4 py-2 m-5 text-sm font-medium text-blue-900 hover:bg-indigo-200 focus:outline-none 
      focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
      >
        Ekle
      </button>
      <ToastContainer />
    </div>
    </>
  );
 
}
export default AdminAddRoleToUser;