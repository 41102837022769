import React, {useState} from 'react'
import { Outlet } from 'react-router-dom'
import EducatorNavbar from './EducatorNavbar'


function EducatorPanel() {
  const [move, setMove]=useState(true);
  return (
    <>
      <div className="w-full min-h-full grid grid-cols-12 gap-x-4">
        <div className="col-span-2 z-0" >
         <EducatorNavbar move={move} setMove={setMove}/>
        </div>
        <main className={`${move ? "" : "pl-24 lg:pl-0"} col-span-9 items-start`}>
          <Outlet/>
        </main>
      </div>
    </>
  )
}

export default EducatorPanel