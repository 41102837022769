import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Swal from "sweetalert2/dist/sweetalert2";
import { ColorRing } from "react-loader-spinner";
import { errors } from "../../../data/errors";
import {
  getMainCategories,
  getSubCategoriesByMainId,
  postCourse,
} from "../../../store/features/AdminSlice";
function AddCourse() {
  const [subCatId, setSubCatId] = useState("");
  const [mainId, setMainId] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [content, setContent] = useState("");
  const [subContentTitle, setSubContentTitle] = useState("");
  const [examInfo, setExamInfo] = useState("");
  const [kdv, setKdv] = useState("");
  const [educationType, setEducationType] = useState("");
  const [certificate, setCertificate] = useState("")
  const [certificateImg, setCertificateImg] = useState("");
  const [courseImg, setCourseImg] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [title, setTitle] = useState("");

  const [loading, setLoading] = useState(false);
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let bodyFormData = new FormData();
  bodyFormData.append("Name", name);
  bodyFormData.append("Price", price);
  bodyFormData.append("Duration", duration);
  bodyFormData.append("Content", content);
  bodyFormData.append("SubCategoryId", subCatId);
  bodyFormData.append("SubContentTitle", subContentTitle);
  bodyFormData.append("examInfo", examInfo);
  bodyFormData.append("KDV", kdv);
  bodyFormData.append("isRemote", educationType);
  bodyFormData.append("isCertified", certificate);
  bodyFormData.append("CertificateImage", certificateImg);
  bodyFormData.append("Image", courseImg);
  bodyFormData.append("VideoLink", videoLink);
  bodyFormData.append("Title", title);

  useEffect(() => {
    const fetchMainCategories = async () => {
      try {
        dispatch(getMainCategories(JSON.parse(jwtTokenRedux)));
      } catch (error) {
        console.log(error);
      }
    };
    fetchMainCategories();
  }, [jwtTokenRedux, dispatch]);
  const mainCategory = useSelector(
    (state) => state.AdminSlice.getMainCategories
  );

  const handleMain = (e) => {
    const getMainId = e.target.value;
    setMainId(getMainId);
  };

  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        dispatch(
          getSubCategoriesByMainId({
            jwt: JSON.parse(jwtTokenRedux),
            mainCategoryId: mainId,
          })
        );
      } catch (response) {
        setLoading(false);
      }
    };
    fetchSubCategories();
  }, [mainId, jwtTokenRedux,  dispatch]);
  const subCategories = useSelector(
    (state) => state.AdminSlice.getSubCategoriesByMainId
  );

  const handleSubmit = () => {
    Swal.fire({
      title: "Yeni Kurs eklensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          setLoading(true);
          try {
            await dispatch(
              postCourse({
                jwt: JSON.parse(jwtTokenRedux),
                bodyFormData: bodyFormData,
              })
            );
            setLoading(false);
            Swal.fire("Eklendi!");
            navigate("/adminpanel/kurslar");
          } catch (error) {
            setLoading(false);
            toast.error("Hata!");
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              console.log(error);
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
               isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen hata! Bizimle iletişime geçiniz.");
            }
          }
        };
        submit();
      }
    });
  };

  return (
    <>
      <div className="flex mb-5 mt-5">
        <NavLink
          to={`/adminpanel/kurslar`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <h2 className="text-[#2196f3] text-center font-semibold mb-5 mt-8 text-2xl">
        KURS EKLE
      </h2>
      {!loading && (
        <div>
          <form className="space-y-6">
            <div className="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h2
                    className="inline-flex items-center rounded-full py-1 px-4 mt-8 text-[#2196f3] ring-1 ring-inset ring-gray-400"
                    id="table-of-contents-title"
                  >
                    <span className="font-mono text-sm" aria-hidden="true">
                      01
                    </span>
                    <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                    <span className="ml-3 text-base font-medium tracking-tight">
                      Kurs Bilgisi
                    </span>
                  </h2>
                </div>
                <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                  <div className="relative rounded-md border mt-8 mb-10 border-gray-300 px-2 py-2  focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                    <label
                      htmlFor="name"
                      className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
                    >
                      ANA KATEGORİ
                    </label>
                    <select
                      onChange={(e) => handleMain(e)}
                      value={mainId}
                      className="form-select w-full block px-1 text-gray-900 sm:text-sm"
                      required
                    >
                      <option value="anaKategoriSeçin">Seçiniz</option>
                      {!!mainCategory &&
                        mainCategory?.map((maincategory) => (
                          <option key={maincategory.id} value={maincategory.id}>
                            {maincategory.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                    <label
                      htmlFor="name"
                      className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
                    >
                      ALT KATEGORİ
                    </label>
                    <select
                      onChange={(e) => setSubCatId(e.target.value)}
                      value={subCatId}
                      required
                      className="form-select block w-full p-1 text-gray-900 sm:text-sm"
                    >
                      <option value="">Seçiniz</option>
                      {!!subCategories &&
                        subCategories?.map((subcategory) => (
                          <option key={subcategory.id} value={subcategory.id}>
                            {subcategory.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="relative rounded-md border mb-10 mt-8 border-gray-300 px-3 py-2 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                    <label
                      htmlFor="name"
                      className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
                    >
                      Kurs Adı
                    </label>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      type="text"
                      name="name"
                      id=""
                      className="block w-full p-1 text-gray-900 sm:text-sm"
                    />
                  </div>

                  <div className="relative rounded-md border mb-10 mt-8 border-gray-300 px-3 py-2 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                    <label
                      htmlFor="name"
                      className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
                    >
                      Kurs Başlığı
                    </label>
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                      type="text"
                      name="name"
                      id=""
                      className="block w-full p-1 text-gray-900 sm:text-sm"
                    />
                  </div>
                  <div className="mt-8">
                    <h2 className="text-base font-medium tracking-tight text-gray-600 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500">
                      Giriş
                    </h2>
                    <CKEditor
                      data={subContentTitle}
                      value={subContentTitle}
                      editor={Editor}
                      extraPlugins
                      onReady={(editor) => {}}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setSubContentTitle(data);
                      }}
                    />
                  </div>
                  <div className="relative rounded-md border mb-10 mt-8 border-gray-300 px-3 py-2 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                    <label
                      htmlFor="name"
                      className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      Video Linki
                    </label>
                    <input
                      value={videoLink}
                      onChange={(e) => setVideoLink(e.target.value)}
                      type="text"
                      name="video"
                      id="video"
                      className="block w-full p-1 text-gray-900 sm:text-sm"
                    />
                  </div>

                  <div className="relative rounded-md border mb-10 mt-8 border-gray-300 px-3 py-2 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                    <label
                      htmlFor=""
                      className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
                    >
                      Eğitim Saati
                    </label>
                    <input
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      required
                      type="number"
                      name="hour"
                      id=""
                      className="block w-full p-1 text-gray-900 sm:text-sm"
                    />
                  </div>

                  <div className="mt-10 mb-5 space-y-5">
                    <h2 className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
                      Sertifika Bilgisi
                    </h2>
                    <div>
                      <select
                        onChange={(e) => setCertificate(e.target.value)}
                        value={certificate}
                        required
                        id="certificate"
                        name="certificate"
                        className="mt-1 w-48 block border rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      >
                        <option>Seçiniz</option>
                        <option value={true}>Sertifikalı</option>
                        <option value={false}>Sertifikasız</option>
                      </select>
                    </div>
                  </div>

                  <div className="mt-10 mb-5 space-y-5">
                    <h2 className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
                      Eğitim Şekli
                    </h2>
                    <div>
                      <select
                        onChange={(e) => setEducationType(e.target.value)}
                        value={educationType}
                        required
                        id="certificate"
                        name="certificate"
                        className="mt-1 w-48 block border rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      >
                        <option>Seçiniz</option>
                        <option value={true}>Uzaktan Eğitim</option>
                        <option value={false}>Yüz yüze Eğitim</option>
                      </select>
                    </div>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-base font-medium tracking-tight text-gray-600 mb-2 after:ml-0.5 after:text-red-500">
                      Açıklama
                    </h2>
                    <CKEditor
                      data={content}
                      value={content}
                      allowedContent={"p b i ul ol li; img[!src]"}
                      editor={Editor}
                      onReady={(editor) => {}}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setContent(data);
                      }}
                    />
                  </div>

                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <h2 className="text-base font-medium tracking-tight text-gray-600 mb-2">
                      Kurs Fotoğrafı
                    </h2>
                    <div className="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label className="relative cursor-pointer rounded-md bg-white font-medium text-[#2196f3]">
                            <input
                              onChange={(e) => setCourseImg(e.target.files[0])}
                              type="file"
                              name="image"
                              id="course-photo"
                              accept="image/*"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h2
                    className="inline-flex items-center rounded-full py-1 px-4 mt-8 text-[#2196f3] ring-1 ring-inset ring-gray-400"
                    id="table-of-contents-title"
                  >
                    <span className="font-mono text-sm" aria-hidden="true">
                      02
                    </span>
                    <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                    <span className="ml-3 text-base font-medium tracking-tight">
                      Sertifika
                    </span>
                  </h2>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <h2 className="text-base font-medium tracking-tight text-gray-600 mb-2">
                    Sertifika Fotoğrafı
                  </h2>
                  <div className="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <label className="relative cursor-pointer rounded-md bg-white font-medium text-[#2196f3]">
                          <input
                            onChange={(e) =>
                              setCertificateImg(e.target.files[0])
                            }
                            type="file"
                            name="image"
                            id="course-banner"
                            accept="image/*"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h2
                    className="inline-flex items-center rounded-full py-1 px-4 mt-8 text-[#2196f3] ring-1 ring-inset ring-gray-400"
                    id="table-of-contents-title"
                  >
                    <span className="font-mono text-sm" aria-hidden="true">
                      03
                    </span>
                    <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                    <span className="ml-3 text-base font-medium tracking-tight">
                      Sınav Bilgisi
                    </span>
                  </h2>
                </div>
                <div className="mt-10 space-y-6 md:col-span-2 md:mt-0">
                  <fieldset>
                    <h2 className="text-base font-medium tracking-tight text-gray-600 mt-8 after:content-['*'] after:ml-0.5 after:text-red-500">
                      Açıklama
                    </h2>
                    <div className="mt-2 w-11/12">
                      <CKEditor
                        data={examInfo}
                        value={examInfo}
                        editor={Editor}
                        onReady={(editor) => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setExamInfo(data);
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div className="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h2
                    className="inline-flex items-center rounded-full py-1 px-4 mt-8 text-[#2196f3] ring-1 ring-inset ring-gray-400"
                    id="table-of-contents-title"
                  >
                    <span className="font-mono text-sm" aria-hidden="true">
                      04
                    </span>
                    <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                    <span className="ml-3 text-base font-medium tracking-tight">
                      Satın Al
                    </span>
                  </h2>
                </div>
                <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                  <fieldset>
                    <div className="relative rounded-md border mb-6 mt-8 border-gray-300 px-3 py-2 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                      <label
                        htmlFor="name"
                        className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
                      >
                        FİYAT
                      </label>
                      <input
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                        type="number"
                        name="price"
                        id=""
                        className="block w-full p-1 text-gray-900 sm:text-sm"
                      />
                    </div>
                    <div className="relative rounded-md border mb-10 mt-8 border-gray-300 px-3 py-2 focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                      <label
                        htmlFor="name"
                        className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
                      >
                        KDV
                      </label>
                      <input
                        value={kdv}
                        onChange={(e) => setKdv(e.target.value)}
                        required
                        type="number"
                        name="kdv"
                        id=""
                        className="block w-full p-1 text-gray-900 sm:text-sm"
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      {loading && (
        <div className="grid place-content-center bg-grey-light text-center mt-3 text-md">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
          Yükleniyor...
        </div>
      )}
      <div className="flex justify-end">
        <button
          onClick={handleSubmit}
          type="submit"
          className="inline-flex shadow-xl items-center mx-auto rounded-md border-2 bg-blue-900 
                 px-4 py-2 m-5 text-sm font-medium text-white hover:text-blue-900 hover:bg-blue-300 focus:outline-none 
                 focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          Ekle
        </button>
        <ToastContainer />
      </div>
    </>
  );
}

export default AddCourse;
