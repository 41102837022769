import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getUserProfile } from "../../../store/features/UserSlice";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import Swal from "sweetalert2/dist/sweetalert2";

const UserHome = () => {
  const dispatch = useDispatch();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const userName = useSelector((state) => state.login.firstName);
  const lastName = useSelector((state) => state.login.lastName);
  useEffect(() => {
    dispatch(getUserProfile(JSON.parse(jwtTokenRedux)));
  }, []);
  const userInfo = useSelector((state) => state.userSlice.usersProfile);
  const information = (e) => {
    e.preventDefault();
    Swal.fire({
      title:
        "Sertifikanızı alabilmek için önce kimlik bilgilerinizi kaydedip sonra hesabınızı onaylamanız gerekmektedir!",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });
  };

  return (
    <>
      <div className="w-[calc(100%-3.73rem)] ml-auto">
        <div className="2xl:container mx-auto space-y-3">
          <div className="px-0 lg:px-12">
            <div className="text-center mb-10 mt-5 border-b">
              <h1 className="text-3xl md:text-4xl text-black font-bold">
                Merhaba, {JSON.parse(userName)} {JSON.parse(lastName)}{" "}
                {userInfo[0]?.isConfirmed ? (
                  <>
                    <lord-icon
                      src="https://cdn.lordicon.com/cfyppusy.json"
                      trigger="hover"
                      stroke="70"
                      colors="outline:#1b1091,primary:#1b1091,secondary:#ffffff"
                      style={{ width: "40px", height: "40px" }}
                      id="props-basic"
                    ></lord-icon>
                    <Tooltip
                      anchorId="props-basic"
                      content="Onaylanmış hesap!"
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        backgroundColor: "rgb(203, 213, 225)",
                        color: "black",
                        padding: "5px",
                      }}
                    />
                    <lord-icon
                      src="https://cdn.lordicon.com/ihyatngg.json"
                      trigger="loop"
                      colors="primary:#66d7ee,secondary:#1663c7"
                      style={{ width: "50px", height: "50px" }}
                    ></lord-icon>
                  </>
                ) : (
                  <div
                    className="text-xs flex items-center align-center justify-center"
                    id="props-unconfirmed"
                  >
                    <lord-icon
                      src="https://cdn.lordicon.com/zlhhhefv.json"
                      trigger="hover"
                      colors="primary:#66d7ee,secondary:#1663c7"
                      style={{ width: "40px", height: "40px" }}
                    ></lord-icon>
                    <Tooltip
                      anchorId="props-unconfirmed"
                      content="Lütfen ayarlar sayfasından hesabınızı onaylayın!"
                      place="bottom"
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        backgroundColor: "rgb(203, 213, 225)",
                        color: "black",
                        padding: "5px",
                      }}
                    />
                    Onaylanmamış hesap
                    <button
                      href="/"
                      className="ml-1 flex-shrink-0 text-gray-400 hover:text-gray-500"
                      onClick={(e) => information(e)}
                    >
                      <QuestionMarkCircleIcon
                        className="h-4 w-4"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                )}
              </h1>
            </div>
            <div className="grid grid-cols-12 gap-4 mb-20">
              <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mx-auto mt-10 bg-white rounded-[2rem] border-[1px] h-44 w-72 shadow-2xl	duration-500 hover:scale-105 cursor-pointer">
                <NavLink to="/kullanicipaneli/ayarlarim">
                  <div className="h-40 w-72 mx-auto text-center">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/738/738853.png"
                      className="h-24 mx-auto mt-5 mb-2"
                      alt=""
                    />
                    <span className="text-xl font-semibold text-[#142850] mx-auto">
                      Ayarlar
                    </span>
                  </div>
                </NavLink>
              </div>
             
              <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mx-auto mt-10 bg-white rounded-[2rem] border-[1px] h-44 w-72 shadow-2xl	duration-500 hover:scale-105 cursor-pointer">
                <NavLink to="/kullanicipaneli/evraklarim">
                  <div className="h-40 w-72 mx-auto text-center">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/2015/2015058.png"
                      className="h-24 mx-auto mt-5 mb-2"
                      alt=""
                    />
                    <span className="text-xl font-semibold text-[#142850] mx-auto">
                      Evraklarım
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mx-auto mt-10 bg-white rounded-[2rem] border-[1px] h-44 w-72 shadow-2xl	duration-500 hover:scale-105 cursor-pointer">
                <NavLink to="/kullanicipaneli/kurslarim">
                  <div className="h-40 w-72 mx-auto text-center">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/7688/7688675.png"
                      className="h-28 mx-auto mt-2"
                      alt=""
                    />
                    <span className="text-xl font-semibold text-[#142850] mx-auto">
                      Kurslarım
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mx-auto mt-10 bg-white rounded-[2rem] border-[1px] h-44 w-72 shadow-2xl	duration-500 hover:scale-105 cursor-pointer">
                <NavLink to="/kullanicipaneli/kurs-dokumanlari">
                  <div className="h-40 w-72 mx-auto text-center">
                    <img
                      src="https://img.icons8.com/doodle/96/null/documents.png"
                      className="h-28 mx-auto mt-2"
                    />
                    <span className="text-xl font-semibold text-[#142850] mx-auto">
                      Kurs Dökümanları
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mx-auto mt-10 bg-white rounded-[2rem] border-[1px] h-44 w-72 shadow-2xl	duration-500 hover:scale-105 cursor-pointer">
                <NavLink to="/kullanicipaneli/siparisgecmisi">
                  <div className="h-40 w-72 mx-auto text-center">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/4313/4313096.png"
                      className="h-28 mx-auto mt-2"
                      alt=""
                    />
                    <span className="text-xl font-semibold text-[#142850] mx-auto">
                      Ödeme Geçmişim
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mx-auto mt-10 bg-white rounded-[2rem] border-[1px] h-44 w-72 shadow-2xl	duration-500 hover:scale-105 cursor-pointer">
                <NavLink to="/kullanicipaneli/sertifikalarim">
                  <div className="h-40 w-72 mx-auto text-center">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/2490/2490354.png"
                      className="h-24 mx-auto mt-6"
                      alt=""
                    />
                    <span className="text-xl font-semibold text-[#142850] mx-auto">
                      Sertifikalarım
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mx-auto mt-10 bg-white rounded-[2rem] border-[1px] h-44 w-72 shadow-2xl	duration-500 hover:scale-105 cursor-pointer">
                <NavLink to="/kullanicipaneli/sinavlar">
                  <div className="h-40 w-72 mx-auto text-center">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/3048/3048732.png"
                      className="h-24 mx-auto mt-6"
                      alt=""
                    />
                    <span className="text-xl font-semibold text-[#142850] mx-auto">
                      Sınavlar
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mx-auto mt-10 bg-white rounded-[2rem] border-[1px] h-44 w-72 shadow-2xl	duration-500 hover:scale-105 cursor-pointer">
                <NavLink to="/kullanicipaneli/yorumlarim">
                  <div className="h-40 w-72 mx-auto text-center">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/2593/2593491.png"
                      className="h-28 mx-auto mt-2"
                      alt=""
                    />
                    <span className="text-xl font-semibold text-[#142850] mx-auto">
                      Yorumlarım
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 mx-auto mt-10 bg-white rounded-[2rem] border-[1px] h-44 w-72 shadow-2xl	duration-500 hover:scale-105 cursor-pointer">
                <NavLink to="/kullanicipaneli/egitmen-istegi">
                  <div className="h-40 w-72 mx-auto text-center">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/9971/9971872.png"
                      className="h-28 mx-auto mt-2"
                      alt=""
                    />
                    <span className="text-xl font-semibold text-[#142850] mx-auto">
                      Eğitmen Talebi
                    </span>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHome;
