import React, { useEffect, useState , useReducer} from "react";
import { CalendarIcon, MapPinIcon, QueueListIcon } from "@heroicons/react/20/solid";
import { Link, NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import PostExam from "./PostExam";

function AdminExams() {
  const id = useParams();
  const jwtToken = useSelector((state) => state.login.accessToken);
  const [exams, setExams] = useState("");
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const fetchExams = async () => {
      try {
        const response = await axios(`${process.env.REACT_APP_BASE_URL}/Exam?CourseId=${id.id}`, {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${JSON.parse(jwtToken)}`,
          },
        });
        setExams(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchExams();
  }, [reducerValue]);


  return (
    <>
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel/kurslar/duzenle/${id.id}`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Sınavlar
        </h1>
      </div>
     <PostExam forceUpdate={forceUpdate}/>
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {exams &&
            exams?.map((item) => (
              <li key={item.id}>
                <Link
                  to={`/adminpanel/kurslar/sinav-duzenle/${id.id}/${item.id}`}
                  className="block hover:bg-gray-50"
                >
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="truncate text-sm font-medium text-blue-900">
                        {item.name}
                      </p>
                      <div className="ml-2 flex flex-shrink-0">
                        <Link
                          to={`/adminpanel/kurslar/sinavlar/${item.course.id}/sorular/${item.id}`}
                          className="inline-flex rounded-md bg-blue-100 px-2 py-1 text-xs font-semibold leading-5 text-blue-800 hover:bg-gray-100 hover:border"
                        >
                          Sorular / Soru Ekle
                        </Link>
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500">
                          <QueueListIcon                           className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {item.course.mainCategoryName}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <MapPinIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          Online
                        </p>
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <CalendarIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          Oluşturuldu - {item.created.slice(8, 10)}.
                          {item.created.slice(5, 7)}.{item.created.slice(0, 4)}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}

export default AdminExams;
