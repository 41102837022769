import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { errors } from "../../../data/errors";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2/dist/sweetalert2";
import { postDocument } from "../../../store/features/AdminSlice";

const AddDocument = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [documentsName, setDocumentsName] = useState("");
  const [description, setDescription] = useState("");
  const [documentsFile, setDocumentsFile] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  let bodyFormData = new FormData();
  bodyFormData.append("Name", documentsName);
  bodyFormData.append("Description", description);
  bodyFormData.append("Document", documentsFile);
  bodyFormData.append("IsDefault", isDefault);
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(
        postDocument({
          jwt: JSON.parse(jwtTokenRedux),
          bodyFormData: bodyFormData,
        })
      );
      setDocumentsFile("");
      Swal.fire("Eklendi!");
      navigate("/adminpanel/evraklar");
    } catch (response) {
      setLoading(true);
      let isEqual = false;
      for (let index = 0; index < errors.length; index++) {
        if (response.response.data.details.errorCodes === errors[index].id) {
          isEqual = true;
          return toast.error(errors[index].content);
        } else if (response.response.data.details.errorCodes === "999") {
          isEqual = true;
          return toast.error(response.response.data.details.message);
        } else {
          isEqual = false;
        }
      }
      if (!isEqual) {
        if (!isEqual) {
          toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçiniz.");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const sweetAlert = (e) => {
    e.preventDefault();
      Swal.fire({
        title: "Yeni evrak eklensin mi?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleSubmit(e);
        }
      })
  };
  return (
    <>
      <div className="items-start m-10">
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel/evraklar`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
        <form onSubmit={(e) => sweetAlert(e)}>
          <h2 className="text-green-500 text-center font-semibold mb-5 text-3xl">
            Yeni Evrak Ekle
          </h2>
          <div className="flex justify-center mb-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
              />
            </svg>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Evrak İsmi :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                <input
                  value={documentsName}
                  onChange={(e) => setDocumentsName(e.target.value)}
                  type="text"
                  name="document"
                  id="document"
                  required
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="lastname"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Açıklama :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                <input
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  name="description"
                  id="description"
                  required
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                />
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="lastname"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Evrak Örneği :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                <input
                  onChange={(e) => setDocumentsFile(e.target.files[0])}
                  type="file"
                  name="sample"
                  id="sample"
                  required
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                />
              </div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="remember"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Default :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                <input
                  id="remember"
                  type="checkbox"
                  className="block text-gray-900 sm:text-sm"
                  onClick={() => setIsDefault(!isDefault)}
                  value={isDefault}
                />
              </div>
            </div>
          </div>
          {loading ? (
            <div className="text-center">
              <span>Yükleniyor...</span>
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{ margin: "0 auto" }}
                wrapperClass="blocks-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            </div>
          ) : (
            <div className="pt-5">
              <div className="flex justify-end">
                <Link
                  to="/adminpanel/evraklar"
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Geri Dön
                </Link>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Kaydet
                </button>
             <ToastContainer /> 
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default AddDocument;
