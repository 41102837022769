import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../data/errors";

function AdminMainCat() {
  const [mainCategories, setMainCategories] = useState("");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);

  useEffect(() => {
    const fetchMainCats = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/MainCategory`,
          {
            headers: {
              accept: "text/plain",
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            },
          }
        );
        setMainCategories(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMainCats();
  }, []);

  const deleteMain = (id) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            axios
              .delete(
                `${process.env.REACT_APP_BASE_URL}/MainCategory?id=${id}`,
                {
                  headers: {
                    Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                  },
                }
              )
              .then((res) => {
                const newMains = mainCategories.filter(
                  (main) => main.id !== id
                );
                setMainCategories(newMains);
              });
            Swal.fire("Silindi!");
          } catch (error) {
            let isEqual= false;
            for (let index = 0; index < errors.length; index++) {
              if ( error.response.data.details.errorCodes === errors[index].id) {
                 let isEqual= true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === 999) {
                let isEqual=true;
                return toast.error(error.response.data.details.message);
              } else {
               let isEqual= false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
            }
          }
        };
        submit();
      }
    });
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-2xl text-gray-800 font-bold">Ana Kategoriler</h1>
        </div>
        <div className="flex gap-x-5 mt-4 justify-end">
          <Link to={"/adminpanel/anakategoriler/ekle"}>
            <button
              type="button"
              className="items-center rounded-lg shadow-xl border border-gray-200 bg-[#142850] px-4 py-2 text-md font-medium hover:bg-blue-700 text-white focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-700"
            >
              Yeni Ekle +
            </button>
          </Link>
        </div>
        <div className="-mx-4 mt-5 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Adı
                </th>
              </tr>
            </thead>
            <tbody>
              {!!mainCategories &&
                mainCategories?.map((main) => (
                  <tr key={main.id}>
                    <td
                      className="border-t border-r
                  relative py-4 pl-4 sm:pl-6 pr-3 text-sm"
                    >
                      <div className="font-medium text-gray-900">
                        {main.name}
                      </div>
                    </td>

                    <td className="border-t space-x-6 space-y-5 relative sm:pr-6 text-right text-sm font-medium">
                      <Link
                        to={`/adminpanel/anakategoriler/duzenle/${main.id}`}
                      >
                        <button
                          type="button"
                          className="rounded-md text-white font-light bg-[#142850] hover:bg-blue-700 px-2 py-1"
                        >
                          Düzenle
                        </button>
                      </Link>
                      <button
                        onClick={() => deleteMain(main.id)}
                        type="button"
                        className="rounded-md text-white font-light bg-[#142850] hover:bg-blue-700 px-2 py-1"
                      >
                        Sil
                      </button>
                      <ToastContainer />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default AdminMainCat;
