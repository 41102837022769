import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../../hooks/useAxios";
import axiosmain from "../../../apis/GetMainCategory";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../data/errors";
import { getCourses, deleteCourse } from "../../../store/features/AdminSlice";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

function AdminCourses() {
  const dispatch = useDispatch();
  const notify = () => toast.success("Silindi!");
  const [search, setSearch] = useState("");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [mainId, setMainId] = useState("");
  const [subCat, setSubCat] = useState("");
  const [subTest, setSubTest] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [errorMessage, setErrorMessage] = useState(
    "Bilinmeyen bir hata oluştu. Lütfen bizimle iletisime geçiniz"
  );
  const coursesPerPage = 10;
  const dataCount = useSelector((state) => state.AdminSlice.pageCount);
  const pageCount = Math.ceil(dataCount.dataCount / coursesPerPage);
  const courses = useSelector((state) => state.AdminSlice.adminCourses);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getCourses({ data: JSON.parse(jwtTokenRedux), pageNumber, search })
    );
  }, [pageNumber, search, dispatch, jwtTokenRedux]);

  const handleMain = (event) => {
    const getMainId = event.target.value;
    setMainId(getMainId);
  };
  const [main] = useAxios({
    axiosInstance: axiosmain,
    method: "GET",
  });
  const mainCategories = main.data;
  useEffect(() => {
    const fetchTodos = async () => {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/SubCategory?MainCategoryId=${mainId}`,
        {
          headers: {
            accept: "text/plain",
          },
        }
      );
      setSubCat(response.data);
    };
    fetchTodos();
  }, [mainId]);
  const handleClear = () => {
    setSubTest("");
    setMainId("");
  };

  const courseInfo = (e) => {
    e.preventDefault();
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "bg-green-400 px-8 py-3 rounded-md ml-3",
        cancelButton: "bg-red-400 px-8 py-3 rounded-md",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Onaylanmamış kurs",
        text: "Bu kurs bir eğitmen tarafından eklenmiştir ve onay beklemektedir. Lütfen eğitmen kursları alanından onaylayınız.",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Eğitmen kursları",
        cancelButtonText: "Tamam",
        cancelButtonColor: "#d33",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          navigate("/adminpanel/egitmen-kurslari");
        }
      });
  };

  const approveCourse = (e, courseId) => {
    e.preventDefault();
    Swal.fire({
      title: "Dikkat",
      text: "Kursu aktifleştirmek üzeresiniz!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Onayla",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
          const response = await axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/Admin/Instructor/ApproveCourse`,
              {
                courseId: courseId,
                approveStatus: true,
              },
              {
                headers: {
                  ContentType: "application/json-patch+json",
                  accept: "text/plain",
                  Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                },
              }
            )
            .then((datas) => {
              dispatch(
                getCourses({
                  data: JSON.parse(jwtTokenRedux),
                  pageNumber,
                  search,
                })
              );
            })
            .catch((error) => {
              for (let index = 0; index < errors.length; index++) {
                if (error.response.data.details.errorCodes === errors[index].id)
                  setErrorMessage(errors[index].content.toString());
              }
              let message = errorMessage;
              Swal.fire({
                title: "İşlem Başarısız!",
                text: message,
                confirmButtonText: "Tamam",
              });
            });
        };
        response();
      }
    });
  };

  const deactivateCourse = (e, courseId) => {
    e.preventDefault();
    Swal.fire({
      title: "Dikkat",
      text: "Kursu gizlemek üzeresiniz!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Onayla",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
          const response = await axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/Admin/Instructor/ApproveCourse`,
              {
                courseId: courseId,
                approveStatus: false,
              },
              {
                headers: {
                  ContentType: "application/json-patch+json",
                  accept: "text/plain",
                  Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                },
              }
            )
            .then((datas) => {
              dispatch(
                getCourses({
                  data: JSON.parse(jwtTokenRedux),
                  pageNumber,
                  search,
                })
              );
            })
            .catch((error) => {
              for (let index = 0; index < errors.length; index++) {
                if (error.response.data.details.errorCodes === errors[index].id)
                  setErrorMessage(errors[index].content.toString());
              }
              let message = errorMessage;
              Swal.fire({
                title: "İşlem Başarısız!",
                text: message,
                confirmButtonText: "Tamam",
              });
            });
        };
        response();
      }
    });
  };
  const handleClick = (id) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            await dispatch(
              deleteCourse({ data: JSON.parse(jwtTokenRedux), id })
            );
            dispatch(
              getCourses({ data: JSON.parse(jwtTokenRedux), pageNumber })
            );
            notify();
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === 999) {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        submit();
      }
    });
  };

  const displayCourses = Object.values(courses)
    .filter((course) => {
      return search.toLowerCase() === ""
        ? course
        : course.name.toLowerCase().includes(search);
    })
    .filter((course) =>
      course.mainCategoryId
        .toLocaleLowerCase("tr-TR")
        .includes(mainId.toLocaleLowerCase("tr-TR"))
    )
    .filter((course) =>
      course.subCategoryName
        .toLocaleLowerCase("tr-TR")
        .includes(subTest.toLocaleLowerCase("tr-TR"))
    )
    .map((course) => {
      return (
        <tr
          className={`${course.isApproved ? "bg-green-200" : "bg-red-200"}`}
          key={course.id}
        >
          <td className="border-t relative py-4 pl-4 sm:pl-6 pr-3 text-sm  ">
            <div className="font-medium text-gray-900">{course.name}</div>

            <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
              <span>
                {course.mainCategoryName} / {course.subCategoryName}
              </span>
            </div>
          </td>
          <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-800 lg:table-cell">
            {course.mainCategoryName}
          </td>
          <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
            {course.subCategoryName}
          </td>
          <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
            {course.isApproved ? (
              <></>
            ) : (
              <a
                href="/"
                className="text-gray-400 hover:text-gray-500"
                onClick={(e) => courseInfo(e)}
              >
                <span className="sr-only">Detaylar </span>
                <QuestionMarkCircleIcon
                  className="h-5 w-5 mx-2"
                  aria-hidden="true"
                />
              </a>
            )}
          </td>

          <td className="border-t space-x-3 space-y-2 flex lg:flex-none relative sm:pr-4 pr-2 text-right text-sm font-medium">
            <NavLink
              to={`/kurslar/${course.name.split(" ").join("")}?courseId=${
                course.id
              }`}
              state={{ id: course.id }}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `https://unvanakademi.com/kurslar/${course.name
                    .split(" ")
                    .join("")}?courseId=${course.id}`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              <button target="_blank" className="px-2 py-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-4 h-4 text-gray-500"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </NavLink>

            <Link to={`/adminpanel/kurslar/duzenle/${course.id}`}>
              <button
                type="button"
                className="rounded-md text-white font-light bg-[#142850] hover:bg-blue-700 px-2 py-1"
              >
                Düzenle
              </button>
            </Link>
            <button
              onClick={() => handleClick(course.id)}
              type="button"
              className="rounded-md text-white font-light bg-[#142850] hover:bg-blue-700 px-2 py-1"
            >
              Sil
              <span className="sr-only">, {course.name}</span>
            </button>

            {course.isApproved ? (
              <div className="pl-5 pr-5">
                <button
                  onClick={(e) => deactivateCourse(e, course.id)}
                  type="button"
                  className="rounded-md bg-red-600 text-white font-light bg-[#142850] hover:bg-green-600 px-2 py-1"
                >
                  Gizle
                </button>
              </div>
            ) : (
              <button
                onClick={(e) => approveCourse(e, course.id)}
                type="button"
                className="rounded-md bg-red-600 text-white font-light bg-[#142850] hover:bg-green-600 px-2 py-1"
              >
                Aktifleştir
              </button>
            )}

            <ToastContainer />
          </td>
        </tr>
      );
    });
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div>
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Kurslar
        </h1>
      </div>
      <div className="px-1">
        <div className="grid grid-cols-3 gap-y-3 gap-x-5 mt-2 w-full shadow-sm">
          <div className="md:col-span-1 col-span-4 mx-auto mt-3 border border-gray-300 rounded-lg items-center w-full">
            <input
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Hızlı ara..."
              type="text"
              name="search"
              id="search"
              className="block w-full h-8 p-3 rounded-md border-gray-500 pr-12 shadow-sm sm:text-sm"
            />
          </div>
          <div className="border border-gray-300 rounded-lg md:col-span-1 col-span-4 mx-auto mt-3 items-center w-full">
            <select
              onChange={(e) => handleMain(e)}
              value={mainId}
              className="text-black p-1 w-full"
              placeholder="asd"
            >
              <option>Ana Kategori Seçin</option>
              {mainCategories?.map((main) => (
                <option key={main.id} value={main.id}>
                  {main.name}
                </option>
              ))}
            </select>
          </div>
          <div className="border border-gray-300 rounded-lg md:col-span-1 col-span-4 mx-auto mt-3 items-center w-full">
            <select
              value={subTest}
              onChange={(e) => setSubTest(e.target.value)}
              className="text-black p-1 w-full"
            >
              <option>Alt Kategori Seçin</option>
              {subCat.data?.map((sub) => (
                <option key={sub.id}>{sub.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex gap-x-5 mt-2 justify-end">
          <div className="sm:items-center mt-5">
            <button
              className="items-center rounded-lg shadow-xl border-2 border-gray-200 bg-white px-4 py-2 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              onClick={(e) => handleClear(e)}
            >
              Temizle
            </button>
          </div>
          <div className="sm:items-center mt-5">
            <Link to={"/adminpanel/kurslar/ekle"}>
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border border-gray-200 bg-[#142850] px-4 py-2 text-md font-medium hover:bg-blue-700 text-white focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-700"
              >
                Yeni Ekle +
              </button>
            </Link>
          </div>
        </div>

        <div className="-mx-4 mt-5 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Kurs Adı
                </th>
                <th
                  scope="col"
                  className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:table-cell"
                >
                  Ana Kategorisi
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Alt Kategorisi
                </th>

                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Bilgi
                </th>

                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Seç</span>
                </th>
              </tr>
            </thead>
            <tbody>{displayCourses}</tbody>
          </table>
        </div>
      </div>
      <div className="mt-5 mb-10">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
        />
      </div>
    </div>
  );
}

export default AdminCourses;
