import React, { useEffect } from "react";
//import img3 from "../../../assets/plus.png";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
//import { BiLeftArrowAlt } from "react-icons/bi";
import { deleteRole, getRoles } from "../../../store/features/AdminSlice";

function AdminRoles() {
  const notifyErr = () => toast.warning("İşlem Başarısız!");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoles(JSON.parse(jwtTokenRedux)));
  }, []);
  const roles = useSelector((state) => state.AdminSlice.roles);

  const handleClick = (id) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            await dispatch(deleteRole({ data: JSON.parse(jwtTokenRedux), id }));
            dispatch(getRoles(getRoles(JSON.parse(jwtTokenRedux))));
            Swal.fire("Silindi!");
          } catch (error) {
            console.log(error);
            notifyErr();
          }
        };
        submit();
      }
    });
  };

  return (
    <div className="mb-20">
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Roller
        </h1>
      </div>

      <div className="flex sm:items-center justify-center">
        <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none">
          <Link to={"/adminpanel/roller/ekle"}>
            <button
              type="button"
              className="items-center rounded-md border bg-[#142850] border-gray-200 px-2 py-2 text-md font-medium text-white hover:bg-blue-700 focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-700"
            >
              Yeni Ekle +
            </button>
          </Link>
        </div>
      </div>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-5"
      >
        {!!roles &&
          roles?.map((user) => (
            <li
              key={user?.id}
              className="col-span-1 border flex flex-col divide-y divide-gray-300 rounded-lg bg-white text-center shadow-lg"
            >
              <div className="flex flex-1 flex-col p-8 border">
                <img
                  className="mx-auto w-32 flex-shrink-0 rounded-full"
                  //todo: this picture have to moved assets
                  src="https://www.nicepng.com/png/detail/136-1366211_group-of-10-guys-login-user-icon-png.png"
                  alt="user"
                />
                <h3 className="mt-6 text-sm font-medium text-gray-900">
                  {user?.name}
                </h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dt className="sr-only">Title</dt>
                  <dt className="sr-only">Role</dt>
                  <dd className="mt-3">
                    <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                      {user?.name}
                    </span>
                  </dd>
                </dl>
              </div>
              <div>
                <div className="flex justify-center space-x-8 shadow-md">
                  <div className="flex">
                    <button
                      onClick={() => handleClick(user?.id)}
                      className="rounded-md px-2 py-1 bg-white font-medium text-[#142850] hover:text-blue-700"
                    >
                      Sil
                    </button>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default AdminRoles;
