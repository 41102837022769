import React, { useState, useEffect, useReducer } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


function EducatorCommentSection() {
    //const notify = () => toast.success("Silindi!");
    //const notifyErr = () => toast.error("Silinemedi!");
    const dispatch = useDispatch();
  
    const [courseComments, setCourseComments] = useState("");
    const jwtToken = useSelector((state) => state.login.accessToken);
    const [pageNumber, setPageNumber] = useState(0);
    const coursesPerPage = 9;
    const [dataCount, setDataCount] = useState(null);
    const pageCount = Math.ceil(dataCount / coursesPerPage);

    const headerOptions = {
      headers: {
        'Authorization': `Bearer ${JSON.parse(jwtToken)}`,
        'Accept': "text/plain"
      }
     }
  
  
    const courseCommnets = (pageNumber) => 
    {
      const fetchComments = async () => {
        try {
          const response = await axios(
            `${
              process.env.REACT_APP_BASE_URL
            }/Instructor/CourseComments?PageSize=${coursesPerPage}&PageNumber=${pageNumber + 1}`,
            headerOptions
          );
          setDataCount(response.data.metaData.dataCount);
          setCourseComments(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchComments();
    }

    useEffect(() => {
      courseCommnets(pageNumber);
    }, [pageNumber]);
  
    const deleteComment = (id) => {
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Vazgeç",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet!",
      }).then((result) => {
        if (result.isConfirmed) {
              axios.delete(
                `${process.env.REACT_APP_BASE_URL}/Instructor/Course/DeleteCourseComment?id=${id}`,
                headerOptions)
                .then((data)=>{

                Swal.fire("Başarı ile silindi")
                dispatch(courseCommnets(pageNumber));
                
              })
              .catch((error)=> {
               
               //alert("im here");
              });
            }
        
      });
    };
    const handleCheck = (id) => {
        Swal.fire({
            title: "Yorumun gürünürlüğü değiştirilmek üzere?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Vazgeç",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Evet!",
          }).then((result) => {
            if (result.isConfirmed) {
              axios.patch(
                `${process.env.REACT_APP_BASE_URL}/Instructor/Course/ChangeCommentStatus?id=${id}`,
                {

                },
                headerOptions
              ).then(data=>{

                Swal.fire("Başarı ile değiştirildi!")
                dispatch(courseCommnets(pageNumber));
                
              })
              .catch(error=> {
               
               //alert("im here");
              });
            
            
            }
            
          });

      
    };
  
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };
  
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <h1 className="text-lg px-5 py-4 text-[#2196f3] font-bold">
          Kurs Yorumları
        </h1>
  
        <div className="divide-y divide-x divide-gray-200 overflow-hidden rounded-lg border-2 bg-gray-200 shadow sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-px">
          {courseComments &&
            courseComments?.map((item, actionIdx) => (
              <div
                key={item.id}
                className={classNames(
                  actionIdx === 0
                    ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                    : "",
                  actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                  actionIdx === courseComments.length - 2
                    ? "sm:rounded-bl-lg"
                    : "",
                  actionIdx === courseComments.length - 1
                    ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                    : "",
                  "relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-700 hover:bg-blue-100"
                )}
              >
                <div className="rounded-lg inline-flex p-3">
                  {item.created.slice(8, 10)}.{item.created.slice(5, 7)}.
                  {item.created.slice(0, 4)}
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={() => deleteComment(item.id)}
                    type="button"
                    className="rounded-md font-normal border-2 hover:bg-[#142850] hover:text-white px-2"
                  >
                    Sil
                    <span className="sr-only">Sil</span>
                  </button>
                  <ToastContainer />
                </div>
                <div className="flex justify-start align-center items-center gap-x-2">
                  <input
                    className="h-4 w-4 pb-0 rounded border-gray-300 text-[#142850] focus:ring-blue-700"
                    type="checkbox"
                    onClick={() => handleCheck(item.id)}
                    checked={item.isAvailable}
                  />
                  <button
                    onClick={() => handleCheck(item.id)}
                    type="button"
                    className="rounded-md cursor-pointer font-medium text-[#142850] hover:text-blue-700 hover:underline"
                  >
                    Görünürlük
                    <span className="sr-only">Görünürlük</span>
                  </button>
                </div>
  
                <div className="mt-4">
                  <h3 className="text-lg font-medium">{item.courseName}</h3>
                  <span className="text-gray-500 text-xs">
                    {item.userFullName}
                  </span>
                  <br />
                  <span className="text-gray-500 text-xs">
                    Puan: {item.rateScore}/5
                  </span>
  
                  <p className="mt-2 text-sm text-gray-500">{item.comment}</p>
                </div>
  
                <Link
                  to={`/tumkurslar`}
                  className="absolute top-6 right-6 cursor-pointer text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </Link>
              </div>
            ))}
        </div>
        <div className="mt-5">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
          />
        </div>
      </div>
    );
}

export default EducatorCommentSection