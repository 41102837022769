import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import AdminShoppingModal from "./AdminShoppingModal";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { getShopping } from "../../../store/features/AdminSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function AdminShopping() {
  const dispatch = useDispatch();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [pageNumber, setPageNumber] = useState(0);
  const shoppingPerPage = 10;
  const [selectedData, setSelectedData] = useState("");
  const [isOpen, setisOpen] = useState(false);
  const dataCount = useSelector((state) => state.AdminSlice.pageCount);
  const shoppingData = useSelector((state) => state.AdminSlice.shopping);
  const pageCount = Math.ceil(dataCount.dataCount / shoppingPerPage);

  useEffect(() => {
    dispatch(getShopping({ data: JSON.parse(jwtTokenRedux), pageNumber }));
  }, [pageNumber]);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleShoppingDetailsModal = (person) => {
    setSelectedData(person);
    setisOpen(true);
  };

  return (
    <div className="relative mb-20">
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Alışveriş
        </h1>
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Alışveriş ve Ödeme Detayları
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Site içi yapılan satın alma detaylarını buradan
              inceleyebilirsiniz.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to="/adminpanel/odemeler"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-[#142850] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
            >
              Ödemeler
            </Link>
          </div>
        </div>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        Kullanıcı
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                      >
                        Tarih
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Satın Alınan Kurslar
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {shoppingData &&
                      shoppingData?.map((person, personIdx) => (
                        <tr key={person.id}>
                          {person.userDetails?.map((item) => (
                            <td
                              key={item.id}
                              className={classNames(
                                "border-b border-gray-200 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {item.firstName} {item.lastName}
                            </td>
                          ))}

                          <td
                            className={classNames(
                              "border-b border-gray-200 whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
                            )}
                          >
                            {person.created.slice(8, 10)}.
                            {person.created.slice(5, 7)}.
                            {person.created.slice(0, 4)}
                          </td>

                          <td
                            className={classNames(
                              "border-b border-gray-200 whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell"
                            )}
                          >
                            {person.shoppingDetails?.map((item) => (
                              <p key={item.id}> {item.course.name}</p>
                            ))}
                          </td>

                          <td
                            className={classNames(
                              "border-b border-gray-200 relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8"
                            )}
                          >
                            <button
                              onClick={() => handleShoppingDetailsModal(person)}
                              className="inline-flex items-center justify-center rounded-md border border-transparent bg-[#142850] px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-blue-700 sm:w-auto"
                            >
                              İncele
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
        />
      </div>
      {isOpen && (
        <AdminShoppingModal
          isOpen={isOpen}
          setisOpen={setisOpen}
          selectedData={selectedData}
        />
      )}
    </div>
  );
}

export default AdminShopping;
