import { NavLink, useParams } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { AiOutlineDelete } from "react-icons/ai";
import { useReducer, useState, useEffect } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useSelector } from "react-redux";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";

function AddQToExam() {
  const id = useParams();
  const jwtToken = useSelector((state) => state.login.accessToken);
  const [question, setQuestion] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [loading, setLoading] = useState(false);
  let answers = [answer1, answer2, answer3];
  const [examQuestions, setExamQuestions] = useState("");
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const fetchExamQuestions = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Exam/${id.examId}`,
          {
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${JSON.parse(jwtToken)}`,
            },
          }
        );
        setExamQuestions(response.data.data[0].questions);
      } catch (error) {
        console.log(error);
      }
    };
    fetchExamQuestions();
  }, [reducerValue]);

  const postArray = () => {
    Swal.fire({
      title: "Yeni soru eklensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const response = async () => {
          try {
            await axios(
              `${process.env.REACT_APP_BASE_URL}/Exam/AddQuestionToExam`,
              {
                method: "post",
                headers: {
                  "Content-Type": "application/json-patch+json",
                  accept: "*/*",
                  Authorization: `Bearer ${JSON.parse(jwtToken)}`,
                },
                data: {
                  examId: id.examId,
                  questionText: question,
                  correctAnswer: correctAnswer,
                  otherAnswers: answers,
                },
              }
            );
            Swal.fire("Yeni soru eklendi!");
            forceUpdate();
            setLoading(false);
            setQuestion("");
            setCorrectAnswer("");
            setAnswer1("");
            setAnswer2("");
            setAnswer3("");
          } catch (error) {
            console.log(error);
            Swal.fire("Hata!");
            setLoading(false);
          }
        };
        response();
      }
    });
  };

  const deleteQuestion = (id) => {
    Swal.fire({
      title: "Soru silinsin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteIt = async () => {
          try {
            await axios.delete(
              `${process.env.REACT_APP_BASE_URL}/Exam/RemoveQustion?questionId=${id}`,
              {
                headers: {
                  accept: "*/*",
                  Authorization: `Bearer ${JSON.parse(jwtToken)}`,
                },
              }
            );
            Swal.fire("Silindi!");
            forceUpdate();
          } catch (error) {
            console.log(error);
            Swal.fire("Hata!");
          }
        };
        deleteIt();
      }
    });
  };

  return (
    <>
      <div className="flex mb-3 mt-5">
        <NavLink
          to={`/adminpanel/kurslar/sinavlar/${id.id}`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Sorular
        </h1>
      </div>
      <div className="mt-10 text-lg text-gray-800">Soru</div>
      <div className="mt-1 grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-10">
        <div className="col-span-1">
          <div className="mt-1 shadow-xl border border-gray-300 rounded-md">
            <textarea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              rows={5}
              name="comment"
              id="comment"
              className="p-2 block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-600 sm:text-sm"
              defaultValue={""}
            />
          </div>
        </div>
        <div className="col-span-1">
          <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-BLUE-600">
            <label
              htmlFor="name"
              className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Doğru Cevap
            </label>
            <input
              value={correctAnswer}
              onChange={(e) => setCorrectAnswer(e.target.value)}
              type="text"
              name="correct-answer"
              id="correct-answer"
              className="block w-full p-1 text-gray-900 sm:text-sm"
            />
          </div>
          <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-BLUE-600">
            <label
              htmlFor="name"
              className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Cevap
            </label>
            <input
              value={answer1}
              onChange={(e) => setAnswer1(e.target.value)}
              type="text"
              name=""
              id=""
              className="block w-full p-1 text-gray-900 sm:text-sm"
            />
          </div>
          <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-BLUE-600">
            <label
              htmlFor="name"
              className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Cevap
            </label>
            <input
              value={answer2}
              onChange={(e) => setAnswer2(e.target.value)}
              type="text"
              name=""
              id=""
              className="block w-full p-1 text-gray-900 sm:text-sm"
            />
          </div>
          <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-BLUE-600">
            <label
              htmlFor="name"
              className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Cevap
            </label>
            <input
              value={answer3}
              onChange={(e) => setAnswer3(e.target.value)}
              type="text"
              name=""
              id=""
              className="block w-full p-1 text-gray-900 sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div className="flex md:justify-end justify-center border-b-2 border-gray-300 pb-5">
        {loading ? (
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{ margin: "0 auto" }}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        ) : (
          <button
            onClick={postArray}
            className="inline-flex items-center rounded-md border shadow-lg bg-blue-100 px-3 py-2 mt-5 text-sm font-medium leading-4 text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Soru Ekle
          </button>
        )}
      </div>

      <div className="mt-10 mb-20">
        <div className="text-xl font-semibold text-gray-800 text-center">
          Sorular
        </div>
        <ol class="list-decimal">
          {examQuestions &&
            examQuestions?.map((item) => (
              <li>
                <div className="w-full px-4 pt-5">
                  <div className="mx-auto w-full rounded-lg bg-white p-2">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-700 hover:bg-blue-200  focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                            <span>{item.text}</span>

                            <ChevronUpIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-blue-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-10 pt-4 pb-2 text-sm">
                            <ol className="list-[upper-roman]">
                              {item.answers?.map((answer) => (
                                <li
                                  className={`${
                                    answer.isTrue
                                      ? `text-green-500 font-semibold`
                                      : `text-gray-700`
                                  }`}
                                >
                                  {answer.text}
                                </li>
                              ))}
                            </ol>
                            <div className="flex justify-end border-t pt-2 mt-5">
                              <button
                                onClick={() => deleteQuestion(item.id)}
                                className="flex items-center gap-x-1 hover:scale-105"
                              >
                                Sil
                                <AiOutlineDelete className="h-6 w-6 font-bold" />
                              </button>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </li>
            ))}
        </ol>
      </div>
    </>
  );
}

export default AddQToExam;
