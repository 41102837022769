import React, { useEffect, useState } from "react";
import {
  PhoneIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import "./AdminUsers.css";
import { getUsers } from "../../../store/features/AdminSlice";
import axios from "axios";

function AdminUsers() {
  const dispatch = useDispatch();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const [search, setSearch] = useState("");
  const dataCount = useSelector((state) => state.AdminSlice.pageCount);
  const pageCount = Math.ceil(dataCount.dataCount / usersPerPage);
  useEffect(() => {
    dispatch(getUsers({ data: JSON.parse(jwtTokenRedux), pageNumber }));
  }, [pageNumber]);
  const userss = useSelector((state) => state.AdminSlice.users);

  const [users, setUsers] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [mainId, setMainId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [identityNumber, setIdentityNumber] = useState("");

  useEffect(() => {
    //setLoading(true);
    const fetchTodos = async () => {
      try {
        const response = await axios(
          `${
            process.env.REACT_APP_BASE_URL
          }/Admin/User/GetAllUser?IdentityNumber=${identityNumber}&Email=${email}&FirstName=${firstName}&LastName=${lastName}&PageNumber=${
            pageNumber + 1
          }`,
          {
            headers: {
              accept: "text/plain",
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            },
          }
        );
        setUsers(response.data.data);
      } catch (error) {
        console.log(error);
        //setLoading(true);
      } finally {
        //setLoading(false);
      }
    };
    fetchTodos();
  }, [pageNumber, firstName, lastName, email, identityNumber]);

  const displayUsers =
    users &&
    users
      ?.filter(
        (user) =>
          user.firstName
            ?.toLocaleLowerCase("tr-TR")
            .includes(search.toLocaleLowerCase("tr-TR")) ||
          user.lastName
            ?.toLocaleLowerCase("tr-TR")
            .includes(search.toLocaleLowerCase("tr-TR")) ||
          user.email
            ?.toLocaleLowerCase("tr-TR")
            .includes(search.toLocaleLowerCase("tr-TR")) ||
          user.phoneNumber
            ?.toLocaleLowerCase("tr-TR")
            .includes(search.toLocaleLowerCase("tr-TR"))
      )
      .map((user) => {
        return (
          <li key={user.id}>
            <Link
              to={`/adminpanel/kullanicilar/duzenle/${user.id}`}
              className="block hover:bg-gray-50"
            >
              <div
                className={`flex items-center px-4 py-4 sm:px-6 ${
                  user.isActive ? "bg-green-200" : "bg-red-200"
                }`}
              >
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-12 w-12 rounded-full"
                      src={`${user.profilePicturePath}`}
                      alt="profil-fotoğrafı"
                    />
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="truncate text-sm font-medium text-black">
                        {user.firstName} {user.lastName}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        {user.userName}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <EnvelopeIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="truncate">{user.email}</span>
                      </p>
                    </div>
                    <div className="hidden md:block">
                      <div>
                        <p className="text-sm text-gray-900">
                          Sehir: {user.city}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <PhoneIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {user.phoneNumber}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        );
      });
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="mb-10">
        <div className="text-center mb-5 border-b">
          <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
            Kullanıcılar
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-5 mx-10 md:mx-3">
          <div className="mx-auto mt-1">
            <input
              className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={"Mail adresine göre ara"}
            />
          </div>
          <div className="mx-auto mt-1">
            <input
              className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md "
              type="text"
              value={identityNumber}
              onChange={(e) => setIdentityNumber(e.target.value)}
              placeholder={"Kimlik numarasına göre ara"}
            />
          </div>
          <div className="mx-auto mt-1">
            <input
              className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md "
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder={"İsme göre ara"}
            />
          </div>
          <div className="mx-auto mt-1">
            <input
              className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder={"Soyada göre ara"}
            />
          </div>
        </div>

        <div className="flex sm:items-center justify-center mx-auto sm:mx-0 mb-10 mt-5">
          <div className="mt-4 sm:mt-0 lg:ml-auto sm:flex-none ">
            <Link to={"/adminpanel/kullanicilar/ekle"}>
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border border-gray-300 bg-white w-64 px-2 py-2 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Yeni Kullanıcı Ekle
              </button>
            </Link>
          </div>
        </div>

        <div className="overflow-hidden bg-white shadow sm:rounded-md mb-10 mx-10 md:mx-3 p-1 border border-gray-200">
          <ul className="divide-y divide-gray-200">{displayUsers}</ul>
        </div>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
        />
      </div>
    </>
  );
}

export default AdminUsers;
