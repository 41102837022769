export const cityTest = [
    {
      "ulke": "Türkiye",
      "bolge": "AKDENİZ",
      "il": "ADANA",
      "plaka": 1
    },
    {
      "ulke": "Türkiye",
      "bolge": "GÜNEYDOĞU ANADOLU",
      "il": "ADIYAMAN",
      "plaka": 2
    },
    {
      "ulke": "Türkiye",
      "bolge": "EGE",
      "il": "AFYONKARAHİSAR",
      "plaka": 3
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "AĞRI",
      "plaka": 4
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "AMASYA",
      "plaka": 5
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "ANKARA",
      "plaka": 6
    },
    {
      "ulke": "Türkiye",
      "bolge": "AKDENİZ",
      "il": "ANTALYA",
      "plaka": 7
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "ARTVİN",
      "plaka": 8
    },
    {
      "ulke": "Türkiye",
      "bolge": "EGE",
      "il": "AYDIN",
      "plaka": 9
    },
    {
      "ulke": "Türkiye",
      "bolge": "MARMARA",
      "il": "BALIKESİR",
      "plaka": 10
    },
    {
      "ulke": "Türkiye",
      "bolge": "MARMARA",
      "il": "BİLECİK",
      "plaka": 11
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "BİNGÖL",
      "plaka": 12
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "BİTLİS",
      "plaka": 13
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "BOLU",
      "plaka": 14
    },
    {
      "ulke": "Türkiye",
      "bolge": "AKDENİZ",
      "il": "BURDUR",
      "plaka": 15
    },
    {
      "ulke": "Türkiye",
      "bolge": "MARMARA",
      "il": "BURSA",
      "plaka": 16
    },
    {
      "ulke": "Türkiye",
      "bolge": "MARMARA",
      "il": "ÇANAKKALE",
      "plaka": 17
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "ÇANKIRI",
      "plaka": 18
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "ÇORUM",
      "plaka": 19
    },
    {
      "ulke": "Türkiye",
      "bolge": "EGE",
      "il": "DENİZLİ",
      "plaka": 20
    },
    {
      "ulke": "Türkiye",
      "bolge": "GÜNEYDOĞU ANADOLU",
      "il": "DİYARBAKIR",
      "plaka": 21
    },
    {
      "ulke": "Türkiye",
      "bolge": "MARMARA",
      "il": "EDİRNE",
      "plaka": 22
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "ELAZIĞ",
      "plaka": 23
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "ERZİNCAN",
      "plaka": 24
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "ERZURUM",
      "plaka": 25
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "ESKİŞEHİR",
      "plaka": 26
    },
    {
      "ulke": "Türkiye",
      "bolge": "GÜNEYDOĞU ANADOLU",
      "il": "GAZİANTEP",
      "plaka": 27
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "GİRESUN",
      "plaka": 28
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "GÜMÜŞHANE",
      "plaka": 29
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "HAKKARİ",
      "plaka": 30
    },
    {
      "ulke": "Türkiye",
      "bolge": "AKDENİZ",
      "il": "HATAY",
      "plaka": 31
    },
    {
      "ulke": "Türkiye",
      "bolge": "AKDENİZ",
      "il": "ISPARTA",
      "plaka": 32
    },
    {
      "ulke": "Türkiye",
      "bolge": "AKDENİZ",
      "il": "MERSİN",
      "plaka": 33
    },
    {
      "ulke": "Türkiye",
      "bolge": "MARMARA",
      "il": "İSTANBUL",
      "plaka": 34
    },
    {
      "ulke": "Türkiye",
      "bolge": "EGE",
      "il": "İZMİR",
      "plaka": 35
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "KARS",
      "plaka": 36
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "KASTAMONU",
      "plaka": 37
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "KAYSERİ",
      "plaka": 38
    },
    {
      "ulke": "Türkiye",
      "bolge": "MARMARA",
      "il": "KIRKLARELİ",
      "plaka": 39
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "KIRŞEHİR",
      "plaka": 40
    },
    {
      "ulke": "Türkiye",
      "bolge": "MARMARA",
      "il": "KOCAELİ",
      "plaka": 41
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "KONYA",
      "plaka": 42
    },
    {
      "ulke": "Türkiye",
      "bolge": "EGE",
      "il": "KÜTAHYA",
      "plaka": 43
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "MALATYA",
      "plaka": 44
    },
    {
      "ulke": "Türkiye",
      "bolge": "EGE",
      "il": "MANİSA",
      "plaka": 45
    },
    {
      "ulke": "Türkiye",
      "bolge": "AKDENİZ",
      "il": "KAHRAMANMARAŞ",
      "plaka": 46
    },
    {
      "ulke": "Türkiye",
      "bolge": "GÜNEYDOĞU ANADOLU",
      "il": "MARDİN",
      "plaka": 47
    },
    {
      "ulke": "Türkiye",
      "bolge": "EGE",
      "il": "MUĞLA",
      "plaka": 48
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "MUŞ",
      "plaka": 49
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "NEVŞEHİR",
      "plaka": 50
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "NİĞDE",
      "plaka": 51
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "ORDU",
      "plaka": 52
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "RİZE",
      "plaka": 53
    },
    {
      "ulke": "Türkiye",
      "bolge": "MARMARA",
      "il": "SAKARYA",
      "plaka": 54
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "SAMSUN",
      "plaka": 55
    },
    {
      "ulke": "Türkiye",
      "bolge": "GÜNEYDOĞU ANADOLU",
      "il": "SİİRT",
      "plaka": 56
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "SİNOP",
      "plaka": 57
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "SİVAS",
      "plaka": 58
    },
    {
      "ulke": "Türkiye",
      "bolge": "MARMARA",
      "il": "TEKİRDAĞ",
      "plaka": 59
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "TOKAT",
      "plaka": 60
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "TRABZON",
      "plaka": 61
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "TUNCELİ",
      "plaka": 62
    },
    {
      "ulke": "Türkiye",
      "bolge": "GÜNEYDOĞU ANADOLU",
      "il": "ŞANLIURFA",
      "plaka": 63
    },
    {
      "ulke": "Türkiye",
      "bolge": "EGE",
      "il": "UŞAK",
      "plaka": 64
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "VAN",
      "plaka": 65
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "YOZGAT",
      "plaka": 66
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "ZONGULDAK",
      "plaka": 67
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "AKSARAY",
      "plaka": 68
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "BAYBURT",
      "plaka": 69
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "KARAMAN",
      "plaka": 70
    },
    {
      "ulke": "Türkiye",
      "bolge": "İÇ ANADOLU",
      "il": "KIRIKKALE",
      "plaka": 71
    },
    {
      "ulke": "Türkiye",
      "bolge": "GÜNEYDOĞU ANADOLU",
      "il": "BATMAN",
      "plaka": 72
    },
    {
      "ulke": "Türkiye",
      "bolge": "GÜNEYDOĞU ANADOLU",
      "il": "ŞIRNAK",
      "plaka": 73
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "BARTIN",
      "plaka": 74
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "ARDAHAN",
      "plaka": 75
    },
    {
      "ulke": "Türkiye",
      "bolge": "DOĞU ANADOLU",
      "il": "IĞDIR",
      "plaka": 76
    },
    {
      "ulke": "Türkiye",
      "bolge": "MARMARA",
      "il": "YALOVA",
      "plaka": 77
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "KARABÜK",
      "plaka": 78
    },
    {
      "ulke": "Türkiye",
      "bolge": "GÜNEYDOĞU ANADOLU",
      "il": "KİLİS",
      "plaka": 79
    },
    {
      "ulke": "Türkiye",
      "bolge": "AKDENİZ",
      "il": "OSMANİYE",
      "plaka": 80
    },
    {
      "ulke": "Türkiye",
      "bolge": "KARADENİZ",
      "il": "DÜZCE",
      "plaka": 81
    },
    {
      "ulke": "K.K.T.C.",
      "bolge": "Deniz",
      "il": "Lefkoşa",
      "plaka": 82
    },
    {
      "ulke": "K.K.T.C.",
      "bolge": "Deniz",
      "il": "Gazimağusa",
      "plaka": 83
    },
    {
      "ulke": "K.K.T.C.",
      "bolge": "Deniz",
      "il": "Girne",
      "plaka": 84
    },
    {
      "ulke": "K.K.T.C.",
      "bolge": "Deniz",
      "il": "Güzelyurt",
      "plaka": 85
    },
    {
      "ulke": "K.K.T.C.",
      "bolge": "Deniz",
      "il": "İskele",
      "plaka": 86
    },
  ]