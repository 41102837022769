import React from "react";
import { ToastContainer } from "react-toastify";

const AddModal = ({ setModalOn, setChoice, action, setType}) => {
  const handleOKClick = (e) => {
    setChoice(true);
    setModalOn(false);
    action(e);
  };
  const handleCancelClick = () => {
    setChoice(false);
    setModalOn(false);
  };
  return (
    <>
      <div className="opacity-100 fixed inset-0 z-50">
      <ToastContainer />
        <div className="flex h-screen justify-center items-center">
          <div className="flex-col justify-center bg-white py-12 px-24 border-4 rounded-lg">
            <div className="flx text-lg text-black mb-10">
              Eklemek istediğinizden emin misiniz?
            </div>
            <div className="flex w-full">
              <button
                onClick={handleOKClick}
                className="rounded text-white px-4 py-2 bg-red-600 hover:scale-105 duration-500 w-full"
              >
                Ekle
              </button>
              <button
                onClick={handleCancelClick}
                className="rounded px-4 py-2 ml-4 text-white bg-blue-600 hover:scale-105 duration-500 w-full"
              >
                İptal
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddModal;
