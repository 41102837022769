import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { errors } from "../../../data/errors";

const AddUserCourse = () => {
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const [dataCount, setDataCount] = useState();
  const pageCount = Math.ceil(dataCount / usersPerPage);

  const notifySuccess = () => toast.success("Kurs kullanıcıya eklendi!");
  const notifyError = () => toast.error("Kurs eklenemedi!");

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [courses, setCourses] = useState("");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const getUserCourses = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Course?pageNumber=${pageNumber + 1}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            accept: "application/json",
          },
        }
      );
      setCourses(response.data.data);
      setDataCount(response.data.metaData.dataCount);
    } catch (error) {
      notifyError();
    }
  };
  useEffect(() => {
    getUserCourses();
  }, [pageNumber]);

  const addUserCourse = async (userId) => {
    try {
      await axios(`${process.env.REACT_APP_BASE_URL}/Admin/User/AddCourse`, {
        method: "post",
        headers: {
          Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          "Content-Type": "application/json-patch+json",
        },
        data: {
          userId: id,
          courseId: userId,
        },
      });
      notifySuccess();
    } catch (error) {
      let isEqual = false;
      for (let index = 0; index < errors.length; index++) {
        if (error.response.data.details.errorCodes === errors[index].id) {
         isEqual = true;
          return toast.error(errors[index].content);
        } else if (error.response.data.details.errorCodes === "999") {
          isEqual = true;
          return toast.error(error.response.data.details.message);
        } else {
          isEqual = false;
        }
      }
      if (!isEqual) {
        toast.error(
          "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
        );
      }
    }
  };

  const sweetAlert = (id, e) => {
    e.preventDefault();
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "bg-green-400 px-8 py-3 rounded-md ml-3",
        cancelButton: "bg-red-400 px-8 py-3 rounded-md",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title:
          "Kurs kullanıcıya eklensin mi?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
        reverseButtons: true,
        width: "750px",
      })
      .then((result) => {
        if (result.isConfirmed) {
          addUserCourse(id);
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "İşlem iptal edildi!",
            icon: "error",
          });
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <div className=" sm:justify-between grid grid-cols-12 px-10 mb-5 mt-5">
        <div className="sm:items-center  mb-5 col-span-12 md:col-span-6 mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto ">
            <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
              <input
                className="border-[0.5px] border-gray-300 shadow-md text-black px-3 py-2 rounded-md w-64"
                type="text"
                value={search}
                placeholder="Kurs Arama"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none ">
            <Link to={`/adminpanel/kullanicilar/duzenle/kursekle/${id}`}>
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border border-gray-200 bg-white w-64 px-4 py-2.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Geri
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="ml-4 mt-5 ring-1 ring-gray-300 md:mx-0 md:rounded-lg ">
        <table className="min-w-full divide-y divide-gray-300 mb-5">
          <thead>
            <tr className="text-center ">
              <th
                scope="col"
                className="py-3.5  pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6 border-[1px]"
              >
                Kurs Kodu
              </th>
              <th
                scope="col"
                className=" py-3.5 text-center pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 lg:table-cell border-[1px]"
              >
                Kurs Adı
              </th>

              <th
                scope="col"
                className=" px-3 text-center py-3.5  text-sm font-semibold text-gray-900 lg:table-cell border-[1px]"
              >
                Kurs Ücreti
              </th>
              <th
                scope="col"
                className=" py-3.5 text-sm font-semibold text-gray-900 lg:table-cell border-[1px]"
              >
                {" "}
                Kurs Ekle
              </th>
            </tr>
          </thead>
          <tbody>
            {!!courses &&
              courses
                ?.filter((course) =>
                  course.name
                    .toLocaleLowerCase("tr-TR")
                    .includes(search.toLocaleLowerCase("tr-TR"))
                )
                .map((course) => (
                  <tr className="border-[1px] " key={course.id}>
                    <td className="border-[1px] py-3 pl-5">{course.code}</td>
                    <td className="border-[1px] pl-2 px-auto text-center">
                      {course.name}
                    </td>
                    <td className="border-[1px] pl-2 text-center">
                      {course.price} TL
                    </td>
                    <td className="text-center">
                      <button
                        onClick={(e) => sweetAlert(course.id, e)}
                        className="inline-flexitems-center rounded-md border border-gray-300 bg-white px-[26px] py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-blue-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30 gap-1"
                      >
                        Ekle
                      </button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      {dataCount > 10 ? (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      ) : null}
    </>
  );
};

export default AddUserCourse;
