import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";

function EditCourse() {
  const id = useParams();
  const [name, setName] = useState("");
  const jwtToken = useSelector((state) => state.login.accessToken);
  useEffect(() => {
    const result = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Course/${id.id}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(jwtToken)}`,
              accept: "application/json",
            },
          }
        );
        setName(response.data.data[0].name);
      } catch (error) {
        console.log(error);
      }
    };
    result();
  }, []);

  return (
    <>
      <div className="flex mb-3 mt-5">
        <NavLink
          to={`/adminpanel/kurslar`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <h2 className="text-gray-900 text-center font-semibold mb-5 mt-8 text-2xl border-b">
        {name}
      </h2>

      <div className="pt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-6 gap-x-5 px-12 md:px-0">
        
        <div className="py-8 lg:py-0 max-w-lg justify-center align-center items-center min-w-full">
          <Link
            to={`/adminpanel/kurslar/duzenle/kurs-bilgilerini-duzenle/${id.id}`}
          >
            <div className="grid justify-items-center items-center gap-y-5 border-2 border-gray-300 shadow-xl bg-gray-50 px-4 py-2 rounded-lg hover:bg-blue-100 cursor-pointer">
              <lord-icon
                src="https://cdn.lordicon.com/puvaffet.json"
                stroke="90"
                trigger="loop"
                delay="500"
                colors="primary:#0a2e5c,secondary:#848484"
                style={{ width: "70px", height: "70px" }}
              ></lord-icon>
              <span className="font-bold text-md text-center text-gray-800">
                Kurs Bilgilerini Düzenle
              </span>
            </div>
          </Link>
        </div>

        <div className="py-8 lg:py-0 max-w-lg justify-center align-center items-center min-w-full">
          <Link to={`/adminpanel/kurslar/duzenle/evrak-ekle/${id.id}`}>
            <div className="grid justify-items-center items-center gap-y-5 border-2 border-gray-300 shadow-xl bg-gray-50 px-4 py-2 rounded-lg hover:bg-blue-100 cursor-pointer">
              <lord-icon
                src="https://cdn.lordicon.com/zmenvmel.json"
                stroke="90"
                trigger="loop"
                delay="1000"
                colors="primary:#0a2e5c,secondary:#848484"
                style={{ width: "70px", height: "70px" }}
              ></lord-icon>
              <span className="font-bold text-md text-center text-gray-800">
                Gerekli Evrakları Belirle
              </span>
            </div>
          </Link>
        </div>
        <div className="py-8 lg:py-0 max-w-lg justify-center align-center items-center min-w-full">
          <Link to={`/adminpanel/kurslar/duzenle/dokuman-ekle/${id.id}`}>
            <div className="grid justify-items-center gap-y-5 border-2 border-gray-300 shadow-xl bg-gray-50 px-4 py-2 rounded-lg hover:bg-blue-100 cursor-pointer">
              <lord-icon
                src="https://cdn.lordicon.com/ybyshbog.json"
                stroke="90"
                trigger="loop"
                colors="primary:#0a2e5c,secondary:#848484"
                style={{ width: "70px", height: "70px" }}
              ></lord-icon>
              <span className="font-bold text-md text-center text-gray-800 tracking-wider">
                Kurs Dökümanı Ekle
              </span>
            </div>
          </Link>
        </div>
        <div className="py-8 lg:py-0 max-w-lg justify-center align-center items-center min-w-full">
          <Link
            to={`/adminpanel/kurslar/duzenle/seo-bilgilerini-duzenle/${id.id}`}
          >
            <div className="grid justify-items-center items-center gap-y-5 border-2 border-gray-300 shadow-xl bg-gray-50 px-4 py-2 rounded-lg hover:bg-blue-100 cursor-pointer">
              <lord-icon
                src="https://cdn.lordicon.com/urhcgqxz.json"
                stroke="90"
                trigger="loop"
                delay="1000"
                colors="primary:#0a2e5c,secondary:#848484"
                style={{ width: "70px", height: "70px" }}
              ></lord-icon>
              <span className="font-bold text-md text-center text-gray-800">
                SEO Bilgileri Düzenle
              </span>
            </div>
          </Link>
        </div>

        <div className="py-8 lg:py-0 max-w-lg justify-center align-center items-center min-w-full">
          <Link
            to={`/adminpanel/kurslar/incele/${id.id}`}
          >
            <div className="grid justify-items-center items-center gap-y-5 border-2 border-gray-300 shadow-xl bg-gray-50 px-4 py-2 rounded-lg hover:bg-blue-100 cursor-pointer">
              <lord-icon
                src="https://cdn.lordicon.com/qtbtspmd.json"
                stroke="90"
                trigger="loop"
                delay="1000"
                colors="primary:#0a2e5c,secondary:#848484"
                style={{ width: "70px", height: "70px" }}
              ></lord-icon>
              <span className="font-bold text-md text-center text-gray-800">
                Dersler
              </span>
            </div>
          </Link>
        </div>

        <div className="py-8 lg:py-0 max-w-lg justify-center align-center items-center min-w-full">
          <Link
            to={`/adminpanel/kurslar/sinavlar/${id.id}`}
          >
            <div className="grid justify-items-center items-center gap-y-5 border-2 border-gray-300 shadow-xl bg-gray-50 px-4 py-2 rounded-lg hover:bg-blue-100 cursor-pointer">
              <lord-icon
                src="https://cdn.lordicon.com/tgriojic.json"
                stroke="90"
                trigger="loop"
                delay="1000"
                colors="primary:#0a2e5c,secondary:#848484"
                style={{ width: "70px", height: "70px" }}
              ></lord-icon>
              <span className="font-bold text-md text-center text-gray-800">
                Sınavlar
              </span>
            </div>
          </Link>
        </div>

      </div>
    </>
  );
}

export default EditCourse;
