import React, { useEffect, useState } from "react";
import {
  InformationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import { FcGraduationCap } from "react-icons/fc";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { errors } from "../../../data/errors";
import { NavLink } from "react-router-dom";

function InstructorRequest() {
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [isInstructor, setInstructor] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Bilinmeyen bir hata oluştu. Lütfen bizimle iletisime geçiniz"
  );

  useEffect(() => {
    const fetchTodos = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/User/UserRoles`,
        {
          headers: {
            ContentType: "application/json-patch+json",
            accept: "text/plain",
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          },
        }
      );

      for (let index = 0; index <= response.data.data.length; index++) {
        if ("Eğitmen" === response.data.data[index].name) setInstructor(true);
        else setInstructor(false);
      }
    };
    fetchTodos();
  }, [jwtTokenRedux]);

  const instructorInformation = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Nasıl Eğitmen Olurum ? ",
      text: "Proin congue varius odio, eu elementum turpis dictum quis. Nam bibendum tellus at urna ultrices, a placerat tellus porttitor. Suspendisse ultricies ligula purus, tincidunt pharetra leo pretium ut. Cras et rhoncus massa. Vivamus augue enim, imperdiet placerat sapien pulvinar, ultricies tempus neque. Nam imperdiet malesuada nisi, ac dignissim mi fermentum sit amet. Sed eu odio quam. Vestibulum vitae dapibus elit. Aliquam nec lectus at enim fringilla consequat vulputate eu felis.Nulla sollicitudin vulputate dictum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent est eros, vehicula gravida laoreet vitae, porttitor ut nulla. Aliquam convallis dui magna, non iaculis nunc consectetur nec. Etiam tincidunt scelerisque massa, sed tincidunt leo porttitor non. Duis eleifend volutpat lacus et tempus. Mauris placerat fringilla risus, at suscipit est sodales eget. Ut convallis ipsum eu est euismod pharetra.Quisque mollis aliquam orci in auctor. Maecenas eget posuere enim. Cras hendrerit fermentum hendrerit. Aenean fringilla dui sit amet augue rutrum, consectetur cursus purus lacinia. Mauris blandit ac ex ullamcorper imperdiet. Quisque ullamcorper, tortor ac pulvinar fringilla, ex odio bibendum diam, in venenatis erat diam tempor lectus. Vivamus in sem felis. Curabitur et ligula ut sapien laoreet sodales sit amet ut tellus. Phasellus dignissim turpis a dapibus efficitur",
      icon: "info",
      showCancelButton: false,
      //   cancelButtonText: "Anladım",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Anladım",
      width: 1200,
    });
  };

  const handleShipping = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Eğitmen Talebi Göndermek Üzeresiniz?",
      //text: "(Adres bilgilerinizi profil sayfasından güncelleyebilirsiniz.)",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Gönder",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
          await axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/User/InstructorRequest`,
              {
                userMessage: "Eğitmen olmak istiyorum",
              },
              {
                headers: {
                  ContentType: "application/json-patch+json",
                  accept: "text/plain",
                  Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                },
              }
            )
            .then((data) => {
              Swal.fire("İşlem başarılı!");
            })
            .catch((error) => {
              for (let index = 0; index < errors.length; index++) {
                if (error.response.data.details.errorCodes === errors[index].id)
                  setErrorMessage(errors[index].content.toString());
              }
              let message = errorMessage;
              Swal.fire({
                title: "İşlem Başarısız!",
                text: message,
                confirmButtonText: "Tamam",
              });
            });
        };
        response();
      }
    });
  };

  return (
    <>
      <div className="mx-auto flex content-center mb-20 bg-white rounded-xl px-1.5">
        <div className="m-5 flex items-center">
          <div className="relative bg-white">
          <div className="flex mt-5 mb-5">
              <NavLink
                to={`/kullanicipaneli/anasayfa`}
                className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
              >
                <lord-icon
                  src="https://cdn.lordicon.com/zfxxckbp.json"
                  trigger="loop"
                  colors="primary:#4be1ec,secondary:#cb5eee"
                ></lord-icon>
                Geri
              </NavLink>
            </div>
            <div className="bg-blue-600 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2">
              <embed
                className="h-full w-full object-cover"
                src="https://images.pexels.com/photos/5797903/pexels-photo-5797903.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Sertifika"
              />
            </div>
        
            <div className="relative mx-auto max-w-7xl py-8 px-6 sm:py-12 lg:py-16">
              <div className="mx-auto max-w-2xl lg:mr-0 lg:ml-auto lg:w-1/2 lg:max-w-none lg:pl-10">
                <div>
                  <div className="flex h-12 w-12 items-center justify-center rounded-md text-white">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/1940/1940611.png"
                      alt=""
                      className="mobileimage hover:scale-110"
                    />
                  </div>
                </div>
                <p className="mt-6 text-lg text-gray-500">
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse eget neque magna. Aliquam ut leo pulvinar,
                  volutpat diam eget, dignissim risus. Pellentesque eget viverra
                  neque. Quisque ornare sollicitudin sollicitudin. Quisque sed
                  risus vestibulum, fringilla lectus quis, tincidunt leo.
                  Curabitur scelerisque metus vitae tortor auctor hendrerit.
                  Nunc interdum interdum porttitor.
                </p>
                <div className="mt-8 overflow-hidden">
                  <dl className="-mx-8 -mt-8 flex flex-wrap">
                    <div className="flex flex-col px-8 pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        Toplam eğitici
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[#142850] sm:text-3xl sm:tracking-tight">
                        100+
                      </dd>
                    </div>
                    <div className="flex flex-col px-8 pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        Toplam Kurs
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[#142850] sm:text-3xl sm:tracking-tight">
                        5000+
                      </dd>
                    </div>
                    <div className="flex flex-col px-8 pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        Sertifikalı Öğrenci
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[#142850] sm:text-3xl sm:tracking-tight">
                        70000+
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="rounded-md bg-blue-100 p-2 mt-20">
                  <div
                    className="flex cursor-pointer"
                    onClick={instructorInformation}
                  >
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className="h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 flex-1 md:flex">
                      <p className="text-sm text-blue-700">
                        Yasal yükümlülükler ve süreç hakkında
                      </p>
                    </div>
                  </div>
                </div>
                {isInstructor ? (
                  <div className="rounded-md bg-green-100 p-2 mt-5">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 flex-1">
                        <p className="text-sm font-medium text-green-800">
                          Zaten Eğitmensiniz.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-8">
                    <div className="flex justify-center text-center">
                      <button
                        onClick={(e) => handleShipping(e)}
                        className="inline-flex items-center justify-center px-6 py-3 w-full text-base font-medium rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Eğitmen Talebi Oluştur
                        <span className="p-2">
                          {" "}
                          <FcGraduationCap className="h-5 w-5" />
                        </span>
                      </button>
                      <ToastContainer />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InstructorRequest;
