export const errors = [
  {
    id: "101",
    content: "Kimlik numarası geçersiz!",
  },
  {
    id: "102",
    content: "Lütfen tekrar giriş yapın!",
  },
  {
    id: "103",
    content: "Kullanıcı bu kursa sahip değil!",
  },
  {
    id: "104",
    content: "Kursun hiç yorumu yok!",
  },
  {
    id: "105",
    content: "Bu belge mevcut değil!",
  },
  {
    id: "106",
    content: "Dosya adı yanlış!",
  },
  {
    id: "107",
    content: "Dosya silinemez!",
  },
  {
    id: "108",
    content: "Kullanıcı erişimi yok!",
  },
  {
    id: "109",
    content: "Böyle bir kullanıcı mevcut değil!",
  },
  {
    id: "110",
    content: "Alışveriş detayı yok!",
  },
  {
    id: "111",
    content: "Böyle bir alışveriş yok!",
  },
  {
    id: "112",
    content: "Bulunamadı!",
  },
  {
    id: "113",
    content: "Kullanıcı mevcut değil!",
  },
  {
    id: "114",
    content: "Bir hata oluştu! Tekrar deneyin!",
  },
  {
    id: "115",
    content: "Alt Kategori bulunamadı!",
  },
  {
    id: "116",
    content: "Kurs sepetinizde!",
  },
  {
    id: "117",
    content: "Kullanıcı bu kursa sahip!",
  },
  {
    id: "118",
    content: "Kullanıcının bu belgeye erişimi yok!",
  },
  {
    id: "119",
    content: "Kullanıcı kimliği yanlış!",
  },
  {
    id: "120",
    content: "Bu mail adresi zaten kullanımda!",
  },
  {
    id: "121",
    content: "Hesap aktif değil! Bizimle iletişime geçin. ",
  },
  {
    id: "122",
    content: "Lütfen e-mail adresinizi onaylayın!",
  },
  {
    id: "123",
    content: "Mail adresi veya şifre yanlış!",
  },
  {
    id: "124",
    content: "Parola eşleşmiyor!",
  },
  {
    id: "125",
    content: "Şifre değiştirilemez!",
  },
  {
    id: "126",
    content: "Role adı yanlış.",
  },
  {
    id: "127",
    content: "Kurs bulunamadı!",
  },
  {
    id: "128",
    content:
      "Lütfen kullanıcı adını, kimlik numarasını veya doğum yılını kontrol edin!",
  },
  {
    id: "129",
    content: "Bu hesap zaten onaylandı!",
  },

  {
    id: "131",
    content: "Kullanıcı zaten gruba ekli !",
  },
  {
    id: "132",
    content:
      "Kullanıcı ders videolarını izlemedi veya hesabını onaylamadı!",
  },

  {
    id: "133",
    content:
      "Kursun güncel grubu bulunmaktadır! Grup kapanınca tekrar deneyiniz.",
  },
  {
    id: "147",
    content:
      "Eklediğiniz linkte video bulunamamıştır! Lütfen linki konrol edin.!",
  },
  {
    id: "144",
    content: "Bu rol varsayılan roldür. Silinemez!",
  },

  {
    id: "145",
    content: "Kullanıcın rolü yoktur!",
  },

  {
    id: "146",
    content: "Bu rol zaten kullanıcıya tanımlı!",
  },

  {
    id: "999",
    content: "Geçersiz veya boş veri girdiniz!",
  },
  {
    id: "147",
    content: "Video linki hatalı. Kullanılan link herhangi bir video içermiyor!"
  },
  
  {
    id: "148",
    content: "Eğitmen rolü bulanamadı. Veritabanını kontrol ediniz!"
  },

  {
    id: "149",
    content: "Daha önceden eğitmen isteği gönderdiniz!"
  },

  {
    id: "150",
    content: "Kurs onaylı değil. Kursun admin tarafından onaylanması gerekmetedir!"
  },

  {
    id: "150",
    content: "Kurs onaylı değil!"
  },

  {
    id: "151",
    content: "Bu kullanıcı zaten eğitmen!"
  },

  {
    id: "152",
    content: "İki faktörlü dogrulama gerekli !"
  },

  {
    id: "153",
    content: "Hesap kilitlendi. Lütfen tekrar denemedn önce birkaç dakika bekleyiniz!"
  },
  // {
  //   id: "999",
  //   content: "Geçersiz veya boş veri girdiniz!"
  // },
  {
    id: "500",
    content: "Servis hatası! Lütfen daha sonra tekrar deneyiniz.",
  },
  {
    id: "401",
    content: "Önce giriş yapınız!",
  },
];
