import axios from "axios";
import { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

function PostExam({forceUpdate}) {
  const id = useParams();
  const jwtToken = useSelector((state) => state.login.accessToken);
  const [examName, setExamName] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [passNote, setPassNote] = useState("");
  const [loading, setLoading] = useState(false);

  const postExam = () => {
    Swal.fire({
      title: "Yeni sınav eklensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const response = async () => {
          try {
            await axios(`${process.env.REACT_APP_BASE_URL}/Exam`, {
              method: "post",
              headers: {
                "Content-Type": "application/json-patch+json",
                accept: "*/*",
                Authorization: `Bearer ${JSON.parse(jwtToken)}`,
              },
              data: {
                name: examName,
                description: description,
                duration: duration,
                passingScore: passNote,
                courseId: id.id,
              },
            });
            Swal.fire("Yeni sınav eklendi!");
            setLoading(false);
            forceUpdate();
          } catch (error) {
            console.log(error);
            Swal.fire("Hata!");
            setLoading(false);
          }
        };
        response();
      }
    });
  };
  return (
    <div className="mt-5 mb-10 bg-blue-100 py-4 border border-gray-200 rounded-lg">
      <div className="px-4 sm:px-6 grid grid-cols-1 md:grid-cols-2 gap-x-5 bg-blue-100">
        <div className="relative rounded-md border mb-10 bg-white border-gray-300 px-3 py-2">
          <label
            htmlFor="name"
            className="absolute -top-2 left-2 -mt-px inline-block bg-blue-100 rounded-md px-2 text-xs font-medium text-gray-900 after:ml-0.5 after:text-red-500"
          >
            Sınav Adı <span className="text-red-500">*</span>
          </label>
          <input
            value={examName}
            onChange={(e) => setExamName(e.target.value)}
            type="text"
            name=""
            id=""
            className="block w-full p-1 text-gray-900 sm:text-sm"
          />
          <span className="text-gray-500 text-xs">
            *En az 10 karakter girilmelidir.
          </span>
        </div>

        <div className="relative rounded-md border mb-10 bg-white border-gray-300 px-3 py-2">
          <label
            htmlFor="description"
            className="absolute -top-2 left-2 -mt-px inline-block bg-blue-100 rounded-md px-2 text-xs font-medium text-gray-900 after:ml-0.5 after:text-red-500"
          >
            Açıklama
          </label>
          <input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            name=""
            id=""
            className="block w-full p-1 text-gray-900 sm:text-sm"
          />
        </div>
        <div className="relative rounded-md border mb-10 bg-white border-gray-300 px-3 py-2">
          <label
            htmlFor="name"
            className="absolute -top-2 left-2 -mt-px inline-block bg-blue-100 rounded-md px-2 text-xs font-medium text-gray-900 after:ml-0.5 after:text-red-500"
          >
            Zaman <span className="text-red-500">*</span>
          </label>
          <input
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            type="text"
            name=""
            id=""
            className="block w-full p-1 text-gray-900 sm:text-sm"
          />
        </div>
        <div className="relative rounded-md border mb-10 bg-white border-gray-300 px-3 py-2">
          <label
            htmlFor="name"
            className="absolute -top-2 left-2 -mt-px inline-block bg-blue-100 rounded-md px-2 text-xs font-medium text-gray-900 after:ml-0.5 after:text-red-500"
          >
            Geçme notu (0-100) <span className="text-red-500">*</span>
          </label>
          <input
            value={passNote}
            onChange={(e) => setPassNote(e.target.value)}
            type="text"
            name=""
            id=""
            className="block w-full p-1 text-gray-900 sm:text-sm"
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center px-6">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{ margin: "0 auto" }}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      ) : (
        <div className="flex md:justify-end justify-center px-4 sm:px-6">
          <button
            onClick={postExam}
            className="inline-flex items-center rounded-md border shadow-lg bg-blue-900 px-3 py-2 text-sm font-medium leading-4 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
          >
            Sınav Ekle
          </button>
        </div>
      )}
    </div>
  );
}

export default PostExam;
