import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import  Swal  from 'sweetalert2/dist/sweetalert2';
import { errors } from "../../../data/errors";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';

function AddNewGroup() {
  const jwt = useSelector((state) => state.login.accessToken);
  const navigate = useNavigate();
  const {name} = useParams();
  const courseId= useParams();
  const [groupName, setGroupName] = useState("")

  const handleSubmit = () => {
    Swal.fire({
      title: "Yeni grup eklensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if(result.isConfirmed) {
        const submit = async () => {
          try {
           await axios(`${process.env.REACT_APP_BASE_URL}/Group/AddNewGroup`, {
             method:"post",
             headers: {
             ContentType: 'application/json-patch+json',
             accept: 'text/plain',
             Authorization: `Bearer ${jwt}`,
             },
             data: {
               courseId: courseId.id,
               name: groupName,
               isOpen: true
             }
           })
           navigate(`/adminpanel/gruplar/${name}/${courseId.id}`)
           Swal.fire("Yeni grup eklendi!");
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              console.log(error);
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen hata! Bizimle iletişime geçiniz.");
            }
          }
        }
        submit();
      }
    })
  };


  return (
    <>
      <div className="mt-10 grid gap-x-20 gap-y-20 justify-items-start">
        <h2 className="text-blue-900 mb-10 text-left font-semibold text-2xl">
          {name.name} Yeni Grup
        </h2>
      </div>
      <div className="space-y-8">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Grup Adı
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input 
              onChange={(e) => setGroupName(e.target.value)}
              value={groupName}
              type="text"
              className="block w-full max-w-xl p-2 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
         
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-5">
        <button
        onClick={handleSubmit}
          type="submit"
          className="inline-flex shadow-xl items-center mx-auto rounded-md border-2 bg-blue-900 
           px-4 m-5 py-2.5 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          Ekle
        </button>
        <ToastContainer/>
      </div>
    </>
  )
}

export default AddNewGroup