import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  msg: "",
  usersCourse: "",
  loading: false,
  error: false,
  usersComment: "",
  usersProfile: "",
  usersTransaction: "",
  usersDocuments: "",
  usersTransactionDetails: "",
  pageData: "",
  checkAuth: "",
  usersSingleCertificate: "",
  usersCertificate: "",
  usersConfirm: "",
  courseLesson: "",
  getUserDetails: "",
  deleteUserDocument: "",
};

export const getCourseLesson = createAsyncThunk(
  "courseLesson",
  async ({ jwt, id }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Lesson/GetByCourseId/${id}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      isRejectedWithValue(error.response.data);
    }
  }
);

export const getUserCourse = createAsyncThunk("usersCourse", async (data) => {
  try {
    const response = await axios(
      `${process.env.REACT_APP_BASE_URL}/User/Courses`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${data}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    isRejectedWithValue(error.response.data);
  }
});

export const getUserComment = createAsyncThunk("usersComment", async (data) => {
  try {
    const response = await axios(
      `${process.env.REACT_APP_BASE_URL}/User/Comments/CourseComments`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${data}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    isRejectedWithValue(error.response.data);
  }
});

export const getUserProfile = createAsyncThunk("usersProfile", async (data) => {
  try {
    const response = await axios(
      `${process.env.REACT_APP_BASE_URL}/User/UserDetails`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${data}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("hata");
  }
});

export const getUserDetails = createAsyncThunk(
  "usersDetails",
  async ({ jwt, userId }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/UserDetails?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            accept: "*/*",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const patchUserProfile = createAsyncThunk(
  "usersProfileUpdate",
  async ({ bodyFormData, jwt }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/UpdateUser`,
        {
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
          data: bodyFormData,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserTransaction = createAsyncThunk(
  "usersTransaction",
  async ({ data, pageNumber }) => {
    try {
      const response = await axios(
        `${
          process.env.REACT_APP_BASE_URL
        }/User/ShoppingTransaction?PageNumber=${pageNumber + 1}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      isRejectedWithValue(error.response.data);
    }
  }
);

export const getUserDocuments = createAsyncThunk(
  "usersDocuments",
  async (data) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/GetUserDocuments`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      isRejectedWithValue(error.response.data);
    }
  }
);

export const deleteUserDocument = createAsyncThunk(
  "deleteUserDocument",
  async ({ jwt, documentId, userId }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/User/DeleteUserDocuments`,
        {
          headers: {
            ContentType: "application/json-patch+json",
            accept: "*/*",
            Authorization: `Bearer ${jwt}`,
          },
          data: {
            documentId: documentId,
            userId: userId,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const patchUserDocuments = createAsyncThunk(
  "usersDocumentsUpdate",
  async ({ jwt, bodyFormData }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/RegisterDocument`,
        {
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
          data: bodyFormData,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCertificateShipping = createAsyncThunk(
  "certificateShipping",
  async ({ jwt, data }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/Certificate/Shipping`,
        {
          method: "post",
          headers: {
            accept: "text/plain",
            ContentType: "application/json-patch+json",
            Authorization: `Bearer ${jwt}`,
          },
          data: data,
        }
      );
      return response.data;
    } catch (error) {
      isRejectedWithValue(error.response.data);
    }
  }
);

export const getSingleCertificate = createAsyncThunk(
  "singleCertificate",
  async ({ jwt, id }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/Certificate/GetCertificate/${id}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${jwt}`,
            accept: "text/plain",
          },
        }
      );
      return response.data;
    } catch (error) {
      isRejectedWithValue(error.response.data);
    }
  }
);

export const getUserCertificate = createAsyncThunk(
  "userCertificate",
  async (jwt) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/Certificate/GetCertificate`,
        {
          method: "get",
          headers: {
            accept: "text/plain",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      isRejectedWithValue(error.response.data);
    }
  }
);

export const getUserConfirmAccount = createAsyncThunk(
  "getUserConfirmAccount",
  async ({ jwt, userId }) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/ConfirmAccount?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            accept: "*/*",
          },
        }
      );
      return response.data;
    } catch (error) {
      isRejectedWithValue(error.response.data);
      return error;
    }
  }
);

const UserSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers: {
    // -------------------------------- getCourseLessons --------------------------------
    [getCourseLesson.pending]: (state, action) => {
      state.loading = false;
    },
    [getCourseLesson.fulfilled]: (state, { payload: { details, data } }) => {
      state.loading = true;
      state.error = details.succes;
      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.courseLesson = data;
      }
    },
    [getCourseLesson.rejected]: (state, action) => {
      state.loading = true;
    },

    // -------------------------------- getUserCourse --------------------------------
    [getUserCourse.pending]: (state, action) => {
      state.loading = false;
    },
    [getUserCourse.fulfilled]: (state, { payload: { details, data } }) => {
      state.loading = true;
      state.error = details.succes;
      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.usersCourse = data;
      }
    },
    [getUserCourse.rejected]: (state, action) => {
      state.loading = true;
    },

    // -------------------------------- getUserComment --------------------------------
    [getUserComment.pending]: (state, action) => {
      state.loading = false;
    },
    [getUserComment.fulfilled]: (state, { payload: { details, data } }) => {
      state.loading = true;
      state.error = details.success;

      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.usersComment = data;
      }
    },
    [getUserComment.rejected]: (state, action) => {
      state.loading = true;
    },

    // -------------------------------- getUserProfile --------------------------------
    [getUserProfile.pending]: (state, action) => {
      state.loading = false;
    },
    [getUserProfile.fulfilled]: (state, { payload: { details, data } }) => {
      state.loading = true;
      state.error = details.success;

      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.usersProfile = data;
        state.usersTransactionDetails = data.details;
      }
    },
    [getUserProfile.rejected]: (state, action) => {
      state.loading = true;
    },

    // -------------------------------- patchUserProfile --------------------------------
    [patchUserProfile.pending]: (state, action) => {
      state.loading = false;
    },
    [patchUserProfile.fulfilled]: (state, { payload: { details, data } }) => {
      state.loading = true;
      state.error = details.success;

      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.usersProfile = data;
      }
    },
    [patchUserProfile.rejected]: (state, action) => {
      state.loading = true;
    },

    // -------------------------------- getUserTransaction --------------------------------
    [getUserTransaction.pending]: (state, action) => {
      state.loading = false;
    },
    [getUserTransaction.fulfilled]: (
      state,
      { payload: { details, data, metaData } }
    ) => {
      state.loading = true;
      state.error = details.success;

      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.pageData = metaData;
        state.usersTransaction = data;
      }
    },
    [getUserTransaction.rejected]: (state, action) => {
      state.loading = true;
    },

    // -------------------------------- getUserDocuments --------------------------------
    [getUserDocuments.pending]: (state, action) => {
      state.loading = false;
    },
    [getUserDocuments.fulfilled]: (state, { payload: { details, data } }) => {
      state.loading = true;
      state.error = details.success;
      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.usersDocuments = data;
      }
    },
    [getUserDocuments.rejected]: (state, action) => {
      state.loading = true;
    },

    // -------------------------------- getSingleCertificate --------------------------------
    [getSingleCertificate.pending]: (state, action) => {
      state.loading = false;
    },
    [getSingleCertificate.fulfilled]: (
      state,
      { payload: { details, data } }
    ) => {
      state.loading = true;
      state.error = details.success;
      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.usersSingleCertificate = data;
      }
    },
    [getSingleCertificate.rejected]: (state, action) => {
      state.loading = true;
    },

    // -------------------------------- getUserCertificate --------------------------------
    [getUserCertificate.pending]: (state, action) => {
      state.loading = false;
    },
    [getUserCertificate.fulfilled]: (state, { payload: { details, data } }) => {
      state.loading = true;
      state.error = details.success;
      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.usersCertificate = data;
      }
    },
    [getUserCertificate.rejected]: (state, action) => {
      state.loading = true;
    },

    // -------------------------------- getUserConfirmAccount --------------------------------
    [getUserConfirmAccount.pending]: (state, action) => {
      state.loading = false;
    },
    [getUserConfirmAccount.fulfilled]: (
      state,
      { payload: { data, details } }
    ) => {
      state.loading = true;
      state.error = details.success;
      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.usersConfirm = data;
      }
    },
    [getUserConfirmAccount.rejected]: (state, action) => {
      state.loading = true;
    },

    //-----------------------------------getUserDetails----------------------------------------

    [getUserDetails.pending]: (state, action) => {
      state.loading = false;
    },
    [getUserDetails.fulfilled]: (state, { payload: { details, data } }) => {
      state.loading = true;
      state.error = details.success;

      if (state.error === false) {
        state.error = details.success;
      } else {
        state.error = details.success;
        state.msg = details;
        state.getUserDetails = data;
      }
    },
    [getUserDetails.rejected]: (state, action) => {
      state.loading = true;
    },
  },
});

export default UserSlice.reducer;
