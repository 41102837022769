import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { errors } from "../../../../data/errors";
//import Swal from "sweetalert2";
import  Swal  from "sweetalert2/dist/sweetalert2";


function EducatorCourseUpdateLesson() {
  const { id} = useParams();
    const { lessonId} = useParams();
    const jwtTokenRedux = useSelector((state) => state.login.accessToken);
    const [lesson, setLesson] = useState("");
    const navigate = useNavigate();
    const [courseId, setCourseId] = useState("");
    const [lessonName, setLessonName] = useState("");
    const [index, setIndex] = useState("");
    const [link, setLink] = useState("");
    let bodyFormData = new FormData();
    bodyFormData.append("Name", lessonName);
    bodyFormData.append("Link", link);
    bodyFormData.append("Index", index);
    bodyFormData.append("CourseId", courseId);
  
    const getLesson = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Lesson/${lessonId}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
              accept: "text/plain",
            },
          }
        );
        setLesson(response.data.data);
  
        setCourseId(response.data.data[0].courseId);
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getLesson();
    }, []);
  
    const lessonUpdate = async (e) => {
      e.preventDefault();
      try {
        await axios({
          method: "patch",
          url: `${process.env.REACT_APP_BASE_URL}/Lesson?id=${lessonId}`,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            accept: "text/plain",
          },
        });
        setIndex("");
        setLessonName("");
        setLink("");
        Swal.fire("Başarı ile güncellendi");
        navigate(`/egitmenpanel/kurslarim/${id}/dersler`);

        //toast.success("Ders Başarıyla Güncellendi");
        getLesson();
      } catch (response) {
        let isEqual = false;
        for (let index = 0; index < errors.length; index++) {
          if (response.response.data.details.errorCodes === errors[index].id) {
            let isEqual = true;
            return toast.error(errors[index].content);
          } else if (response.response.data.details.errorCodes === 999) {
            let isEqual = true;
            return toast.error(response.response.data.details.message);
          } else {
            let isEqual = false;
          }
        }
        if (!isEqual) {
          toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
        }
      }
    };
  
    const deleteDocument = async (e) => {
      e.preventDefault();
  
      try {
        await axios({
          method: "delete",
          url: `${process.env.REACT_APP_BASE_URL}/Lesson?id=${lessonId}`,
          headers: {
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            accept: "text/plain",
          },
        });
        Swal.fire("Başarı ile güncellendi");
        //toast.success("Ders Başarıyla Silindi");
        navigate(`/egitmenpanel/kurslarim/${id}/dersler`);
        // await setTimeout(() => {
         
        // }, 1300);
      } catch (response) {
        let isEqual = false;
        for (let index = 0; index < errors.length; index++) {
          if (response.response.data.details.errorCodes === errors[index].id) {
            let isEqual = true;
            return toast.error(errors[index].content);
          } else if (response.response.data.details.errorCodes === 999) {
            let isEqual = true;
            return toast.error(response.response.data.details.message);
          } else {
            let isEqual = false;
          }
        }
        if (!isEqual) {
          toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
        }
      }
    };
  
    const updateSweetAlert = (e) => {
      e.preventDefault();
      // const swalWithBootstrapButtons = Swal.mixin({
      //   customClass: {
      //     confirmButton: "bg-green-400 px-8 py-3 rounded-md ml-3",
      //     cancelButton: "bg-red-400 px-8 py-3 rounded-md",
      //   },
      //   buttonsStyling: false,
      // });
  
        Swal.fire({
          title: "İşlemi gerçekleştirmek istediğinizden emin misin?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            lessonUpdate(e);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            Swal.fire({
              title: "İşlem iptal edildi!",
              icon: "error",
            });
          }
        });
    };
  
    const deleteSweetAlert = (e) => {
      e.preventDefault();
      // const swalWithBootstrapButtons = Swal.mixin({
      //   customClass: {
      //     confirmButton: "bg-green-400 px-8 py-3 rounded-md ml-3",
      //     cancelButton: "bg-red-400 px-8 py-3 rounded-md",
      //   },
      //   buttonsStyling: false,
      // });
  
        Swal.fire({
          title: "Dersi silmek istediğinizden emin misiniz?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteDocument(e);
            // swalWithBootstrapButtons.fire({
            //   title: "İşlem başarıyla gerçekleşti!",
            //   icon: "success",
            // });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            Swal.fire({
              title: "İşlem iptal edildi!",
              icon: "error",
            });
          }
        });
    };
  
    return (
      <>
        <ToastContainer />
        <div className="text-center mb-10 ">
          <h1 className="text-3xl md:text-4xl px-5 py-4 text-[#2196f3] font-bold">
            Ders Bilgilerini Düzenle
          </h1>
        </div>
        <div className="user-info ml-5 md:ml-0">
          <form onSubmit={(e) => updateSweetAlert(e)}>
            <div className="mt-5 border-y border-gray-200">
              <dl className="divide-y divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500 py-1">
                    Ders İsmi :
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <input
                      onChange={(e) => setLessonName(e.target.value)}
                      placeholder={lesson[0]?.name}
                      className="flex-grow shadow-sm border-[0.5px] py-1 px-3"
                    />
                  </dd>
                </div>
                {/* <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500 py-1">
                    Index :
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <input
                      onChange={(e) => setIndex(e.target.value)}
                      placeholder={lesson[0]?.index}
                      className="flex-grow border-[0.5px] py-1 px-3"
                    />
                  </dd>
                </div> */}
  
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500 py-1 after:content-['*'] after:ml-0.5 after:text-red-500">
                    Link :
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <input
                      onChange={(e) => setLink(e.target.value)}
                      placeholder={lesson[0]?.link}
                      className="flex-grow border-[0.5px] py-1 px-3"
                    />
                  </dd>
                </div>
              </dl>
  
              <div className="mt-20">
                <div className="grid grid-cols-12 justify-end pb-5 gap-3 ">
                  <Link
                    to={`/egitmenpanel/kurslarim/${id}/dersler`}
                    className="col-span-12 mx-auto sm:col-span-4 rounded-md border w-56 text-center border-gray-300 bg-white py-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Geri Dön
                  </Link>
                  <button
                    type="submit"
                    className="col-span-12 mx-auto sm:col-span-4 inline-flex justify-center w-56 text-center rounded-md border border-transparent bg-green-600 py-3 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Güncelle
                  </button>
                  <button
                    type="submit"
                    onClick={(e) => deleteSweetAlert(e)}
                    className="col-span-12 mx-auto sm:col-span-4 inline-flex justify-center w-56 text-center rounded-md border border-transparent bg-red-600 hover:bg-red-800 py-3 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Sil
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    );
}

export default EducatorCourseUpdateLesson