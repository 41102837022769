import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { getUserCourse } from "../../../store/features/UserSlice";
import CourseDocumentsModal from "./CourseDocumentsModal";

function CourseDocuments() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [courseInfo, setCourseInfo] = useState("");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  useEffect(() => {
    dispatch(getUserCourse(JSON.parse(jwtTokenRedux)));
  }, []);
  const userCourses = useSelector((state) => state.userSlice.usersCourse);

  const handleDocumentModal = (e, id, item) => {
    e.preventDefault();
    setCourseId(id);
    setCourseInfo(item);
    setIsOpen(true);
  };

  const DocumentsContent = () => {
    if (userCourses.length === 0) {
      return (
        <>
          <div className="mx-auto max-w-7xl py-16 px-6 lg:px-8">
            <div className="overflow-hidden rounded-lg bg-[#142850] shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
              <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                <div className="lg:self-center">
                  <h2 className="text-white">
                    <span className="block text-lg">
                      Gösterilecek dökümanınız bulunmamaktadır!
                    </span>
                    <span className="block font-bold text-2xl">
                      Kurslarım sayfasına gidin.
                    </span>
                  </h2>
                  <p className="mt-4 text-lg leading-6 text-white">
                    Satın aldığınız kursların mevcut dökümanlarını burada
                    bulabilirsiniz.
                  </p>
                  <Link
                    to="/kullanicipaneli/kurslarim"
                    className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-[#142850] shadow hover:bg-blue-200"
                  >
                    Kurslarım
                  </Link>
                  <Link
                    to="/iletisim"
                    className="text-base font-semibold leading-7 text-white pl-5"
                  >
                    Eğitim Uzmanıyla Görüş <span aria-hidden="true">→</span>
                  </Link>
                </div>
              </div>
              <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
                <img
                  className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                  src="https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  alt="Payment"
                />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="w-[calc(100%-3.73rem)] px-3">
          <div className="mx-auto py-5 px-4 sm:px-6 lg:px-8">
            <div>
              <ul
                className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
              >
                {userCourses &&
                  userCourses?.map((item) => (
                    <li
                      key={item.id}
                      className="col-span-1 border-2 border-gray-300 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
                    >
                      <div className="flex flex-1 flex-col p-8">
                        <img
                          className="mx-auto object-cover h-32 w-32 flex-shrink-0 rounded-full"
                          src={item?.imagePath}
                          alt="kurs-gorseli"
                        />
                        <h3 className="mt-6 text-md font-medium text-gray-900">
                          {item?.name}
                        </h3>
                      </div>
                      <div>
                        <div className="-mt-px flex divide-x divide-gray-200">
                          <div className="flex w-0 flex-1">
                            <Link
                              to={`/kullanicipaneli/kurslarim/${item.name
                                .split(" ")
                                .join("")}/${item.id}`}
                              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-1 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6 text-gray-400"
                              >
                                <path
                                  stroke-linecap="round"
                                  d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                                />
                              </svg>
                              Videolar
                            </Link>
                          </div>
                          <div className="-ml-px flex w-0 flex-1">
                            <button
                            onClick={(e) => handleDocumentModal(e, item.id, item)} 
                            className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-1 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6 text-gray-400"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                />
                              </svg>
                              Dökümanlar
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          {isOpen && (
            <CourseDocumentsModal
            isOpen={isOpen}
              setIsOpen={setIsOpen}
              courseId={courseId}
              courseInfo={courseInfo}
            />
          )}
        </div>
      );
    }
  };

  return (
    <>
      <div className="w-[calc(100%-3.73rem)] px-3">
        <div className="2xl:container mx-auto space-y-3 py-3">
          <div className="w-full bg-[#142850] rounded-lg mb-6">
            <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
              Kurs Dökümanları
            </p>
          </div>
          <div className="flex mb-5 mt-5">
            <NavLink
              to={`/kullanicipaneli/anasayfa`}
              className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
            >
              <lord-icon
                src="https://cdn.lordicon.com/zfxxckbp.json"
                trigger="loop"
                colors="primary:#4be1ec,secondary:#cb5eee"
              ></lord-icon>
              Geri
            </NavLink>
          </div>
          <DocumentsContent />
        </div>
      </div>
    </>
  );
}

export default CourseDocuments;
