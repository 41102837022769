import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  cart: "",
  loading: false,
  error: "",
  cartTotal: "",
  shoppingData: "",
  success: false,
  cartDetails: "",
};

export const getCart = createAsyncThunk(
  "cartGet",
  async (data) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/Cart`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${data}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      isRejectedWithValue(error.response.data);
    }
  },
  []
);

export const buyCart = createAsyncThunk("cartPost", async (data) => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_BASE_URL}/Pay/PayWithShoppingCart`,
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${data}`,
          "Content-Type": "application/json-patch+json",
        },
        data: JSON.stringify({
          "shipping": true,
          "aggrementIds": [
            "string"
          ]
        })
      }
    );
    return res;
  } catch (error) {
    isRejectedWithValue(error.res.data);
  }
});

const denemeSlice = createSlice({
  name: "deneme",
  initialState,
  reducers: {
    listUser: (state, action) => {
      state.cart = localStorage.getItem("cart");
      state.length = localStorage.getItem("length");
    },
  },
  extraReducers: {
    [getCart.pending]: (state, action) => {
      state.loading = false;
    },
    [getCart.fulfilled]: (state, { payload: { data, metaData } }) => {
      state.cart = data;
      state.cartTotal = data;
      state.cartDetails = metaData;

      localStorage.setItem("cart", JSON.stringify(data));
    },
    [getCart.rejected]: (state, action) => {
      state.loading = true;
    },

    [buyCart.pending]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [buyCart.fulfilled]: (state, { payload: { details, data } }) => {
      state.shoppingData = data.data;
      state.success = true;
    },
    [buyCart.rejected]: (state, action) => {
      state.loading = true;
      state.success = false;
    },
  },
});

export default denemeSlice.reducer;
export const { listUser } = denemeSlice.actions;
