import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

function AdminPaymentModal({ isOpen, setIsOpen, selectedData }) {
  const handleOKClick = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleOKClick}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center font-medium leading-6 text-gray-900"
                  >
                    Döküman İncele
                  </Dialog.Title>
                  <ul
                    className="grid grid-cols-1 px-4 mt-5 mb-5 my-20"
                  >
                    <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                      <div className="flex w-full items-center justify-between space-x-6 p-6 border">
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                            <h3 className="truncate text-sm font-medium text-gray-900">
                              {selectedData.userDetail.firstName}
                            </h3>
                            {selectedData.status ? (
                              <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                                Ödeme Başarılı
                              </span>
                            ) : (
                              <span className="inline-block flex-shrink-0 rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800">
                                Ödeme Başarısız
                              </span>
                            )}
                          </div>
                          <p className="mt-1 truncate text-sm text-gray-500">
                            {selectedData.userDetail.city}
                          </p>
                        </div>
                        <img
                          className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                          src="https://images.pexels.com/photos/2777898/pexels-photo-2777898.jpeg?auto=compress&cs=tinysrgb&w=1600"
                          alt="profil-fotoğrafı"
                        />
                      </div>
                      <div>
                        <div className="-mt-px flex divide-x divide-gray-200 border">
                          <div className="flex w-0 flex-1">
                            <a
                              href={`mailto:${selectedData.userDetail.email}`}
                              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                            >
                              <EnvelopeIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-3">
                                {selectedData.userDetail.email}
                              </span>
                            </a>
                          </div>
                          <div className="-ml-px flex w-0 flex-1">
                            <a
                              href={`tel:${selectedData.userDetail.phoneNumber}`}
                              className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                            >
                              <PhoneIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-3">
                                {selectedData.userDetail.phoneNumber}
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <section
                    aria-labelledby="summary-heading"
                    className="mt-auto sm:px-6 lg:px-8"
                  >
                    <div className="bg-gray-50 p-6 sm:rounded-lg sm:p-8">
                      <h2 id="summary-heading" className="sr-only">
                        Alışveriş Özeti
                      </h2>

                      <div className="flow-root">
                        <dl className="-my-4 divide-y divide-gray-200 text-sm">
                          <p className="text-gray-600 py-4 text-lg">
                            Ara toplam
                          </p>

                          {selectedData?.courses?.map((item) => (
                            <div 
                            key={item.id}
                            className="flex items-center justify-between py-4">
                              <dt className="text-gray-600">{item.name}</dt>
                              <dd className="font-medium text-gray-900">
                                {item.price} TL
                              </dd>
                            </div>
                          ))}
                          <div className="flex items-center justify-between py-4">
                            <dt className="text-gray-600">KDV (%)</dt>
                            <dd className="font-medium text-gray-900">18</dd>
                          </div>
                          <div className="flex items-center justify-between py-4">
                            <dt className="text-base font-medium text-gray-900">
                              Toplam
                            </dt>
                            <dd className="text-base font-medium text-gray-900">
                              {selectedData.details.totalPrice}TL
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </section>

                  <div className="mt-10 flex justify-end space-x-5">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={handleOKClick}
                    >
                      Kapat
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default AdminPaymentModal;
