import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { logout } from "../../../store/features/LoginSlice";
import AdminNavbar from "./AdminNavbar";

function AdminPanel({setCheckAdmin, setCheckUser}) {
  const [move, setMove] = useState(true);
  const dispatch = useDispatch()
  const logoutHandle = async () => {
    await dispatch(logout());
    setCheckAdmin("");
    setCheckUser("");
  };

  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [checkA, setCheckA] = useState();
  const checkAuth = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Auth/CheckIfAuthorized`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          },
        }
      )
      setCheckA(response.data);
    } catch (error) {
      logoutHandle(); 
    }
  }
  useEffect(() => {
    checkAuth();
  },[]);

  return (
    <>
      <div className="w-full min-h-full grid grid-cols-12 gap-x-4">
        <div className="col-span-2 z-0" >
          <AdminNavbar move={move} setMove={setMove} />
        </div>
        <main className={`${move ? "" : "pl-24 lg:pl-0"} col-span-9 items-start`}>
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default AdminPanel;
