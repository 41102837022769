import axios from "axios";
import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { errors } from "../../../data/errors";
import { cityTest } from "../../../data/city.js";
import { townInCity } from "../../../data/townInCity.js";
import { ulkeTest } from "../../../data/country.js";

const EditUserSettings = () => {
  const navigate = useNavigate();
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [profilPicture, setProfilPicture] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [identityNumber, setIdentityNumber] = useState("");
  const [district, setDistrict] = useState("");
  const [Country, setCountry] = useState("");
  const [streetName, setStreetName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [doorNumber, setDoorNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState("");
  let bodyFormData = new FormData();
  bodyFormData.append("Email", email);
  bodyFormData.append("PhoneNumber", phone);
  bodyFormData.append("FirstName", firstName);
  bodyFormData.append("LastName", lastName);
  bodyFormData.append("City", city);
  bodyFormData.append("ProfilePicture", profilPicture);
  bodyFormData.append("BirthDate", birthDate);
  bodyFormData.append("IdentityNumber", identityNumber);
  bodyFormData.append("District", district);
  bodyFormData.append("Country", Country);
  bodyFormData.append("StreetName", streetName);
  bodyFormData.append("ZipCode", zipCode);
  bodyFormData.append("BuildingNumber", buildingNumber);
  bodyFormData.append("DoorNumber", doorNumber);
  bodyFormData.append("Neighborhood", neighborhood);

  const jwtTokenRedux = useSelector((state) => state.login.accessToken);

  const getUserSettings = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/UserDetails?userId=${id}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          },
        }
      );
      setUserInfo(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserSettings();
  }, [reducerValue]);

  const handleSubmit = async (e) => {
    try {
      console.log(email, phone, firstName, lastName, city, profilPicture);
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/User/UpdateUser?userId=${id}`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
        },
      });
      getUserSettings();
      toast.success("Kullanıcı bilgileri başarıyla güncellendi.");
    } catch (response) {
      let isEqual = false;
      for (let index = 0; index < errors.length; index++) {
        if (response.response.data.details.errorCodes === errors[index].id) {
          isEqual = true;
          return toast.error(errors[index].content);
        } else if (response.response.data.details.errorCodes === 999) {
          isEqual = true;
          return toast.error(response.response.data.details.message);
        } else {
          isEqual = false;
        }
      }
      if (!isEqual) {
        toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
      }
    }
  };

  const firstNameInput = (e) => {
    setFirstName(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^A-Za-z-ıöçğİüş" "]/gi, "");
    setFirstName(value);
  };

  const lastNameInput = (e) => {
    setLastName(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^A-Za-z-ıöçğİüş" "]/gi, "");
    setLastName(value);
  };

  const phoneNumberInput = (e) => {
    setPhone(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^0-9""]/gi, "");
    setPhone(value);
  };

  const setIdNumberInput = (e) => {
    setIdentityNumber(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^0-9""]/gi, "");
    setIdentityNumber(value);
  };

  const setZipCodeInput = (e) => {
    setZipCode(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^0-9""]/gi, "");
    setZipCode(value);
  };

  const sweetAlert = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Kullanıcı bilgileri güncellensin mi?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit(e);
        navigate(`/adminpanel/kullanicilar/duzenle/${id}`);
        Swal.fire({
          title: "Başarıyla güncellendi!",
          icon: "success",
        });
      }
    });
  };

  const confirmUser = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Kullanıcı bilgilerini onaylamak istiyor musunuz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
          try {
            await axios(
              `${process.env.REACT_APP_BASE_URL}/User/ConfirmAccount?userId=${id}`,
              {
                headers: {
                  Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                  accept: "*/*",
                },
              }
            );
            Swal.fire("Kullanıcı Bilgileri onaylandı!");
            forceUpdate();
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen hata. Bizimle iletişime geçiniz.");
            }
          }
        };
        response();
      }
    });
  };

  return (
    <div>
      <div className="flex mb-5 mt-5">
        <NavLink
          to={`/adminpanel/kullanicilar/duzenle/${id}`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <ToastContainer />
      <div className="mx-auto  bg-white rounded-xl px-1.5">
        <div className="flex h-full">
          <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
            <main className="flex flex-1 overflow-hidden">
              <div className="flex flex-1 flex-col overflow-y-auto xl:overflow-hidden">
                <div className="flex flex-1 xl:overflow-hidden">
                  <div className="flex-1 xl:overflow-y-auto">
                    <div className="mx-auto py-1 px-3 sm:px-3 lg:py-1 ">
                      <div className="text-center mb-5 border-b">
                        <h1 className="text-3xl md:text-4xl px-5 py-4 text-[#2196f3] font-bold">
                          Kullanıcı Bilgilerini Düzenle
                        </h1>
                      </div>
                      <div className="flex justify-center">
                        <button
                          onClick={(e) => confirmUser(e)}
                          type="button"
                          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-[#2196f3] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          Bilgileri Onayla
                        </button>

                        <button
                          onClick={(e) => sweetAlert(e)}
                          type="button"
                          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-[#2196f3] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          Güncelle
                        </button>
                      </div>

                      <form className="divide-y-blue-gray-200 mt-6 space-y-8 divide-y">
                        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                          <div className="sm:col-span-6">
                            <h2 className="text-xl font-medium text-blue-gray-900">
                              Profil
                            </h2>
                            <p className="mt-1 text-sm text-blue-gray-500">
                              Profil bilgilerini bu sayfadan
                              düzenleyebilirsiniz.
                            </p>
                          </div>
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="photo"
                              className="block text-sm font-medium text-blue-gray-900"
                            >
                              Fotoğraf
                            </label>
                            <div className="mt-1 flex items-center">
                              <img
                                className="inline-block h-12 w-12 rounded-full"
                                src={userInfo[0]?.profilePicturePath}
                                alt=""
                              />
                              <div className="ml-4 flex">
                                <div className="relative flex cursor-pointer items-center rounded-md border border-blue-gray-300 bg-white py-2 px-3 shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 hover:bg-blue-gray-50">
                                  <label
                                    htmlFor="user-photo"
                                    className="pointer-events-none relative text-sm font-medium text-blue-gray-900"
                                  >
                                    <span>Değiştir </span>
                                    <span className="sr-only">
                                      {" "}
                                      Kullanıcı Fotoğraf
                                    </span>
                                  </label>
                                  <input
                                    id="user-photo"
                                    name="user-photo"
                                    onChange={(e) =>
                                      setProfilPicture(e.target.files[0])
                                    }
                                    type="file"
                                    className="absolute  inset-0 h-full w-full  cursor-pointer rounded-md border-gray-300 opacity-0"
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="ml-3 rounded-md border border-transparent bg-transparent py-2 px-3 text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:border-blue-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-gray-50"
                                >
                                  Sil
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-blue-gray-900"
                            >
                              Ad
                            </label>
                            <input
                              placeholder={userInfo[0]?.firstName}
                              value={firstName}
                              onChange={firstNameInput}
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-1 px-3 block w-full rounded-md py-2 border-2 border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-blue-gray-900"
                            >
                              Soyad
                            </label>
                            <input
                              placeholder={userInfo[0]?.lastName}
                              value={lastName}
                              onChange={lastNameInput}
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              className="mt-1 block w-full px-3 rounded-md py-2 border-2 border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
                          <div className="sm:col-span-6">
                            <h2 className="text-xl font-medium text-blue-gray-900">
                              Kişisel Bilgiler
                            </h2>
                            <p className="mt-1 text-sm text-blue-gray-500">
                              Kişisel bilgilerini bu bölümden
                              düzenleyebilirsiniz.
                            </p>
                          </div>

                          <div className="sm:col-span-6">
                            <label
                              htmlFor="identity"
                              className="block text-sm font-medium text-blue-gray-900"
                            >
                              TC Kimlik Numarası
                            </label>
                            <input
                              value={identityNumber}
                              maxLength={11}
                              onChange={setIdNumberInput}
                              type="text"
                              name="identity"
                              id="identity"
                              autoComplete="identity"
                              className="mt-1 px-3 block w-full py-2 border-2 rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="sm:col-span-6">
                            <label
                              htmlFor="date"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Doğum Tarihi
                            </label>
                            <input
                              placeholder={userInfo[0]?.birthDate}
                              onChange={(e) => setBirthDate(e.target.value)}
                              min="1923-01-01"
                              max="2022-12-31"
                              type="date"
                              name="date"
                              id="date"
                              className="mt-1 py-2 border-2 px-3 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>

                          <div className="sm:col-span-6">
                            <label
                              htmlFor="email-address"
                              className="block text-sm font-medium text-blue-gray-900"
                            >
                              Email adresi
                            </label>
                            <input
                              placeholder={userInfo[0]?.email}
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                              name="email-address"
                              id="email-address"
                              autoComplete="email"
                              className="mt-1 px-3 block w-full py-2 border-2 rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>

                          <div className="sm:col-span-6">
                            <label
                              htmlFor="phone-number"
                              className="block text-sm  font-medium text-blue-gray-900"
                            >
                              Telefon Numarası
                            </label>
                            <input
                              placeholder={userInfo[0]?.phoneNumber}
                              value={phone}
                              onChange={phoneNumberInput}
                              maxLength={10}
                              type="text"
                              name="phone-number"
                              id="phone-number"
                              autoComplete="tel"
                              className="mt-1 block w-full px-3 py-2 border-2 rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
                          <div className="sm:col-span-6">
                            <h2 className="text-xl font-medium text-blue-gray-900">
                              Adres Bilgileri
                            </h2>
                            <p className="mt-1 text-sm text-blue-gray-500">
                              Adres bilgilerini bu bölümden düzenleyebilirsiniz.
                            </p>
                          </div>{" "}
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Ülke
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <select
                                placeholder="Ülke"
                                onChange={(e) => setCountry(e.target.value)}
                                id="country"
                                name="country"
                                required
                                autoComplete="country-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                              >
                                <option>{userInfo[0]?.country}</option>
                                {ulkeTest?.map((country, i) => (
                                  <option key={i}>{country.ulke}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="city"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                            >
                              Şehir
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <select
                                placeholder="Ülke"
                                onChange={(e) => setCity(e.target.value)}
                                id="city"
                                name="city"
                                required
                                autoComplete="country-name"
                                className={
                                  Country == "Diğerleri"
                                    ? "hidden w-full  rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                                    : "block w-full  rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                                }
                              >
                                <option>{userInfo[0]?.city}</option>
                                {cityTest
                                  ?.filter((city) =>
                                    city.ulke.includes(Country)
                                  )
                                  .map((city, i) => (
                                    <option key={i}>{city.il}</option>
                                  ))}
                              </select>
                              {Country === "Diğerleri" ? (
                                <label
                                  className="text-gray-400 text-base"
                                  value
                                >
                                  Ülke olarak "Diğerleri" seçtiğiniz için şehir
                                  seçmenize gerek yoktur.
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="town"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                            >
                              İlçe
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <select
                                placeholder="Ülke"
                                onChange={(e) => setDistrict(e.target.value)}
                                required
                                id="town"
                                name="town"
                                autoComplete="town-name"
                                className={
                                  Country === "K.K.T.C." ||
                                  Country === "Diğerleri"
                                    ? " w-full  rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1 hidden"
                                    : "block w-full  rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                                }
                              >
                                <option>{userInfo[0]?.district}</option>
                                {townInCity
                                  ?.filter((town) => town.il.includes(city))
                                  .map((town, i) => (
                                    <option key={i}>{town.ilce}</option>
                                  ))}
                              </select>
                              {Country === "Diğerleri" ? (
                                <label
                                  className="text-gray-400 text-base"
                                  value
                                >
                                  Ülke olarak "Diğerleri" seçtiğiniz için ilçe
                                  seçmenize gerek yoktur.
                                </label>
                              ) : null}
                              {Country === "K.K.T.C." ? (
                                <label
                                  className="text-gray-400 text-base"
                                  value
                                >
                                  Ülke olarak "K.K.T.C." seçtiğiniz için ilçe
                                  seçmenize gerek yoktur.
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="Neighborhood"
                              className="block text-sm font-medium text-blue-gray-900"
                            >
                              Mahalle
                            </label>
                            <input
                              placeholder={userInfo[0]?.neighborhood}
                              onChange={(e) => setNeighborhood(e.target.value)}
                              id="Neighborhood"
                              name="Neighborhood"
                              autoComplete="Neighborhood-name"
                              className="mt-1 py-2 border-2 px-3 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="StreetName"
                              className="block text-sm font-medium text-blue-gray-900"
                            >
                              Sokak Adı
                            </label>
                            <input
                              placeholder={userInfo[0]?.streetName}
                              onChange={(e) => setStreetName(e.target.value)}
                              id="StreetName"
                              name="StreetName"
                              autoComplete="StreetName-name"
                              className="mt-1 py-2 border-2 px-3 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="ZipCode"
                              className="block text-sm font-medium text-blue-gray-900"
                            >
                              Posta Kodu
                            </label>
                            <input
                              placeholder={userInfo[0]?.zipCode}
                              value={zipCode}
                              onChange={setZipCodeInput}
                              maxLength={5}
                              id="ZipCode"
                              name="ZipCode"
                              autoComplete="ZipCode-name"
                              className="mt-1 py-2 border-2 px-3 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="BuildingNumber"
                              className="block text-sm font-medium text-blue-gray-900"
                            >
                              Bina Numarası
                            </label>
                            <input
                              placeholder={userInfo[0]?.buildingNumber}
                              onChange={(e) =>
                                setBuildingNumber(e.target.value)
                              }
                              id="BuildingNumber"
                              name="BuildingNumber"
                              autoComplete="BuildingNumber-name"
                              className="mt-1 py-2 border-2 px-3 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="DoorNumber"
                              className="block text-sm font-medium text-blue-gray-900"
                            >
                              Kapı Numarası
                            </label>
                            <input
                              placeholder={userInfo[0]?.doorNumber}
                              onChange={(e) => setDoorNumber(e.target.value)}
                              id="DoorNumber"
                              name="DoorNumber"
                              autoComplete="DoorNumber-name"
                              className="mt-1 py-2 border-2 px-3 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>
                          <p className="text-sm text-blue-gray-500 sm:col-span-6">
                            Bu hesap{" "}
                            <span>
                              {userInfo[0]?.created.slice(8, 10)}.
                              {userInfo[0]?.created.slice(5, 7)}.
                              {userInfo[0]?.created.slice(0, 4)} tarihinde
                              oluşturuldu.
                            </span>
                            .
                          </p>
                        </div>

                        <div className="flex justify-end pt-8 pb-5">
                          <button
                            onClick={(e) => sweetAlert(e)}
                            type="button"
                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            Güncelle
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserSettings;
