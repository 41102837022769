import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { errors } from "../../../data/errors";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

function AddSSS() {
  const navigate = useNavigate();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [contentTypes, setContentTypes] = useState("");

  const handleSubmit = () => {
    Swal.fire({
      title: "Yeni soru-cevap eklensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
          try {
            await axios(
              `${process.env.REACT_APP_BASE_URL}/FrequentlyAskedQuestions?Question=${question}&Answer=${answer}&ContentTypes=${contentTypes}`,
              {
                method: "post",
                headers: {
                  accept: "text/plain",
                  Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                },
                data: {
                  question: question,
                  answer: answer,
                  contentTypes: contentTypes,
                },
              }
            );
            navigate("/adminpanel/sss");
            Swal.fire("Başarıyla  eklendi!");
          } catch (response) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              console.log(response, "hey")
              if (response.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (response.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(response.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        response();
      }
    });
  };

  return (
    <>
      <div className="flex mb-5 mt-5">
        <NavLink
          to={`/adminpanel/sss`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="mt-10 grid gap-x-20 gap-y-20 justify-items-start">
        <h2 className="text-blue-900 mb-10 text-left font-semibold text-2xl">
          Yeni Soru Ekle
        </h2>
      </div>
      <div className="space-y-8">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  after:content-['*'] after:ml-0.5 after:text-red-500"
          >
            Soru
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <textarea
              onChange={(e) => setQuestion(e.target.value)}
              value={question}
              id="question"
              name="question"
              rows={5}
              cols={100}
              className="block w-full p-2 col-span-2 rounded-md border border-gray-300 shadow-sm sm:text-sm"
              defaultValue={""}
            />
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="answer"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  after:content-['*'] after:ml-0.5 after:text-red-500"
          >
            Cevap
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <textarea
              onChange={(e) => setAnswer(e.target.value)}
              value={answer}
              id="answer"
              name="answer"
              rows={5}
              cols={100}
              className="block w-full p-2 col-span-2 rounded-md border border-gray-300 shadow-sm sm:text-sm"
              defaultValue={""}
            />
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  after:content-['*'] after:ml-0.5 after:text-red-500"
          >
            İçerik
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <select
              onChange={(e) => setContentTypes(e.target.value)}
              value={contentTypes}
              id="content"
              name="content"
              className="block w-full max-w-xl p-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
            >
              <option>Seçiniz</option>
              <option>Sinavlar</option>
              <option>Sertifikalar</option>
              <option>OdemelerveIadeler</option>
              <option>Sikayetler</option>
            </select>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-5">
        <button
          onClick={() => handleSubmit()}
          type="submit"
          className="inline-flex shadow-xl items-center mx-auto rounded-md border-2 bg-blue-900 
           px-4 m-5 py-2.5 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          Ekle
        </button>
        <ToastContainer />
      </div>
    </>
  );
}

export default AddSSS;
