import React from "react";
import img1 from "../../../assets/verified.png";
import img2 from "../../../assets/qa.png";
import img3 from "../../../assets/more.png";
import img4 from "../../../assets/shopping-cart.png";
import img5 from "../../../assets/megaphone.png";
import img6 from "../../../assets/delivery-box.png";
import img7 from "../../../assets/class.png";
import img8 from "../../../assets/user.png";
import img9 from "../../../assets/chat.png";
import img10 from "../../../assets/folder.png";
import img11 from "../../../assets/roles.png";
import img12 from "../../../assets/allusers.png";
import img13 from "../../../assets/group.png";
import img14 from "../../../assets/calendar.png";
import instructor_img from "../../../assets/instructor.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const menuItems = [
  // {
  //   title: "Admin",
  //   pageId: "admin",
  //   src: img8,
  // },
  {
    title: "Alışveriş",
    pageId: "alisveris",
    src: img4,
  },
  {
    title: "Dersler",
    pageId: "dersler",
    src: img7,
  },
  {
    title: "Duyurular",
    pageId: "duyurular",
    src: img5,
  },
  {
    title: "Evraklar",
    pageId: "evraklar",
    src: img10,
  },
  {
    title: "Gruplar",
    pageId: "gruplar",
    src: img13,
  },
  {
    title: "Kategoriler",
    pageId: "kategoriler",
    src: img3,
  },
  {
    title: "Kullanıcılar",
    pageId: "kullanicilar",
    src: img12,
  },
  {
    title: "Kurslar",
    pageId: "kurslar",
    src: img6,
  },
  {
    title: "Roller",
    pageId: "roller",
    src: img11,
  },
  {
    title: "SSS",
    pageId: "sss",
    src: img2,
  },
  {
    title: "Sınavlar",
    pageId: "sinavlar",
    src: img14,
  },
  {
    title: "Sözleşmeler",
    pageId: "sozlesmeler",
    src: img1,
  },
  {
    title: "Yorumlar",
    pageId: "yorumlar",
    src: img9,
  },
  {
    title: "Eğitmen İşlemleri",
    pageId: "egitmen-islemleri",
    src: instructor_img,
  },
];

function MainPart() {
  const userName = useSelector((state) => state.login.firstName);
  const lastName = useSelector((state) => state.login.lastName);
  return (
    <section className="md:h-full grids items-center text-gray-600">
        <div className="text-center mb-10 mt-5 border-b flex justify-center align-center">
          <h1 className="text-2xl md:text-3xl lg:text-4xl text-black lg:font-bold font-semibold">
            Hoşgeldin, 
            <span className="sm:hidden"><br/></span>
            {" "}{JSON.parse(userName)} {JSON.parse(lastName)}
            <lord-icon
            src="https://cdn.lordicon.com/ihyatngg.json"
            trigger="loop"
            colors="primary:#66d7ee,secondary:#1663c7"
            style={{width: "50px", height: "50px"}}
          ></lord-icon>
          </h1>
        </div>
      <div className="px-5 py-4 mx-auto mb-20">
        <div className="grid grid-cols-12 gap-x-10 gap-y-10 mt-10 ">
          {menuItems?.map((change, i) => (
            <div
              key={i}
              className="max-w-xs lg:col-span-3 md:col-span-4 col-span-12 ml-1 md:ml-0 sm:ml-20 border-2 border-opacity-20 shadow-lg rounded-lg cursor-pointer hover:shadow-blue-500 hover:scale-110"
            >
              <Link to={`/adminpanel/${change.pageId}`}>
                <img
                  src={change.src}
                  alt="icon"
                  className="w-24 h-24 m-auto pt-2"
                />
                <div className="p-2 text-black hover:text-blue-900 transition duration-300 ease-in text-center">
                  <h1 className="text-xl font-semibold mb-3">{change.title}</h1>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MainPart;
