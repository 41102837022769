import React, { useEffect, useState, useReducer } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import "./AdminUsers.css";
import Swal from "sweetalert2";
import { errors } from "../../../data/errors";
import { toast, ToastContainer } from "react-toastify";

function AddDocumentToUser() {
  const { id } = useParams();
  const [documents, setDocuments] = useState("");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;

  const [search, setSearch] = useState("");
  const [dataCount, setDataCount] = useState();
  const pageCount = Math.ceil(dataCount / usersPerPage);

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  const sweetAlert = (documentId, documentAssigned) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "bg-green-400 px-8 py-3 rounded-md ml-3",
        cancelButton: "bg-red-400 px-8 py-3 rounded-md",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "İşlemi gerçekleştirmek istediğinizden emin misin?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleCheck(documentId, documentAssigned);
          swalWithBootstrapButtons.fire({
            title: "İşlem başarıyla gerçekleşti!",
            icon: "success",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "İşlem iptal edildi!",
            icon: "error",
          });
        }
      });
  };

  const handleCheck = async (documentId, documentAssigned) => {
    if (documentAssigned === false) {
      try {
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/Course/RegisterDocument`,
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            "Content-Type": "application/json-patch+json",
          },
          data: {
            documentId: documentId,
            courseId: id,
          },
        });
        forceUpdate();
      } catch (response) {
        let isEqual = false;
        for (let index = 0; index < errors.length; index++) {
          if (response.response.data.details.errorCodes === errors[index].id) {
            let isEqual = true;
            return toast.error(errors[index].content);
          } else if (response.response.data.details.errorCodes === 999) {
            let isEqual = true;
            return toast.error(response.response.data.details.message);
          } else {
            let isEqual = false;
          }
        }
        if(!isEqual) {
          toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
        }
      }
    } else {
      try {
        await axios({
          method: "delete",
          url: `${process.env.REACT_APP_BASE_URL}/Course/RegisterDocument?courseId=${id}&documentId=${documentId}`,
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          },
        });
        forceUpdate();
      } catch (response) {
        let isEqual = false;
        for (let index = 0; index < errors.length; index++) {
          if (response.response.data.details.errorCodes === errors[index].id) {
            let isEqual = true;
            return toast.error(errors[index].content);
          } else if (response.response.data.details.errorCodes === 999) {
            let isEqual= true;
            return toast.error(response.response.data.details.message);
          } else {
            let isEqual = false;
          }
        }
        if(!isEqual) {
          toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
        }
      }
    }
  };

  useEffect(() => {
    const getDocuments = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Course/RegisterDocuments/Check/${id}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
              accept: "application/json",
            },
          }
        );
        setDocuments(response.data.data);
        setDataCount(response.data.metaData.dataCount);
      } catch (response) {
        let isEqual = false;
        for (let index = 0; index < errors.length; index++) {
          if (response.response.data.details.errorCodes === errors[index].id) {
            let isEqual= true;
            return toast.error(errors[index].content);
          } else if (response.response.data.details.errorCodes === 999) {
            let isEqual = true;
            return toast.error(response.response.data.details.message);
          } else {
            let isEqual = false;
          }
        }
        if (!isEqual) {
          toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
        }
      }
    };
    getDocuments();
  }, [reducerValue]);

  const displayUsers = Object.values(documents)
    // ?.slice(pagesVisited, pagesVisited + usersPerPage)  BUNU SİLMEYİN LAZIM(OSMAN)
    .filter((document) =>
      document.name
        .toLocaleLowerCase("tr-TR")
        .includes(search.toLocaleLowerCase("tr-TR"))
    )
    .map((document) => {
      return (
        <>
          <ToastContainer />
          <li className="cursor-none" key={document.documentId}>
            <Link className="block hover:bg-gray-50">
              <div
                className={`flex items-center pl-4 py-4 sm:pl-6 ${
                  document.isActive
                    ? "bg-green-200 hover:bg-green-400"
                    : "bg-gray-100 hover:bg-gray-300"
                }`}
              >
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-12 w-12 rounded-full object-fit"
                      src={
                        "https://cdn-icons-png.flaticon.com/512/3135/3135874.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 ">
                    <div>
                      <p className="truncate text-sm font-medium text-black">
                        {document.name}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        {document.description}
                      </p>
                      {/* <a className="mt-2 flex items-center text-sm text-gray-500 z-20">
                        <span
                          onClick={()=>exampleDocument(document.documentPath)}
                          className="truncate text-blue-700 hover:text-blue-400 hover:underline"
                        >
                          Örnek Döküman
                        </span>
                        
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className="relative flex flex-col items-center justify-center overflow-hidden mr-5">
                  <div className="flex gap-3">
                    <span className="text-sm text-gray-400">
                      Döküman Durumu{" "}
                    </span>
                    {/* <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer bg-white z-10"
                        onChange={() => handleCheck(document.documentId)}
                        checked={document.isAssigned}
                      />
                      
                      <div className="w-11 h-6 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600 bg-red-400 mx-auto"></div>
                    </label> */}
                    <input
                      type="checkbox"
                      className=" bg-white "
                      onChange={() =>
                        sweetAlert(document.documentId, document.isAssigned)
                      }
                      checked={document.isAssigned}
                    />
                  </div>
                </div>
              </div>
            </Link>
          </li>
        </>
      );
    });
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-[#2196f3] font-bold">
          Kursa Ait Evrakları Belirle
        </h1>
      </div>

      <div className=" sm:justify-between grid grid-cols-12 px-10 mb-5">
        <div className="sm:items-center  mb-5 col-span-12 md:col-span-6 mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto ">
            <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
              {/* <label className="mr-5" htmlFor="">
                Aratmak istediğiniz veriyi giriniz. (İsim-Soyisim, Telefon,
                Email) :{" "}
              </label> */}
              <input
                className="border-[0.5px] border-gray-300 shadow-md text-black p-3 rounded-md w-64"
                type="text"
                value={search}
                placeholder="Kurs Adıyla Aratın"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none ">
            <Link to={`/adminpanel/kurslar/duzenle/${id}`}>
              <button
                type="button"
                className="items-center cursor-pointer rounded-lg shadow-xl border border-gray-200 bg-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Geri
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2459/2459427.png"
                  alt="plus "
                  className="h-5 w-5 inline-flex ml-2"
                />
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="overflow-hidden bg-white shadow sm:rounded-md mb-10 ml-3 md:mx-3">
        <ul role="list" className="divide-y divide-gray-200">
          {displayUsers}
        </ul>
      </div>

      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </>
  );
}

export default AddDocumentToUser;
