import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2/dist/sweetalert2";
import { getUsers } from "../../../store/features/AdminSlice";
import { errors } from "../../../data/errors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import axios from "axios";
import { useParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AddUserToGroup({ setIsEditActive, activeId }) {
  const jwt = useSelector((state) => state.login.accessToken);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [query, setQuery] = useState("");
  const [selectedPerson, setSelectedPerson] = useState("Kullanıcı seçiniz ...");
  const { id } = useParams();
  const [courseUsers, setCourseUsers] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    dispatch(getUsers({ data: JSON.parse(jwt), pageNumber: 0 }));
  }, [jwt]);
  const users = useSelector((state) => state.AdminSlice.users);

  const filteredUsers =
    query === ""
      ? users
      : users.filter((person) => {
          return person.firstName.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    const fetchTodos = async () => {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Admin/Course/GetCourseUsers?courseId=${id}`,
        {
          headers: {
            ContentType: "application/json-patch+json",
            accept: "text/plain",
            Authorization: `Bearer ${JSON.parse(jwt)}`,
          },
        }
      );
      setCourseUsers(response.data.data);
    };
    fetchTodos();
  }, [reducerValue]);

  const handleEdit = () => {
    Swal.fire({
      title: "Kullanıcı gruba eklensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          setLoading(true);
          try {
            await axios(
              `${process.env.REACT_APP_BASE_URL}/Group/AddUserToGroup`,
              {
                method: "post",
                headers: {
                  ContentType: "application/json-patch+json",
                  accept: "text/plain",
                  Authorization: `Bearer  ${jwt}`,
                },
                data: {
                  groupId: activeId,
                  userId: userId,
                },
              }
            );
            setLoading(false);
            forceUpdate();
          } catch (error) {
            setLoading(false);
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return Swal.fire(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen hata! Bizimle iletişime geçiniz.");
            }
          }
        };
        submit();
      }
    });
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    setIsEditActive(false);
  };

  return (
    <>
      <tr className="border-t border-gray-200 w-full h-24  mb-3">
        <td class="mb-3" colSpan={3}>
          <div class="mb-6">
            <Combobox
              as="div"
              value={selectedPerson}
              onChange={setSelectedPerson}
              class="ml-6 mt-3 "
            >
              <Combobox.Label className="block text-sm font-medium text-gray-700">
                Kurs sahibi Adı-Soyadı
                <ul class="ml-3 py-2">
                  <li class="py-2 mt-1 bg-green-200">
                    Gruba eklenebilir kullanıcıları temsil etmektedir.
                  </li>
                  <li class="py-2 mt-1 bg-orange-200">
                    Aktif olarak grupta yer alan kullanıcıları temsil
                    etmektedir.
                  </li>
                  <li class="py-2 mt-1 bg-red-200">
                    Gruba eklenemez kullanıcıları temsil etmektedir.(Kullanıcı
                    bilgilerini onaylamadı veya videoları izlemedi )
                  </li>
                </ul>
              </Combobox.Label>
              <div className="relative mt-3">
                <Combobox.Input
                  className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                  onChange={(event) => setQuery(event.target.value)}
                  displayValue={selectedPerson}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400 "
                    aria-hidden="true"
                  />
                </Combobox.Button>

                {courseUsers.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {courseUsers.map((person) => (
                      <Combobox.Option
                        onClick={() => setUserId(person.userId)}
                        key={person.id}
                        value={
                          "Ad-Soyad : " +
                          person.userFirstName +
                          " " +
                          person.userLastName +
                          " " +
                          "TC : " +
                          person.userIdentityNumber
                        }
                        className={({ active }) =>
                          classNames(
                            "relative cursor-default select-none py-2 pl-3 pr-9",
                            active ? "bg-blue-300 text-white" : "text-gray-900"
                          )
                        }
                      >
                        {({ active }) => (
                          <>
                            <div
                              className={classNames(
                                "ml-2 truncate text-gray-900",
                                person.isValid
                                  ? person.isArchived
                                    ? "bg-orange-200"
                                    : "bg-green-200"
                                  : "bg-red-200"
                              )}
                            >
                              <span class="text-gray-900">
                                <span class="text-red-700">Ad-Soyad :</span>{" "}
                                {person.userFirstName} {person.userLastName}
                              </span>
                              <span class="text-gray-900">
                                <span class="text-red-700"> TC :</span>{" "}
                                {person?.userIdentityNumber}
                              </span>
                            </div>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </Combobox>
          </div>
        </td>
        <td colSpan={3}>
          <div className="flex justify-center gap-x-5">
            {loading ? (
              <div className="text-center">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{ margin: "0 auto" }}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
                Ekleniyor...
              </div>
            ) : (
              <div className="space-y-2">
                <button
                  className="inline-flex items-center rounded border border-transparent bg-blue-600 px-5 py-2 text-sm font-medium text-white hover:bg-blue-700"
                  onClick={(e) => handleEdit()}
                >
                  Ekle
                </button>

                <button
                  className="inline-flex items-center rounded border border-transparent bg-red-600 px-5 py-2 text-sm font-medium text-white hover:bg-red-700"
                  onClick={(e) => handleCancelClick(e)}
                >
                  Kapat
                </button>
              </div>
            )}
            <ToastContainer />
          </div>
        </td>
      </tr>
    </>
  );
}

export default AddUserToGroup;
