import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import { errors } from "../../../data/errors";
import Swal from "sweetalert2/dist/sweetalert2";
import { ColorRing } from "react-loader-spinner";

const DocumentsById = () => {
  const [documentsName, setDocumentsName] = useState("");
  const [description, setDescription] = useState("");
  const [documentsFile, setDocumentsFile] = useState("");
  const [documentsDefault, setDocumentsDefault] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [document, setDocument] = useState("");
  const navigate = useNavigate();
  let bodyFormData = new FormData();
  bodyFormData.append("Name", documentsName);
  bodyFormData.append("Description", description);
  bodyFormData.append("IsDefault", documentsDefault);
  bodyFormData.append("Document", documentsFile);

  const exampleDocument = (document) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: <p>Örnek Evrak</p>,
      html: (
        <embed src={document} width="100%" height="500px" className="px-auto" />
      ),
      width: "50%",
      margin: "auto",
    });
  };

  const getDocumentById = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Document/${id}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            accept: "text/plain",
          },
        }
      );
      setDocument(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDocumentById();
  }, [jwtTokenRedux]);

  const updateDocument = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Döküman güncellensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        async function submit() {
          setLoading(true);
          try {
            await axios({
              method: "patch",
              url: `${process.env.REACT_APP_BASE_URL}/Document?id=${id}`,
              data: bodyFormData,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                accept: "text/plain",
              },
            });
            getDocumentById();
            setLoading(false);
            Swal.fire("Güncellendi!");
          } catch (error) {
            setLoading(false);
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        }
        submit();
      }
    });
  };

  const clicked = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Döküman silinsin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        async function submit() {
          setLoading(true);
          try {
            await axios({
              method: "delete",
              url: `${process.env.REACT_APP_BASE_URL}/Document?id=${id}`,
              headers: {
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                accept: "text/plain",
              },
            });
            setLoading(false);
            Swal.fire("Silindi!");
            navigate("/adminpanel/evraklar");
          } catch (error) {
            setLoading(false);
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
              if (!isEqual) {
                toast.error("Bilinmeyen hata. Bizimle iletişime geçin.");
              }
            }
          }
        }
        submit();
      }
    });
  };

  return (
    <>
        <div className="flex mb-5 mt-5">
        <NavLink
          to={`/adminpanel/evraklar`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <ToastContainer />
      <div className="text-center mb-10 ">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-[#2196f3] font-bold">
          Döküman Bilgilerini Düzenle
        </h1>
      </div>
      <div className="user-info ml-5 md:ml-0">
        <form onSubmit={(e) => clicked(e)}>
          <div className="mt-5 border-y border-gray-200">
            {!!document &&
              document?.map((document) => (
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 py-1">
                      Döküman İsmi :
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <input
                        value={null}
                        onChange={(e) => setDocumentsName(e.target.value)}
                        placeholder={document.name}
                        className="flex-grow shadow-sm border-[0.5px] py-1 px-3"
                      />
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 py-1">
                      Açıklama :
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <input
                        placeholder={document.description}
                        value={null}
                        onChange={(e) => setDescription(e.target.value)}
                        className="flex-grow border-[0.5px] py-1 px-3"
                      />
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 ">
                    <dt className="text-sm font-medium text-gray-500 py-1">
                      Default Döküman :
                    </dt>
                    <select
                      onChange={(e) => setDocumentsDefault(e.target.value)}
                      value={null}
                      className="flex-grow border-[0.5px] py-1 px-3 text-sm font-medium text-gray-400 "
                      placeholder="asd"
                    >
                      <option>
                        {document.isDefault === true ? "True" : "False"}
                      </option>

                      <option>
                        {document.isDefault === true ? "False" : "True"}
                      </option>
                    </select>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 flex">
                    <dt className="text-sm font-medium text-gray-500 py-1">
                      Örnek Döküman :
                    </dt>
                    <div>
                      <p className="mt-2 flex items-center text-sm text-gray-500 ">
                        <span
                          onClick={() => exampleDocument(document.documentPath)}
                          className="truncate text-blue-700 hover:text-blue-400 hover:underline cursor-pointer"
                        >
                          Döküman
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 flex">
                    <dt className="text-sm font-medium text-gray-500 py-1">
                      Örnek Döküman Değiştir :
                    </dt>
                    <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-0.5 shadow-sm">
                      <input
                        onChange={(e) => setDocumentsFile(e.target.files[0])}
                        type="file"
                        placeholder="Soyisim"
                        name="lastname"
                        id="lastname"
                        className="block w-full p-1 text-gray-900 sm:text-sm"
                      />
                    </div>
                  </div>
                </dl>
              ))}

            {loading ? (
                <div className="grid place-content-center shadow bg-grey-light text-center mt-3 text-md">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                />
                Yükleniyor...
              </div>
            ) : (
              <div>
              <div className="grid grid-cols-12 justify-end pb-5 gap-3 ">
                <Link
                  to="/adminpanel/evraklar"
                  className="col-span-12 mx-auto sm:col-span-4 rounded-md border w-56 text-center border-gray-300 bg-white py-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Geri Dön
                </Link>
                <button
                  onClick={(e) => updateDocument(e)}
                  type="button"
                  className="col-span-12 mx-auto sm:col-span-4 inline-flex justify-center w-56 text-center rounded-md border border-transparent bg-blue-600 py-3 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Güncelle
                </button>
                <button
                  type="submit"
                  className="col-span-12 mx-auto sm:col-span-4 inline-flex justify-center w-56 text-center rounded-md border border-transparent bg-red-600 hover:bg-red-800 py-3 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Sil
                </button>
              </div>
            </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default DocumentsById;
