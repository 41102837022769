import React from "react";
import { Link, NavLink } from "react-router-dom";
import img from "../../../assets/play.png";
import { BiLeftArrowAlt } from "react-icons/bi";

const projects = [
  {
    name: "Kurs 1",
    initials: "GA",
    members: 16,
  },
  {
    name: "Kurs 2",
    initials: "CD",
    members: 12,
  },
  {
    name: "Kurs 3",
    initials: "T",
    members: 16,
  },
  {
    name: "Kurs 4",
    initials: "RC",
    members: 8,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AdminVideos() {
  return (
    <>
      <div className="text-center mb-10 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-[#2196f3] font-bold">
          Videolar
        </h1>
      </div>
      <div className="flex gap-x-1 mb-3 text-sm pl-4 sm:pl-0">
        <NavLink to={`/adminpanel`}
        className="flex items-center hover:text-[#2196f3]">
          <BiLeftArrowAlt />
          Ana Sayfaya Dön
        </NavLink>
      </div>
      <div>
        <h2 className="text-lg font-medium text-gray-800">Kurs Listesi</h2>

        <div className="flex gap-y-3 gap-x-7 mt-2 w-full border-b shadow-sm">
          <div className="relative mt-1 flex items-center w-full">
            <input
              placeholder="Hızlı ara..."
              type="text"
              name="search"
              id="search"
              className="block w-full h-8 p-3 rounded-md border-gray-500 pr-12 shadow-sm sm:text-sm"
            />
          </div>
        </div>
        <Link to={"/adminpanel/videolar/ekle"}>
          <ul
            role="list"
            className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
          >
            {projects.map((project) => (
              <li
                key={project.name}
                className="col-span-1 flex rounded-md shadow-sm"
              >
                <div
                  className={classNames(
                    "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
                  )}
                >
                  <img src={img} alt="video" />
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <p className="font-medium text-gray-900 hover:text-gray-600">
                      {project.name}
                    </p>
                    <p className="text-gray-500">{project.members} Öğrenci</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Link>
      </div>
    </>
  );
}

export default AdminVideos;
