export const townInCity= [
    {
      "bolge": "AKDENİZ",
      "id": 1,
      "il": "ADANA",
      "ilce": "ALADAĞ",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 2,
      "il": "ADANA",
      "ilce": "CEYHAN",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 3,
      "il": "ADANA",
      "ilce": "ÇUKUROVA",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 4,
      "il": "ADANA",
      "ilce": "FEKE",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 5,
      "il": "ADANA",
      "ilce": "İMAMOĞLU",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 6,
      "il": "ADANA",
      "ilce": "KARAİSALI",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 7,
      "il": "ADANA",
      "ilce": "KARATAŞ",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 8,
      "il": "ADANA",
      "ilce": "KOZAN",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 9,
      "il": "ADANA",
      "ilce": "POZANTI",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 10,
      "il": "ADANA",
      "ilce": "SAİMBEYLİ",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 11,
      "il": "ADANA",
      "ilce": "SARIÇAM",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 12,
      "il": "ADANA",
      "ilce": "SEYHAN",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 13,
      "il": "ADANA",
      "ilce": "TUFANBEYLİ",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 14,
      "il": "ADANA",
      "ilce": "YUMURTALIK",
      "plaka": 1
    },
    {
      "bolge": "AKDENİZ",
      "id": 15,
      "il": "ADANA",
      "ilce": "YÜREĞİR",
      "plaka": 1
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 16,
      "il": "ADIYAMAN",
      "ilce": "BESNİ",
      "plaka": 2
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 17,
      "il": "ADIYAMAN",
      "ilce": "ÇELİKHAN",
      "plaka": 2
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 18,
      "il": "ADIYAMAN",
      "ilce": "GERGER",
      "plaka": 2
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 19,
      "il": "ADIYAMAN",
      "ilce": "GÖLBAŞI",
      "plaka": 2
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 20,
      "il": "ADIYAMAN",
      "ilce": "KAHTA",
      "plaka": 2
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 21,
      "il": "ADIYAMAN",
      "ilce": "SAMSAT",
      "plaka": 2
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 22,
      "il": "ADIYAMAN",
      "ilce": "SİNCİK",
      "plaka": 2
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 23,
      "il": "ADIYAMAN",
      "ilce": "TUT",
      "plaka": 2
    },
    {
      "bolge": "EGE",
      "id": 24,
      "il": "AFYONKARAHİSAR",
      "ilce": "BAŞMAKÇI",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 25,
      "il": "AFYONKARAHİSAR",
      "ilce": "BAYAT",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 26,
      "il": "AFYONKARAHİSAR",
      "ilce": "BOLVADİN",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 27,
      "il": "AFYONKARAHİSAR",
      "ilce": "ÇAY",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 28,
      "il": "AFYONKARAHİSAR",
      "ilce": "ÇOBANLAR",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 29,
      "il": "AFYONKARAHİSAR",
      "ilce": "DAZKIRI",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 30,
      "il": "AFYONKARAHİSAR",
      "ilce": "DİNAR",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 31,
      "il": "AFYONKARAHİSAR",
      "ilce": "EMİRDAĞ",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 32,
      "il": "AFYONKARAHİSAR",
      "ilce": "EVCİLER",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 33,
      "il": "AFYONKARAHİSAR",
      "ilce": "HOCALAR",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 34,
      "il": "AFYONKARAHİSAR",
      "ilce": "İHSANİYE",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 35,
      "il": "AFYONKARAHİSAR",
      "ilce": "İSCEHİSAR",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 36,
      "il": "AFYONKARAHİSAR",
      "ilce": "KIZILÖREN",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 37,
      "il": "AFYONKARAHİSAR",
      "ilce": "SANDIKLI",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 38,
      "il": "AFYONKARAHİSAR",
      "ilce": "SİNANPAŞA",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 39,
      "il": "AFYONKARAHİSAR",
      "ilce": "SULTANDAĞI",
      "plaka": 3
    },
    {
      "bolge": "EGE",
      "id": 40,
      "il": "AFYONKARAHİSAR",
      "ilce": "ŞUHUT",
      "plaka": 3
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 41,
      "il": "AĞRI",
      "ilce": "DİYADİN",
      "plaka": 4
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 42,
      "il": "AĞRI",
      "ilce": "DOĞUBAYAZIT",
      "plaka": 4
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 43,
      "il": "AĞRI",
      "ilce": "ELEŞKİRT",
      "plaka": 4
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 44,
      "il": "AĞRI",
      "ilce": "HAMUR",
      "plaka": 4
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 45,
      "il": "AĞRI",
      "ilce": "PATNOS",
      "plaka": 4
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 46,
      "il": "AĞRI",
      "ilce": "TAŞLIÇAY",
      "plaka": 4
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 47,
      "il": "AĞRI",
      "ilce": "TUTA",
      "plaka": 4
    },
    {
      "bolge": "KARADENİZ",
      "id": 48,
      "il": "AMASYA",
      "ilce": "GÖYNÜCEK",
      "plaka": 5
    },
    {
      "bolge": "KARADENİZ",
      "id": 49,
      "il": "AMASYA",
      "ilce": "GÜMÜŞHACIKÖY",
      "plaka": 5
    },
    {
      "bolge": "KARADENİZ",
      "id": 50,
      "il": "AMASYA",
      "ilce": "HAMAMÖZÜ",
      "plaka": 5
    },
    {
      "bolge": "KARADENİZ",
      "id": 51,
      "il": "AMASYA",
      "ilce": "MERZİFON",
      "plaka": 5
    },
    {
      "bolge": "KARADENİZ",
      "id": 52,
      "il": "AMASYA",
      "ilce": "SULUOVA",
      "plaka": 5
    },
    {
      "bolge": "KARADENİZ",
      "id": 53,
      "il": "AMASYA",
      "ilce": "TAŞOVA",
      "plaka": 5
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 54,
      "il": "ANKARA",
      "ilce": "AKYURT",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 55,
      "il": "ANKARA",
      "ilce": "ALTINDAĞ",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 56,
      "il": "ANKARA",
      "ilce": "AYAŞ",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 57,
      "il": "ANKARA",
      "ilce": "BALA",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 58,
      "il": "ANKARA",
      "ilce": "BEYPAZARI",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 59,
      "il": "ANKARA",
      "ilce": "ÇAMLIDERE",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 60,
      "il": "ANKARA",
      "ilce": "ÇANKAYA",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 61,
      "il": "ANKARA",
      "ilce": "ÇUBUK",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 62,
      "il": "ANKARA",
      "ilce": "ELMADAĞ",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 63,
      "il": "ANKARA",
      "ilce": "ETİMESGUT",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 64,
      "il": "ANKARA",
      "ilce": "EVREN",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 65,
      "il": "ANKARA",
      "ilce": "GÖLBAŞI",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 66,
      "il": "ANKARA",
      "ilce": "GÜDÜL",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 67,
      "il": "ANKARA",
      "ilce": "HAYMANA",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 68,
      "il": "ANKARA",
      "ilce": "KALECİK",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 69,
      "il": "ANKARA",
      "ilce": "KAZAN",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 70,
      "il": "ANKARA",
      "ilce": "KEÇİÖREN",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 71,
      "il": "ANKARA",
      "ilce": "KIZILCAHAMAM",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 72,
      "il": "ANKARA",
      "ilce": "MAMAK",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 73,
      "il": "ANKARA",
      "ilce": "NALLIHAN",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 74,
      "il": "ANKARA",
      "ilce": "POLATLI",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 75,
      "il": "ANKARA",
      "ilce": "PURSAKLAR",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 76,
      "il": "ANKARA",
      "ilce": "SİNCAN",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 77,
      "il": "ANKARA",
      "ilce": "ŞEREFLİKOÇHİSAR",
      "plaka": 6
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 78,
      "il": "ANKARA",
      "ilce": "YENİMAHALLE",
      "plaka": 6
    },
    {
      "bolge": "AKDENİZ",
      "id": 79,
      "il": "ANTALYA",
      "ilce": "AKSEKİ",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 80,
      "il": "ANTALYA",
      "ilce": "AKSU",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 81,
      "il": "ANTALYA",
      "ilce": "ALANYA",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 82,
      "il": "ANTALYA",
      "ilce": "DEMRE",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 83,
      "il": "ANTALYA",
      "ilce": "DÖŞEMEALTI",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 84,
      "il": "ANTALYA",
      "ilce": "ELMALI",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 85,
      "il": "ANTALYA",
      "ilce": "FİNİKE",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 86,
      "il": "ANTALYA",
      "ilce": "GAZİPAŞA",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 87,
      "il": "ANTALYA",
      "ilce": "GÜNDOĞMUŞ",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 88,
      "il": "ANTALYA",
      "ilce": "İBRADI",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 89,
      "il": "ANTALYA",
      "ilce": "KAŞ",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 90,
      "il": "ANTALYA",
      "ilce": "KEMER",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 91,
      "il": "ANTALYA",
      "ilce": "KEPEZ",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 92,
      "il": "ANTALYA",
      "ilce": "KONYAALTI",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 93,
      "il": "ANTALYA",
      "ilce": "KORKUTELİ",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 94,
      "il": "ANTALYA",
      "ilce": "KUMLUCA",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 95,
      "il": "ANTALYA",
      "ilce": "MANAVGAT",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 96,
      "il": "ANTALYA",
      "ilce": "MURATPAŞA",
      "plaka": 7
    },
    {
      "bolge": "AKDENİZ",
      "id": 97,
      "il": "ANTALYA",
      "ilce": "SERİK",
      "plaka": 7
    },
    {
      "bolge": "KARADENİZ",
      "id": 98,
      "il": "ARTVİN",
      "ilce": "ARDANUÇ",
      "plaka": 8
    },
    {
      "bolge": "KARADENİZ",
      "id": 99,
      "il": "ARTVİN",
      "ilce": "ARHAVİ",
      "plaka": 8
    },
    {
      "bolge": "KARADENİZ",
      "id": 100,
      "il": "ARTVİN",
      "ilce": "BORÇKA",
      "plaka": 8
    },
    {
      "bolge": "KARADENİZ",
      "id": 101,
      "il": "ARTVİN",
      "ilce": "HOPA",
      "plaka": 8
    },
    {
      "bolge": "KARADENİZ",
      "id": 102,
      "il": "ARTVİN",
      "ilce": "MURGUL",
      "plaka": 8
    },
    {
      "bolge": "KARADENİZ",
      "id": 103,
      "il": "ARTVİN",
      "ilce": "ŞAVŞAT",
      "plaka": 8
    },
    {
      "bolge": "KARADENİZ",
      "id": 104,
      "il": "ARTVİN",
      "ilce": "YUSUFELİ",
      "plaka": 8
    },
    {
      "bolge": "EGE",
      "id": 105,
      "il": "AYDIN",
      "ilce": "BOZDOĞAN",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 106,
      "il": "AYDIN",
      "ilce": "BUHARKENT",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 107,
      "il": "AYDIN",
      "ilce": "ÇİNE",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 108,
      "il": "AYDIN",
      "ilce": "DİDİM",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 109,
      "il": "AYDIN",
      "ilce": "GERMENCİK",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 110,
      "il": "AYDIN",
      "ilce": "İNCİRLİOVA",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 111,
      "il": "AYDIN",
      "ilce": "KARACASU",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 112,
      "il": "AYDIN",
      "ilce": "KARPUZLU",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 113,
      "il": "AYDIN",
      "ilce": "KOÇARLI",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 114,
      "il": "AYDIN",
      "ilce": "KÖŞK",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 115,
      "il": "AYDIN",
      "ilce": "KUŞADASI",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 116,
      "il": "AYDIN",
      "ilce": "KUYUCAK",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 117,
      "il": "AYDIN",
      "ilce": "NAZİLLİ",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 118,
      "il": "AYDIN",
      "ilce": "SÖKE",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 119,
      "il": "AYDIN",
      "ilce": "SULTANHİSAR",
      "plaka": 9
    },
    {
      "bolge": "EGE",
      "id": 120,
      "il": "AYDIN",
      "ilce": "YENİPAZAR",
      "plaka": 9
    },
    {
      "bolge": "MARMARA",
      "id": 121,
      "il": "BALIKESİR",
      "ilce": "AYVALIK",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 122,
      "il": "BALIKESİR",
      "ilce": "BALYA",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 123,
      "il": "BALIKESİR",
      "ilce": "BANDIRMA",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 124,
      "il": "BALIKESİR",
      "ilce": "BİGADİÇ",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 125,
      "il": "BALIKESİR",
      "ilce": "BURHANİYE",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 126,
      "il": "BALIKESİR",
      "ilce": "DURSUNBEY",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 127,
      "il": "BALIKESİR",
      "ilce": "EDREMİT",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 128,
      "il": "BALIKESİR",
      "ilce": "ERDEK",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 129,
      "il": "BALIKESİR",
      "ilce": "GÖMEÇ",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 130,
      "il": "BALIKESİR",
      "ilce": "GÖNEN",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 131,
      "il": "BALIKESİR",
      "ilce": "HAVRAN",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 132,
      "il": "BALIKESİR",
      "ilce": "İVRİNDİ",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 133,
      "il": "BALIKESİR",
      "ilce": "KEPSUT",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 134,
      "il": "BALIKESİR",
      "ilce": "MANYAS",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 135,
      "il": "BALIKESİR",
      "ilce": "MARMARA",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 136,
      "il": "BALIKESİR",
      "ilce": "SAVAŞTEPE",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 137,
      "il": "BALIKESİR",
      "ilce": "SINDIRGI",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 138,
      "il": "BALIKESİR",
      "ilce": "SUSURLUK",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 139,
      "il": "BİLECİK",
      "ilce": "BOZÜYÜK",
      "plaka": 11
    },
    {
      "bolge": "MARMARA",
      "id": 140,
      "il": "BİLECİK",
      "ilce": "GÖLPAZARI",
      "plaka": 11
    },
    {
      "bolge": "MARMARA",
      "id": 141,
      "il": "BİLECİK",
      "ilce": "İNHİSAR",
      "plaka": 11
    },
    {
      "bolge": "MARMARA",
      "id": 142,
      "il": "BİLECİK",
      "ilce": "OSMANELİ",
      "plaka": 11
    },
    {
      "bolge": "MARMARA",
      "id": 143,
      "il": "BİLECİK",
      "ilce": "PAZARYERİ",
      "plaka": 11
    },
    {
      "bolge": "MARMARA",
      "id": 144,
      "il": "BİLECİK",
      "ilce": "SÖĞÜT",
      "plaka": 11
    },
    {
      "bolge": "MARMARA",
      "id": 145,
      "il": "BİLECİK",
      "ilce": "YENİPAZAR",
      "plaka": 11
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 146,
      "il": "BİNGÖL",
      "ilce": "ADAKLI",
      "plaka": 12
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 147,
      "il": "BİNGÖL",
      "ilce": "GENÇ",
      "plaka": 12
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 148,
      "il": "BİNGÖL",
      "ilce": "KARLIOVA",
      "plaka": 12
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 149,
      "il": "BİNGÖL",
      "ilce": "KİĞI",
      "plaka": 12
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 150,
      "il": "BİNGÖL",
      "ilce": "SOLHAN",
      "plaka": 12
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 151,
      "il": "BİNGÖL",
      "ilce": "YAYLADERE",
      "plaka": 12
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 152,
      "il": "BİNGÖL",
      "ilce": "YEDİSU",
      "plaka": 12
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 153,
      "il": "BİTLİS",
      "ilce": "ADİLCEVAZ",
      "plaka": 13
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 154,
      "il": "BİTLİS",
      "ilce": "AHLAT",
      "plaka": 13
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 155,
      "il": "BİTLİS",
      "ilce": "GÜROYMAK",
      "plaka": 13
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 156,
      "il": "BİTLİS",
      "ilce": "HİZAN",
      "plaka": 13
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 157,
      "il": "BİTLİS",
      "ilce": "MUTKİ",
      "plaka": 13
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 158,
      "il": "BİTLİS",
      "ilce": "TATVAN",
      "plaka": 13
    },
    {
      "bolge": "KARADENİZ",
      "id": 159,
      "il": "BOLU",
      "ilce": "DÖRTDİVAN",
      "plaka": 14
    },
    {
      "bolge": "KARADENİZ",
      "id": 160,
      "il": "BOLU",
      "ilce": "GEREDE",
      "plaka": 14
    },
    {
      "bolge": "KARADENİZ",
      "id": 161,
      "il": "BOLU",
      "ilce": "GÖYNÜK",
      "plaka": 14
    },
    {
      "bolge": "KARADENİZ",
      "id": 162,
      "il": "BOLU",
      "ilce": "KIBRISCIK",
      "plaka": 14
    },
    {
      "bolge": "KARADENİZ",
      "id": 163,
      "il": "BOLU",
      "ilce": "MENGEN",
      "plaka": 14
    },
    {
      "bolge": "KARADENİZ",
      "id": 164,
      "il": "BOLU",
      "ilce": "MUDURNU",
      "plaka": 14
    },
    {
      "bolge": "KARADENİZ",
      "id": 165,
      "il": "BOLU",
      "ilce": "SEBEN",
      "plaka": 14
    },
    {
      "bolge": "KARADENİZ",
      "id": 166,
      "il": "BOLU",
      "ilce": "YENİÇAĞA",
      "plaka": 14
    },
    {
      "bolge": "AKDENİZ",
      "id": 167,
      "il": "BURDUR",
      "ilce": "AĞLASUN",
      "plaka": 15
    },
    {
      "bolge": "AKDENİZ",
      "id": 168,
      "il": "BURDUR",
      "ilce": "ALTINYAYLA",
      "plaka": 15
    },
    {
      "bolge": "AKDENİZ",
      "id": 169,
      "il": "BURDUR",
      "ilce": "BUCAK",
      "plaka": 15
    },
    {
      "bolge": "AKDENİZ",
      "id": 170,
      "il": "BURDUR",
      "ilce": "ÇAVDIR",
      "plaka": 15
    },
    {
      "bolge": "AKDENİZ",
      "id": 171,
      "il": "BURDUR",
      "ilce": "ÇELTİKÇİ",
      "plaka": 15
    },
    {
      "bolge": "AKDENİZ",
      "id": 172,
      "il": "BURDUR",
      "ilce": "GÖLHİSAR",
      "plaka": 15
    },
    {
      "bolge": "AKDENİZ",
      "id": 173,
      "il": "BURDUR",
      "ilce": "KARAMANLI",
      "plaka": 15
    },
    {
      "bolge": "AKDENİZ",
      "id": 174,
      "il": "BURDUR",
      "ilce": "KEMER",
      "plaka": 15
    },
    {
      "bolge": "AKDENİZ",
      "id": 175,
      "il": "BURDUR",
      "ilce": "TEFENNİ",
      "plaka": 15
    },
    {
      "bolge": "AKDENİZ",
      "id": 176,
      "il": "BURDUR",
      "ilce": "YEŞİLOVA",
      "plaka": 15
    },
    {
      "bolge": "MARMARA",
      "id": 177,
      "il": "BURSA",
      "ilce": "BÜYÜKORHAN",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 178,
      "il": "BURSA",
      "ilce": "GEMLİK",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 179,
      "il": "BURSA",
      "ilce": "GÜRSU",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 180,
      "il": "BURSA",
      "ilce": "HARMANCIK",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 181,
      "il": "BURSA",
      "ilce": "İNEGÖL",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 182,
      "il": "BURSA",
      "ilce": "İZNİK",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 183,
      "il": "BURSA",
      "ilce": "KARACABEY",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 184,
      "il": "BURSA",
      "ilce": "KELES",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 185,
      "il": "BURSA",
      "ilce": "KESTEL",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 186,
      "il": "BURSA",
      "ilce": "MUDANYA",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 187,
      "il": "BURSA",
      "ilce": "MUSTAFAKEMALPAŞA",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 188,
      "il": "BURSA",
      "ilce": "NİLÜFER",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 189,
      "il": "BURSA",
      "ilce": "ORHANELİ",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 190,
      "il": "BURSA",
      "ilce": "ORHANGAZİ",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 191,
      "il": "BURSA",
      "ilce": "OSMANGAZİ",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 192,
      "il": "BURSA",
      "ilce": "YENİŞEHİR",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 193,
      "il": "BURSA",
      "ilce": "YILDIRIM",
      "plaka": 16
    },
    {
      "bolge": "MARMARA",
      "id": 194,
      "il": "ÇANAKKALE",
      "ilce": "AYVACIK",
      "plaka": 17
    },
    {
      "bolge": "MARMARA",
      "id": 195,
      "il": "ÇANAKKALE",
      "ilce": "BAYRAMİÇ",
      "plaka": 17
    },
    {
      "bolge": "MARMARA",
      "id": 196,
      "il": "ÇANAKKALE",
      "ilce": "BİGA",
      "plaka": 17
    },
    {
      "bolge": "MARMARA",
      "id": 197,
      "il": "ÇANAKKALE",
      "ilce": "BOZCAADA",
      "plaka": 17
    },
    {
      "bolge": "MARMARA",
      "id": 198,
      "il": "ÇANAKKALE",
      "ilce": "ÇAN",
      "plaka": 17
    },
    {
      "bolge": "MARMARA",
      "id": 199,
      "il": "ÇANAKKALE",
      "ilce": "ECEABAT",
      "plaka": 17
    },
    {
      "bolge": "MARMARA",
      "id": 200,
      "il": "ÇANAKKALE",
      "ilce": "EZİNE",
      "plaka": 17
    },
    {
      "bolge": "MARMARA",
      "id": 201,
      "il": "ÇANAKKALE",
      "ilce": "GELİBOLU",
      "plaka": 17
    },
    {
      "bolge": "MARMARA",
      "id": 202,
      "il": "ÇANAKKALE",
      "ilce": "İMROZ",
      "plaka": 17
    },
    {
      "bolge": "MARMARA",
      "id": 203,
      "il": "ÇANAKKALE",
      "ilce": "LAPSEKİ",
      "plaka": 17
    },
    {
      "bolge": "MARMARA",
      "id": 204,
      "il": "ÇANAKKALE",
      "ilce": "YENİCE",
      "plaka": 17
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 205,
      "il": "ÇANKIRI",
      "ilce": "ATKARACALAR",
      "plaka": 18
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 206,
      "il": "ÇANKIRI",
      "ilce": "BAYRAMÖREN",
      "plaka": 18
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 207,
      "il": "ÇANKIRI",
      "ilce": "ÇERKEŞ",
      "plaka": 18
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 208,
      "il": "ÇANKIRI",
      "ilce": "ELDİVAN",
      "plaka": 18
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 209,
      "il": "ÇANKIRI",
      "ilce": "ILGAZ",
      "plaka": 18
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 210,
      "il": "ÇANKIRI",
      "ilce": "KIZILIRMAK",
      "plaka": 18
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 211,
      "il": "ÇANKIRI",
      "ilce": "KORGUN",
      "plaka": 18
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 212,
      "il": "ÇANKIRI",
      "ilce": "KURŞUNLU",
      "plaka": 18
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 213,
      "il": "ÇANKIRI",
      "ilce": "ORTA",
      "plaka": 18
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 214,
      "il": "ÇANKIRI",
      "ilce": "ŞABANÖZÜ",
      "plaka": 18
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 215,
      "il": "ÇANKIRI",
      "ilce": "YAPRAKLI",
      "plaka": 18
    },
    {
      "bolge": "KARADENİZ",
      "id": 216,
      "il": "ÇORUM",
      "ilce": "ALACA",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 217,
      "il": "ÇORUM",
      "ilce": "BAYAT",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 218,
      "il": "ÇORUM",
      "ilce": "BOĞAZKALE",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 219,
      "il": "ÇORUM",
      "ilce": "DODURGA",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 220,
      "il": "ÇORUM",
      "ilce": "İSKİLİP",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 221,
      "il": "ÇORUM",
      "ilce": "KARGI",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 222,
      "il": "ÇORUM",
      "ilce": "LAÇİN",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 223,
      "il": "ÇORUM",
      "ilce": "MECİTÖZÜ",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 224,
      "il": "ÇORUM",
      "ilce": "OĞUZLAR",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 225,
      "il": "ÇORUM",
      "ilce": "ORTAKÖY",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 226,
      "il": "ÇORUM",
      "ilce": "OSMANCIK",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 227,
      "il": "ÇORUM",
      "ilce": "SUNGURLU",
      "plaka": 19
    },
    {
      "bolge": "KARADENİZ",
      "id": 228,
      "il": "ÇORUM",
      "ilce": "UĞURLUDAĞ",
      "plaka": 19
    },
    {
      "bolge": "EGE",
      "id": 229,
      "il": "DENİZLİ",
      "ilce": "ACIPAYAM",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 230,
      "il": "DENİZLİ",
      "ilce": "PAMUKKALE",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 231,
      "il": "DENİZLİ",
      "ilce": "BABADAĞ",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 232,
      "il": "DENİZLİ",
      "ilce": "BAKLAN",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 233,
      "il": "DENİZLİ",
      "ilce": "BEKİLLİ",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 234,
      "il": "DENİZLİ",
      "ilce": "BEYAĞAÇ",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 235,
      "il": "DENİZLİ",
      "ilce": "BOZKURT",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 236,
      "il": "DENİZLİ",
      "ilce": "BULDAN",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 237,
      "il": "DENİZLİ",
      "ilce": "ÇAL",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 238,
      "il": "DENİZLİ",
      "ilce": "ÇAMELİ",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 239,
      "il": "DENİZLİ",
      "ilce": "ÇARDAK",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 240,
      "il": "DENİZLİ",
      "ilce": "ÇİVRİL",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 241,
      "il": "DENİZLİ",
      "ilce": "GÜNEY",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 242,
      "il": "DENİZLİ",
      "ilce": "HONAZ",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 243,
      "il": "DENİZLİ",
      "ilce": "KALE",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 244,
      "il": "DENİZLİ",
      "ilce": "SARAYKÖY",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 245,
      "il": "DENİZLİ",
      "ilce": "SERİNHİSAR",
      "plaka": 20
    },
    {
      "bolge": "EGE",
      "id": 246,
      "il": "DENİZLİ",
      "ilce": "TAVAS",
      "plaka": 20
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 247,
      "il": "DİYARBAKIR",
      "ilce": "BAĞLAR",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 248,
      "il": "DİYARBAKIR",
      "ilce": "BİSMİL",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 249,
      "il": "DİYARBAKIR",
      "ilce": "ÇERMİK",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 250,
      "il": "DİYARBAKIR",
      "ilce": "ÇINAR",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 251,
      "il": "DİYARBAKIR",
      "ilce": "ÇÜNGÜŞ",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 252,
      "il": "DİYARBAKIR",
      "ilce": "DİCLE",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 253,
      "il": "DİYARBAKIR",
      "ilce": "EĞİL",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 254,
      "il": "DİYARBAKIR",
      "ilce": "ERGANİ",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 255,
      "il": "DİYARBAKIR",
      "ilce": "HANİ",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 256,
      "il": "DİYARBAKIR",
      "ilce": "HAZRO",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 257,
      "il": "DİYARBAKIR",
      "ilce": "KAYAPINAR",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 258,
      "il": "DİYARBAKIR",
      "ilce": "KOCAKÖY",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 259,
      "il": "DİYARBAKIR",
      "ilce": "KULP",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 260,
      "il": "DİYARBAKIR",
      "ilce": "LİCE",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 261,
      "il": "DİYARBAKIR",
      "ilce": "SİLVAN",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 262,
      "il": "DİYARBAKIR",
      "ilce": "SUR",
      "plaka": 21
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 263,
      "il": "DİYARBAKIR",
      "ilce": "YENİŞEHİR",
      "plaka": 21
    },
    {
      "bolge": "MARMARA",
      "id": 264,
      "il": "EDİRNE",
      "ilce": "ENEZ",
      "plaka": 22
    },
    {
      "bolge": "MARMARA",
      "id": 265,
      "il": "EDİRNE",
      "ilce": "HAVSA",
      "plaka": 22
    },
    {
      "bolge": "MARMARA",
      "id": 266,
      "il": "EDİRNE",
      "ilce": "İPSALA",
      "plaka": 22
    },
    {
      "bolge": "MARMARA",
      "id": 267,
      "il": "EDİRNE",
      "ilce": "KEŞAN",
      "plaka": 22
    },
    {
      "bolge": "MARMARA",
      "id": 268,
      "il": "EDİRNE",
      "ilce": "LALAPAŞA",
      "plaka": 22
    },
    {
      "bolge": "MARMARA",
      "id": 269,
      "il": "EDİRNE",
      "ilce": "MERİÇ",
      "plaka": 22
    },
    {
      "bolge": "MARMARA",
      "id": 270,
      "il": "EDİRNE",
      "ilce": "SÜLOĞLU",
      "plaka": 22
    },
    {
      "bolge": "MARMARA",
      "id": 271,
      "il": "EDİRNE",
      "ilce": "UZUNKÖPRÜ",
      "plaka": 22
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 272,
      "il": "ELAZIĞ",
      "ilce": "AĞIN",
      "plaka": 23
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 273,
      "il": "ELAZIĞ",
      "ilce": "ALACAKAYA",
      "plaka": 23
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 274,
      "il": "ELAZIĞ",
      "ilce": "ARICAK",
      "plaka": 23
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 275,
      "il": "ELAZIĞ",
      "ilce": "BASKİL",
      "plaka": 23
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 276,
      "il": "ELAZIĞ",
      "ilce": "KARAKOÇAN",
      "plaka": 23
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 277,
      "il": "ELAZIĞ",
      "ilce": "KEBAN",
      "plaka": 23
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 278,
      "il": "ELAZIĞ",
      "ilce": "KOVANCILAR",
      "plaka": 23
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 279,
      "il": "ELAZIĞ",
      "ilce": "MADEN",
      "plaka": 23
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 280,
      "il": "ELAZIĞ",
      "ilce": "PALU",
      "plaka": 23
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 281,
      "il": "ELAZIĞ",
      "ilce": "SİVRİCE",
      "plaka": 23
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 282,
      "il": "ERZİNCAN",
      "ilce": "ÇAYIRLI",
      "plaka": 24
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 283,
      "il": "ERZİNCAN",
      "ilce": "İLİÇ",
      "plaka": 24
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 284,
      "il": "ERZİNCAN",
      "ilce": "KEMAH",
      "plaka": 24
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 285,
      "il": "ERZİNCAN",
      "ilce": "KEMALİYE",
      "plaka": 24
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 286,
      "il": "ERZİNCAN",
      "ilce": "OTLUKBELİ",
      "plaka": 24
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 287,
      "il": "ERZİNCAN",
      "ilce": "REFAHİYE",
      "plaka": 24
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 288,
      "il": "ERZİNCAN",
      "ilce": "TERCAN",
      "plaka": 24
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 289,
      "il": "ERZİNCAN",
      "ilce": "ÜZÜMLÜ",
      "plaka": 24
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 290,
      "il": "ERZURUM",
      "ilce": "AŞKALE",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 291,
      "il": "ERZURUM",
      "ilce": "AZİZİYE",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 292,
      "il": "ERZURUM",
      "ilce": "ÇAT",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 293,
      "il": "ERZURUM",
      "ilce": "HINIS",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 294,
      "il": "ERZURUM",
      "ilce": "HORASAN",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 295,
      "il": "ERZURUM",
      "ilce": "İSPİR",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 296,
      "il": "ERZURUM",
      "ilce": "KARAÇOBAN",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 297,
      "il": "ERZURUM",
      "ilce": "KARAYAZI",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 298,
      "il": "ERZURUM",
      "ilce": "KÖPRÜKÖY",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 299,
      "il": "ERZURUM",
      "ilce": "NARMAN",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 300,
      "il": "ERZURUM",
      "ilce": "OLTU",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 301,
      "il": "ERZURUM",
      "ilce": "OLUR",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 302,
      "il": "ERZURUM",
      "ilce": "PALANDÖKEN",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 303,
      "il": "ERZURUM",
      "ilce": "PASİNLER",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 304,
      "il": "ERZURUM",
      "ilce": "PAZARYOLU",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 305,
      "il": "ERZURUM",
      "ilce": "ŞENKAYA",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 306,
      "il": "ERZURUM",
      "ilce": "TEKMAN",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 307,
      "il": "ERZURUM",
      "ilce": "TORTUM",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 308,
      "il": "ERZURUM",
      "ilce": "UZUNDERE",
      "plaka": 25
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 309,
      "il": "ERZURUM",
      "ilce": "YAKUTİYE",
      "plaka": 25
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 310,
      "il": "ESKİŞEHİR",
      "ilce": "ALPU",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 311,
      "il": "ESKİŞEHİR",
      "ilce": "BEYLİKOVA",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 312,
      "il": "ESKİŞEHİR",
      "ilce": "ÇİFTELER",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 313,
      "il": "ESKİŞEHİR",
      "ilce": "GÜNYÜZÜ",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 314,
      "il": "ESKİŞEHİR",
      "ilce": "HAN",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 315,
      "il": "ESKİŞEHİR",
      "ilce": "İNÖNÜ",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 316,
      "il": "ESKİŞEHİR",
      "ilce": "MAHMUDİYE",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 317,
      "il": "ESKİŞEHİR",
      "ilce": "MİHALGAZİ",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 318,
      "il": "ESKİŞEHİR",
      "ilce": "MİHALIÇÇIK",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 319,
      "il": "ESKİŞEHİR",
      "ilce": "ODUNPAZARI",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 320,
      "il": "ESKİŞEHİR",
      "ilce": "SARICAKAYA",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 321,
      "il": "ESKİŞEHİR",
      "ilce": "SEYİTGAZİ",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 322,
      "il": "ESKİŞEHİR",
      "ilce": "SİVRİHİSAR",
      "plaka": 26
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 323,
      "il": "ESKİŞEHİR",
      "ilce": "TEPEBAŞI",
      "plaka": 26
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 324,
      "il": "GAZİANTEP",
      "ilce": "ARABAN",
      "plaka": 27
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 325,
      "il": "GAZİANTEP",
      "ilce": "İSLAHİYE",
      "plaka": 27
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 326,
      "il": "GAZİANTEP",
      "ilce": "KARKAMIŞ",
      "plaka": 27
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 327,
      "il": "GAZİANTEP",
      "ilce": "NİZİP",
      "plaka": 27
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 328,
      "il": "GAZİANTEP",
      "ilce": "NURDAĞI",
      "plaka": 27
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 329,
      "il": "GAZİANTEP",
      "ilce": "OĞUZELİ",
      "plaka": 27
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 330,
      "il": "GAZİANTEP",
      "ilce": "ŞAHİNBEY",
      "plaka": 27
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 331,
      "il": "GAZİANTEP",
      "ilce": "ŞEHİTKAMİL",
      "plaka": 27
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 332,
      "il": "GAZİANTEP",
      "ilce": "YAVUZELİ",
      "plaka": 27
    },
    {
      "bolge": "KARADENİZ",
      "id": 333,
      "il": "GİRESUN",
      "ilce": "ALUCRA",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 334,
      "il": "GİRESUN",
      "ilce": "BULANCAK",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 335,
      "il": "GİRESUN",
      "ilce": "ÇAMOLUK",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 336,
      "il": "GİRESUN",
      "ilce": "ÇANAKÇI",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 337,
      "il": "GİRESUN",
      "ilce": "DERELİ",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 338,
      "il": "GİRESUN",
      "ilce": "DOĞANKENT",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 339,
      "il": "GİRESUN",
      "ilce": "ESPİYE",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 340,
      "il": "GİRESUN",
      "ilce": "EYNESİL",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 341,
      "il": "GİRESUN",
      "ilce": "GÖRELE",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 342,
      "il": "GİRESUN",
      "ilce": "GÜCE",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 343,
      "il": "GİRESUN",
      "ilce": "KEŞAP",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 344,
      "il": "GİRESUN",
      "ilce": "PİRAZİZ",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 345,
      "il": "GİRESUN",
      "ilce": "ŞEBİNKARAHİSAR",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 346,
      "il": "GİRESUN",
      "ilce": "TİREBOLU",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 347,
      "il": "GİRESUN",
      "ilce": "YAĞLIDERE",
      "plaka": 28
    },
    {
      "bolge": "KARADENİZ",
      "id": 348,
      "il": "GÜMÜŞHANE",
      "ilce": "KELKİT",
      "plaka": 29
    },
    {
      "bolge": "KARADENİZ",
      "id": 349,
      "il": "GÜMÜŞHANE",
      "ilce": "KÖSE",
      "plaka": 29
    },
    {
      "bolge": "KARADENİZ",
      "id": 350,
      "il": "GÜMÜŞHANE",
      "ilce": "KÜRTÜN",
      "plaka": 29
    },
    {
      "bolge": "KARADENİZ",
      "id": 351,
      "il": "GÜMÜŞHANE",
      "ilce": "ŞİRAN",
      "plaka": 29
    },
    {
      "bolge": "KARADENİZ",
      "id": 352,
      "il": "GÜMÜŞHANE",
      "ilce": "TORUL",
      "plaka": 29
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 353,
      "il": "HAKKARİ",
      "ilce": "ÇUKURCA",
      "plaka": 30
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 354,
      "il": "HAKKARİ",
      "ilce": "ŞEMDİNLİ",
      "plaka": 30
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 355,
      "il": "HAKKARİ",
      "ilce": "YÜKSEKOVA",
      "plaka": 30
    },
    {
      "bolge": "AKDENİZ",
      "id": 356,
      "il": "HATAY",
      "ilce": "ALTINÖZÜ",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 357,
      "il": "HATAY",
      "ilce": "BELEN",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 358,
      "il": "HATAY",
      "ilce": "DÖRTYOL",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 359,
      "il": "HATAY",
      "ilce": "ERZİN",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 360,
      "il": "HATAY",
      "ilce": "HASSA",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 361,
      "il": "HATAY",
      "ilce": "İSKENDERUN",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 362,
      "il": "HATAY",
      "ilce": "KIRIKHAN",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 363,
      "il": "HATAY",
      "ilce": "KUMLU",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 364,
      "il": "HATAY",
      "ilce": "REYHANLI",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 365,
      "il": "HATAY",
      "ilce": "SAMANDAĞ",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 366,
      "il": "HATAY",
      "ilce": "YAYLADAĞI",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 367,
      "il": "ISPARTA",
      "ilce": "AKSU",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 368,
      "il": "ISPARTA",
      "ilce": "ATABEY",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 369,
      "il": "ISPARTA",
      "ilce": "EĞİRDİR",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 370,
      "il": "ISPARTA",
      "ilce": "GELENDOST",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 371,
      "il": "ISPARTA",
      "ilce": "GÖNEN",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 372,
      "il": "ISPARTA",
      "ilce": "KEÇİBORLU",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 373,
      "il": "ISPARTA",
      "ilce": "SENİRKENT",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 374,
      "il": "ISPARTA",
      "ilce": "SÜTÇÜLER",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 375,
      "il": "ISPARTA",
      "ilce": "ŞARKİKARAAĞAÇ",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 376,
      "il": "ISPARTA",
      "ilce": "ULUBORLU",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 377,
      "il": "ISPARTA",
      "ilce": "YALVAÇ",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 378,
      "il": "ISPARTA",
      "ilce": "YENİŞARBADEMLİ",
      "plaka": 32
    },
    {
      "bolge": "AKDENİZ",
      "id": 379,
      "il": "MERSİN",
      "ilce": "AKDENİZ",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 380,
      "il": "MERSİN",
      "ilce": "ANAMUR",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 381,
      "il": "MERSİN",
      "ilce": "AYDINCIK",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 382,
      "il": "MERSİN",
      "ilce": "BOZYAZI",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 383,
      "il": "MERSİN",
      "ilce": "ÇAMLIYAYLA",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 384,
      "il": "MERSİN",
      "ilce": "ERDEMLİ",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 385,
      "il": "MERSİN",
      "ilce": "GÜLNAR",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 386,
      "il": "MERSİN",
      "ilce": "MEZİTLİ",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 387,
      "il": "MERSİN",
      "ilce": "MUT",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 388,
      "il": "MERSİN",
      "ilce": "SİLİFKE",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 389,
      "il": "MERSİN",
      "ilce": "TARSUS",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 390,
      "il": "MERSİN",
      "ilce": "TOROSLAR",
      "plaka": 33
    },
    {
      "bolge": "AKDENİZ",
      "id": 391,
      "il": "MERSİN",
      "ilce": "YENİŞEHİR",
      "plaka": 33
    },
    {
      "bolge": "MARMARA",
      "id": 392,
      "il": "İSTANBUL",
      "ilce": "ADALAR",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 393,
      "il": "İSTANBUL",
      "ilce": "ARNAVUTKÖY",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 394,
      "il": "İSTANBUL",
      "ilce": "ATAŞEHİR",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 395,
      "il": "İSTANBUL",
      "ilce": "AVCILAR",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 396,
      "il": "İSTANBUL",
      "ilce": "BAĞCILAR",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 397,
      "il": "İSTANBUL",
      "ilce": "BAHÇELİEVLER",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 398,
      "il": "İSTANBUL",
      "ilce": "BAKIRKÖY",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 399,
      "il": "İSTANBUL",
      "ilce": "BAŞAKŞEHİR",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 400,
      "il": "İSTANBUL",
      "ilce": "BAYRAMPAŞA",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 401,
      "il": "İSTANBUL",
      "ilce": "BEŞİKTAŞ",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 402,
      "il": "İSTANBUL",
      "ilce": "BEYKOZ",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 403,
      "il": "İSTANBUL",
      "ilce": "BEYLİKDÜZÜ",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 404,
      "il": "İSTANBUL",
      "ilce": "BEYOĞLU",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 405,
      "il": "İSTANBUL",
      "ilce": "BÜYÜKÇEKMECE",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 406,
      "il": "İSTANBUL",
      "ilce": "ÇATALCA",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 407,
      "il": "İSTANBUL",
      "ilce": "ÇEKMEKÖY",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 408,
      "il": "İSTANBUL",
      "ilce": "ESENLER",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 409,
      "il": "İSTANBUL",
      "ilce": "ESENYURT",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 410,
      "il": "İSTANBUL",
      "ilce": "EYÜP",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 411,
      "il": "İSTANBUL",
      "ilce": "FATİH",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 412,
      "il": "İSTANBUL",
      "ilce": "GAZİOSMANPAŞA",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 413,
      "il": "İSTANBUL",
      "ilce": "GÜNGÖREN",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 414,
      "il": "İSTANBUL",
      "ilce": "KADIKÖY",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 415,
      "il": "İSTANBUL",
      "ilce": "KAĞITHANE",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 416,
      "il": "İSTANBUL",
      "ilce": "KARTAL",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 417,
      "il": "İSTANBUL",
      "ilce": "KÜÇÜKÇEKMECE",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 418,
      "il": "İSTANBUL",
      "ilce": "MALTEPE",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 419,
      "il": "İSTANBUL",
      "ilce": "PENDİK",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 420,
      "il": "İSTANBUL",
      "ilce": "SANCAKTEPE",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 421,
      "il": "İSTANBUL",
      "ilce": "SARIYER",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 422,
      "il": "İSTANBUL",
      "ilce": "SİLİVRİ",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 423,
      "il": "İSTANBUL",
      "ilce": "SULTANBEYLİ",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 424,
      "il": "İSTANBUL",
      "ilce": "SULTANGAZİ",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 425,
      "il": "İSTANBUL",
      "ilce": "ŞİLE",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 426,
      "il": "İSTANBUL",
      "ilce": "ŞİŞLİ",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 427,
      "il": "İSTANBUL",
      "ilce": "TUZLA",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 428,
      "il": "İSTANBUL",
      "ilce": "ÜMRANİYE",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 429,
      "il": "İSTANBUL",
      "ilce": "ÜSKÜDAR",
      "plaka": 34
    },
    {
      "bolge": "MARMARA",
      "id": 430,
      "il": "İSTANBUL",
      "ilce": "ZEYTİNBURNU",
      "plaka": 34
    },
    {
      "bolge": "EGE",
      "id": 431,
      "il": "İZMİR",
      "ilce": "ALİAĞA",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 432,
      "il": "İZMİR",
      "ilce": "BALÇOVA",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 433,
      "il": "İZMİR",
      "ilce": "BAYINDIR",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 434,
      "il": "İZMİR",
      "ilce": "BAYRAKLI",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 435,
      "il": "İZMİR",
      "ilce": "BERGAMA",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 436,
      "il": "İZMİR",
      "ilce": "BEYDAĞ",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 437,
      "il": "İZMİR",
      "ilce": "BORNOVA",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 438,
      "il": "İZMİR",
      "ilce": "BUCA",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 439,
      "il": "İZMİR",
      "ilce": "ÇEŞME",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 440,
      "il": "İZMİR",
      "ilce": "ÇİĞLİ",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 441,
      "il": "İZMİR",
      "ilce": "DİKİLİ",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 442,
      "il": "İZMİR",
      "ilce": "FOÇA",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 443,
      "il": "İZMİR",
      "ilce": "GAZİEMİR",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 444,
      "il": "İZMİR",
      "ilce": "GÜZELBAHÇE",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 445,
      "il": "İZMİR",
      "ilce": "KARABAĞLAR",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 446,
      "il": "İZMİR",
      "ilce": "KARABURUN",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 447,
      "il": "İZMİR",
      "ilce": "KARŞIYAKA",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 448,
      "il": "İZMİR",
      "ilce": "KEMALPAŞA",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 449,
      "il": "İZMİR",
      "ilce": "KINIK",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 450,
      "il": "İZMİR",
      "ilce": "KİRAZ",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 451,
      "il": "İZMİR",
      "ilce": "KONAK",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 452,
      "il": "İZMİR",
      "ilce": "MENDERES",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 453,
      "il": "İZMİR",
      "ilce": "MENEMEN",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 454,
      "il": "İZMİR",
      "ilce": "NARLIDERE",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 455,
      "il": "İZMİR",
      "ilce": "ÖDEMİŞ",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 456,
      "il": "İZMİR",
      "ilce": "SEFERİHİSAR",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 457,
      "il": "İZMİR",
      "ilce": "SELÇUK",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 458,
      "il": "İZMİR",
      "ilce": "TİRE",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 459,
      "il": "İZMİR",
      "ilce": "TORBALI",
      "plaka": 35
    },
    {
      "bolge": "EGE",
      "id": 460,
      "il": "İZMİR",
      "ilce": "URLA",
      "plaka": 35
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 461,
      "il": "KARS",
      "ilce": "AKYAKA",
      "plaka": 36
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 462,
      "il": "KARS",
      "ilce": "ARPAÇAY",
      "plaka": 36
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 463,
      "il": "KARS",
      "ilce": "DİGOR",
      "plaka": 36
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 464,
      "il": "KARS",
      "ilce": "KAĞIZMAN",
      "plaka": 36
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 465,
      "il": "KARS",
      "ilce": "SARIKAMIŞ",
      "plaka": 36
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 466,
      "il": "KARS",
      "ilce": "SELİM",
      "plaka": 36
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 467,
      "il": "KARS",
      "ilce": "SUSUZ",
      "plaka": 36
    },
    {
      "bolge": "KARADENİZ",
      "id": 468,
      "il": "KASTAMONU",
      "ilce": "ABANA",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 469,
      "il": "KASTAMONU",
      "ilce": "AĞLI",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 470,
      "il": "KASTAMONU",
      "ilce": "ARAÇ",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 471,
      "il": "KASTAMONU",
      "ilce": "AZDAVAY",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 472,
      "il": "KASTAMONU",
      "ilce": "BOZKURT",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 473,
      "il": "KASTAMONU",
      "ilce": "CİDE",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 474,
      "il": "KASTAMONU",
      "ilce": "ÇATALZEYTİN",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 475,
      "il": "KASTAMONU",
      "ilce": "DADAY",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 476,
      "il": "KASTAMONU",
      "ilce": "DEVREKANİ",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 477,
      "il": "KASTAMONU",
      "ilce": "DOĞANYURT",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 478,
      "il": "KASTAMONU",
      "ilce": "HANÖNÜ",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 479,
      "il": "KASTAMONU",
      "ilce": "İHSANGAZİ",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 480,
      "il": "KASTAMONU",
      "ilce": "İNEBOLU",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 481,
      "il": "KASTAMONU",
      "ilce": "KÜRE",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 482,
      "il": "KASTAMONU",
      "ilce": "PINARBAŞI",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 483,
      "il": "KASTAMONU",
      "ilce": "SEYDİLER",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 484,
      "il": "KASTAMONU",
      "ilce": "ŞENPAZAR",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 485,
      "il": "KASTAMONU",
      "ilce": "TAŞKÖPRÜ",
      "plaka": 37
    },
    {
      "bolge": "KARADENİZ",
      "id": 486,
      "il": "KASTAMONU",
      "ilce": "TOSYA",
      "plaka": 37
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 487,
      "il": "KAYSERİ",
      "ilce": "AKKIŞLA",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 488,
      "il": "KAYSERİ",
      "ilce": "BÜNYAN",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 489,
      "il": "KAYSERİ",
      "ilce": "DEVELİ",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 490,
      "il": "KAYSERİ",
      "ilce": "FELAHİYE",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 491,
      "il": "KAYSERİ",
      "ilce": "HACILAR",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 492,
      "il": "KAYSERİ",
      "ilce": "İNCESU",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 493,
      "il": "KAYSERİ",
      "ilce": "KOCASİNAN",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 494,
      "il": "KAYSERİ",
      "ilce": "MELİKGAZİ",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 495,
      "il": "KAYSERİ",
      "ilce": "ÖZVATAN",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 496,
      "il": "KAYSERİ",
      "ilce": "PINARBAŞI",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 497,
      "il": "KAYSERİ",
      "ilce": "SARIOĞLAN",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 498,
      "il": "KAYSERİ",
      "ilce": "SARIZ",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 499,
      "il": "KAYSERİ",
      "ilce": "TALAS",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 500,
      "il": "KAYSERİ",
      "ilce": "TOMARZA",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 501,
      "il": "KAYSERİ",
      "ilce": "YAHYALI",
      "plaka": 38
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 502,
      "il": "KAYSERİ",
      "ilce": "YEŞİLHİSAR",
      "plaka": 38
    },
    {
      "bolge": "MARMARA",
      "id": 503,
      "il": "KIRKLARELİ",
      "ilce": "BABAESKİ",
      "plaka": 39
    },
    {
      "bolge": "MARMARA",
      "id": 504,
      "il": "KIRKLARELİ",
      "ilce": "DEMİRKÖY",
      "plaka": 39
    },
    {
      "bolge": "MARMARA",
      "id": 505,
      "il": "KIRKLARELİ",
      "ilce": "KOFÇAZ",
      "plaka": 39
    },
    {
      "bolge": "MARMARA",
      "id": 506,
      "il": "KIRKLARELİ",
      "ilce": "LÜLEBURGAZ",
      "plaka": 39
    },
    {
      "bolge": "MARMARA",
      "id": 507,
      "il": "KIRKLARELİ",
      "ilce": "PEHLİVANKÖY",
      "plaka": 39
    },
    {
      "bolge": "MARMARA",
      "id": 508,
      "il": "KIRKLARELİ",
      "ilce": "PINARHİSAR",
      "plaka": 39
    },
    {
      "bolge": "MARMARA",
      "id": 509,
      "il": "KIRKLARELİ",
      "ilce": "VİZE",
      "plaka": 39
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 510,
      "il": "KIRŞEHİR",
      "ilce": "AKÇAKENT",
      "plaka": 40
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 511,
      "il": "KIRŞEHİR",
      "ilce": "AKPINAR",
      "plaka": 40
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 512,
      "il": "KIRŞEHİR",
      "ilce": "BOZTEPE",
      "plaka": 40
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 513,
      "il": "KIRŞEHİR",
      "ilce": "ÇİÇEKDAĞI",
      "plaka": 40
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 514,
      "il": "KIRŞEHİR",
      "ilce": "KAMAN",
      "plaka": 40
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 515,
      "il": "KIRŞEHİR",
      "ilce": "MUCUR",
      "plaka": 40
    },
    {
      "bolge": "MARMARA",
      "id": 516,
      "il": "KOCAELİ",
      "ilce": "BAŞİSKELE",
      "plaka": 41
    },
    {
      "bolge": "MARMARA",
      "id": 517,
      "il": "KOCAELİ",
      "ilce": "ÇAYIROVA",
      "plaka": 41
    },
    {
      "bolge": "MARMARA",
      "id": 518,
      "il": "KOCAELİ",
      "ilce": "DARICA",
      "plaka": 41
    },
    {
      "bolge": "MARMARA",
      "id": 519,
      "il": "KOCAELİ",
      "ilce": "DERİNCE",
      "plaka": 41
    },
    {
      "bolge": "MARMARA",
      "id": 520,
      "il": "KOCAELİ",
      "ilce": "DİLOVASI",
      "plaka": 41
    },
    {
      "bolge": "MARMARA",
      "id": 521,
      "il": "KOCAELİ",
      "ilce": "GEBZE",
      "plaka": 41
    },
    {
      "bolge": "MARMARA",
      "id": 522,
      "il": "KOCAELİ",
      "ilce": "GÖLCÜK",
      "plaka": 41
    },
    {
      "bolge": "MARMARA",
      "id": 523,
      "il": "KOCAELİ",
      "ilce": "İZMİT",
      "plaka": 41
    },
    {
      "bolge": "MARMARA",
      "id": 524,
      "il": "KOCAELİ",
      "ilce": "KANDIRA",
      "plaka": 41
    },
    {
      "bolge": "MARMARA",
      "id": 525,
      "il": "KOCAELİ",
      "ilce": "KARAMÜRSEL",
      "plaka": 41
    },
    {
      "bolge": "MARMARA",
      "id": 526,
      "il": "KOCAELİ",
      "ilce": "KARTEPE",
      "plaka": 41
    },
    {
      "bolge": "MARMARA",
      "id": 527,
      "il": "KOCAELİ",
      "ilce": "KÖRFEZ",
      "plaka": 41
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 528,
      "il": "KONYA",
      "ilce": "AHIRLI",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 529,
      "il": "KONYA",
      "ilce": "AKÖREN",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 530,
      "il": "KONYA",
      "ilce": "AKŞEHİR",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 531,
      "il": "KONYA",
      "ilce": "ALTINEKİN",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 532,
      "il": "KONYA",
      "ilce": "BEYŞEHİR",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 533,
      "il": "KONYA",
      "ilce": "BOZKIR",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 534,
      "il": "KONYA",
      "ilce": "CİHANBEYLİ",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 535,
      "il": "KONYA",
      "ilce": "ÇELTİK",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 536,
      "il": "KONYA",
      "ilce": "ÇUMRA",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 537,
      "il": "KONYA",
      "ilce": "DERBENT",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 538,
      "il": "KONYA",
      "ilce": "DEREBUCAK",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 539,
      "il": "KONYA",
      "ilce": "DOĞANHİSAR",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 540,
      "il": "KONYA",
      "ilce": "EMİRGAZİ",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 541,
      "il": "KONYA",
      "ilce": "EREĞLİ",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 542,
      "il": "KONYA",
      "ilce": "GÜNEYSINIR",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 543,
      "il": "KONYA",
      "ilce": "HADİM",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 544,
      "il": "KONYA",
      "ilce": "HALKAPINAR",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 545,
      "il": "KONYA",
      "ilce": "HÜYÜK",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 546,
      "il": "KONYA",
      "ilce": "ILGIN",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 547,
      "il": "KONYA",
      "ilce": "KADINHANI",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 548,
      "il": "KONYA",
      "ilce": "KARAPINAR",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 549,
      "il": "KONYA",
      "ilce": "KARATAY",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 550,
      "il": "KONYA",
      "ilce": "KULU",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 551,
      "il": "KONYA",
      "ilce": "MERAM",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 552,
      "il": "KONYA",
      "ilce": "SARAYÖNÜ",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 553,
      "il": "KONYA",
      "ilce": "SELÇUKLU",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 554,
      "il": "KONYA",
      "ilce": "SEYDİŞEHİR",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 555,
      "il": "KONYA",
      "ilce": "TAŞKENT",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 556,
      "il": "KONYA",
      "ilce": "TUZLUKÇU",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 557,
      "il": "KONYA",
      "ilce": "YALIHÜYÜK",
      "plaka": 42
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 558,
      "il": "KONYA",
      "ilce": "YUNAK",
      "plaka": 42
    },
    {
      "bolge": "EGE",
      "id": 559,
      "il": "KÜTAHYA",
      "ilce": "ALTINTAŞ",
      "plaka": 43
    },
    {
      "bolge": "EGE",
      "id": 560,
      "il": "KÜTAHYA",
      "ilce": "ASLANAPA",
      "plaka": 43
    },
    {
      "bolge": "EGE",
      "id": 561,
      "il": "KÜTAHYA",
      "ilce": "ÇAVDARHİSAR",
      "plaka": 43
    },
    {
      "bolge": "EGE",
      "id": 562,
      "il": "KÜTAHYA",
      "ilce": "DOMANİÇ",
      "plaka": 43
    },
    {
      "bolge": "EGE",
      "id": 563,
      "il": "KÜTAHYA",
      "ilce": "DUMLUPINAR",
      "plaka": 43
    },
    {
      "bolge": "EGE",
      "id": 564,
      "il": "KÜTAHYA",
      "ilce": "EMET",
      "plaka": 43
    },
    {
      "bolge": "EGE",
      "id": 565,
      "il": "KÜTAHYA",
      "ilce": "GEDİZ",
      "plaka": 43
    },
    {
      "bolge": "EGE",
      "id": 566,
      "il": "KÜTAHYA",
      "ilce": "HİSARCIK",
      "plaka": 43
    },
    {
      "bolge": "EGE",
      "id": 567,
      "il": "KÜTAHYA",
      "ilce": "PAZARLAR",
      "plaka": 43
    },
    {
      "bolge": "EGE",
      "id": 568,
      "il": "KÜTAHYA",
      "ilce": "SİMAV",
      "plaka": 43
    },
    {
      "bolge": "EGE",
      "id": 569,
      "il": "KÜTAHYA",
      "ilce": "ŞAPHANE",
      "plaka": 43
    },
    {
      "bolge": "EGE",
      "id": 570,
      "il": "KÜTAHYA",
      "ilce": "TAVŞANLI",
      "plaka": 43
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 571,
      "il": "MALATYA",
      "ilce": "AKÇADAĞ",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 572,
      "il": "MALATYA",
      "ilce": "ARAPGİR",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 573,
      "il": "MALATYA",
      "ilce": "ARGUVAN",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 574,
      "il": "MALATYA",
      "ilce": "BATTALGAZİ",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 575,
      "il": "MALATYA",
      "ilce": "DARENDE",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 576,
      "il": "MALATYA",
      "ilce": "DOĞANŞEHİR",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 577,
      "il": "MALATYA",
      "ilce": "DOĞANYOL",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 578,
      "il": "MALATYA",
      "ilce": "HEKİMHAN",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 579,
      "il": "MALATYA",
      "ilce": "KALE",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 580,
      "il": "MALATYA",
      "ilce": "KULUNCAK",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 581,
      "il": "MALATYA",
      "ilce": "PÜTÜRGE",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 582,
      "il": "MALATYA",
      "ilce": "YAZIHAN",
      "plaka": 44
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 583,
      "il": "MALATYA",
      "ilce": "YEŞİLYURT",
      "plaka": 44
    },
    {
      "bolge": "EGE",
      "id": 584,
      "il": "MANİSA",
      "ilce": "AHMETLİ",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 585,
      "il": "MANİSA",
      "ilce": "AKHİSAR",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 586,
      "il": "MANİSA",
      "ilce": "ALAŞEHİR",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 587,
      "il": "MANİSA",
      "ilce": "DEMİRCİ",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 588,
      "il": "MANİSA",
      "ilce": "GÖLMARMARA",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 589,
      "il": "MANİSA",
      "ilce": "GÖRDES",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 590,
      "il": "MANİSA",
      "ilce": "KIRKAĞAÇ",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 591,
      "il": "MANİSA",
      "ilce": "KÖPRÜBAŞI",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 592,
      "il": "MANİSA",
      "ilce": "KULA",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 593,
      "il": "MANİSA",
      "ilce": "SALİHLİ",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 594,
      "il": "MANİSA",
      "ilce": "SARIGÖL",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 595,
      "il": "MANİSA",
      "ilce": "SARUHANLI",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 596,
      "il": "MANİSA",
      "ilce": "SELENDİ",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 597,
      "il": "MANİSA",
      "ilce": "SOMA",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 598,
      "il": "MANİSA",
      "ilce": "TURGUTLU",
      "plaka": 45
    },
    {
      "bolge": "AKDENİZ",
      "id": 599,
      "il": "KAHRAMANMARAŞ",
      "ilce": "AFŞİN",
      "plaka": 46
    },
    {
      "bolge": "AKDENİZ",
      "id": 600,
      "il": "KAHRAMANMARAŞ",
      "ilce": "ANDIRIN",
      "plaka": 46
    },
    {
      "bolge": "AKDENİZ",
      "id": 601,
      "il": "KAHRAMANMARAŞ",
      "ilce": "ÇAĞLAYANCERİT",
      "plaka": 46
    },
    {
      "bolge": "AKDENİZ",
      "id": 602,
      "il": "KAHRAMANMARAŞ",
      "ilce": "EKİNÖZÜ",
      "plaka": 46
    },
    {
      "bolge": "AKDENİZ",
      "id": 603,
      "il": "KAHRAMANMARAŞ",
      "ilce": "ELBİSTAN",
      "plaka": 46
    },
    {
      "bolge": "AKDENİZ",
      "id": 604,
      "il": "KAHRAMANMARAŞ",
      "ilce": "GÖKSUN",
      "plaka": 46
    },
    {
      "bolge": "AKDENİZ",
      "id": 605,
      "il": "KAHRAMANMARAŞ",
      "ilce": "NURHAK",
      "plaka": 46
    },
    {
      "bolge": "AKDENİZ",
      "id": 606,
      "il": "KAHRAMANMARAŞ",
      "ilce": "PAZARCIK",
      "plaka": 46
    },
    {
      "bolge": "AKDENİZ",
      "id": 607,
      "il": "KAHRAMANMARAŞ",
      "ilce": "TÜRKOĞLU",
      "plaka": 46
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 608,
      "il": "MARDİN",
      "ilce": "DARGEÇİT",
      "plaka": 47
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 609,
      "il": "MARDİN",
      "ilce": "DERİK",
      "plaka": 47
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 610,
      "il": "MARDİN",
      "ilce": "KIZILTEPE",
      "plaka": 47
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 611,
      "il": "MARDİN",
      "ilce": "MAZIDAĞI",
      "plaka": 47
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 612,
      "il": "MARDİN",
      "ilce": "MİDYAT",
      "plaka": 47
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 613,
      "il": "MARDİN",
      "ilce": "NUSAYBİN",
      "plaka": 47
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 614,
      "il": "MARDİN",
      "ilce": "ÖMERLİ",
      "plaka": 47
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 615,
      "il": "MARDİN",
      "ilce": "SAVUR",
      "plaka": 47
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 616,
      "il": "MARDİN",
      "ilce": "YEŞİLLİ",
      "plaka": 47
    },
    {
      "bolge": "EGE",
      "id": 617,
      "il": "MUĞLA",
      "ilce": "BODRUM",
      "plaka": 48
    },
    {
      "bolge": "EGE",
      "id": 618,
      "il": "MUĞLA",
      "ilce": "DALAMAN",
      "plaka": 48
    },
    {
      "bolge": "EGE",
      "id": 619,
      "il": "MUĞLA",
      "ilce": "DATÇA",
      "plaka": 48
    },
    {
      "bolge": "EGE",
      "id": 620,
      "il": "MUĞLA",
      "ilce": "FETHİYE",
      "plaka": 48
    },
    {
      "bolge": "EGE",
      "id": 621,
      "il": "MUĞLA",
      "ilce": "KAVAKLIDERE",
      "plaka": 48
    },
    {
      "bolge": "EGE",
      "id": 622,
      "il": "MUĞLA",
      "ilce": "KÖYCEĞİZ",
      "plaka": 48
    },
    {
      "bolge": "EGE",
      "id": 623,
      "il": "MUĞLA",
      "ilce": "MARMARİS",
      "plaka": 48
    },
    {
      "bolge": "EGE",
      "id": 624,
      "il": "MUĞLA",
      "ilce": "MİLAS",
      "plaka": 48
    },
    {
      "bolge": "EGE",
      "id": 625,
      "il": "MUĞLA",
      "ilce": "ORTACA",
      "plaka": 48
    },
    {
      "bolge": "EGE",
      "id": 626,
      "il": "MUĞLA",
      "ilce": "ULA",
      "plaka": 48
    },
    {
      "bolge": "EGE",
      "id": 627,
      "il": "MUĞLA",
      "ilce": "YATAĞAN",
      "plaka": 48
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 628,
      "il": "MUŞ",
      "ilce": "BULANIK",
      "plaka": 49
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 629,
      "il": "MUŞ",
      "ilce": "HASKÖY",
      "plaka": 49
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 630,
      "il": "MUŞ",
      "ilce": "KORKUT",
      "plaka": 49
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 631,
      "il": "MUŞ",
      "ilce": "MALAZGİRT",
      "plaka": 49
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 632,
      "il": "MUŞ",
      "ilce": "VARTO",
      "plaka": 49
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 633,
      "il": "NEVŞEHİR",
      "ilce": "ACIGÖL",
      "plaka": 50
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 634,
      "il": "NEVŞEHİR",
      "ilce": "AVANOS",
      "plaka": 50
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 635,
      "il": "NEVŞEHİR",
      "ilce": "DERİNKUYU",
      "plaka": 50
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 636,
      "il": "NEVŞEHİR",
      "ilce": "GÜLŞEHİR",
      "plaka": 50
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 637,
      "il": "NEVŞEHİR",
      "ilce": "HACIBEKTAŞ",
      "plaka": 50
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 638,
      "il": "NEVŞEHİR",
      "ilce": "KOZAKLI",
      "plaka": 50
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 639,
      "il": "NEVŞEHİR",
      "ilce": "ÜRGÜP",
      "plaka": 50
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 640,
      "il": "NİĞDE",
      "ilce": "ALTUNHİSAR",
      "plaka": 51
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 641,
      "il": "NİĞDE",
      "ilce": "BOR",
      "plaka": 51
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 642,
      "il": "NİĞDE",
      "ilce": "ÇAMARDI",
      "plaka": 51
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 643,
      "il": "NİĞDE",
      "ilce": "ÇİFTLİK",
      "plaka": 51
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 644,
      "il": "NİĞDE",
      "ilce": "ULUKIŞLA",
      "plaka": 51
    },
    {
      "bolge": "KARADENİZ",
      "id": 645,
      "il": "ORDU",
      "ilce": "AKKUŞ",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 646,
      "il": "ORDU",
      "ilce": "AYBASTI",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 647,
      "il": "ORDU",
      "ilce": "ÇAMAŞ",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 648,
      "il": "ORDU",
      "ilce": "ÇATALPINAR",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 649,
      "il": "ORDU",
      "ilce": "ÇAYBAŞI",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 650,
      "il": "ORDU",
      "ilce": "FATSA",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 651,
      "il": "ORDU",
      "ilce": "GÖLKÖY",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 652,
      "il": "ORDU",
      "ilce": "GÜLYALI",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 653,
      "il": "ORDU",
      "ilce": "GÜRGENTEPE",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 654,
      "il": "ORDU",
      "ilce": "İKİZCE",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 655,
      "il": "ORDU",
      "ilce": "KABADÜZ",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 656,
      "il": "ORDU",
      "ilce": "KABATAŞ",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 657,
      "il": "ORDU",
      "ilce": "KORGAN",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 658,
      "il": "ORDU",
      "ilce": "KUMRU",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 659,
      "il": "ORDU",
      "ilce": "MESUDİYE",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 660,
      "il": "ORDU",
      "ilce": "PERŞEMBE",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 661,
      "il": "ORDU",
      "ilce": "ULUBEY",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 662,
      "il": "ORDU",
      "ilce": "ÜNYE",
      "plaka": 52
    },
    {
      "bolge": "KARADENİZ",
      "id": 663,
      "il": "RİZE",
      "ilce": "ARDEŞEN",
      "plaka": 53
    },
    {
      "bolge": "KARADENİZ",
      "id": 664,
      "il": "RİZE",
      "ilce": "ÇAMLIHEMŞİN",
      "plaka": 53
    },
    {
      "bolge": "KARADENİZ",
      "id": 665,
      "il": "RİZE",
      "ilce": "ÇAYELİ",
      "plaka": 53
    },
    {
      "bolge": "KARADENİZ",
      "id": 666,
      "il": "RİZE",
      "ilce": "DEREPAZARI",
      "plaka": 53
    },
    {
      "bolge": "KARADENİZ",
      "id": 667,
      "il": "RİZE",
      "ilce": "FINDIKLI",
      "plaka": 53
    },
    {
      "bolge": "KARADENİZ",
      "id": 668,
      "il": "RİZE",
      "ilce": "GÜNEYSU",
      "plaka": 53
    },
    {
      "bolge": "KARADENİZ",
      "id": 669,
      "il": "RİZE",
      "ilce": "HEMŞİN",
      "plaka": 53
    },
    {
      "bolge": "KARADENİZ",
      "id": 670,
      "il": "RİZE",
      "ilce": "İKİZDERE",
      "plaka": 53
    },
    {
      "bolge": "KARADENİZ",
      "id": 671,
      "il": "RİZE",
      "ilce": "İYİDERE",
      "plaka": 53
    },
    {
      "bolge": "KARADENİZ",
      "id": 672,
      "il": "RİZE",
      "ilce": "KALKANDERE",
      "plaka": 53
    },
    {
      "bolge": "KARADENİZ",
      "id": 673,
      "il": "RİZE",
      "ilce": "PAZAR",
      "plaka": 53
    },
    {
      "bolge": "MARMARA",
      "id": 674,
      "il": "SAKARYA",
      "ilce": "ADAPAZARI",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 675,
      "il": "SAKARYA",
      "ilce": "AKYAZI",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 676,
      "il": "SAKARYA",
      "ilce": "ARİFİYE",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 677,
      "il": "SAKARYA",
      "ilce": "ERENLER",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 678,
      "il": "SAKARYA",
      "ilce": "FERİZLİ",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 679,
      "il": "SAKARYA",
      "ilce": "GEYVE",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 680,
      "il": "SAKARYA",
      "ilce": "HENDEK",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 681,
      "il": "SAKARYA",
      "ilce": "KARAPÜRÇEK",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 682,
      "il": "SAKARYA",
      "ilce": "KARASU",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 683,
      "il": "SAKARYA",
      "ilce": "KAYNARCA",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 684,
      "il": "SAKARYA",
      "ilce": "KOCAALİ",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 685,
      "il": "SAKARYA",
      "ilce": "PAMUKOVA",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 686,
      "il": "SAKARYA",
      "ilce": "SAPANCA",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 687,
      "il": "SAKARYA",
      "ilce": "SERDİVAN",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 688,
      "il": "SAKARYA",
      "ilce": "SÖĞÜTLÜ",
      "plaka": 54
    },
    {
      "bolge": "MARMARA",
      "id": 689,
      "il": "SAKARYA",
      "ilce": "TARAKLI",
      "plaka": 54
    },
    {
      "bolge": "KARADENİZ",
      "id": 690,
      "il": "SAMSUN",
      "ilce": "ONDOKUZMAYIS",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 691,
      "il": "SAMSUN",
      "ilce": "ALAÇAM",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 692,
      "il": "SAMSUN",
      "ilce": "ASARCIK",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 693,
      "il": "SAMSUN",
      "ilce": "ATAKUM",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 694,
      "il": "SAMSUN",
      "ilce": "AYVACIK",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 695,
      "il": "SAMSUN",
      "ilce": "BAFRA",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 696,
      "il": "SAMSUN",
      "ilce": "CANİK",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 697,
      "il": "SAMSUN",
      "ilce": "ÇARŞAMBA",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 698,
      "il": "SAMSUN",
      "ilce": "HAVZA",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 699,
      "il": "SAMSUN",
      "ilce": "İLKADIM",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 700,
      "il": "SAMSUN",
      "ilce": "KAVAK",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 701,
      "il": "SAMSUN",
      "ilce": "LADİK",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 702,
      "il": "SAMSUN",
      "ilce": "SALIPAZARI",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 703,
      "il": "SAMSUN",
      "ilce": "TEKKEKÖY",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 704,
      "il": "SAMSUN",
      "ilce": "TERME",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 705,
      "il": "SAMSUN",
      "ilce": "VEZİRKÖPRÜ",
      "plaka": 55
    },
    {
      "bolge": "KARADENİZ",
      "id": 706,
      "il": "SAMSUN",
      "ilce": "YAKAKENT",
      "plaka": 55
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 707,
      "il": "SİİRT",
      "ilce": "AYDINLAR",
      "plaka": 56
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 708,
      "il": "SİİRT",
      "ilce": "BAYKAN",
      "plaka": 56
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 709,
      "il": "SİİRT",
      "ilce": "ERUH",
      "plaka": 56
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 710,
      "il": "SİİRT",
      "ilce": "KURTALAN",
      "plaka": 56
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 711,
      "il": "SİİRT",
      "ilce": "PERVARİ",
      "plaka": 56
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 712,
      "il": "SİİRT",
      "ilce": "ŞİRVAN",
      "plaka": 56
    },
    {
      "bolge": "KARADENİZ",
      "id": 713,
      "il": "SİNOP",
      "ilce": "AYANCIK",
      "plaka": 57
    },
    {
      "bolge": "KARADENİZ",
      "id": 714,
      "il": "SİNOP",
      "ilce": "BOYABAT",
      "plaka": 57
    },
    {
      "bolge": "KARADENİZ",
      "id": 715,
      "il": "SİNOP",
      "ilce": "DİKMEN",
      "plaka": 57
    },
    {
      "bolge": "KARADENİZ",
      "id": 716,
      "il": "SİNOP",
      "ilce": "DURAĞAN",
      "plaka": 57
    },
    {
      "bolge": "KARADENİZ",
      "id": 717,
      "il": "SİNOP",
      "ilce": "ERFELEK",
      "plaka": 57
    },
    {
      "bolge": "KARADENİZ",
      "id": 718,
      "il": "SİNOP",
      "ilce": "GERZE",
      "plaka": 57
    },
    {
      "bolge": "KARADENİZ",
      "id": 719,
      "il": "SİNOP",
      "ilce": "SARAYDÜZÜ",
      "plaka": 57
    },
    {
      "bolge": "KARADENİZ",
      "id": 720,
      "il": "SİNOP",
      "ilce": "TÜRKELİ",
      "plaka": 57
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 721,
      "il": "SİVAS",
      "ilce": "AKINCILAR",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 722,
      "il": "SİVAS",
      "ilce": "ALTINYAYLA",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 723,
      "il": "SİVAS",
      "ilce": "DİVRİĞİ",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 724,
      "il": "SİVAS",
      "ilce": "DOĞANŞAR",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 725,
      "il": "SİVAS",
      "ilce": "GEMEREK",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 726,
      "il": "SİVAS",
      "ilce": "GÖLOVA",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 727,
      "il": "SİVAS",
      "ilce": "GÜRÜN",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 728,
      "il": "SİVAS",
      "ilce": "HAFİK",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 729,
      "il": "SİVAS",
      "ilce": "İMRANLI",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 730,
      "il": "SİVAS",
      "ilce": "KANGAL",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 731,
      "il": "SİVAS",
      "ilce": "KOYULHİSAR",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 732,
      "il": "SİVAS",
      "ilce": "SUŞEHRİ",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 733,
      "il": "SİVAS",
      "ilce": "ŞARKIŞLA",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 734,
      "il": "SİVAS",
      "ilce": "ULAŞ",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 735,
      "il": "SİVAS",
      "ilce": "YILDIZELİ",
      "plaka": 58
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 736,
      "il": "SİVAS",
      "ilce": "ZARA",
      "plaka": 58
    },
    {
      "bolge": "MARMARA",
      "id": 737,
      "il": "TEKİRDAĞ",
      "ilce": "ÇERKEZKÖY",
      "plaka": 59
    },
    {
      "bolge": "MARMARA",
      "id": 738,
      "il": "TEKİRDAĞ",
      "ilce": "ÇORLU",
      "plaka": 59
    },
    {
      "bolge": "MARMARA",
      "id": 739,
      "il": "TEKİRDAĞ",
      "ilce": "HAYRABOLU",
      "plaka": 59
    },
    {
      "bolge": "MARMARA",
      "id": 740,
      "il": "TEKİRDAĞ",
      "ilce": "MALKARA",
      "plaka": 59
    },
    {
      "bolge": "MARMARA",
      "id": 741,
      "il": "TEKİRDAĞ",
      "ilce": "MARMARAEREĞLİSİ",
      "plaka": 59
    },
    {
      "bolge": "MARMARA",
      "id": 742,
      "il": "TEKİRDAĞ",
      "ilce": "MURATLI",
      "plaka": 59
    },
    {
      "bolge": "MARMARA",
      "id": 743,
      "il": "TEKİRDAĞ",
      "ilce": "SARAY",
      "plaka": 59
    },
    {
      "bolge": "MARMARA",
      "id": 744,
      "il": "TEKİRDAĞ",
      "ilce": "ŞARKÖY",
      "plaka": 59
    },
    {
      "bolge": "KARADENİZ",
      "id": 745,
      "il": "TOKAT",
      "ilce": "ALMUS",
      "plaka": 60
    },
    {
      "bolge": "KARADENİZ",
      "id": 746,
      "il": "TOKAT",
      "ilce": "ARTOVA",
      "plaka": 60
    },
    {
      "bolge": "KARADENİZ",
      "id": 747,
      "il": "TOKAT",
      "ilce": "BAŞÇİFTLİK",
      "plaka": 60
    },
    {
      "bolge": "KARADENİZ",
      "id": 748,
      "il": "TOKAT",
      "ilce": "ERBAA",
      "plaka": 60
    },
    {
      "bolge": "KARADENİZ",
      "id": 749,
      "il": "TOKAT",
      "ilce": "NİKSAR",
      "plaka": 60
    },
    {
      "bolge": "KARADENİZ",
      "id": 750,
      "il": "TOKAT",
      "ilce": "PAZAR",
      "plaka": 60
    },
    {
      "bolge": "KARADENİZ",
      "id": 751,
      "il": "TOKAT",
      "ilce": "REŞADİYE",
      "plaka": 60
    },
    {
      "bolge": "KARADENİZ",
      "id": 752,
      "il": "TOKAT",
      "ilce": "SULUSARAY",
      "plaka": 60
    },
    {
      "bolge": "KARADENİZ",
      "id": 753,
      "il": "TOKAT",
      "ilce": "TURHAL",
      "plaka": 60
    },
    {
      "bolge": "KARADENİZ",
      "id": 754,
      "il": "TOKAT",
      "ilce": "YEŞİLYURT",
      "plaka": 60
    },
    {
      "bolge": "KARADENİZ",
      "id": 755,
      "il": "TOKAT",
      "ilce": "ZİLE",
      "plaka": 60
    },
    {
      "bolge": "KARADENİZ",
      "id": 756,
      "il": "TRABZON",
      "ilce": "AKÇAABAT",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 757,
      "il": "TRABZON",
      "ilce": "ARAKLI",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 758,
      "il": "TRABZON",
      "ilce": "ARSİN",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 759,
      "il": "TRABZON",
      "ilce": "BEŞİKDÜZÜ",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 760,
      "il": "TRABZON",
      "ilce": "ÇARŞIBAŞI",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 761,
      "il": "TRABZON",
      "ilce": "ÇAYKARA",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 762,
      "il": "TRABZON",
      "ilce": "DERNEKPAZARI",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 763,
      "il": "TRABZON",
      "ilce": "DÜZKÖY",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 764,
      "il": "TRABZON",
      "ilce": "HAYRAT",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 765,
      "il": "TRABZON",
      "ilce": "KÖPRÜBAŞI",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 766,
      "il": "TRABZON",
      "ilce": "MAÇKA",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 767,
      "il": "TRABZON",
      "ilce": "OF",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 768,
      "il": "TRABZON",
      "ilce": "SÜRMENE",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 769,
      "il": "TRABZON",
      "ilce": "ŞALPAZARI",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 770,
      "il": "TRABZON",
      "ilce": "TONYA",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 771,
      "il": "TRABZON",
      "ilce": "VAKFIKEBİR",
      "plaka": 61
    },
    {
      "bolge": "KARADENİZ",
      "id": 772,
      "il": "TRABZON",
      "ilce": "YOMRA",
      "plaka": 61
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 773,
      "il": "TUNCELİ",
      "ilce": "ÇEMİŞGEZEK",
      "plaka": 62
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 774,
      "il": "TUNCELİ",
      "ilce": "HOZAT",
      "plaka": 62
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 775,
      "il": "TUNCELİ",
      "ilce": "MAZGİRT",
      "plaka": 62
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 776,
      "il": "TUNCELİ",
      "ilce": "NAZIMİYE",
      "plaka": 62
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 777,
      "il": "TUNCELİ",
      "ilce": "OVACIK",
      "plaka": 62
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 778,
      "il": "TUNCELİ",
      "ilce": "PERTEK",
      "plaka": 62
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 779,
      "il": "TUNCELİ",
      "ilce": "PÜLÜMÜR",
      "plaka": 62
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 780,
      "il": "ŞANLIURFA",
      "ilce": "AKÇAKALE",
      "plaka": 63
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 781,
      "il": "ŞANLIURFA",
      "ilce": "BİRECİK",
      "plaka": 63
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 782,
      "il": "ŞANLIURFA",
      "ilce": "BOZOVA",
      "plaka": 63
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 783,
      "il": "ŞANLIURFA",
      "ilce": "CEYLANPINAR",
      "plaka": 63
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 784,
      "il": "ŞANLIURFA",
      "ilce": "HALFETİ",
      "plaka": 63
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 785,
      "il": "ŞANLIURFA",
      "ilce": "HARRAN",
      "plaka": 63
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 786,
      "il": "ŞANLIURFA",
      "ilce": "HİLVAN",
      "plaka": 63
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 787,
      "il": "ŞANLIURFA",
      "ilce": "SİVEREK",
      "plaka": 63
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 788,
      "il": "ŞANLIURFA",
      "ilce": "SURUÇ",
      "plaka": 63
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 789,
      "il": "ŞANLIURFA",
      "ilce": "VİRANŞEHİR",
      "plaka": 63
    },
    {
      "bolge": "EGE",
      "id": 790,
      "il": "UŞAK",
      "ilce": "BANAZ",
      "plaka": 64
    },
    {
      "bolge": "EGE",
      "id": 791,
      "il": "UŞAK",
      "ilce": "EŞME",
      "plaka": 64
    },
    {
      "bolge": "EGE",
      "id": 792,
      "il": "UŞAK",
      "ilce": "KARAHALLI",
      "plaka": 64
    },
    {
      "bolge": "EGE",
      "id": 793,
      "il": "UŞAK",
      "ilce": "SİVASLI",
      "plaka": 64
    },
    {
      "bolge": "EGE",
      "id": 794,
      "il": "UŞAK",
      "ilce": "ULUBEY",
      "plaka": 64
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 795,
      "il": "VAN",
      "ilce": "BAHÇESARAY",
      "plaka": 65
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 796,
      "il": "VAN",
      "ilce": "BAŞKALE",
      "plaka": 65
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 797,
      "il": "VAN",
      "ilce": "ÇALDIRAN",
      "plaka": 65
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 798,
      "il": "VAN",
      "ilce": "ÇATAK",
      "plaka": 65
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 799,
      "il": "VAN",
      "ilce": "EDREMİT",
      "plaka": 65
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 800,
      "il": "VAN",
      "ilce": "ERCİŞ",
      "plaka": 65
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 801,
      "il": "VAN",
      "ilce": "GEVAŞ",
      "plaka": 65
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 802,
      "il": "VAN",
      "ilce": "GÜRPINAR",
      "plaka": 65
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 803,
      "il": "VAN",
      "ilce": "MURADİYE",
      "plaka": 65
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 804,
      "il": "VAN",
      "ilce": "ÖZALP",
      "plaka": 65
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 805,
      "il": "VAN",
      "ilce": "SARAY",
      "plaka": 65
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 806,
      "il": "YOZGAT",
      "ilce": "AKDAĞMADENİ",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 807,
      "il": "YOZGAT",
      "ilce": "AYDINCIK",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 808,
      "il": "YOZGAT",
      "ilce": "BOĞAZLIYAN",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 809,
      "il": "YOZGAT",
      "ilce": "ÇANDIR",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 810,
      "il": "YOZGAT",
      "ilce": "ÇAYIRALAN",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 811,
      "il": "YOZGAT",
      "ilce": "ÇEKEREK",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 812,
      "il": "YOZGAT",
      "ilce": "KADIŞEHRİ",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 813,
      "il": "YOZGAT",
      "ilce": "SARAYKENT",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 814,
      "il": "YOZGAT",
      "ilce": "SARIKAYA",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 815,
      "il": "YOZGAT",
      "ilce": "SORGUN",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 816,
      "il": "YOZGAT",
      "ilce": "ŞEFAATLİ",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 817,
      "il": "YOZGAT",
      "ilce": "YENİFAKILI",
      "plaka": 66
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 818,
      "il": "YOZGAT",
      "ilce": "YERKÖY",
      "plaka": 66
    },
    {
      "bolge": "KARADENİZ",
      "id": 819,
      "il": "ZONGULDAK",
      "ilce": "ALAPLI",
      "plaka": 67
    },
    {
      "bolge": "KARADENİZ",
      "id": 820,
      "il": "ZONGULDAK",
      "ilce": "ÇAYCUMA",
      "plaka": 67
    },
    {
      "bolge": "KARADENİZ",
      "id": 821,
      "il": "ZONGULDAK",
      "ilce": "DEVREK",
      "plaka": 67
    },
    {
      "bolge": "KARADENİZ",
      "id": 822,
      "il": "ZONGULDAK",
      "ilce": "EREĞLİ",
      "plaka": 67
    },
    {
      "bolge": "KARADENİZ",
      "id": 823,
      "il": "ZONGULDAK",
      "ilce": "GÖKÇEBEY",
      "plaka": 67
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 824,
      "il": "AKSARAY",
      "ilce": "AĞAÇÖREN",
      "plaka": 68
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 825,
      "il": "AKSARAY",
      "ilce": "ESKİL",
      "plaka": 68
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 826,
      "il": "AKSARAY",
      "ilce": "GÜLAĞAÇ",
      "plaka": 68
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 827,
      "il": "AKSARAY",
      "ilce": "GÜZELYURT",
      "plaka": 68
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 828,
      "il": "AKSARAY",
      "ilce": "ORTAKÖY",
      "plaka": 68
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 829,
      "il": "AKSARAY",
      "ilce": "SARIYAHŞİ",
      "plaka": 68
    },
    {
      "bolge": "KARADENİZ",
      "id": 830,
      "il": "BAYBURT",
      "ilce": "AYDINTEPE",
      "plaka": 69
    },
    {
      "bolge": "KARADENİZ",
      "id": 831,
      "il": "BAYBURT",
      "ilce": "DEMİRÖZÜ",
      "plaka": 69
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 832,
      "il": "KARAMAN",
      "ilce": "AYRANCI",
      "plaka": 70
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 833,
      "il": "KARAMAN",
      "ilce": "BAŞYAYLA",
      "plaka": 70
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 834,
      "il": "KARAMAN",
      "ilce": "ERMENEK",
      "plaka": 70
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 835,
      "il": "KARAMAN",
      "ilce": "KAZIMKARABEKİR",
      "plaka": 70
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 836,
      "il": "KARAMAN",
      "ilce": "SARIVELİLER",
      "plaka": 70
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 837,
      "il": "KIRIKKALE",
      "ilce": "BAHŞİLİ",
      "plaka": 71
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 838,
      "il": "KIRIKKALE",
      "ilce": "BALIŞEYH",
      "plaka": 71
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 839,
      "il": "KIRIKKALE",
      "ilce": "ÇELEBİ",
      "plaka": 71
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 840,
      "il": "KIRIKKALE",
      "ilce": "DELİCE",
      "plaka": 71
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 841,
      "il": "KIRIKKALE",
      "ilce": "KARAKEÇİLİ",
      "plaka": 71
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 842,
      "il": "KIRIKKALE",
      "ilce": "KESKİN",
      "plaka": 71
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 843,
      "il": "KIRIKKALE",
      "ilce": "SULAKYURT",
      "plaka": 71
    },
    {
      "bolge": "İÇ ANADOLU",
      "id": 844,
      "il": "KIRIKKALE",
      "ilce": "YAHŞİHAN",
      "plaka": 71
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 845,
      "il": "BATMAN",
      "ilce": "BEŞİRİ",
      "plaka": 72
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 846,
      "il": "BATMAN",
      "ilce": "GERCÜŞ",
      "plaka": 72
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 847,
      "il": "BATMAN",
      "ilce": "HASANKEYF",
      "plaka": 72
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 848,
      "il": "BATMAN",
      "ilce": "KOZLUK",
      "plaka": 72
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 849,
      "il": "BATMAN",
      "ilce": "SASON",
      "plaka": 72
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 850,
      "il": "ŞIRNAK",
      "ilce": "BEYTÜŞŞEBAP",
      "plaka": 73
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 851,
      "il": "ŞIRNAK",
      "ilce": "CİZRE",
      "plaka": 73
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 852,
      "il": "ŞIRNAK",
      "ilce": "GÜÇLÜKONAK",
      "plaka": 73
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 853,
      "il": "ŞIRNAK",
      "ilce": "İDİL",
      "plaka": 73
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 854,
      "il": "ŞIRNAK",
      "ilce": "SİLOPİ",
      "plaka": 73
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 855,
      "il": "ŞIRNAK",
      "ilce": "ULUDERE",
      "plaka": 73
    },
    {
      "bolge": "KARADENİZ",
      "id": 856,
      "il": "BARTIN",
      "ilce": "AMASRA",
      "plaka": 74
    },
    {
      "bolge": "KARADENİZ",
      "id": 857,
      "il": "BARTIN",
      "ilce": "KURUCAŞİLE",
      "plaka": 74
    },
    {
      "bolge": "KARADENİZ",
      "id": 858,
      "il": "BARTIN",
      "ilce": "ULUS",
      "plaka": 74
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 859,
      "il": "ARDAHAN",
      "ilce": "ÇILDIR",
      "plaka": 75
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 860,
      "il": "ARDAHAN",
      "ilce": "DAMAL",
      "plaka": 75
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 861,
      "il": "ARDAHAN",
      "ilce": "GÖLE",
      "plaka": 75
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 862,
      "il": "ARDAHAN",
      "ilce": "HANAK",
      "plaka": 75
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 863,
      "il": "ARDAHAN",
      "ilce": "POSOF",
      "plaka": 75
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 864,
      "il": "IĞDIR",
      "ilce": "ARALIK",
      "plaka": 76
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 865,
      "il": "IĞDIR",
      "ilce": "KARAKOYUNLU",
      "plaka": 76
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 866,
      "il": "IĞDIR",
      "ilce": "TUZLUCA",
      "plaka": 76
    },
    {
      "bolge": "MARMARA",
      "id": 867,
      "il": "YALOVA",
      "ilce": "ALTINOVA",
      "plaka": 77
    },
    {
      "bolge": "MARMARA",
      "id": 868,
      "il": "YALOVA",
      "ilce": "ARMUTLU",
      "plaka": 77
    },
    {
      "bolge": "MARMARA",
      "id": 869,
      "il": "YALOVA",
      "ilce": "ÇINARCIK",
      "plaka": 77
    },
    {
      "bolge": "MARMARA",
      "id": 870,
      "il": "YALOVA",
      "ilce": "ÇİFTLİKKÖY",
      "plaka": 77
    },
    {
      "bolge": "MARMARA",
      "id": 871,
      "il": "YALOVA",
      "ilce": "TERMAL",
      "plaka": 77
    },
    {
      "bolge": "KARADENİZ",
      "id": 872,
      "il": "KARABÜK",
      "ilce": "EFLANİ",
      "plaka": 78
    },
    {
      "bolge": "KARADENİZ",
      "id": 873,
      "il": "KARABÜK",
      "ilce": "ESKİPAZAR",
      "plaka": 78
    },
    {
      "bolge": "KARADENİZ",
      "id": 874,
      "il": "KARABÜK",
      "ilce": "OVACIK",
      "plaka": 78
    },
    {
      "bolge": "KARADENİZ",
      "id": 875,
      "il": "KARABÜK",
      "ilce": "SAFRANBOLU",
      "plaka": 78
    },
    {
      "bolge": "KARADENİZ",
      "id": 876,
      "il": "KARABÜK",
      "ilce": "YENİCE",
      "plaka": 78
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 877,
      "il": "KİLİS",
      "ilce": "ELBEYLİ",
      "plaka": 79
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 878,
      "il": "KİLİS",
      "ilce": "MUSABEYLİ",
      "plaka": 79
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 879,
      "il": "KİLİS",
      "ilce": "POLATELİ",
      "plaka": 79
    },
    {
      "bolge": "AKDENİZ",
      "id": 880,
      "il": "OSMANİYE",
      "ilce": "BAHÇE",
      "plaka": 80
    },
    {
      "bolge": "AKDENİZ",
      "id": 881,
      "il": "OSMANİYE",
      "ilce": "DÜZİÇİ",
      "plaka": 80
    },
    {
      "bolge": "AKDENİZ",
      "id": 882,
      "il": "OSMANİYE",
      "ilce": "HASANBEYLİ",
      "plaka": 80
    },
    {
      "bolge": "AKDENİZ",
      "id": 883,
      "il": "OSMANİYE",
      "ilce": "KADİRLİ",
      "plaka": 80
    },
    {
      "bolge": "AKDENİZ",
      "id": 884,
      "il": "OSMANİYE",
      "ilce": "SUMBAS",
      "plaka": 80
    },
    {
      "bolge": "AKDENİZ",
      "id": 885,
      "il": "OSMANİYE",
      "ilce": "TOPRAKKALE",
      "plaka": 80
    },
    {
      "bolge": "KARADENİZ",
      "id": 886,
      "il": "DÜZCE",
      "ilce": "AKÇAKOCA",
      "plaka": 81
    },
    {
      "bolge": "KARADENİZ",
      "id": 887,
      "il": "DÜZCE",
      "ilce": "CUMAYERİ",
      "plaka": 81
    },
    {
      "bolge": "KARADENİZ",
      "id": 888,
      "il": "DÜZCE",
      "ilce": "ÇİLİMLİ",
      "plaka": 81
    },
    {
      "bolge": "KARADENİZ",
      "id": 889,
      "il": "DÜZCE",
      "ilce": "GÖLYAKA",
      "plaka": 81
    },
    {
      "bolge": "KARADENİZ",
      "id": 890,
      "il": "DÜZCE",
      "ilce": "GÜMÜŞOVA",
      "plaka": 81
    },
    {
      "bolge": "KARADENİZ",
      "id": 891,
      "il": "DÜZCE",
      "ilce": "KAYNAŞLI",
      "plaka": 81
    },
    {
      "bolge": "KARADENİZ",
      "id": 892,
      "il": "DÜZCE",
      "ilce": "YIĞILCA",
      "plaka": 81
    },
    {
      "bolge": "EGE",
      "id": 893,
      "il": "AYDIN",
      "ilce": "EFELER",
      "plaka": 9
    },
    {
      "bolge": "MARMARA",
      "id": 894,
      "il": "BALIKESİR",
      "ilce": "KARESİ",
      "plaka": 10
    },
    {
      "bolge": "MARMARA",
      "id": 895,
      "il": "BALIKESİR",
      "ilce": "ALTIEYLÜL",
      "plaka": 10
    },
    {
      "bolge": "EGE",
      "id": 896,
      "il": "DENİZLİ",
      "ilce": "MERKEZEFENDİ",
      "plaka": 20
    },
    {
      "bolge": "AKDENİZ",
      "id": 897,
      "il": "HATAY",
      "ilce": "ANTAKYA",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 898,
      "il": "HATAY",
      "ilce": "DEFNE",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 899,
      "il": "HATAY",
      "ilce": "ARSUZ",
      "plaka": 31
    },
    {
      "bolge": "AKDENİZ",
      "id": 900,
      "il": "HATAY",
      "ilce": "PAYAS",
      "plaka": 31
    },
    {
      "bolge": "EGE",
      "id": 901,
      "il": "MANİSA",
      "ilce": "ŞEHZADELER",
      "plaka": 45
    },
    {
      "bolge": "EGE",
      "id": 902,
      "il": "MANİSA",
      "ilce": "YUNUSEMRE",
      "plaka": 45
    },
    {
      "bolge": "AKDENİZ",
      "id": 903,
      "il": "KAHRAMANMARAŞ",
      "ilce": "DULKADİROĞLU",
      "plaka": 46
    },
    {
      "bolge": "AKDENİZ",
      "id": 904,
      "il": "KAHRAMANMARAŞ",
      "ilce": "ONİKİŞUBAT",
      "plaka": 46
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 905,
      "il": "MARDİN",
      "ilce": "ARTUKLU",
      "plaka": 47
    },
    {
      "bolge": "EGE",
      "id": 906,
      "il": "MUĞLA",
      "ilce": "MENTEŞE",
      "plaka": 48
    },
    {
      "bolge": "EGE",
      "id": 907,
      "il": "MUĞLA",
      "ilce": "SEYDİKEMER",
      "plaka": 48
    },
    {
      "bolge": "MARMARA",
      "id": 908,
      "il": "TEKİRDAĞ",
      "ilce": "SÜLEYMANPAŞA",
      "plaka": 59
    },
    {
      "bolge": "MARMARA",
      "id": 909,
      "il": "TEKİRDAĞ",
      "ilce": "KAPAKLI",
      "plaka": 59
    },
    {
      "bolge": "MARMARA",
      "id": 910,
      "il": "TEKİRDAĞ",
      "ilce": "ERGENE",
      "plaka": 59
    },
    {
      "bolge": "KARADENİZ",
      "id": 911,
      "il": "TRABZON",
      "ilce": "ORTAHİSAR",
      "plaka": 61
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 912,
      "il": "ŞANLIURFA",
      "ilce": "EYYÜBİYE",
      "plaka": 63
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 913,
      "il": "ŞANLIURFA",
      "ilce": "HALİLİYE",
      "plaka": 63
    },
    {
      "bolge": "GÜNEYDOĞU ANADOLU",
      "id": 914,
      "il": "ŞANLIURFA",
      "ilce": "KARAKÖPRÜ",
      "plaka": 63
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 915,
      "il": "VAN",
      "ilce": "TUŞBA",
      "plaka": 65
    },
    {
      "bolge": "DOĞU ANADOLU",
      "id": 916,
      "il": "VAN",
      "ilce": "İPEKYOLU",
      "plaka": 65
    },
    {
      "bolge": "KARADENİZ",
      "id": 917,
      "il": "ZONGULDAK",
      "ilce": "KİLİMLİ",
      "plaka": 67
    }
  ]