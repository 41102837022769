import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import control from "../../../assets/before.png";
import menu from "../../../assets/menu.png";

const menuItems = [
  {
    tittle: "Ana Sayfa",
    pageId: "",
    id: 0,
  },
  {
    tittle: "Kurslarım",
    pageId: "kurslarim",
    id: 1,
  },
  {
    tittle: "Yeni Kurs Ekle",
    pageId: "kurslarim/yenikurstalebi",
    id: 2,
  },
  {
    tittle: "Kurs Yorumları",
    pageId: "kursyorumlari",
    id: 3,
  },
  {
    tittle: "Mesajlar",
    pageId: "mesajlar",
    id: 4,
    gap: true,
  },
  {
    tittle: "Öğrencilerim",
    pageId: "ogrencilerim",
    id: 5,
  },
  {
    tittle: "Notlar",
    pageId: "notlar",
    id: 6,
  },

  {
    tittle: "Duyurular",
    pageId: "duyurular",
    id: 7,
    gap: true,
  },
  {
    tittle: "Videolar",
    pageId: "videolar",
    id: 8,
  },

  {
    tittle: "Profil",
    pageId: "profil",
    id: 9,
  },
  {
    tittle: "Ayarlar",
    pageId: "ayarlar",
    id: 10,
    gap: true,
  },
];
function EducatorNavbar({ move, setMove }) {
  const [open, setOpen] = useState(false);
  function show() {
    setOpen(!open);
    setMove(!move);
  }
  return (
    <div className="flex sticky top-0 left-0">
      <div
        className={`${
          open ? "w-64" : "w-20"
        } duration-300 h-screen p-5 pt-8 sm:border-r shadow-md relative bg-gray-200`}
      >
        <img
          src={control}
          alt="dasboard"
          className={`absolute cursor-pointer -right-3 top-9 w-7 rounded-full ${
            !open && "rotate-180"
          }`}
          onClick={show}
        />
        <div
          onClick={show}
          className="flex gap-x-4 items-center cursor-pointer"
        >
          <img
            src={menu}
            alt="menu"
            className={`h-7 w-7 cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />
          <h1
            className={`text-blue-900 font-semibold origin-left font-large text-lg duration-300 ${
              !open && "scale-0"
            }`}
          >
            Menü
          </h1>
        </div>
        <ul className="pt-4">
          {menuItems.map((item) => (
            <NavLink key={item.tittle} to={`/egitmenpanel/${item.pageId}`}>
              <li
                className={`text-gray-900 text-md flex items-center gap-x-4 cursor-pointer p-2 ${
                  open ? "hover:bg-blue-500" : ""
                } hover:text-white rounded-md ${item.gap ? "mt-9" : "mt-2"} `}
              >
                <span
                  className={`text-base flex-1 font-semibold ${
                    !open && "hidden"
                  }`}
                >
                  {item.tittle}
                </span>
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default EducatorNavbar;
