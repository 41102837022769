import axios from "axios";
import React, { useState, useEffect } from "react";
import { BiCartAlt } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AdminCoursePage() {
  const { id } = useParams();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [lessons, setLessons] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const [dataCount, setDataCount] = useState();
  const pageCount = Math.ceil(dataCount / usersPerPage);
  const [search, setSearch] = useState("");

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const getCourses = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Lesson/GetByCourseId/${id}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
              accept: "text/plain",
            },
          }
        );
        setLessons(response.data.data);
        setDataCount(response.data.metaData.dataCount);
      } catch (error) {
        console.log(error);
      }
    };
    getCourses();
  }, []);

  // useEffect(() => {   ŞİMDİLİK DURSUN
  //   const getCourses = async () => {
  //     try {
  //       const response = await axios(
  //         `${process.env.REACT_APP_BASE_URL}/Lesson?pageNumber=${
  //           pageNumber + 1
  //         }`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
  //             accept: "text/plain",
  //           },
  //         }
  //       );
  //       setLessons(response.data.data);
  //       setDataCount(response.data.metaData.dataCount);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getCourses();
  // }, [pageNumber]);

  return (
    <>
       <div className="flex mb-5 mt-5">
        <NavLink
          to={`/adminpanel/kurslar/duzenle/${id}`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-2xl px-5 py-4 text-[#2196f3] font-semibold">
          MEVCUT KURSA AİT DERSLER
        </h1>
      </div>
      <div className=" sm:justify-between grid grid-cols-12 px-10 mb-5">
        <div className="sm:items-center  mb-5 col-span-12 md:col-span-6 mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto ">
            <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
              <input
                className="border-[0.5px] border-gray-300 shadow-md text-black p-3 rounded-md w-64"
                type="text"
                value={search}
                placeholder="Ders Adıyla Aratın"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none ">
            <NavLink to={`/adminpanel/kurslar/dersler/${id}`}>
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border border-gray-200 bg-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Yeni Ekle
              </button>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="pl-4 mt-10 mb-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Ders Adı
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Döküman Linki
              </th>
              {/* <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                İndex
              </th> */}
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Select</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {!!lessons &&
              lessons
                ?.filter((plan) =>
                  plan.name
                    .toLocaleLowerCase("tr-TR")
                    .includes(search.toLocaleLowerCase("tr-TR"))
                )
                .map((plan, planIdx) => (
                  <tr key={plan.id}>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-4 pl-4 sm:pl-6 pr-3 text-sm"
                      )}
                    >
                      <div className="font-medium text-gray-900">
                        {plan.name}
                        {plan.isCurrent ? (
                          <span className="ml-1 text-indigo-600">
                            (Current Plan)
                          </span>
                        ) : null}
                      </div>
                      <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                        <span>
                          <a className="hover:text-blue-500" href={plan.link}>
                            Ders Linki
                          </a>{" "}
                          / {plan.index} Saat
                        </span>
                        <span className="hidden sm:inline">·</span>
                        <span>{plan.storage}</span>
                      </div>
                      {planIdx !== 0 ? (
                        <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" />
                      ) : null}
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                      )}
                    >
                      <a className="hover:text-blue-500" href={plan.link}>
                        Ders Linki
                      </a>
                    </td>
                    {/* <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                      )}
                    >
                      {plan.index}
                    </td> */}
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-gray-200",
                        "px-3 py-3.5 text-sm text-gray-500 hidden lg:table-cell"
                      )}
                    >
                      <div className="sm:hidden">{plan.code}/mo</div>
                      <div className="hidden sm:block">{plan.code}</div>
                    </td>
                    <td
                      className={classNames(
                        planIdx === 0 ? "" : "border-t border-transparent",
                        "relative py-3.5 pl-3 pr-4 sm:pr-6 sm:mt-2 text-right text-sm font-medium"
                      )}
                    >
                      <NavLink
                        to={`/adminpanel/kurslar/dersler/düzenle/${plan.id}`}
                      >
                        <button
                          type="button"
                          className="inline-flex ml-2 w-34 my-1 items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-green-400 hover:text-white  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30 gap-1"
                          disabled={plan.isCurrent}
                        >
                          <BiCartAlt className="hidden xs:block" size={18} />{" "}
                          {""} Düzenle
                          <span className="sr-only">, {plan.name}</span>
                        </button>
                      </NavLink>

                      {planIdx !== 0 ? (
                        <div className="absolute right-6 left-0 -top-px h-px bg-gray-200" />
                      ) : null}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      {dataCount > 10 ? (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      ) : null}
    </>
  );
}

export default AdminCoursePage;
