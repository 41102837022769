import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import {
  deleteLesson,
  getCourses,
  lessonGetById,
  patchLesson,
} from "../../../store/features/AdminSlice";
import { errors } from "../../../data/errors";

function EditLessons() {
  const id = useParams();
  const lessonId = id.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [index, setIndex] = useState("");
  const [courseId, setCourseId] = useState("");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  let bodyFormData = new FormData();
  bodyFormData.append("Name", name);
  bodyFormData.append("Link", link);
  bodyFormData.append("Index", index);
  bodyFormData.append("CourseId", courseId);

  useEffect(() => {
    dispatch(getCourses({ data: JSON.parse(jwtTokenRedux), pageNumber }));
  }, []);
  const courses = useSelector((state) => state.AdminSlice.adminCourses);
  useEffect(() => {
    dispatch(lessonGetById({ jwt: JSON.parse(jwtTokenRedux), id: lessonId }));
  }, []);
  const lesson = useSelector((state) => state.AdminSlice.lesson);

  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Ders güncellensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Güncelle",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
          try {
            await dispatch(
              patchLesson({
                jwt: JSON.parse(jwtTokenRedux),
                bodyFormData,
                id: lessonId,
              })
            );
            Swal.fire("Güncellendi!");
            navigate("/adminpanel/dersler");
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes == errors[index].id) {
                let isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes == 999) {
                let isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                let isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        response();
      }
    });
  };

  const deleteTheLesson = (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = async () => {
          try {
            await dispatch(
              deleteLesson({ jwt: JSON.parse(jwtTokenRedux), id: id })
            );
            Swal.fire("Silindi!");
            navigate("/adminpanel/dersler")
            
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes == errors[index].id) {
                let isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes == 999) {
                let isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                let isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        response();
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="text-center mb-10 ">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-[#2196f3] font-bold">
          Ders Bilgilerini Düzenle
        </h1>
      </div>
      <div className="user-info ml-5 md:ml-0">
        <form>
          {lesson &&
            lesson?.map((item) => (
              <div className="mt-5 border-y border-gray-200">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 py-1 after:content-['*'] after:ml-0.5 after:text-red-500">
                      Ders İsmi :
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <input
                        onChange={(e) => setName(e.target.value)}
                        placeholder={lesson[0]?.name}
                        className="flex-grow shadow-sm border-[0.5px] border-gray-300 py-1 px-3"
                      />
                    </dd>
                  </div>
                  {/* <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 py-1">
                      Index :
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <input
                        onChange={(e) => setIndex(e.target.value)}
                        placeholder={lesson[0]?.index}
                        className="flex-grow border-[0.5px] border-gray-300 py-1 px-3"
                      />
                    </dd>
                  </div> */}

                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 py-1 after:content-['*'] after:ml-0.5 after:text-red-500" >
                      Yeni Link :
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <input
                        onChange={(e) => setLink(e.target.value)}
                        className="flex-grow border-[0.5px] border-gray-300 py-1 px-3"
                      />
                    </dd>
                    <dt className="text-sm font-medium text-gray-500 py-1">
                      Mevcut Link :
                    </dt>
                    <dd className="mt-1 flex-wrap text-sm text-blue-700 underline sm:col-span-2 sm:mt-0">
                      <a href={lesson[0]?.link} target="_blank">
                        {lesson[0]?.link}
                      </a>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 py-1 after:content-['*'] after:ml-0.5 after:text-red-500">
                      Kurs :
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <select
                        onChange={(e) => setCourseId(e.target.value)}
                        value={courseId}
                        className="w-full border border-gray-300"
                      >
                        <option className="bg-blue-200">
                          {lesson[0]?.course.name}
                        </option>
                        {courses &&
                          courses?.map((course) => (
                            <option value={course.id} key={course.id}>
                              {course.name}
                            </option>
                          ))}
                      </select>
                    </dd>
                  </div>
                </dl>

                <div className="mt-20">
                  <div className="grid grid-cols-12 justify-end pb-5 gap-3 ">
                    <Link
                      to={`/adminpanel/dersler`}
                      className="col-span-12 mx-auto sm:col-span-4 rounded-md border w-56 text-center border-gray-300 bg-white py-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Geri Dön
                    </Link>
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="col-span-12 mx-auto sm:col-span-4 inline-flex justify-center w-56 text-center rounded-md border border-transparent bg-green-600 py-3 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Güncelle
                    </button>
                    <button
                      type="submit"
                       onClick={(e) => deleteTheLesson(e, lesson[0].id)}
                      className="col-span-12 mx-auto sm:col-span-4 inline-flex justify-center w-56 text-center rounded-md border border-transparent bg-red-600 hover:bg-red-800 py-3 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Sil
                    </button>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            ))}
        </form>
      </div>
    </>
  );
}

export default EditLessons;
