import React, { useEffect, useReducer, useState } from "react";
import { Link, NavLink ,useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFAQ } from "../../../store/features/AdminSlice";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2/dist/sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errors } from "../../../data/errors";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AdminSSS() {
  const [pageNumber, setpageNumber] = useState(0);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [contentTypes, setContentTypes] = useState("");
  const [editable, setEditable] = useState(false);
  const [activeId, setActiveId] = useState("");
  const faqPerPage = 10;
  const dataCount = useSelector((state) => state.AdminSlice.pageCount);
  const pageCount = Math.ceil(dataCount.dataCount / faqPerPage);
  const jwtToken = useSelector((state) => state.login.accessToken);
  const dispatch = useDispatch();
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  let bodyFormData = new FormData();
  bodyFormData.append("Question", question);
  bodyFormData.append("Answer", answer);
  bodyFormData.append("ContentTypes", contentTypes);

  useEffect(() => {
    dispatch(getFAQ({ jwt: JSON.parse(jwtToken), pageNumber: pageNumber }));
  }, [pageNumber, reducerValue]);
  const faqs = useSelector((state) => state.AdminSlice.fAQ);
  const changePage = ({ selected }) => {
    setpageNumber(selected);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            await axios.delete(
              `${process.env.REACT_APP_BASE_URL}/FrequentlyAskedQuestions?id=${id}`,
              {
                headers: {
                  Authorization: `Bearer ${JSON.parse(jwtToken)}`,
                  accept: "text/plain",
                },
              }
            );
            forceUpdate();
            Swal.fire({
              icon: "success",
              title: "Başarılı",
              text : "Başarı ile silindi"
            });
            //toast.success("Silindi!");
       
          } catch (error) {
            console.log(error);
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes == "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast("Bilinmeyen bir hata oluştu!");
            }
          }
        };
        submit();
      }
    });
  };
  const handleClick = (e, id) => {
    e.preventDefault();
    setActiveId(id);
    setEditable(true);
  };
  const handleEdit = (id) => {
    Swal.fire({
      title: "Değiştirilsin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            await axios(
              `${process.env.REACT_APP_BASE_URL}/FrequentlyAskedQuestions?id=${id}`,
              {
                method: "patch",
                headers: {
                  ContentType: "multipart/form-data",
                  Authorization: `Bearer ${JSON.parse(jwtToken)}`,
                  accept: "text/plain",
                },
                data: bodyFormData,
              }
            );
            forceUpdate();
            Swal.fire({
              icon: "success",
              title: "Başarılı",
              text : "Başarı ile güncellendi"
            });
            //toast.success('Güncellendi!');
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === 999) {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        submit();
        setEditable(false)
      }
    });
  };
  const handleCancel = () => {
    setEditable(false);
  };
  return (
    <>
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-10 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Sıkça Sorulan Sorular
        </h1>
      </div>
      <div className="flex justify-end mt-5">
        <Link to={"/adminpanel/sss/ekle"}>
          <button
            type="button"
            className="items-center rounded-lg shadow-xl border border-gray-200 bg-[#142850] px-4 py-2 text-md font-medium hover:bg-blue-700 text-white focus:z-10 focus:outline-none"
          >
            Yeni Ekle +
          </button>
        </Link>
      </div>

      <div className="px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-my-2 -mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full border-separate border-spacing-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      İçerik
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:pl-8"
                    >
                      Soru
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Cevap
                    </th>

                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pr-6 pl-3 backdrop-blur backdrop-filter lg:pr-8"
                    >
                      <span className="sr-only">Düzenle</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {faqs &&
                    faqs?.map((item, personIdx) => (
                      <tr key={item.id}>
                        <td
                          className={classNames(
                            personIdx !== item.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 lg:pl-8"
                          )}
                        >
                          {editable && activeId === item.id && (
                            <>
                              <select
                                onChange={(e) =>
                                  setContentTypes(e.target.value)
                                }
                                value={contentTypes}
                                id="content"
                                name="content"
                                className="block w-full max-w-xl p-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-lg sm:text-sm"
                              >
                                <option className="bg-blue-200">
                                  {item?.contentTypeName}
                                </option>
                                <option>Sinavlar</option>
                                <option>Sertifikalar</option>
                                <option>OdemelerveIadeler</option>
                                <option>Sikayetler</option>
                              </select>
                            </>
                          )}
                          {editable ||
                            (activeId.id !== item.id && (
                              <>{item?.contentTypeName}</>
                            ))}
                        </td>
                        <td
                          className={classNames(
                            personIdx !== item.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-500 lg:pl-8"
                          )}
                        >
                          {editable && activeId === item.id && (
                            <>
                              <textarea
                                onChange={(e) => setQuestion(e.target.value)}
                                id="question"
                                name="question"
                                rows={5}
                                cols={100}
                                className="block w-full p-2 rounded-md border border-gray-300 shadow-sm sm:text-sm"
                                defaultValue={item?.question}
                              />
                            </>
                          )}
                          {editable ||
                            (activeId.id !== item.id && <>{item?.question}</>)}
                        </td>
                        <td
                          className={classNames(
                            personIdx !== item.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {editable && activeId === item.id && (
                            <>
                              <textarea
                                onChange={(e) => setAnswer(e.target.value)}
                                id="answer"
                                name="answer"
                                rows={5}
                                cols={100}
                                className="block w-full p-2 rounded-md border border-gray-300 shadow-sm sm:text-sm"
                                defaultValue={item?.answer}
                              />
                            </>
                          )}
                          {!editable && activeId.id !== item.id && (
                            <> {item?.answer}</>
                          )}
                        </td>
                        <td
                          className={classNames(
                            personIdx !== item.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "relative whitespace-nowrap py-4 pr-6 pl-3 text-center text-sm tracking-wide lg:pr-8"
                          )}
                        >
                          {editable && activeId === item.id && (
                            <>
                              <button
                                onClick={() => handleEdit(item.id)}
                                className="px-8 py-2 border bg-[#142850] text-white rounded-lg hover:bg-blue-700"
                              >
                                Güncelle
                              </button>
                              <button
                                onClick={() => handleCancel()}
                                className="px-8 py-2 border bg-red-600 text-white rounded-lg hover:bg-red-700"
                              >
                                İptal
                              </button>
                            </>
                          )}
                          {editable ? (
                            <></>
                          ) : (
                            <>
                              <button
                                onClick={(e) => handleClick(e, item.id)}
                                className="px-5 py-2 border bg-[#142850] text-white rounded-lg hover:bg-blue-700"
                              >
                                Düzenle
                              </button>
                              <button
                                onClick={() => handleDelete(item.id)}
                                className="px-5 py-2 ml-3 border bg-[#142850] text-white rounded-lg hover:bg-blue-700"
                              >
                                Sil
                              </button>
                            </>
                          )}

                          <ToastContainer />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
          />
        </div>
      </div>
    </>
  );
}

export default AdminSSS;
