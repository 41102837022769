import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { errors } from "../../../data/errors";

function AddRole() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const userData = {
    name: name,
  };
  const postUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/Admin/Role/Add`,
        data: userData,
        headers: {
          ContentType: "application/json-patch+json",
          accept: "text/plain",
          Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
        },
      });
      setName(response.data.data);
      navigate("/adminpanel/roller");
    } catch (error) {
      let isEqual = false;
      for (let index = 0; index <= errors.length; index++) {
        if (error.response.data.details.errorCodes === errors[index].id) {
          isEqual = true;
          return toast.error(errors[index].content);
        } else if (error.response.data.details.errorCodes == 999) {
          isEqual = true;
          return toast.error(error.response.data.details.message);
        } else {
          isEqual = false;
        }
      }
      if (!isEqual) {
        toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
      }
    }
  };
  return (
    <div className="mt-20">
      <h2 className="text-blue-900 text-left font-semibold mb-5 text-2xl">
        Yeni Rol
      </h2>
      <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
        <label
          htmlFor="name"
          className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900  after:content-['*'] after:ml-0.5 after:text-red-500"
        >
         Rol
        </label>
        <input
          onChange={(e) => setName(e.target.value)}
          value={name}
          type="text"
          name="Tittle"
          id="1"
          className="block w-full p-1 text-gray-900 sm:text-sm"
        />
      </div>
      <button
        onClick={postUser}
        type="submit"
        className="inline-flex items-center mx-auto rounded-md border border-transparent bg-blue-100 
      px-4 py-2 m-5 text-sm font-medium text-blue-900 hover:bg-indigo-200 focus:outline-none 
      focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
      >
        Ekle
      </button>
      <ToastContainer />
    </div>
  );
}

export default AddRole;
