import axios from "axios";
import React, { useState , useEffect} from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const AdminUserLogs = () => {
  const [courses, setCourses] = useState("");
  const { id } = useParams();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const getUserCourses = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/UserActions?userId=${id}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          },
        }
      );
      setCourses(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserCourses();
  }, []);
  console.log(courses);
  return (
    <>
      <div className=" sm:justify-between mt-5">
        <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none ">
            <Link to={`/adminpanel/kullanicilar/duzenle/${id}`}>
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border border-gray-200 bg-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Geri
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2459/2459427.png"
                  alt="plus "
                  className="h-5 w-5 inline-flex ml-2"
                />
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="-mx-4 mt-5 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300 ">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 border-r-[1px] bg-blue-500 rounded-tl-md"
              >
                İşlemler
              </th>
              <th
                scope="col"
                className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:table-cell border-r-[1px] bg-blue-500"
              >
                Detaylar
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell border-r-[1px] bg-blue-500"
              >
                Durum
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell bg-blue-500 rounded-tr-md"
              >
                Tarih
              </th>
            </tr>
          </thead>
          <tbody>
            {!!courses &&
              courses?.map((course) => (
                <tr
                  className="border-[1px]  rounded-b-md bg-blue-100"
                  key={course.id}
                >
                  {course?.lenght === 0 ? (
                    <>
                    <td className="border-[1px] border-r-white border-b-white py-2 pl-2">
                      asd
                    </td>
                    <td className="border-[1px] border-r-white border-b-white pl-2">
                      asd
                    </td>
                    <td className="border-[1px] border-r-white border-b-white pl-2">
                      asd
                    </td>
                    <td className="border-[1px] border-r-white border-b-white pl-2">
                      asd
                    </td>
                  </>
                  ) : (
                    <>
                      <td className="border-[1px] border-r-white border-b-white py-2 pl-2">
                        {course.transaction}
                      </td>
                      <td className="border-[1px] border-r-white border-b-white pl-2">
                        {course.details}
                      </td>
                      <td className="border-[1px] border-r-white border-b-white pl-2">
                        {course.status}
                      </td>
                      <td className="border-[1px] border-r-white border-b-white pl-2">
                        {course.created.slice(0, 10)}
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminUserLogs;
