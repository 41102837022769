import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Worker, Viewer  } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

function AddDocumentToCourseModal({ courseDoc, setIsOpen, isOpen }) {
  const handleOKClick = () => {
    setIsOpen(false);
  };
  const pageNavigationPluginInstance = pageNavigationPlugin();
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleOKClick}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center font-medium leading-6 text-gray-900"
                  >
                    Döküman İncele
                  </Dialog.Title>
                  <div className="flex-col justify-center mt-10 p-1">
                    <p className="text-center px-1 py-2 text-md text-gray-600">
                      {courseDoc.documentName}
                    </p>
                    <img
                      src={courseDoc.documentPath}
                      alt="dokuman-gorseli-yok"
                      className="object-cover mx-auto"
                    />
                    <div
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        height: "750px",
                        margin: "10px",
                      }}
                    >
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer fileUrl={courseDoc.documentPath} plugins={[pageNavigationPluginInstance]} />
                      </Worker>
                    </div>
                  </div>

                  <div className="mt-10 flex justify-end space-x-5">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={handleOKClick}
                    >
                      Kapat
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default AddDocumentToCourseModal;
