import axios from "axios";
import { useState, useEffect } from "react";
import { ColorRing } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

function UpdateExam() {
  const id = useParams();
  const jwtToken = useSelector((state) => state.login.accessToken);
  const [examInfo, setExamInfo] = useState("");
  const [examName, setExamName] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [passNote, setPassNote] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExamInfo = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Exam/${id.examId}`,
          {
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${JSON.parse(jwtToken)}`,
            },
          }
        );
        console.log(response.data.data[0]);
        setExamName(response.data.data[0].name);
        setDescription(response.data.data[0].description);
        setDuration(response.data.data[0].duration);
        setPassNote(response.data.data[0].passingScore);
        setExamInfo(response.data.data);
      } catch (error) {}
    };
    fetchExamInfo();
  }, []);

  const updateExam = () => {
    Swal.fire({
      title: "Sınav güncellensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const response = async () => {
          try {
            await axios(
              `${process.env.REACT_APP_BASE_URL}/Exam?id=${id.examId}`,
              {
                method: "patch",
                headers: {
                  "Content-Type": "application/json-patch+json",
                  accept: "*/*",
                  Authorization: `Bearer ${JSON.parse(jwtToken)}`,
                },
                data: {
                  name: examName,
                  description: description,
                  duration: duration,
                  passingScore: passNote,
                  courseId: id.id,
                },
              }
            );
            Swal.fire("Güncellendi!");
            setLoading(false);
            navigate(`/adminpanel/kurslar/sinavlar/${id.id}`);
          } catch (error) {
            console.log(error);
            Swal.fire("Hata!");
            setLoading(false);
          }
        };
        response();
      }
    });
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Sınav silinsin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const response = async () => {
          try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/Exam?id=${id.examId}`, {
              headers: {
                accept: "*/*",
                Authorization: `Bearer ${JSON.parse(jwtToken)}`,
              },
            });
            Swal.fire("Silindi!");
            navigate(`/adminpanel/kurslar/sinavlar/${id.id}`);
          } catch (error) {
            console.log(error);
            Swal.fire("Hata!");
            setLoading(false);
          }
        };
        response();
      }
    });
  };
  return (
    <>
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel/kurslar/sinavlar/${id.id}`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Sınav Güncelle
        </h1>
        <p className="font-semibold text-lg text-gray-500">{examName}</p>
      </div>

      <div className="mt-5 mb-10 bg-blue-100 py-4 border border-gray-200 rounded-lg">
        {examInfo &&
          examInfo?.map((exam) => (
            <div
              key={exam.id}
              className="px-4 sm:px-6 grid grid-cols-1 md:grid-cols-2 gap-x-5 bg-blue-100"
            >
              <div className="relative rounded-md border mb-10 bg-white border-gray-300 px-3 py-2">
                <label
                  htmlFor="name"
                  className="absolute -top-2 left-2 -mt-px inline-block bg-blue-100 rounded-md px-2 text-xs font-medium text-gray-900 after:ml-0.5 after:text-red-500"
                >
                  Sınav Adı
                </label>
                <input
                  value={examName}
                  onChange={(e) => setExamName(e.target.value)}
                  type="text"
                  name=""
                  id=""
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                  placeholder={exam.name}
                />
                <span className="text-gray-500 text-xs">
                  *En az 10 karakter girilmelidir.
                </span>
              </div>

              <div className="relative rounded-md border mb-10 bg-white border-gray-300 px-3 py-2">
                <label
                  htmlFor="description"
                  className="absolute -top-2 left-2 -mt-px inline-block bg-blue-100 rounded-md px-2 text-xs font-medium text-gray-900 after:ml-0.5 after:text-red-500"
                >
                  Açıklama
                </label>
                <input
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  name=""
                  id=""
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                  placeholder={exam.description}
                />
              </div>
              <div className="relative rounded-md border mb-10 bg-white border-gray-300 px-3 py-2">
                <label
                  htmlFor="name"
                  className="absolute -top-2 left-2 -mt-px inline-block bg-blue-100 rounded-md px-2 text-xs font-medium text-gray-900 after:ml-0.5 after:text-red-500"
                >
                  Zaman
                </label>
                <input
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  type="text"
                  name=""
                  id=""
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                  placeholder={exam.duration}
                />
              </div>
              <div className="relative rounded-md border mb-10 bg-white border-gray-300 px-3 py-2">
                <label
                  htmlFor="name"
                  className="absolute -top-2 left-2 -mt-px inline-block bg-blue-100 rounded-md px-2 text-xs font-medium text-gray-900 after:ml-0.5 after:text-red-500"
                >
                  Geçme notu (0-100)
                </label>
                <input
                  value={passNote}
                  onChange={(e) => setPassNote(e.target.value)}
                  type="text"
                  name=""
                  id=""
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                  placeholder={exam.passingScore}
                />
              </div>
            </div>
          ))}

        {loading ? (
          <div className="flex justify-center px-6">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{ margin: "0 auto" }}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        ) : (
          <div className="flex md:justify-end justify-center px-4 sm:px-6 gap-x-5">
            <button
              onClick={handleDelete}
              className="inline-flex items-center rounded-md border shadow-lg bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
            >
              Sil
            </button>
            <button
              onClick={updateExam}
              className="inline-flex items-center rounded-md border shadow-lg bg-blue-900 px-3 py-2 text-sm font-medium leading-4 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
            >
              Güncelle
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default UpdateExam;
