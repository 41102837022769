import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { TiDelete } from "react-icons/ti";
import { Link, NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AdminUserOrderHistory = () => {
  const { id } = useParams();
  const jwtToken = useSelector((state) => state.login.accessToken);
  const [usersTransaction, setUsersTransaction] = useState();
  const getDocuments = async () => {
    try {
      const response = await axios(
        `https://services.rysesoft.com/api/User/ShoppingTransaction?userId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(jwtToken)}`,
          },
        }
      );
      setUsersTransaction(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDocuments();
  }, []);
  return (
    <>
      <div>
        {usersTransaction?.length === 0 ? (
          <>
            <div className="w-[calc(100%-3.73rem)] px-3">
              <div className="2xl:container mx-auto space-y-3 py-3">
                <h1 className="bg-green-200 py-2 px-4 rounded-md text-green-800 text-3xl font-semibold tracking-tight  sm:text-3xl my-5">
                  Bu kullanıcı henüz bir kurs satın almadı!
                </h1>
                <h3>
                  Geri dönmek için{" "}
                  <NavLink
                    className=" bg-yellow-200 px-2 py-0.5 rounded-md text-yellow-700"
                    to={`/adminpanel/kullanicilar/duzenle/${id}`}
                  >
                    tıklayınız.
                  </NavLink>
                </h3>
              </div>
            </div>
          </>
        ) : (
          <div className="w-[calc(100%-3.73rem)]  px-3">
            <div className=" sm:justify-between mt-5"></div>
            <div className="2xl:container  mx-auto space-y-3 py-3">
              <div className="bg-white ">
                <div className="text-center mb-5">
                  <h1 className="text-3xl xl:text-4xl px-5 py-4 text-[#2196f3] font-bold">
                    Kullanıcı Ödeme Geçmişi
                  </h1>
                </div>
                <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
                  <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none ">
                    <NavLink to={`/adminpanel/kullanicilar/duzenle/${id}`}>
                      <button
                        type="button"
                        className="items-center rounded-lg shadow-xl border border-gray-200 bg-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                      >
                        Geri
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/2459/2459427.png"
                          alt="plus "
                          className="h-5 w-5 inline-flex ml-2"
                        />
                      </button>
                    </NavLink>
                  </div>
                </div>
                <div className="py-0">
                  <div className="mx-auto sm:px-0 lg:px-0">
                    <div className="mx-auto px-0 lg:px-0">
                      <p className="mt-2 text-sm text-gray-500">
                        Son siparişlerin durumunu kontrol edin.
                      </p>
                    </div>
                  </div>

                  <div className="mt-6 ">
                    <div className="mx-auto ">
                      <div className="mx-auto space-y-8  lg:px-0">
                        {!!usersTransaction &&
                          usersTransaction?.map((transaction) => (
                            <div
                              key={transaction?.shoppingId}
                              className="border-t border-b border-gray-200 bg-gray-100 shadow-sm sm:rounded-lg sm:border"
                            >
                              <div className="flex items-center border-b border-gray-600 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-6">
                                <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                                  <div>
                                    <dt className="font-medium text-gray-900">
                                      Sipariş Numarası
                                    </dt>
                                    <dd className="mt-1 text-gray-500">
                                      {transaction?.details?.orderId}
                                    </dd>
                                  </div>
                                  <div className="hidden sm:block">
                                    <dt className="font-medium text-gray-900">
                                      Sipariş Tarihi
                                    </dt>
                                    <dd className="mt-1 text-gray-500">
                                      <time dateTime={transaction?.created}>
                                        {transaction?.created?.slice(0, 10)}
                                      </time>
                                    </dd>
                                  </div>
                                  <div>
                                    <dt className="font-medium text-gray-900">
                                      Toplam Tutar
                                    </dt>
                                    <dd className="mt-1 font-medium text-gray-900">
                                      APIDEN TOPLAM TUTAR GELCEK
                                    </dd>
                                  </div>
                                </dl>

                                <Menu
                                  as="div"
                                  className="relative flex justify-end lg:hidden"
                                >
                                  <div className="flex items-center">
                                    <Menu.Button className="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
                                      <span className="sr-only">
                                        Options for order{" "}
                                        {transaction?.shoppingId}
                                      </span>
                                      <EllipsisVerticalIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a
                                              href="#"
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900"
                                                  : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              View
                                            </a>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a
                                              href="#"
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900"
                                                  : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              Invoice
                                            </a>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>

                                <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                                  <a
                                    href="#"
                                    className="flex items-center justify-center rounded-md border border-gray-300 bg-white py-2 px-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    <span>Faturayı Görüntüle</span>
                                    <span className="sr-only">
                                      sipariş {transaction?.shoppingId}
                                    </span>
                                  </a>
                                </div>
                              </div>

                              {/* Products */}
                              <h4 className="sr-only">Ürünler</h4>
                              <ul
                                role="list"
                                className="divide-y divide-gray-200"
                              >
                                {transaction?.courses?.map((course) => (
                                  <li
                                    key={
                                      <li
                                        key={course?.courseId}
                                        className="p-4 sm:p-6"
                                      ></li>
                                    }
                                    className="p-4 sm:p-6"
                                  >
                                    <div className="flex items-center sm:items-start">
                                      <div className="ml-6 flex-1 text-sm">
                                        <p className="hidden text-black font-semibold text-lg sm:mt-2 sm:block">
                                          {course?.name}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="mt-6 sm:flex sm:justify-between">
                                      {transaction?.details?.status === true ? (
                                        <div className="flex items-center">
                                          <CheckCircleIcon
                                            className="h-5 w-5 text-green-500"
                                            aria-hidden="true"
                                          />
                                          <p className="ml-2 text-sm font-medium text-gray-500">
                                            Ödeme Başarılı{" "}
                                            {transaction?.details?.status}
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="mt-6 sm:flex sm:justify-between">
                                          <TiDelete
                                            size={23}
                                            className=" text-red-500 mb-2"
                                            aria-hidden="true"
                                          />
                                          <p className="ml-2 text-sm font-medium text-gray-500 mt-0.5">
                                            Ödeme Başarısız{" "}
                                            {transaction?.details?.status}
                                          </p>
                                        </div>
                                      )}
                                      <div className="mt-6 flex items-center space-x-4 divide-x divide-gray-200 border-t border-gray-200 pt-4 text-sm font-medium sm:mt-0 sm:ml-4 sm:border-none sm:pt-0">
                                        <div className="flex flex-1 justify-center">
                                          <Link
                                            to={`/${course?.mainCategoryName
                                              .split(" ")
                                              .join(
                                                "-"
                                              )}/${course?.subCategoryName
                                              .split(" ")
                                              .join("-")}/${course?.name
                                              .split(" ")
                                              .join("-")}/${course?.id}`}
                                            className="whitespace-nowrap text-[#142850] hover:text-blue-500"
                                          >
                                            Kursu Görüntüle
                                          </Link>
                                        </div>
                                        <div className="flex flex-1 justify-center pl-4">
                                          <button className="whitespace-nowrap text-[#142850] hover:text-blue-500">
                                            İptal Et
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminUserOrderHistory;
