import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2";
import { getAllGroup } from "../../../store/features/AdminSlice";
import AddUserToGroup from "./AddUserToGroup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errors } from "../../../data/errors";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AdminGroupsCoursePage() {
  const [isEditActive, setIsEditActive] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [downloadURL, setDownloadURL] = useState("");
  const [linkButton, setLinkButton] = useState(false);
  const dispatch = useDispatch();
  const courseId = useParams();
  const name = useParams();
  const jwt = useSelector((state) => state.login.accessToken);
  //const [groups, setGroups] = useState([]);

  const groups = useSelector((state) => state.AdminSlice.getAllGroup);

  useEffect(() => {
    dispatch(getAllGroup({ jwt: JSON.parse(jwt), courseId: courseId.id }));

    //let group = dispatch(getAllGroup({ jwt: JSON.parse(jwt), courseId: courseId.id }));
    //console.log(group.data);
    //setGroups(group.data)
  }, []);

  const handleClick = (groupId) => {
    setActiveId(groupId);
    setIsEditActive(!isEditActive);
  };

  const handleDelete = (id, groupId) => {
    Swal.fire({
      title: "Kullanıcıyı silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      console.log(id);
      console.log(groupId);
      let group = groupId;
      let user = id;
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            await axios.post(
              `${process.env.REACT_APP_BASE_URL}/Group/DeleteUserFromGroup`,
              {
                userId: user,
                groupId: group,
              },
              {
                headers: {
                  accept: "text/plain",
                  ContentType: "application/json-patch+json",
                  Authorization: `Bearer ${jwt}`,
                },
              }
            );
            Swal.fire("Silindi!");
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              console.log(error);
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen hata! Bizimle iletişime geçiniz.");
            }
          }
        };
        submit();
      }
    });
  };

  const handleClose = (groupId) => {
    Swal.fire({
      title:
        "Grubu kapatmak istiyor musunuz ?(Kapatıldıktan sonra tekrar açılamaz.)",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            await axios.post(
              `${process.env.REACT_APP_BASE_URL}/Group/CloseTheGroup?groupId=${groupId}`,
              {
                headers: {
                  accept: "text/plain",
                  ContentType: "application/json-patch+json",
                  Authorization: `Bearer ${jwt}`,
                },
              }
            );
            dispatch(
              getAllGroup({ jwt: JSON.parse(jwt), courseId: courseId.id })
            );
            Swal.fire("Grup başarı ile kapatıldı!");
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              console.log(error);
              if (error.response.data.details.errorCodes === errors[index].id) {
                isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error("Bilinmeyen hata! Bizimle iletişime geçiniz.");
            }
          }
        };
        submit();
      }
    });
  };

  const downloadGroupList = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/Group/ExportCurrentGroup?courseId=${courseId.id}`,
        {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${jwt}`,
          },
          data: {
            courseId: courseId.id,
          },
        }
      );
      setDownloadURL(response.config.url);
      setLinkButton(true);
      toast.success("Grup listesi oluşturuldu!");
    } catch (error) {
      console.log(error);
      toast.error("Hata!");
    }
  };

  return (
    <div>
      <div className="flex mb-5 mt-5">
        <NavLink
          to={`/adminpanel/gruplar`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold tracking-wide">
          {name.name} Gruplar
        </h1>
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center align-center md:justify-end gap-x-5">
          <div className="mt-4 sm:mt-0">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/Group/ExportAllGroups?courseId=${courseId.id}`}
              target="_blank"
              className="flex items-center rounded-lg shadow-xl border-2 border-gray-200 bg-white px-4 py-2 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-900"
            >
              Tüm Listeleri İndir
            </a>
          </div>
          <div className="mt-4 sm:mt-0">
            <Link
              to={`/adminpanel/gruplar/${name.name}/${courseId.id}/yeni-ekle`}
              className="flex items-center rounded-lg shadow-xl border-2 border-gray-200 bg-white px-4 py-2 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-900"
            >
              Grup Ekle
            </Link>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Kurs
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        İsim-Soyisim
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Telefon
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Kimlik Numarası
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Şehir
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 sr-only"
                      >
                        <span className="sr-only">Seçenekler</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {groups &&
                      groups?.map((item) => (
                        <Fragment key={item.groupId}>
                          <tr className="border-t border-gray-200">
                            <th
                              colSpan={5}
                              scope="colgroup"
                              className={`${
                                item.isOpen ? "bg-green-200" : "bg-red-200"
                              } px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6`}
                            >
                              {item.name}
                              {item.isOpen ? (
                                <button
                                  onClick={() => handleClick(item.groupId)}
                                  disabled={!item.isOpen}
                                  className="ml-5 border border-gray-200 font-normal shadow-sm rounded-lg px-3 bg-white text-green-500 hover:bg-gray-100 hover:scale-110"
                                >
                                  Ekle +
                                </button>
                              
                              ) : (
                                <></>
                              )}

                              {linkButton ? (
                                <a
                                  href={downloadURL}
                                  target="_blank"
                                  className="ml-5 border border-gray-200 font-semibold shadow-sm rounded-lg px-3 bg-white text-green-500 hover:bg-gray-100"
                                >
                                  İndir
                                </a>
                              ) : (
                                <button
                                  onClick={() => downloadGroupList()}
                                  className="ml-5 border border-gray-200 font-normal shadow-sm rounded-lg px-3 bg-white text-green-500 hover:bg-gray-100 hover:scale-110"
                                >
                                  Grup Listesini Oluştur
                                </button>
                              )}

                              {item.isOpen ? (
                                <button
                                  className="ml-5 border border-gray-200 font-normal shadow-sm rounded-lg px-3 bg-white text-green-500 hover:bg-gray-100 hover:scale-110"
                                  onClick={() => handleClose(item.groupId)}
                                >
                                  Grubu Kapat
                                </button>
                              ) : (
                                <></>
                              )}
                            </th>
                          </tr>
                          {isEditActive && activeId === item.groupId && (
                            <>
                              <AddUserToGroup
                                activeId={activeId}
                                setIsEditActive={setIsEditActive}
                              />
                            </>
                          )}
                          {item.groupMember.map((member, personIdx) => (
                            <tr
                              key={member.id}
                              className={classNames(
                                personIdx === 0
                                  ? "border-gray-300"
                                  : "border-gray-200",
                                "border-t"
                              )}
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {member.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {member.userDetails.firstName}{" "}
                                {member.userDetails.lastName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {member.userDetails.phoneNumber}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {member.userDetails.identityNumber}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {member.userDetails.city}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                {item.isOpen ? (<>
                                  <button
                                  onClick={() =>
                                    handleDelete(
                                      member.userDetails.id,
                                      item.groupId
                                    )
                                  }
                                >
                                  <lord-icon
                                    src="https://cdn.lordicon.com/muhvdmrh.json"
                                    trigger="hover"
                                    stroke="80"
                                    colors="primary:#121331,secondary:#1663c7"
                                    style={{ width: "40px", height: "40px" }}
                                  ></lord-icon>
                                </button>

                                <ToastContainer />
                                </>) : (
                                  <></>
                                ) }
                          
                              </td>
                            </tr>
                          ))}
                        </Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminGroupsCoursePage;
