import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Bulunamadı | 404 | Unvan Eğitim Kurumları</title>
        <meta
          data-react-helmet="true"
          name="description"
          content="Üniversite veya MEB onaylı sertifikalı eğitimlerimizle ihtiyaç duyduğunuz becerileri öğrenin ve kariyerinizi ilerletin. Kurslarımız, iş dünyasının taleplerine uygun olarak tasarlanmıştır ve sektördeki uzmanlar tarafından verilmektedir. İşletme, dijital pazarlama, programlama ve daha birçok alanda sertifika programlarımıza göz atın ve kariyerinizi güçlendirin."
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content="üniversite sertifikalı eğitimler, meb sertifikalı eğitimler,,kariyer eğitimleri, sertifikalı kurslar, işletme eğitimi, dijital pazarlama kursları, programlama eğitimleri, sektörel eğitimler"
        />
        <meta
          data-react-helmet="true"
          name="copyright"
          content="© 2023 Unvan Eğitim Kurumları. Tüm hakları saklıdır. Web sitemizde yer alan tüm içerikler, telif hakkı kanunlarıyla korunmaktadır. İzinsiz kullanımı yasaktır."
        />
      </Helmet>
      <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-4xl font-bold tracking-tight text-[#142850] sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-bold tracking-tight text-[#142850] sm:text-5xl">
                  Sayfa Bulunamadı
                </h1>
                <p className="mt-1 text-base text-gray-500">
                  Lütfen adres çubuğundaki URL'yi kontrol edin ve tekrar
                  deneyin.
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <NavLink
                  aria-current="page"
                  to="/"
                  className="nav-link inline-flex items-center rounded-md border border-transparent bg-[#142850] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Anasayfaya Dön
                </NavLink>
                <NavLink
                  aria-current="page"
                  to="/iletisim"
                  className="nav-link inline-flex items-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-[#142850] hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  İletişim
                </NavLink>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default NotFound;
