import React, { useEffect, useState, useReducer } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function AdminSiteComments() {
  const notify = () => toast.success("Silindi!");
  const notifyErr = () => toast.error("Silinemedi!");
  const [visibility, setVisibility] = useState("");
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [siteComments, setSiteComments] = useState("");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);

      
  const headerOptions = {
    headers: {
      'Authorization': `Bearer ${JSON.parse(jwtTokenRedux)}`,
      'Accept': "text/plain"
    }
   }


  useEffect(() => {
    const fetchSiteComments = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Admin/Comment/SiteComments`,
          {
            headers: {
              accept: "text/plain",
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            },
          }
        );
        setSiteComments(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSiteComments();
  }, [reducerValue]);

  const deleteComment = (id) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            axios.delete(
                `${process.env.REACT_APP_BASE_URL}/Admin/Comment/DeleteSiteComment?id=${id}`,
                headerOptions
              )
              .then(() => {
                const newComments = siteComments.filter(
                  (comment) => comment.id !== id
                );
                setSiteComments(newComments);
                Swal.fire("Başarıyla silindi!")
                //notify();
              });
          } catch (error) {
            console.log(error);
            notifyErr();
          }
        };
        submit();
      }
    });
  };

  const handleCheck = async (id) => {
    setVisibility(true);
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/Admin/Comment/ChangeSiteCommentStatus?id=${id}`,
        data: visibility,
        headers: {
          accept: "text/plain",
          Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
        },
      });
      forceUpdate();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <h1 className="text-lg px-5 py-4 text-[#2196f3] font-bold">
        Site Yorumları
      </h1>

      <div className="divide-y divide-x divide-gray-200 overflow-hidden rounded-lg border-2 bg-gray-200 shadow sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-px">
        {siteComments &&
          siteComments?.map((item, actionIdx) => (
            <div
              key={item.id}
              className={classNames(
                actionIdx === 0
                  ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                  : "",
                actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                actionIdx === siteComments.length - 2 ? "sm:rounded-bl-lg" : "",
                actionIdx === siteComments.length - 1
                  ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                  : "",
                "relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-700 hover:bg-blue-100"
              )}
            >
              <div className="rounded-lg inline-flex p-3">
                {item.created.slice(8, 10)}.{item.created.slice(5, 7)}.
                {item.created.slice(0, 4)}
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() => deleteComment(item.id)}
                  type="button"
                  className="rounded-md font-normal border-2 hover:bg-[#142850] hover:text-white px-2"
                >
                  Sil
                  <span className="sr-only">Sil</span>
                </button>
                <ToastContainer />
              </div>
              <div className="flex justify-start align-center items-center gap-x-2">
                <input
                  className="h-4 w-4 pb-0 rounded border-gray-300 text-[#142850] focus:ring-blue-700"
                  type="checkbox"
                  checked={item.isAvailable}
                />
                <button
                  onClick={() => handleCheck(item.id)}
                  type="button"
                  className="rounded-md cursor-pointer font-medium text-[#142850] hover:text-blue-700 hover:underline"
                >
                  Görünürlük
                  <span className="sr-only">Görünürlük</span>
                </button>
              </div>

              <div className="mt-4">
                <h3 className="text-lg font-medium">
                  {item.firstName} {item.lastName}
                </h3>
                <span className="text-gray-500 text-xs">{item.email}</span>
                <br />
                <span className="text-gray-500 text-xs">
                  {item.phoneNumber}
                </span>

                <p className="mt-2 text-sm text-gray-500">
                  {item.commentContent}
                </p>
              </div>

              <Link
                to={`/duyurular/${item.id}`}
                className="absolute top-6 right-6 cursor-pointer text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
}

export default AdminSiteComments;
