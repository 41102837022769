import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../../data/errors";

function EducatorUpdateCourseInfo() {

    const { id }  = useParams();
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [duration, setDuration] = useState("");
    const [content, setContent] = useState("");
    const [subContentTitle, setSubContentTitle] = useState("");
    const [examInfo, setExamInfo] = useState("");
    const [kdv, setKdv] = useState("");
    const [certificate, setCertificate] = useState();
    const [educationType, setEducationType] = useState();
    const [bannerImg, setBannerImg] = useState("");
    const [certificateImg, setCertificateImg] = useState("");
    const [courseImg, setCourseImg] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const [subId, setSubId] = useState("");
    const [subName, setSubName] = useState("");
    const [subCategories, setSubCategories] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  
    let bodyFormData = new FormData();
    bodyFormData.append("Name", name);
    bodyFormData.append("Price", price);
    bodyFormData.append("Duration", duration);
    bodyFormData.append("Content", content);
    bodyFormData.append("subContentTitle", subContentTitle);
    bodyFormData.append("ExamInfo", examInfo);
    bodyFormData.append("kdv", kdv);
    bodyFormData.append("isRemote", educationType);
    bodyFormData.append("isCertified", certificate);
    bodyFormData.append("CourseBannerImage", bannerImg);
    bodyFormData.append("CertificateImage", certificateImg);
    bodyFormData.append("Image", courseImg);
    bodyFormData.append("videoPath", videoLink);
    bodyFormData.append("SubCategoryId", subId);
  
    useEffect(() => {
      const fetchSubCategories = async () => {
        try {
          const response = await axios(
            `${process.env.REACT_APP_BASE_URL}/SubCategory`,
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
              },
            }
          );
          setSubCategories(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchSubCategories();
    }, []);
  
    const handleEdit = () => {
      Swal.fire({
        title: "Kurs Güncellensin mi?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Vazgeç",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet!",
      }).then((result) => {
        if (result.isConfirmed) {
          const submit = async () => {
            setLoading(true);
            try {
              await axios({
                method: "patch",
                url: `${process.env.REACT_APP_BASE_URL}/Course?id=${id}`,
                data: bodyFormData,
                headers: {
                  ContentType: "multipart/form-data",
                  accept: "application/json",
                  Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                },
              });
              setLoading(false);
              Swal.fire("Güncellendi!");
              navigate("/egitmenpanel/kurslarim");
            } catch (response) {
              let isEqual = false;
              for (let index = 0; index < errors.length; index++) {
                if (
                  response.response.data.details.errorCodes === errors[index].id
                ) {
                  let isEqual = true;
                  return toast.error(errors[index].content);
                } else if (response.response.data.details.errorCodes === 999) {
                  let isEqual = true;
                  return toast.error(response.response.data.details.message);
                } else {
                  let isEqual = false;
                }
              }
              if (!isEqual) {
                toast.error(
                  "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
                );
              }
            }
          };
          submit();
        }
      });
    };
  
    useEffect(() => {
      const result = async () => {
        try {
          const response = await axios(
            `${process.env.REACT_APP_BASE_URL}/Course/${id}`,
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                accept: "application/json",
              },
            }
          );
          setName(response.data.data[0].name);
          setPrice(response.data.data[0].price);
          setDuration(response.data.data[0].duration);
          setContent(response.data.data[0].content);
          setSubContentTitle(response.data.data[0].subContentTitle);
          setExamInfo(response.data.data[0].examInfo);
          setKdv(response.data.data[0].kdv);
          setEducationType(response.data.data[0].isRemote);
          setCertificateImg(response.data.data[0].certificateImagePath);
          setCourseImg(response.data.data[0].courseBannerPath);
          setBannerImg(response.data.data[0].courseBannerPath);
          setCertificate(response.data.data[0].isCertified);
          setVideoLink(response.data.data[0].videoPath);
          setSubId(response.data.data[0].subCategoryId);
          setSubName(response.data.data[0].subCategoryName);
        } catch (error) {
          console.log(error);
        }
      };
      result();
    }, []);

    return (
    
        <>
        <div className="flex mb-5 mt-5 ml-8 md:ml-0">
            <NavLink
            to={`/egitmenpanel/kurslarim/duzenle/${id}`}
            className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
            >
            <lord-icon
                src="https://cdn.lordicon.com/zfxxckbp.json"
                trigger="loop"
                colors="primary:#4be1ec,secondary:#cb5eee"
            ></lord-icon>
            Geri
            </NavLink>
        </div>

        
    <h2 className="text-[#2196f3] text-center font-semibold mb-5 mt-8 text-2xl">
      KURS DÜZENLE
    </h2>
    <hr />
    {!loading && (
      <>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <h2 className="text-base font-medium tracking-tight text-gray-600 mb-2">
            Kapak Fotoğrafı
          </h2>
          <div className="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
            <div className="space-y-1 text-center">
              <embed
                src={courseImg}
                className="h-48 w-full"
                alt="kurs-kapak-foto"
              />
              <div className="flex text-sm text-gray-600">
                <label className="relative cursor-pointer rounded-md bg-white font-medium text-[#2196f3]">
                  <input
                    onChange={(e) => setBannerImg(e.target.files[0])}
                    type="file"
                    name="image"
                    id="course-banner"
                    accept="image/*"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <form className="space-y-6">
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h2
                  className="inline-flex items-center rounded-full py-1 px-4 mt-8 text-[#2196f3] ring-1 ring-inset ring-gray-400"
                  id="table-of-contents-title"
                >
                  <span className="font-mono text-sm" aria-hidden="true">
                    01
                  </span>
                  <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                  <span className="ml-3 text-base font-medium tracking-tight">
                    Kurs Bilgisi
                  </span>
                </h2>
              </div>
              <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                <div className="relative rounded-md border mb-10 mt-8 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                  <label
                    htmlFor="name"
                    className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    ALT KATEGORİ
                  </label>
                  <select
                    onChange={(e) => setSubId(e.target.value)}
                    value={subId}
                    className="form-select block w-full p-1 text-gray-900 sm:text-sm"
                  >
                    <option className="text-gray-500">{subName}</option>
                    {!!subCategories &&
                      subCategories?.map((subcategory) => (
                        <option key={subcategory?.id} value={subcategory?.id}>
                          {subcategory?.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="relative rounded-md border mb-10 mt-8 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                  <label
                    htmlFor="name"
                    className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Kurs Adı
                  </label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    name="name"
                    id=""
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
                <div className="mt-8">
                  <h2 className="text-base font-medium tracking-tight text-gray-600 mb-2">
                    Giriş
                  </h2>
                   <CKEditor
                    data={subContentTitle}
                    value={subContentTitle}
                    editor={Editor}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setSubContentTitle(data);
                    }}
                  /> 
                </div>
                <div className="relative rounded-md border mb-10 mt-8 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                  <label
                    htmlFor="name"
                    className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Video Linki
                  </label>
                  <input
                    value={videoLink}
                    onChange={(e) => setVideoLink(e.target.value)}
                    type="text"
                    name="video"
                    id=""
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
                <div className="relative rounded-md border mb-10 mt-8 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                  <label
                    htmlFor="name"
                    className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Eğitim Saati
                  </label>
                  <input
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    type="text"
                    name="name"
                    id=""
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>

                <div className="mt-10 mb-5 space-y-5">
                  <h2 className="block text-sm font-medium text-gray-700">
                    Sertifika Bilgisi
                  </h2>
                  <div>
                    <select
                      onChange={(e) => setCertificate(e.target.value)}
                      value={certificate}
                      id="certificate"
                      name="certificate"
                      className="mt-1 w-48 block border rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                      <option>Seçiniz</option>
                      <option value={true}>Sertifikalı</option>
                      <option value={false}>Sertifikasız</option>
                    </select>
                  </div>
                </div>

                <div className="mt-10 mb-5 space-y-5">
                  <h2 className="block text-sm font-medium text-gray-700">
                    Eğitim Şekli
                  </h2>
                  <div>
                    <select
                      onChange={(e) => setEducationType(e.target.value)}
                      value={educationType}
                      id="certificate"
                      name="certificate"
                      className="mt-1 w-48 block border rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                      <option>Seçiniz</option>
                      <option value={true}>Uzaktan Eğitim</option>
                      <option value={false}>Yüz yüze Eğitim</option>
                    </select>
                  </div>
                </div>

                <div className="mt-10">
                  <h2 className="text-base font-medium tracking-tight text-gray-600 mb-2">
                    Açıklama
                  </h2>
                   <CKEditor
                    data={content}
                    value={content}
                    editor={Editor}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setContent(data);
                    }}
                  /> 
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <h2 className="text-base font-medium tracking-tight text-gray-600 mb-2">
                    Kurs Fotoğrafı
                  </h2>
                  <div className="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                    <div className="space-y-1 text-center">
                      <img
                        className="h-48 w-full"
                        src={bannerImg}
                        alt="sertifika-foto"
                      />
                      <div className="flex text-sm text-gray-600">
                        <label className="relative cursor-pointer rounded-md bg-white font-medium text-[#2196f3]">
                          <input
                            onChange={(e) => setCourseImg(e.target.files[0])}
                            type="file"
                            name="image"
                            id="course-photo"
                            accept="image/*"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h2
                  className="inline-flex items-center rounded-full py-1 px-4 mt-8 text-[#2196f3] ring-1 ring-inset ring-gray-400"
                  id="table-of-contents-title"
                >
                  <span className="font-mono text-sm" aria-hidden="true">
                    02
                  </span>
                  <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                  <span className="ml-3 text-base font-medium tracking-tight">
                    Sertifika
                  </span>
                </h2>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                  <div className="space-y-1 text-center">
                    <img
                      className="h-48 w-full"
                      alt="sertifika-foto"
                      src={certificateImg}
                    />
                    <div className="flex text-sm text-gray-600">
                      <label className="relative cursor-pointer rounded-md bg-white font-medium text-[#2196f3]">
                        <input
                          onChange={(e) =>
                            setCertificateImg(e.target.files[0])
                          }
                          type="file"
                          name="image"
                          id="course-banner"
                          accept="image/*"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h2 className="inline-flex items-center rounded-full py-1 px-4 mt-8 text-[#2196f3] ring-1 ring-inset ring-gray-400">
                  <span className="font-mono text-sm" aria-hidden="true">
                    03
                  </span>
                  <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                  <span className="ml-3 text-base font-medium tracking-tight">
                    Sınav Bilgisi
                  </span>
                </h2>
              </div>
              <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                <fieldset>
                  <h2 className="text-base font-medium tracking-tight text-gray-600 mt-8">
                    Açıklama
                  </h2>
                  <div className="mt-2">
                   <CKEditor
                      data={examInfo}
                      value={examInfo}
                      editor={Editor}
                      onReady={(editor) => {}}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setExamInfo(data);
                      }}
                    /> 
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h2 className="inline-flex items-center rounded-full py-1 px-4 mt-8 text-[#2196f3] ring-1 ring-inset ring-gray-400">
                  <span className="font-mono text-sm" aria-hidden="true">
                    04
                  </span>
                  <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                  <span className="ml-3 text-base font-medium tracking-tight">
                    Satın Al
                  </span>
                </h2>
              </div>
              <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                <fieldset>
                  <div className="relative rounded-md border mb-6 mt-8 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                    <label
                      htmlFor="name"
                      className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      FİYAT
                    </label>
                    <input
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      type="text"
                      name="price"
                      id=""
                      className="block w-full p-1 text-gray-900 sm:text-sm"
                    />
                  </div>
                  <div className="relative rounded-md border mb-10 mt-8 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                    <label
                      htmlFor="name"
                      className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      KDV
                    </label>
                    <input
                      value={kdv}
                      onChange={(e) => setKdv(e.target.value)}
                      type="text"
                      name="kdv"
                      id=""
                      className="block w-full p-1 text-gray-900 sm:text-sm"
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </form>
      </>
    )}
    {loading && (
      <div className="grid place-content-center shadow bg-grey-light text-center mt-3 text-md">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
        Yükleniyor...
      </div>
    )}
    <div className="flex">
      <button
        onClick={handleEdit}
        className="inline-flex shadow-md items-center mx-auto rounded-md border bg-blue-100 
      px-4 py-2 m-5 text-sm font-medium text-blue-900 hover:bg-blue-300 focus:outline-none 
      focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
      >
        Paylaş
      </button>
      <ToastContainer />
    </div>
  </>
    )
}

export default EducatorUpdateCourseInfo