
function ExamResults({result}) {
    const stats = [
        { id: 1, name: "Soru sayısı", value: `${result.questionCount}` },
        { id: 2, name: "İşaretlenen soru sayısı", value: `${result.selectedChoicesCount}` },
        { id: 3, name: "İşaretlenmemiş soru sayısı", value: `${result.unselectedChoicesCount}` },
        { id: 4, name: "Yanlış cevap sayısı", value: `${result.wrongAnswerCount}`  },
      ];
  return (
    <div className="relative bg-white">
      <img
        className="h-56 w-full bg-gray-50 object-cover lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-1/2"
        src="https://images.pexels.com/photos/207691/pexels-photo-207691.jpeg?auto=compress&cs=tinysrgb&w=1600"
        alt=""
      />
      <div className="mx-auto grid max-w-7xl lg:grid-cols-2">
        <div className="px-6 pb-24 pt-16 sm:pb-32 sm:pt-20 lg:col-start-2 lg:px-8 lg:pt-32">
          <div className="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
            <h2 className="text-base font-semibold leading-8 text-[#142850]">
             Sayın {result.user.firstName} {result.user.lastName},
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Verdiğiniz cevaplara göre sınav sonucunuz
              <p className={`${result.passResult ? "text-green-600" : "text-red-500"}`}>{result.passResult ? "Başarılı!" : "Başarısız!"} </p>
            </p>
            <p className="mt-4 text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl">Sınav Notunuz: {result.userScore}</p>
            <p className="mt-4 text-lg font-semibold tracking-tight text-gray-500 sm:text-xl">Geçme Notu: {result.passScore}</p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Yapılan sınavda, toplam 100 puan olacak şekilde her soru {result.singleAnswerScore} puandı. Aşağıda detayları inceleyebilirsiniz.
            </p>
            <dl className="mt-16 grid max-w-xl grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-16">
              {stats.map((stat) => (
                <div
                  key={stat.id}
                  className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
                >
                  <dt className="text-sm leading-6 text-gray-600">
                    {stat.name}
                  </dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamResults;
