import axios from "axios";
import React, { useEffect, useState, useReducer } from "react";
import { AiFillWarning } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getUserDocuments } from "../../../store/features/UserSlice";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ColorRing } from "react-loader-spinner";
import { errors } from "../../../data/errors";
import { NavLink } from "react-router-dom";
import { FiCheckSquare } from "react-icons/fi";

const UsersDocuments = () => {
  const dispatch = useDispatch();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const usersDocuments = useSelector((state) => state.userSlice.usersDocuments);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(false);
  const [documentFile, setDocumentFile] = useState("");
  const [documentId, setDocumentId] = useState("");
  let bodyFormData = new FormData();
  bodyFormData.append("DocumentId", documentId);
  bodyFormData.append("Document", documentFile);

  useEffect(() => {
    dispatch(getUserDocuments(JSON.parse(jwtTokenRedux)));
  }, [reducerValue]);

  const handleSubmit = () => {
    Swal.fire({
      title: "Belge yüklensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          setLoading(true);
          try {
            await axios({
              method: "post",
              url: `${process.env.REACT_APP_BASE_URL}/User/RegisterDocument`,
              data: bodyFormData,
              onUploadProgress: (p) => {
                setDocumentFile({
                  fileprogress: (p.loaded / p.total).toFixed(2),
                });
              },
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
              },
            }).then((data) => {
              setDocumentFile({
                fileprogress: 1.0,
              });
            });
            setDocumentFile("");
            setDocumentId("");
            dispatch(getUserDocuments(JSON.parse(jwtTokenRedux))).then(
              Swal.fire("İşlem başarılı!")
            );
            setLoading(false);
          } catch (response) {
            setLoading(false);
            for (let index = 0; index < errors.length; index++) {
              if (
                response.response.data.details.errorCodes == errors[index].id
              ) {
                return toast.error(errors[index].content);
              } else if (response.response.data.details.errorCodes == 999) {
                return toast.error(response.response.data.details.message);
              } else {
                return toast.error(
                  "Bilinmeyen hata. Bizimle iletişime geçiniz. "
                );
              }
            }
          }
        };
        submit();
      }
    });
    forceUpdate();
  };

  const handleDocumentDisplay = (e, path) => {
    Swal.fire({
      imageUrl: path,
      width: "80%",
      imageWidth: 900,
      imageHeight: 700,
      imageAlt: "belge-gorseli",
      confirmButtonText: "Kapat",
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="w-[calc(100%-3.73rem)] ml-auto bg-gray-50">
        <div className="2xl:container mx-auto space-y-3 py-3">
          <div className="px-0 lg:px-12 mb-20">
            <div className="w-full bg-[#142850] rounded-lg mb-6">
              <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                EVRAKLARIM
              </p>
            </div>
            <div className="flex mb-5 mt-5">
              <NavLink
                to={`/kullanicipaneli/anasayfa`}
                className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
              >
                <lord-icon
                  src="https://cdn.lordicon.com/zfxxckbp.json"
                  trigger="loop"
                  colors="primary:#4be1ec,secondary:#cb5eee"
                ></lord-icon>
                Geri
              </NavLink>
            </div>
            <div className="mx-3">
              {!loading && (
                <div className="grid grid-cols-4 sm:gap-0 md:gap-8">
                  {!!usersDocuments &&
                    usersDocuments?.map((documents) => (
                      <div
                        className="col-span-4 lg:col-span-2"
                        key={documents.documentId}
                      >
                        <div className="w-full mt-3 border-2 border-gray-300 bg-white p-10 rounded-md">
                          <label className="w-full flex flex-col items-center px-4 py-3 bg-white text-[#142850] rounded-md tracking-wide uppercase border-2 border-dashed border-gray-300 cursor-pointer hover:bg-[#142850] hover:text-white hover:border-none">
                            <svg
                              className="w-8 h-8"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span className="mt-2 text-xl leading-normal">
                              {documents.document.name}
                            </span>
                            <input
                              type="file"
                              className="file:hidden text-sm pl-36"
                              onClick={() =>
                                setDocumentId(documents.documentId)
                              }
                              onChange={(e) =>
                                setDocumentFile(e.target.files[0])
                              }
                            />
                          </label>
                          <div className="mt-10 flex justify-center">
                            <div className="flex ml-5">
                              {documents.path === "" ? (
                                <div>
                                  <p className="text-center text-sm mb-1">
                                    Örnek Görsel
                                  </p>
                                  <embed
                                    src={documents.document.documentPath}
                                    alt="resim yok"
                                    className="h-48 w-80"
                                  />
                                </div>
                              ) : (
                                <>
                                  <div>
                                    <div className="flex justify-center text-green-500 text-sm mb-2 gap-x-2">
                                      Belge yüklendi <FiCheckSquare />
                                    </div>
                                    <embed
                                      src={documents.path}
                                      alt="resim yok"
                                      className="h-48 w-80"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="flex justify-center mt-5 mb-5">
                            <button
                              className="px-3 py-1 bg-slate-100 border border-gray-300 font-semibold rounded-md hover:bg-gray-300"
                              onClick={(e) =>
                                handleDocumentDisplay(e, documents.path)
                              }
                            >
                              Aç
                            </button>
                          </div>

                          <div className="mx-auto grid">
                            <button
                              className="mb-8 mx-auto py-2 px-6  bg-[#142850] rounded-md text-white hover:bg-blue-700 "
                              onClick={handleSubmit}
                            >
                              Yükle
                            </button>
                          </div>
                          <div className="flex gap-3">
                            <AiFillWarning size={24} color="orange" />
                            <p className="text-sm text-gray-500 mt-0.5">
                              {documents.document.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
              {loading && (
                <div className="grid place-content-center shadow bg-grey-light text-center mt-3">
                  <div
                    className="bg-[#142850] text-xs leading-none py-0.5 text-center text-white"
                    style={{
                      width:
                        "%" + documentFile.fileprogress &&
                        documentFile.fileprogress * 100,
                    }}
                  >
                    <div>
                      {documentFile.fileprogress &&
                        documentFile.fileprogress * 100 + "%"}
                    </div>
                  </div>
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClassName="blocks-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                  Yükleniyor...
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersDocuments;
