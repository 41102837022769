import axios from "axios";
import React, { useEffect, useState } from "react";
//import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import UsersLessonVideo from "./UsersLessonVideo";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2";
import { errors } from "../../../data/errors";
import { useDispatch, useSelector } from "react-redux";
import { getCourseLesson } from "../../../store/features/UserSlice";
import { useReducer } from "react";

const UsersLesson = () => {
  const notify = () => toast.success("Tüm videolar izlendi!");
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  //const [lesson, setLesson] = useState("");
  const { id } = useParams();
  const [paths, setData] = useState(
    "https://player.vimeo.com/video/795147085?h=588fec6a75&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  );
  const [approved, setApproved] = useState(false);

  const dispatch = useDispatch();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const courseLessons = useSelector((state) => state.userSlice.courseLesson);

  useEffect(() => {
    dispatch(
      getCourseLesson({
        jwt: JSON.parse(jwtTokenRedux),
        id: id,
      })
    );
  }, [reducerValue]);

  //Swall section
  const handleClick = () => {
    Swal.fire({
      title: "Tüm videolar izlendi olarak işaretlensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "İptal",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
    }).then((result) => {
      if (result.isConfirmed) {
        const approvedVideosWatched = async () => {
          try {
            await axios({
              method: "get",
              url: `${process.env.REACT_APP_BASE_URL}/User/ApproveAllVideoWatched?courseId=${id}`,
              headers: {
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
                accept: "*/*",
              },
            });
            setApproved(true);
            console.log(approved);
            notify();
          } catch (response) {
            for (let index = 0; index < errors.length; index++) {
              if (
                response.response.data.details.errorCodes == errors[index].id
              ) {
                return toast.error(errors[index].content);
              } else if (response.response.data.details.errorCodes == 999) {
                return toast.error(response.response.data.details.message);
              } else {
                return toast.error(
                  "Bilinmeyen bir hata! Bizimle iletişime geçiniz."
                );
              }
            }
          }
        };
        approvedVideosWatched();
      }
    });
  };

  ///Bu kullanıcının videoyu izlediğini kayıt etmek için önemli
  const lessonData = (id) => {
    const lesson = async () => {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}/Lesson/${id}`,
        headers: {
          Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          accept: "*/*",
        },
      })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: `${response.data.data[0].name} isimli kurs izlendi`,
          });
          setData(response.data.data[0].link);
          forceUpdate();
        })
        .catch((error) => {
          console.log(error);
          toast.error(`Bir hata oluştu`);
        });
    };
    lesson();
  };

  return (
    <>
      <div className="w-[calc(100%-3.73rem)] mb-20">
        <div className="2xl:container mx-auto space-y-3 py-3 ">
          <div className="mx-auto  bg-white rounded-xl px-1.5">
            <div className="flex mb-5 mt-5">
              <NavLink
                to={`/kullanicipaneli/kurslarim`}
                className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
              >
                <lord-icon
                  src="https://cdn.lordicon.com/zfxxckbp.json"
                  trigger="loop"
                  colors="primary:#4be1ec,secondary:#cb5eee"
                ></lord-icon>
                Geri
              </NavLink>
            </div>
            <div className="grid grid-cols-12 mt-3 gap-3">
              <div className="col-span-12 lg:col-span-5 px-10">
                <div className="w-full bg-[#142850] rounded-lg mb-0">
                  <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                    Kurs İçeriği
                  </p>
                </div>

                <div className="mt-3">
                  {!!courseLessons &&
                    courseLessons?.map((lesson) => (
                      <button
                        className="w-full font-medium text-left pl-3 mt-3 border-[1px] py-2 hover:bg-gray-200"
                        onClick={() => lessonData(lesson.id)}
                      >
                        <div>
                          {lesson.watchStatus ? (
                            <lord-icon
                              src="https://cdn.lordicon.com/egiwmiit.json"
                              trigger="loop"
                              delay="9999"
                            ></lord-icon>
                          ) : (
                            <lord-icon
                              src="https://cdn.lordicon.com/nhfyhmlt.json"
                              trigger="loop"
                              delay="9999"
                              className="mr-8 accent-[#142850]"
                            ></lord-icon>
                          )}
                          {/* <input
                    type="checkbox"
                    checked={lesson.watchStatus}
                    className="mr-8 accent-[#142850]"
                  /> */}

                          {lesson.name}
                        </div>
                      </button>
                    ))}
                </div>

                <div className="mt-5 w-100">
                  <button
                    onClick={() => handleClick()}
                    className="inline-flex items-center rounded-md border border-transparent bg-[#142850] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    <span className="p-1">Dersleri Geç</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                  <ToastContainer />
                </div>
              </div>

              <div className="col-span-12 lg:col-span-7 max-w-4xl px-10 ">
                <div className="w-full bg-[#142850] rounded-lg mb-0">
                  <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                    Kurs İçeriği
                  </p>
                </div>
                <div className="h-96 border-[1px] rounded-lg mt-6 my-auto">
                  <iframe
                    className="w-full h-full text-center rounded-lg"
                    src={paths}
                    title="React JS Form Validation | Axios User Registration Form Submit | Beginners to Intermediate"
                    frameborder="0"
                    allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                {/* video */}
                {/* <UsersLessonVideo/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
//

export default UsersLesson;
