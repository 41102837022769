import React from "react";
import {
  AiOutlineCodeSandbox,
  AiOutlineComment,
  AiOutlineHistory,
  AiOutlineHome,
} from "react-icons/ai";
import {
  HiOutlineNewspaper
} from "react-icons/hi";
import { CgMenuGridR } from "react-icons/cg";
import { FiSettings } from "react-icons/fi";
import { TbCertificate } from "react-icons/tb";
import { NavLink } from "react-router-dom";
const UserSidebar = () => {

  return (
    
    <>
      <aside className="border-2 bg-gray-50 border-[#142850] sticky max-h-screen top-0 left-0 overflow-hidden z-10 w-[calc(4.1rem)] border-r border-gray-300/40   delay-100 md:hover:duration-1000 md:duration-1000  md:hover:w-72 md:hover:shadow-2xl">
        <div className="flex flex-col justify-between ">
          <div>
            <div className="h-16 border-b border-gray-300/40 dark:border-gray-700 flex items-center">
              <a href="" className="block w-max px-2.5 ml-2">
                <CgMenuGridR size={26} color="#142850" />
              </a>

              {/* <span className="ml-3 font-medium text-md">test</span> */}
            </div>
            <div className="mt-6">
              <ul className="px-1.5  -ml-px font-medium tracking-wide flex-1 mt-10 mb-40">
                <li className="w-max space-y-4 font-medium group-hover:w-full">
                  <NavLink
                    to="anasayfa"
                    
                    //className="block py-3 w-[52px] rounded-full text-[#142850] focus:text-white focus:bg-[#142850] focus:duration-1000 duration-1000"
                    
                    className={({isActive})=>{
                      return(
                         ( 
                          !isActive ? 'block py-3 w-[52px] rounded-full duration-1000  text-[#142850] ': 'block py-3 w-[52px] rounded-full duration-1000  bg-[#142850] text-white'
                        )
                      )
                    }}
                  >
                    <div className="w-max flex items-center px-3 gap-4 ">
                      <AiOutlineHome size={30}  />
                      <span className="ml-1 md:ml-4 hover:text-gray-400 text-[#142850]">
                        Anasayfa
                      </span>
                    </div>
                  </NavLink>
                </li>

                <li className="w-max space-y-4 font-medium group-hover:w-full mt-3 underline-offset-2 ">
                  <NavLink
                    to="kurslarim"
                    className={({isActive})=>{
                      return(
                         (
                          !isActive ? 'block py-3 w-[52px] rounded-full duration-1000  text-[#142850]': 'block py-3 w-[52px] rounded-full duration-1000  bg-[#142850] text-white'
                        )
                      )
                    }}
                  >
                    <div className="w-max flex items-center px-3 gap-4">
                      <AiOutlineCodeSandbox
                        size={30}
                       
                      />
                      <a className="ml-1 md:ml-4 hover:text-gray-400 text-[#142850] ">
                        Kurslarım
                      </a>
                    </div>
                  </NavLink>
                </li>

                <li className="w-max space-y-4 font-medium group-hover:w-full mt-3  ">
                  <NavLink
                    to="sertifikalarim"
                    className={({isActive})=>{
                      return(
                         (
                          !isActive ? 'block py-3 w-[52px] rounded-full duration-1000  text-[#142850]': 'block py-3 w-[52px] rounded-full duration-1000  bg-[#142850] text-white'
                        )
                      )
                    }}
                  >
                    <div className="w-max flex items-center px-3 gap-4 ">
                      <TbCertificate size={30}  />
                      <span className="ml-1 md:ml-4 hover:text-gray-400 text-[#142850]">
                        Sertifikalarım
                      </span>
                    </div>
                  </NavLink>
                </li>

                <li className="w-max space-y-4 font-medium group-hover:w-full mt-3 rounded-full ">
                  <NavLink
                    to="yorumlarim"
                    className={({isActive})=>{
                      return(
                         (
                          !isActive ? 'block py-3 w-[52px] rounded-full duration-1000  text-[#142850]': 'block py-3 w-[52px] rounded-full duration-1000  bg-[#142850] text-white'
                        )
                      )
                    }}
                  >
                    <div className="w-max flex items-center px-3 gap-4 ">
                      <AiOutlineComment size={30} />
                      <span className="ml-1 md:ml-4 hover:text-gray-400 text-[#142850]  ">
                        Yorumlarım
                      </span>
                    </div>
                  </NavLink>
                </li>

                <li className="w-max space-y-4 font-medium group-hover:w-full mt-3">
                  <NavLink
                    to="siparisgecmisi"
                    className={({isActive})=>{
                      return(
                         (
                          !isActive ? 'block py-3 w-[52px] rounded-full duration-1000  text-[#142850]': 'block py-3 w-[52px] rounded-full duration-1000  bg-[#142850] text-white'
                        )
                      )
                    }}
                  >
                    <div className="w-max flex items-center px-3 gap-4">
                      <AiOutlineHistory size={30}  />
                      <span className="ml-1 md:ml-4 hover:text-gray-400 text-[#142850]">
                        Ödeme Geçmişi
                      </span>
                    </div>
                  </NavLink>
                </li>

                <li className="w-max space-y-4 font-medium group-hover:w-full mt-3">
                  <NavLink
                    to="evraklarim"
                    className="block py-3 w-[52px] rounded-full text-[#142850] focus:text-white focus:bg-[#142850] focus:duration-1000 duration-1000"
                  >
                    <div className="w-max flex items-center px-3 gap-4">
                      <HiOutlineNewspaper size={30}  />
                      <span className="ml-1 md:ml-4 hover:text-gray-400 text-[#142850]">
                        Evraklarım
                      </span>
                    </div>
                  </NavLink>
                </li>

                <li className="w-max space-y-4 font-medium group-hover:w-full mt-3">
                  <NavLink
                    to="ayarlarim"
                    className={({isActive})=>{
                      return(
                         (
                          !isActive ? 'block py-3 w-[52px] rounded-full duration-1000  text-[#142850]': 'block py-3 w-[52px] rounded-full duration-1000  bg-[#142850] text-white'
                        )
                      )
                    }}
                  >
                    <div className="w-max flex items-center px-3 gap-4">
                      <FiSettings size={30} />
                      <span className="ml-1 md:ml-4 hover:text-gray-400 text-[#142850]">
                        Ayarlar
                      </span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default UserSidebar;
