import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { getUserTransaction } from "../../../store/features/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UsersOrderHistory = () => {
  const dispatch = useDispatch();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [pageNumber, setPageNumber] = useState(0);

  const usersTransaction = useSelector(
    (state) => state.userSlice.usersTransaction
  );
  const dataCount = useSelector((state) => state.userSlice.pageData);
  const ordersPerPage = 10;
  const pageCount = Math.ceil(dataCount?.dataCount / ordersPerPage);

  useEffect(() => {
    dispatch(
      getUserTransaction({
        data: JSON.parse(jwtTokenRedux),
        pageNumber,
      })
    );
  }, [pageNumber]);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      {usersTransaction.length === 0 ? (
        <>
          <div className="w-[calc(100%-3.73rem)] px-3">
            <div className="2xl:container mx-auto space-y-3 py-3">
              <div className="bg-white">
                <div className="w-full bg-[#142850] rounded-lg mb-6">
                  <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                    Ödeme Geçmişi
                  </p>
                </div>
                <div className="flex mb-5 mt-5">
                  <NavLink
                    to={`/kullanicipaneli/anasayfa`}
                    className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
                  >
                    <lord-icon
                      src="https://cdn.lordicon.com/zfxxckbp.json"
                      trigger="loop"
                      colors="primary:#4be1ec,secondary:#cb5eee"
                    ></lord-icon>
                    Geri
                  </NavLink>
                </div>
                <div className="mx-auto max-w-7xl py-16 px-6 lg:px-8">
                  <div className="overflow-hidden rounded-lg bg-[#142850] shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
                    <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                      <div className="lg:self-center">
                        <h2 className="text-white">
                          <span className="block text-lg">
                            Henüz kurs satın almadınız.
                          </span>
                          <span className="block font-bold text-2xl">
                            Kariyerinzi geliştirmeye bugün başlayın!
                          </span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-blue-200">
                          Yüzlerce kurstan dilediğinizi seçip güvenli ödeme
                          yöntemleriyle alışverişinizi tamamlayabilirsiniz.
                        </p>
                        <Link
                          to="/tumkurslar"
                          className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-[#142850] shadow hover:bg-blue-200"
                        >
                          Tüm Kurslar
                        </Link>
                        <Link
                          to="/iletisim"
                          className="text-base font-semibold leading-7 text-white pl-5"
                        >
                          Eğitim Uzmanıyla Görüş{" "}
                          <span aria-hidden="true">→</span>
                        </Link>
                      </div>
                    </div>
                    <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
                      <img
                        className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                        src="https://images.pexels.com/photos/4482896/pexels-photo-4482896.jpeg?auto=compress&cs=tinysrgb&w=1600"
                        alt="Payment"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="w-[calc(100%-3.73rem)]  px-3">
          <div className="2xl:container  mx-auto space-y-3 py-3">
            <div className="bg-white ">
              <div className="w-full bg-[#142850] rounded-lg mb-6">
                <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                  Ödeme Geçmişi
                </p>
              </div>
              <div className="flex mb-5 mt-5">
                <NavLink
                  to={`/kullanicipaneli/anasayfa`}
                  className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/zfxxckbp.json"
                    trigger="loop"
                    colors="primary:#4be1ec,secondary:#cb5eee"
                  ></lord-icon>
                  Geri
                </NavLink>
              </div>
              <div className="py-0">
                <div className="mx-auto sm:px-0 lg:px-0">
                  <div className="mx-auto px-0 lg:px-0">
                    <p className="mt-2 text-sm text-gray-500">
                      Son siparişlerin durumunu kontrol edin, iadeleri yönetin
                      ve benzer ürünleri keşfedin.
                    </p>
                  </div>
                </div>

                <div className="mt-6">
                  <h2 className="sr-only">Son Siparişler</h2>
                  <div className="mx-auto ">
                    <div className="mx-auto space-y-8 border-b mb-10  lg:px-0">
                      {!!usersTransaction &&
                        usersTransaction?.map((transaction) => (
                          <div
                            key={transaction.shoppingId}
                            className="border-b bg-gray-100 shadow-sm sm:rounded-lg"
                          >
                            <div className="flex items-center border-b border-gray-200 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-6">
                              <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                                <div>
                                  <dt className="font-medium text-gray-900">
                                    Sipariş Numarası
                                  </dt>
                                  <dd className="mt-1 text-gray-500">
                                    {transaction.details?.orderId}
                                  </dd>
                                </div>
                                <div className="hidden sm:block">
                                  <dt className="font-medium text-gray-900">
                                    Sipariş Tarihi
                                  </dt>
                                  <dd className="mt-1 text-gray-500">
                                    <time dateTime={transaction.created}>
                                      {transaction.created.slice(0, 10)}
                                    </time>
                                  </dd>
                                </div>
                                <div>
                                  <dt className="font-medium text-gray-900">
                                    Toplam Tutar
                                  </dt>
                                  <dd className="mt-1 font-medium text-gray-900">
                                    APIDEN TOPLAM TUTAR GELCEK
                                  </dd>
                                </div>
                              </dl>

                              <Menu
                                as="div"
                                className="relative flex justify-end lg:hidden"
                              >
                                <div className="flex items-center">
                                  <Menu.Button className="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">
                                      Options for order {transaction.shoppingId}
                                    </span>
                                    <EllipsisVerticalIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            View
                                          </a>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            Invoice
                                          </a>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>

                              <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                                {transaction.details?.invoiceId === null ? (
                                  <div
                                    class="flex p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50"
                                    role="alert"
                                  >
                                    <svg
                                      aria-hidden="true"
                                      class="flex-shrink-0 inline w-5 h-5 mr-3"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                        clip-rule="evenodd"
                                      ></path>
                                    </svg>
                                    <span class="sr-only">Info</span>
                                    <div>
                                      <span class="font-medium"></span> Fatura
                                      bulunmamaktadır.
                                    </div>
                                  </div>
                                ) : (
                                  <a
                                    href={`${transaction.details?.invoiceId}`}
                                    target="_blank"
                                    className="flex items-center justify-center rounded-md border border-gray-300 bg-white py-2 px-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    <span>Faturayı İndir</span>
                                    <span className="sr-only">
                                      sipariş {transaction.details?.invoiceId}
                                    </span>
                                  </a>
                                )}
                              </div>
                            </div>

                            {/* Products */}
                            <h4 className="sr-only">Ürünler</h4>
                            <ul
                              role="list"
                              className="divide-y divide-gray-200 mb-20"
                            >
                              {transaction.courses.length === 0 ? (
                                <div
                                  class="flex p-4 text-sm text-gray-800 rounded-lg bg-gray-50 "
                                  role="alert"
                                >
                                  <svg
                                    aria-hidden="true"
                                    class="flex-shrink-0 inline w-5 h-5 mr-3"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                      clip-rule="evenodd"
                                    ></path>
                                  </svg>
                                  <span class="sr-only">Bilgi</span>
                                  <div>
                                    <span class="font-medium">
                                      Kurs silinmiş.
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="px-10">
                                  <div
                                    className="flex p-4 mb-4 text-sm text-blue-800 rounded-lg"
                                    role="alert"
                                  >
                                    <svg
                                      aria-hidden="true"
                                      class="flex-shrink-0 inline w-5 h-5 mr-3"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                        clip-rule="evenodd"
                                      ></path>
                                    </svg>
                                    <span>Sipariş Bilgisi</span>
                                  </div>
                                  <div className="overflow-hidden bg-white shadow sm:rounded-md">
                                    <ul
                                      role="list"
                                      className="divide-y divide-gray-100"
                                    >
                                      {transaction.courses?.map((item) => (
                                        <li key={item.id}>
                                          <div className="flex items-center px-4 py-4 sm:px-6">
                                            <div className="flex min-w-0 flex-1 items-center">
                                              <div className="flex-shrink-0">
                                                <img
                                                  className="h-12 w-12 rounded-full"
                                                  src={item.courseBannerPath}
                                                  alt="kurs-görseli"
                                                />
                                              </div>
                                              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                <div>
                                                  <p className="truncate text-sm font-bold text-gray-800">
                                                    {item.name}
                                                  </p>
                                                  <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      stroke-width="1.5"
                                                      stroke="currentColor"
                                                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                      aria-hidden="true"
                                                    >
                                                      <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
                                                      />
                                                      <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
                                                      />
                                                    </svg>
                                                    <span className="truncate text-sm">
                                                      Kurs Kodu: {item.code}
                                                    </span>
                                                  </p>
                                                </div>
                                                <div className="hidden md:block">
                                                  <div>
                                                    <p className="text-sm text-gray-900">
                                                      {item.mainCategoryName}{" "}
                                                      Kursları
                                                    </p>
                                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                                      Tutar: {item.price} TL
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </ul>
                          </div>
                        ))}
                    </div>
                    {
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UsersOrderHistory;
