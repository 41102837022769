import React, { useEffect, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import "./AdminUsers.css";
import { getDocuments } from "../../../store/features/AdminSlice";

function AdminDocument() {
  // const exampleDocument = (document) => {
  //     const MySwal = withReactContent(Swal);
  //     console.log(document);
  //     MySwal.fire({
  //       title: <p>Örnek Döküman</p>,
  //       html: <embed src={document} width="100%" height="500px" className="px-auto" />,
  //       width: "50%",
  //       margin: "auto"
  //     });

  // const exampleDocument = (document) => {
  //     const MySwal = withReactContent(Swal);
  //     console.log(document);
  //     MySwal.fire({
  //       title: <p>Örnek Döküman</p>,
  //       html: <embed src={document} width="100%" height="500px" className="px-auto" />,
  //       width: "50%",
  //       margin: "auto"
  //     });
  //   };

  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const [search, setSearch] = useState("");
  const dataCount = useSelector((state) => state.AdminSlice.pageCount);
  const pageCount = Math.ceil(dataCount.dataCount / usersPerPage);
  const documents = useSelector((state) => state.AdminSlice.documents);

  useEffect(() => {
    dispatch(getDocuments({ data: JSON.parse(jwtTokenRedux), pageNumber }));
  }, [pageNumber]);

  const displayDocuments = Object.values(documents)
    .filter((document) =>
      document.name
        .toLocaleLowerCase("tr-TR")
        .includes(search.toLocaleLowerCase("tr-TR"))
    )
    .map((document) => {
      return (
        <>
          <li key={document.id}>
            <Link
              to={`/adminpanel/evraklar/${document.id}`}
              className="block hover:bg-gray-50 "
            >
              <div
                className={`flex items-center pl-4 py-4 sm:pl-6 hover:bg-blue-100 ${
                  document.isActive
                    ? "border-l-2 border-green-500"
                    : "border-l-2 border-red-500"
                }`}
              >
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-12 w-12 rounded-full object-fit"
                      src={
                        "https://cdn-icons-png.flaticon.com/512/3135/3135874.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 ">
                    <div>
                      <p className="truncate text-sm font-medium text-black">
                        {document.name}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        {document.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        </>
      );
    });
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
       <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>

      <div className="mb-10">
        <div className="text-center mb-5 border-b">
          <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
            Evraklar
          </h1>
        </div>

        <div className=" sm:justify-between grid grid-cols-12 px-10 mb-5">
          <div className="sm:items-center  mb-5 col-span-12 md:col-span-6 mx-auto md:mx-0">
            <div className="mt-4 sm:mt-0 sm:ml-auto ">
              <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
                <input
                  className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md w-64"
                  type="text"
                  value={search}
                  placeholder="Kurs Adıyla Aratın"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto md:mx-0">
            <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none ">
              <Link to={"/adminpanel/evraklar/ekle"}>
                <button
                  type="button"
                  className="items-center rounded-lg shadow-lg border border-gray-200 w-64 px-2 py-2 text-md font-medium bg-[#142850] hover:bg-blue-700  text-white focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-700"
                >
                  Yeni Ekle
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="overflow-hidden shadow border sm:rounded-md mb-10 ml-3 md:mx-3">
          <ul role="list" className="divide-y divide-gray-300">
            {displayDocuments}
          </ul>
        </div>

        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </>
  );
}

export default AdminDocument;
