import React ,{useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link ,NavLink} from "react-router-dom";
import axios from "axios";
import {
    PhoneIcon,
    ChevronRightIcon,
    EnvelopeIcon,
  } from "@heroicons/react/20/solid";
  import ReactPaginate from "react-paginate";



function Instructors (){

    const jwtTokenRedux = useSelector((state) => state.login.accessToken);
    const [instructors , setInstructors ] = useState([]);
    const [pageNumber,setPageNumber] = useState(0);
    const [firstName,setFirstName] = useState("");
    const [lastName,setLastName] = useState("");
    const [email,setEmail] = useState("");
    const [identityNumber,setIdentityNumber] = useState("");
    const [dataCount , setDataCount] = useState();
    const [usersPerPage , setUsersPerPage] = useState();
    const pageCount = Math.ceil(dataCount / usersPerPage);

    useEffect(() => {
        const fetchTodos = async () => {
            const response = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/Admin/Instructors?Email=${email}&FirstName=${firstName}&LastName=${lastName}&PageNumber=${
                pageNumber + 1
              }`,
              {
              headers: {
                  ContentType: "application/json-patch+json",
                  accept: "text/plain",
                  Authorization:`Bearer ${JSON.parse(jwtTokenRedux)}`,
                },
              }
            );
            setInstructors(response.data.data);
            setDataCount(response.data.data.length);
            setUsersPerPage(10);
    
          };
          fetchTodos();
       
       
      }, [pageNumber,firstName,lastName,email,identityNumber]);


      const displayUsers =
      instructors &&
      instructors .map((user) => {
          return (
            <>
              <li key={user.id}>
                <Link
                  to={`/adminpanel/kullanicilar/duzenle/${user.id}`}
                  className="block hover:bg-gray-50"
                >
                  <div
                    className={`flex items-center px-4 py-4 sm:px-6 ${
                      user.isActive ? "bg-green-200" : "bg-red-200"
                    }`}
                  >
                    <div className="flex min-w-0 flex-1 items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="h-12 w-12 rounded-full"
                          src={`${user.profilePicturePath}`}
                          alt="profil-fotoğrafı"
                        />
                      </div>
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="truncate text-sm font-medium text-black">
                            {user.firstName} {user.lastName}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            {user.userName}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <EnvelopeIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="truncate">{user.email}</span>
                          </p>
                        </div>
                        <div className="hidden md:block">
                          <div>
                            <p className="text-sm text-gray-900">
                              Sehir: {user.city}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <PhoneIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {user.phoneNumber}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </Link>
              </li>
            </>
          );
        });
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };


  return (
    <>


        <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel/egitmen-islemleri`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
        </div>  
        <div className="mb-10">
        <div className="text-center mb-5 border-b">
          <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
            Eğitmenler
          </h1>
        </div>
        <div className=" sm:justify-between grid grid-cols-4 gap-4 px-10 mb-5">
          <div className="sm:items-center  mb-5 col-span-12 md:col-span-6 mx-auto sm:mx-0">
            {/*
             <div className="mt-4 sm:mt-0 flex sm:ml-auto ">
              <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
               
                <input
                  className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md w-64"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={"Mail adresine göre ara"}
                />
              </div>
              
              <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
                <input
                  className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md w-64"
                  type="text"
                  value={identityNumber}
                  onChange={(e) => setIdentityNumber(e.target.value)}
                  placeholder={"Kimlik numarasına göre ara"}
                />
                </div>

              <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
              <input
                  className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md w-64"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder={"İsme göre ara"}
                />
                </div>
                
                <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-4 mx-auto mt-3">
                <input
                  className="border-[0.5px] border-gray-300 shadow-md text-black p-2 rounded-md w-64"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder={"Soyada göre ara"}
                />
                </div>

               

            </div> 
            */}
          </div>
          <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
            <div className="mt-4 sm:mt-0 lg:ml-auto sm:flex-none ">
              <Link to={"/adminpanel/kullanicilar/ekle"}>
                <button
                  type="button"
                  className="items-center rounded-lg shadow-xl border border-gray-300 bg-white w-64 px-2 py-2 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                >
                  Yeni Eğitmen Ekle
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white shadow sm:rounded-md mb-10 mx-10 md:mx-3">
          <ul role="list" className="divide-y divide-gray-200">
            {displayUsers}
          </ul>
        </div>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
        />
      </div>

        {/* <h1>Eğitmenler</h1>
            <div className="overflow-hidden bg-white shadow sm:rounded-md mb-10 mx-10 md:mx-3">
          <ul role="list" className="divide-y divide-gray-200">
            {displayUsers}
          </ul>
        </div>   */}


         
    </>
  );
};

export default Instructors;
