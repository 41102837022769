import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
  msg: "",
  user: "",
  accessToken: "",
  roles: "",
  loading: false,
  error: "",
  success: false,
  to: "/login",
  firstName: "",
  lastName: "",
  forgetPassword: "",
  rememberMe: "",
  checkAuth:"",
  loginLoading:""
};

export const signInUser = createAsyncThunk("sighinuser", async (body) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/Auth/Login`, {
        method: "post",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(body),
      });
      return await res.json();
  } catch (error) {
    return error;
  }
});

export const forgetPassword = createAsyncThunk("forgetPassword", async (body) => {
  try {
    const response = await axios(
      `${process.env.REACT_APP_BASE_URL}/Auth/ForgetPassword?email=${body}`,
      {
        method: "post",
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const googleAuth = createAsyncThunk("googleAuthpost", async (body) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/Auth/Login?`, {
        method: "post",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify({
          "googleToken": body,
          "rememberMe": true,
        }),
      });
      return await res.json();
  } catch (error) {
    return console.log("error.res");
  }
});

export const checkIfAuthorized = createAsyncThunk("checkIfAuthorized", async (data) => {
  try {
    const response = await axios(
      `${process.env.REACT_APP_BASE_URL}/Auth/CheckIfAuthorized`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${data}`,
        },
      }
    );
    return response.data;
  } catch (response) {
    return response.data;
  }
});



const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    addToken:  (state, action) => {
      state.accessToken =  localStorage.getItem("accessToken");
      state.firstName = localStorage.getItem("userName");
      state.lastName = localStorage.getItem("lastName");
    //   state.accessToken = true
    },
    addUser: (state, action) => {
      state.user = localStorage.getItem("user");
      
    },
   
    logout: (state, action) => {
      state.accessToken = null;
      state.firstName = null;
      state.error = false
      localStorage.clear();
      state.success = false
    },
   
  },
  extraReducers: {
    [signInUser.pending]: (state, action) => {
       state.loading = true;
       
    },
    [signInUser.fulfilled]:  ( state, { payload: {details, data } }) => {
      state.loading = false;
      state.error = details.success
      if (state.error===false) {
        state.error = details;
        state.to = "yanlış giriş"
      } else {
        state.firstName = data.firstName;
        state.lastName = data.lastName;
        state.success = true;
        state.error = details;
        state.msg = details;
        state.accessToken = data.accessToken;
        state.user = data;
        
        localStorage.setItem("msg", JSON.stringify(details.message));
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("accessToken", JSON.stringify(data.accessToken));
        localStorage.setItem("success", JSON.stringify(details.success));
        localStorage.setItem("userName", JSON.stringify(data.firstName));
        localStorage.setItem("lastName", JSON.stringify(data.lastName));
        

      }
    },
    [signInUser.rejected]: (state, action) => {
      state.loading = true;
    },



    [forgetPassword.pending]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [forgetPassword.fulfilled]: (state, { payload: { details, data } }) => {
      state.loading = true;
      state.error = details.success;
      state.success = false;
      if (state.error === false) {
        state.error = details.success;
        state.success = true;
      } else {
        state.error = details.success;
        state.msg = details;
        state.forgetPassword = data;
      }
    },
    [forgetPassword.rejected]: (state, action) => {
      state.loading = true;
      state.success = false;
    },

    [googleAuth.pending]: (state, action) => {
      state.loading = false
      
   },
   [googleAuth.fulfilled]:  ( state, { payload: {details, data } }) => {
     state.loading = true;
     state.error = details.success
     if (state.error===false) {
       state.error = details.success;
       state.to = "yanlış giriş"
     } else {
       state.success = true;
       state.error = details.success;
       state.msg = details;
       state.accessToken = data.accessToken;
       state.firstName = data.firstName;
        state.lastName = data.lastName;

       
       localStorage.setItem("msg", JSON.stringify(details.message));
       localStorage.setItem("accessToken", JSON.stringify(data.accessToken));
       localStorage.setItem("success", JSON.stringify(details.success));
       localStorage.setItem("userName", JSON.stringify(data.firstName));
       localStorage.setItem("lastName", JSON.stringify(data.lastName));

     }
   },
   [googleAuth.rejected]: (state, action) => {
     state.loading = true;
   },

   // -------------------------------- checkIfAuthorized --------------------------------
   [checkIfAuthorized.pending]: (state, action) => {
    state.loading = false;
  },
  [checkIfAuthorized.fulfilled]: (state, { payload: { details, data } }) => {
    state.loading = true;
    state.error = details.succes;
    if (state.error === false) {
      state.error = details.success;
    } else {
      state.error = details.success;
      state.msg = details;
      state.checkAuth = details;
    }
  },
  [checkIfAuthorized.rejected]: (state, { payload: { details, data } }) => {
    state.loading = true;
    state.error = details;
  },



  },
});

export const { addToken, addUser, logout } = LoginSlice.actions;
export default LoginSlice.reducer;