import React, { useEffect, useState } from "react";
import AdminPaymentModal from "./AdminPaymentModal";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPayment } from "../../../store/features/AdminSlice";
import ReactPaginate from "react-paginate";

function AdminPayment() {
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false)
  const [selectedData, setSelectedData] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const paymentsPerPage = 10;
  const dataCount = useSelector((state) => state.AdminSlice.pageCount);
  const pageCount = Math.ceil(dataCount.dataCount / paymentsPerPage);

  useEffect(() => {
    dispatch(getPayment({ data: JSON.parse(jwtTokenRedux), pageNumber }));
  }, [pageNumber]);
  const paymentsData = useSelector((state) => state.AdminSlice.payment);

  const handlePaymentDetails = (item) => {
    setSelectedData(item);
    setIsOpen(true);
  };
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="relative mb-20">
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel/alisveris`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-10 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Ödemeler
        </h1>
      </div>

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto px-2 sm:px-0">
            <h1 className="text-xl font-semibold text-gray-900">
              Ödeme Detayları
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Tüm ödeme detaylarını buradan inceleyebilirsiniz.
            </p>
          </div>
        </div>
        <div className="mt-8 flex flex-col px-5 sm:p-1">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        Kullanıcı
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                      >
                        Tarih
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                      >
                        Tutar
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                      >
                        Ödeme
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                      >
                        <span className="sr-only">İncele</span>
                      </th>
                    </tr>
                  </thead>

                  {!!paymentsData &&
                    paymentsData?.map((item) => (
                      <tbody key={item.id} className="bg-white">
                        <tr>
                          <td
                            className="border-b border-gray-200
                           whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                          >
                            {item.userDetail.firstName}{" "}
                            {item.userDetail.lastName}
                          </td>
                          <td
                            className="border-b border-gray-200
                           whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                          >
                             {item.created.slice(8,10)}.{item.created.slice(5,7)}.{item.created.slice(0,4)}
                          </td>
                          <td
                            className="border-b border-gray-200
                         whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                          >
                            {item.totalPrice}
                          </td>
                          <td
                            className="border-b border-gray-200
                         whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                          >
                            {item.status ? (
                              <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                                Başarılı
                              </span>
                            ) : (
                              <span className="inline-block flex-shrink-0 rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800">
                                Başarısız
                              </span>
                            )}
                          </td>
                          <td
                            className="border-b border-gray-200
                         whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900 sm:pl-6 lg:pl-8"
                          >
                         <div className="flex gap-x-5">
                         <button
                              onClick={() => handlePaymentDetails(item)}
                              className="bg-[#142850] hover:bg-blue-700 text-white px-2 py-1 rounded-md cursor-pointer"
                            >
                              İncele
                              <span className="sr-only">İncele</span>
                            </button>
                            {item.status ? (   <a
                              href={`${item.details.invoiceId}`}
                              target="_blank"
                              className={`${item.details.invoiceId  === null ? "bg-gray-200 text-gray-800 px-2 py-1 rounded-md" : "bg-[#142850] hover:bg-blue-700 text-white px-2 py-1 rounded-md cursor-pointer"} `}
                            >
                              {item.details.invoiceId === null ? "Fatura oluşturulamadı" : "Faturayı İndir"}
                            </a>) : (
                              <button 
                              disabled
                              className="px-2 py-1 font-normal rounded-md border-2">
                                Faturayı İndir
                              </button>
                            )}
                         
                         </div>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
        />
      </div>
      </div>
      {isOpen && (
        <AdminPaymentModal
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          selectedData={selectedData}
        />
      )}
    </div>
  );
}

export default AdminPayment;
