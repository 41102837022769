import React from 'react'
import { BiLink } from 'react-icons/bi'

function AdminSettings() {
  return (
    <>
    <div className="text-center mb-10 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-[#2196f3] font-bold">
         Ayarlar
        </h1>
      </div>
      <button
        type="button"
        className="inline-flex items-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-[#2196f3] hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-[#2196f3] focus:ring-offset-2"
      >
       Evraklarım
       <BiLink/>
      </button>

    </>
  )
}

export default AdminSettings