import axios from "axios";
import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { cityTest } from "../../../data/city.js";
import { townInCity } from "../../../data/townInCity.js";
import { ulkeTest } from "../../../data/country.js";
import Swal from "sweetalert2";
import { errors } from "../../../data/errors";
import { ColorRing } from "react-loader-spinner";

function AddUser() {
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("Şehir Seçiniz");
  const [town, setTown] = useState("İlçe Seçiniz");
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [country, setCountry] = useState("Ülke Seçiniz");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [buildingNo, setBuildingNo] = useState("");
  const [doorNo, setDoorNo] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [loading, setLoading] = useState(false);
  const addUser = async () => {
    setLoading(true);
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/Admin/User/AddUser`,
        data: {
          firstName: name,
          lastName: lastName,
          phoneNumber: phoneNumber,
          city: city,
          mailAddress: email,
          birthDate: birthday,
          identityNumber: idNumber,
          country: country,
          streetName: street,
          zipCode: zipCode,
          buildingNumber: buildingNo,
          doorNumber: doorNo,
        },
        headers: {
          Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          accept: "*/*",
          ContentType: "application/json-patch+json",
        },
      });
      setLoading(false);
      Swal.fire("Eklendi!");
      navigate("/adminpanel/kullanicilar");
    } catch (error) {
      setLoading(false);
      let isEqual = false;
      for (let index = 0; index < errors.length; index++) {
        if (error.response.data.details.errorCodes === errors[index].id) {
          isEqual = true;
          return toast.error(errors[index].content);
        } else if (error.response.data.details.errorCodes === "999") {
          isEqual = true;
          return toast.error(error.response.data.details.message);
        } else {
          isEqual = false;
        }
      }
      if (!isEqual) {
        toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
      }
    }
  };
  const clicked = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Yeni kullanıcı eklensin mi?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Vazgeç",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        addUser();
      }
    });
  };
  const firstNameInput = (e) => {
    setName(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^A-Za-z-ıöçğİüş" "]/gi, "");
    setName(value);
  };

  const lastNameInput = (e) => {
    setLastName(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^A-Za-z-ıöçğİüş" "]/gi, "");
    setLastName(value);
  };

  const phoneNumberInput = (e) => {
    setPhoneNumber(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^0-9""]/gi, "");
    setPhoneNumber(value);
  };

  const setIdNumberInput = (e) => {
    setIdNumber(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^0-9""]/gi, "");
    setIdNumber(value);
  };

  const setZipCodeInput = (e) => {
    setZipCode(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^0-9""]/gi, "");
    setZipCode(value);
  };

  return (
    <>
      <div className="items-start m-10">
        <div className="flex mb-5 mt-5 ml-8 md:ml-0">
          <NavLink
            to={`/adminpanel/kullanicilar`}
            className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
          >
            <lord-icon
              src="https://cdn.lordicon.com/zfxxckbp.json"
              trigger="loop"
              colors="primary:#4be1ec,secondary:#cb5eee"
            ></lord-icon>
            Geri
          </NavLink>
        </div>
        {loading ? (
          <div className="grid place-content-center shadow bg-grey-light text-center mt-3 text-md">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
            Yükleniyor...
          </div>
        ) : (
          <form onSubmit={(e) => clicked(e)}>
            <h2 className="text-green-500 text-center font-semibold mb-5 text-3xl">
              Yeni Kullanıcı
            </h2>
            <div className="flex justify-center mb-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-12 h-12"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="identity"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                T.C. Kimlik Numarası <br />
                <span className="ml-1 pt-0 text-xs text-red-500">*gerekli</span>
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    value={idNumber}
                    onChange={setIdNumberInput}
                    type="tel"
                    maxLength={11}
                    placeholder="11 haneli T.C kimlik numaranızı giriniz"
                    required
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                İsim <br />
                <span className="ml-1 pt-0 text-xs text-red-500">*gerekli</span>
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    value={name}
                    onChange={firstNameInput}
                    type="text"
                    name="username"
                    placeholder="İsim"
                    id="username"
                    required
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="lastname"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Soyisim <br />
                <span className="ml-1 pt-0 text-xs text-red-500">*gerekli</span>
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    value={lastName}
                    onChange={lastNameInput}
                    type="text"
                    placeholder="Soyisim"
                    name="lastname"
                    id="lastname"
                    required
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="mail"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Mail Adresi
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    value={email}
                    placeholder="Mail adresi"
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    name="mail"
                    id="mail"
                    required
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="tel"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Telefon Numarası <br />
                <span className="ml-1 pt-0 text-xs text-red-500">*gerekli</span>
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    // value={phoneNumber}
                    onChange={phoneNumberInput}
                    type="int"
                    maxLength={10}
                    placeholder="Başında 0 olmadan telefon numaranızı giriniz"
                    name="tel"
                    id="tel"
                    required
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Doğum Tarihi
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    value={birthday}
                    min="1923-01-01"
                    max="2022-12-31"
                    onChange={(e) => setBirthday(e.target.value)}
                    type="date"
                    name="date"
                    id="date"
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 mb-5">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Ülke
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <select
                  value={country}
                  placeholder="Ülke"
                  onChange={(e) => setCountry(e.target.value)}
                  id="country"
                  name="country"
                  required
                  autoComplete="country-name"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                >
                  <option>Ülke Seçiniz</option>
                  {ulkeTest?.map((country) => (
                    <option>{country.ulke}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 mb-5 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
              >
                Şehir
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <select
                  value={city}
                  placeholder="Ülke"
                  onChange={(e) => setCity(e.target.value)}
                  id="city"
                  name="city"
                  required
                  autoComplete="country-name"
                  className={
                    country === "Diğerleri"
                      ? "hidden w-full max-w-lg rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                      : "block w-full max-w-lg rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                  }
                >
                  <option>Şehir Seçiniz</option>
                  {cityTest
                    ?.filter((city) => city.ulke.includes(country))
                    .map((city) => (
                      <option>{city.il}</option>
                    ))}
                </select>
                {country === "Diğerleri" ? (
                  <label className="text-gray-400 text-base" value>
                    Ülke olarak "Diğerleri" seçtiğiniz için şehir seçmenize
                    gerek yoktur.
                  </label>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 mb-5">
              <label
                htmlFor="town"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
              >
                İlçe
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <select
                  value={town}
                  placeholder="Ülke"
                  onChange={(e) => setTown(e.target.value)}
                  required
                  id="town"
                  name="town"
                  autoComplete="town-name"
                  className={
                    country === "K.K.T.C." || country === "Diğerleri"
                      ? " w-full max-w-lg rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1 hidden"
                      : "block w-full max-w-lg rounded-md border-gray-300 shadow-sm border-[1px] px-2 py-1"
                  }
                >
                  <option>İlçe Seçiniz</option>
                  {townInCity
                    ?.filter((town) => town.il.includes(city))
                    .map((town) => (
                      <option>{town.ilce}</option>
                    ))}
                </select>
                {country === "Diğerleri" ? (
                  <label className="text-gray-400 text-base" value>
                    Ülke olarak "Diğerleri" seçtiğiniz için ilçe seçmenize gerek
                    yoktur.
                  </label>
                ) : null}
                {country === "K.K.T.C." ? (
                  <label className="text-gray-400 text-base" value>
                    Ülke olarak "K.K.T.C." seçtiğiniz için ilçe seçmenize gerek
                    yoktur.
                  </label>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="neighborhood"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Mahalle
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    value={neighborhood}
                    onChange={(e) => setNeighborhood(e.target.value)}
                    placeholder="Mahalle"
                    type="text"
                    name="neighborhood"
                    id="neighborhood"
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="street"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Sokak/Cadde
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    value={street}
                    placeholder="Sokak/Cadde"
                    onChange={(e) => setStreet(e.target.value)}
                    type="text"
                    name="street"
                    id="street"
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="buildingNo"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Apartman No
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    value={buildingNo}
                    placeholder="Apartman No"
                    onChange={(e) => setBuildingNo(e.target.value)}
                    type="text"
                    name="buildingNo"
                    id="buildingNo"
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="doorNo"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Daire No
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    value={doorNo}
                    placeholder="Daire No"
                    onChange={(e) => setDoorNo(e.target.value)}
                    type="text"
                    name="doorNo"
                    id="doorNo"
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="zipCode"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Posta Kodu
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm">
                  <input
                    value={zipCode}
                    placeholder="Posta Kodu"
                    onChange={setZipCodeInput}
                    maxLength={5}
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    className="block w-full p-1 text-gray-900 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <Link
                  to="/adminpanel/kullanicilar"
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Vazgeç
                </Link>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Kaydet
                </button>
                <ToastContainer />
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default AddUser;
