import axios from "axios";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/MainCategory`
const jwt =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNhYTY4OTgwLTg0N2ItNDNiNS1iZDU3LTdmODIzZjA4ZTkxMCIsIm5iZiI6MTY2OTI5MTkyOSwiZXhwIjoxNjY5ODk2NzI5LCJpYXQiOjE2NjkyOTE5Mjl9.U3dfPzQgigjQfMMuVY7wFn6Uy8nV6piOvbBgTbmZegk";
const bearer_token = `Bearer ${jwt}`;

export default axios.create({
    baseURL: BASE_URL,
    headers: {
        "accept": "text/plain",
        "Authorization": bearer_token,
    }
})

// MainCategory API 200 döneceği zaman çalışacak.