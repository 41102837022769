import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const EditUserCourse = () => {
  const [courses, setCourses] = useState("");
  const { id } = useParams();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const getUserCourses = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_BASE_URL}/User/Courses?userId=${id}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          },
        }
      );
      setCourses(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserCourses();
  }, []);
  return (
    <>
      <div className=" sm:justify-between mt-5">
        <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none">
            <Link to={`/adminpanel/kullanicilar/duzenle/${id}`}>
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border ml-3 border-gray-200 bg-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Geri
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2459/2459427.png"
                  alt="plus "
                  className="h-5 w-5 inline-flex ml-2"
                />
              </button>
            </Link>
            <Link
              to={`/adminpanel/kullanicilar/duzenle/kullaniciya-kursekle/${id}`}
            >
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border ml-3 border-gray-200 bg-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Yeni Kurs Ekle
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="-mx-4 mt-5 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 border-r border-white bg-blue-200 rounded-tl-md"
              >
                Kurs Adı
              </th>
              <th
                scope="col"
                className=" py-3.5 pl-4 pr-3 hidden lg:table-cell text-left text-sm font-semibold text-gray-900 sm:pl-6  border-r border-white bg-blue-200"
              >
                Ana Kategorisi
              </th>
              <th
                scope="col"
                className=" px-3 py-3.5 text-left  hidden lg:table-cell text-sm font-semibold text-gray-900  border-r border-white bg-blue-200"
              >
                Alt Kategorisi
              </th>
              <th
                scope="col"
                className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r border-white bg-blue-200"
              >
               Sertifika Durumu
              </th>
            </tr>
          </thead>
          <tbody>
            {!!courses.data &&
              courses.data?.map((course) => (
                <tr className="border-[1px] hover:bg-green-50" key={course.id}>
                  <td className="border-[1px]  bg-blue-100  border-white py-3 rounded-bl-md pl-2">
                    {course.name}
                  </td>
                  <td className="border-[1px] hidden lg:table-cell bg-blue-100 border-white pl-2">
                    {course.mainCategoryName}
                  </td>
                  <td className="border-[1px] hidden lg:table-cell bg-blue-100 border-white pl-2">
                    {course.subCategoryName}
                  </td>
                  <td className="border-[1px] bg-blue-100 border-white pl-2">
                    <Link
                    to={`/adminpanel/kullanicilar/duzenle/kursekle/${id}/detaylar/${course.id}`}
                      className="rounded-md font-medium text-[#2196f3] hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                      Detaylar
                    </Link>
                  </td>
                 
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EditUserCourse;
