import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../data/errors";
import {
  getAgreement,
  deleteAgreement,
} from "../../../store/features/AdminSlice";

function AdminAgreements() {
  const notify = () => toast.success("Silindi!");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAgreement(JSON.parse(jwtTokenRedux)));
  }, []);
  const agreements = useSelector((state) => state.AdminSlice.agreements);

  const handleClick = (id) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            await dispatch(
              deleteAgreement({ data: JSON.parse(jwtTokenRedux), id })
            );
            dispatch(getAgreement(JSON.parse(jwtTokenRedux)));
            notify();
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes == errors[index].id) {
                let isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes == 999) {
                let isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                let isEqual = true;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        submit();
      }
    });
  };

  const agreementModal = (data, name) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: <h2>{name}</h2>,
      html: (
        <div
          dangerouslySetInnerHTML={{ __html: data }}
          className="prose mt-3 text-base text-justify px-2"
        />
      ),
      width: 600,
    });
  };

  return (
    <div className="mb-20">
      <div className="flex mb-5 mt-5 ml-8 md:ml-0">
        <NavLink
          to={`/adminpanel`}
          className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
        >
          <lord-icon
            src="https://cdn.lordicon.com/zfxxckbp.json"
            trigger="loop"
            colors="primary:#4be1ec,secondary:#cb5eee"
          ></lord-icon>
          Geri
        </NavLink>
      </div>
      <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Sözleşmeler
        </h1>
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none">
            <Link to={"/adminpanel/sozlesmeler/ekle"}>
              <button
                type="button"
                className="items-center bg-[#142850] rounded-md border shadow-xl border-gray-200 text-white px-2 py-2 text-md font-medium focus:z-10 focus:border-blue-700 hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-700"
              >
                Yeni Ekle +
              </button>
            </Link>
          </div>
        </div>

        <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Başlık
                </th>
                <th
                  scope="col"
                  className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:table-cell"
                >
                  Versiyon
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Tarih
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Tür
                </th>

                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Seç</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {!!agreements &&
                agreements?.map((agreement) => (
                  <tr key={agreement.id}>
                    <td className="border-t relative py-4 pl-4 sm:pl-6 pr-3 text-sm hover:underline hover:text-gray-700">
                      <a
                        className="font-medium cursor-pointer"
                        onClick={() =>
                          agreementModal(agreement.data, agreement.name)
                        }
                      >
                        {agreement.name}
                      </a>
                      <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                        <span>{agreement.created.slice(8,10)}.{agreement.created.slice(5,7)}.{agreement.created.slice(0,4)}</span>
                      </div>
                    </td>
                    <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-800 lg:table-cell">
                      {agreement.version}
                    </td>
                    <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                    {agreement.created.slice(8,10)}.{agreement.created.slice(5,7)}.{agreement.created.slice(0,4)}
                    </td>
                    <td className="border-t border-gray-200 hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                    {agreement.isMandatory ? "Zorunlu" : "İstege bağlı"}
                    </td>

                    <td className="border-t space-x-6 space-y-2 relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-light">
                      <Link
                        to={`/adminpanel/sozlesmeler/duzenle/${agreement.id}`}
                      >
                        <button
                          type="button"
                          className="rounded-md text-white bg-[#142850] hover:bg-blue-700 px-2 py-1"
                        >
                          Düzenle
                        </button>
                      </Link>
                      <button
                        onClick={() => handleClick(agreement.id)}
                        type="button"
                        className="rounded-md text-white bg-[#142850] hover:bg-blue-700 px-2 py-1"
                      >
                        Sil
                      </button>
                      <ToastContainer />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminAgreements;
