import React from 'react'

function AddDocumentModal({courseDoc, setModalOn}) {
    const handleOKClick = () => {
        setModalOn(false);
      };
  return (
    <div className="opacity-100 absolute backdrop-blur sm:-inset-10 inset-0 z-50 overflow-y-auto">
      <div className="m-16 p-14">
        <div className=" flex flex-col border-2 border-gray-400 bg-white pt-6 pb-10 sm:rounded-lg sm:pb-12 lg:py-10 lg:px-5 mx-20 my-5">
          <div className="flex justify-center px-4 sm:px-6 lg:px-8 mb-5">
            <h2 className="text-2xl font-semibold tracking-wide text-gray-900">
              Döküman Önizleme
            </h2>
          </div>

          {courseDoc && courseDoc?.map((item) => (
              <div className="flex justify-center align-center">
                <embed
                  width="200"
                  height="500"
                  src={`${item?.documentPath}`}
                  alt="dokuman"
                />
              </div>
            ))}

          <div className="mt-8 flex justify-end px-4 sm:px-6 lg:px-8">
            <button
              onClick={handleOKClick}
              type="submit"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-[#2196f3] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
            >
              Tamam
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddDocumentModal