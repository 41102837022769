import React from "react";
import img2 from "../../../assets/bin.png";

const posts = [
  {
    title: "Boost your conversion rate",
    href: "#",
    category: { name: "Video", href: "#" },
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius.",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    imageUrl:
      "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "6 dk",
  },
  {
    title: "How to use search engine optimization",
    href: "#",
    category: { name: "Video", href: "#" },
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore.",
    date: "Mar 10, 2020",
    datetime: "2020-03-10",
    imageUrl:
      "https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "4 dk",
  },
  {
    title: "Improve your customer experience",
    href: "#",
    category: { name: "Video", href: "#" },
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint recusandae magni placeat saepe molestiae, sed excepturi cumque hic.",
    date: "Feb 12, 2020",
    datetime: "2020-02-12",
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "11 dk",
  },
];
function AddVideos() {
  return (
    <>
     <span className="mt-5 block text-center text-3xl font-bold leading-8 tracking-tight text-orange-600 sm:text-4xl">
       Kurs Adı
     </span>
      <form
        method="post"
        encType="multipart/form-data"
        className="mt-8 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
      >
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-medium text-[#2196f3]"
            >
              <span>Video Yükle</span>
              <input
                id="file-upload"
                name="file-upload"
                accept="video/mp4,video/x-m4v,video/*"
                type="file"
                className="sr-only"
              />
            </label>
            <p className="pl-1">ya da sürükle ve bırak</p>
          </div>
          <p className="text-xs text-gray-500">
            video/mp4
          </p>
        </div>
      </form>

      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:mt-5 sm:ml-auto sm:flex-none">
          <button
            type="button"
            className="items-center rounded-md border border-gray-400 shadow-md bg-white px-4 py-2 text-md font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
          >
            Yeni Ekle
          </button>
        </div>
      </div>


      <div className="relative bg-gray-50 px-4 pb-20 sm:px-6 lg:px-8 lg:pb-28">
        <h1 className="text-md font-semibold">Mevcut Videolar</h1>
        <div className="relative mx-auto max-w-7xl">
          <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
            {posts.map((post, i) => (
              <div
                key={i}
                className="flex flex-col overflow-hidden rounded-lg shadow-lg"
              >
                <div className="flex shrink justify-center">
                  <iframe
                    className="top-2 border"
                    src="https://player.vimeo.com/video/765901446?h=720cd3d546&badge=0&autopause=0&player_id=0&app_id=58479/embed"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    title="Video"
                  ></iframe>
                </div>
                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-[#2196f3]">
                      <a href={post.category.href} className="hover:underline">
                        {post.category.name}
                      </a>
                    </p>
                    <a href={post.href} className="mt-2 block">
                      <p className="text-xl font-semibold text-gray-900">
                        {post.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {post.description}
                      </p>
                    </a>
                  </div>
                  <div className="mt-6 flex items-center">
                    <div className="ml-3">
                      <div className="flex space-x-1 text-sm text-gray-500">
                        <time dateTime={post.datetime}>{post.date}</time>
                        <span aria-hidden="true">&middot;</span>
                        <span>{post.readingTime}</span>
                      </div>
                      <button
                        type="button"
                        className="rounded-md bg-white text-lg mt-3 text-[#2196f3] hover:text-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 hover:scale-125"
                      >
                        Sil
                        <img
                          src={img2}
                          alt=""
                          className="h-4 w-4 inline-flex ml-2"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddVideos;
