import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import  Swal  from "sweetalert2/dist/sweetalert2";
import { errors } from "../../../data/errors";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AdminAnnouncementsComments() {
  const [comments, setComments] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const coursesPerPage = 10;
  const [dataCount, setDataCount] = useState(null);
  const pageCount = Math.ceil(dataCount / coursesPerPage);
  const jwtToken = useSelector((state) => state.login.accessToken);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0)

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios(
          `${
            process.env.REACT_APP_BASE_URL
          }/Admin/Comment/AnnouncementComments?PageNumber=${pageNumber + 1}`,
          {
            headers: {
              accept: "text/plain",
              Authorization: `Bearer ${JSON.parse(jwtToken)}`,
            },
          }
        );
        setDataCount(response.data.metaData.dataCount);
        setComments(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchComments();
  }, [reducerValue , pageNumber]);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const deleteComment = (id) => {
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            axios.delete(`${process.env.REACT_APP_BASE_URL}/Admin/AnnouncementComment/DeleteAnnouncementComment?id=${id}`,
            {
              headers: {
                accept: "text/plain",
                Authorization: `Bearer ${JSON.parse(jwtToken)}`,
              },
            });
            const newComments = comments.filter(
              (comment) => comment.id !== id
            );
            setComments(newComments);
            forceUpdate();
            toast.success("Silindi!");
          } catch (error) {
            console.log(error);
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
               isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === "999") {
               isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        }
        submit();
      }
    })
  };

  const handleCheck = async (id) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/Admin/AnnouncementComment/ChangeAnnouncementCommentStatus?id=${id}`,
        headers:{
          accept: "text/plain",
          Authorization: `Bearer ${JSON.parse(jwtToken)}`,
        }
      });
      forceUpdate();
      toast.success("Değiştirildi!");
    } catch (error) {
      console.log(error);
      toast.error("Hata");
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <h1 className="text-lg px-5 py-4 text-[#2196f3] font-bold">
        Duyuru Yorumları
      </h1>
      <div className="flex justify-center md:justify-end mb-3">
        <div className="mt-1 flex md:flex-none">
          <Link
            to= {`/duyurular`}
            className="items-center rounded-lg shadow-xl border border-gray-200 bg-[#142850] px-3 py-1 text-md font-light hover:bg-blue-700 text-white focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-700"
          >
            Tüm Duyurular
          </Link>
        </div>
      </div>

      <div className="divide-y divide-x divide-gray-200 overflow-hidden rounded-lg border-2 bg-gray-200 shadow sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-px">
        {comments &&
          comments?.map((item, commentsIdx) => (
            <div
              key={item.id}
              className={classNames(
                commentsIdx === 0
                  ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                  : "",
                commentsIdx === 1 ? "sm:rounded-tr-lg" : "",
                commentsIdx === comments.length - 2 ? "sm:rounded-bl-lg" : "",
                commentsIdx === comments.length - 1
                  ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                  : "",
                "relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-700 hover:bg-blue-100"
              )}
            >
              <div className="rounded-lg inline-flex p-3">
                {item.created.slice(8, 10)}.{item.created.slice(5, 7)}.
                {item.created.slice(0, 4)}
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() => deleteComment(item.id)}
                  type="button"
                  className="rounded-md font-normal border-2 hover:bg-[#142850] hover:text-white px-2"
                >
                  Sil
                  <span className="sr-only">Sil</span>
                </button>
                <ToastContainer />
              </div>
              <div className="flex justify-start align-center items-center gap-x-2">
                <input
                  className="h-4 w-4 pb-0 rounded border-gray-300 text-[#142850] focus:ring-blue-700"
                  type="checkbox"
                  checked={item.isAvailable}
                />
                <button
                onClick={() =>handleCheck(item.id)}
                  type="button"
                  className="rounded-md cursor-pointer font-medium text-[#142850] hover:text-blue-700 hover:underline"
                >
                  Görünürlük
                  <span className="sr-only">Görünürlük</span>
                </button>
              </div>
              <div className="mt-5">
                <h3 className="text-lg font-medium">
                  {item.firstName} {item.lastName}
                </h3>
                <span className="text-gray-500 text-xs">{item.email}</span>
                <br />
                <span className="text-gray-500 text-xs">
                  {item.phoneNumber}
                </span>

                <p className="mt-2 text-sm text-gray-500">{item.comment}</p>
              </div>

              <Link
                to={`/duyurular`}
                className="absolute top-6 right-6 cursor-pointer text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          ))}
      </div>
      <div className="mt-5">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
        />
      </div>
    </div>
  );
}

export default AdminAnnouncementsComments;
