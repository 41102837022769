import React, { useState, useEffect, useReducer } from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { Link, NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import EditUserPageButtons from "./EditUserPageButtons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PostUserDocModal from "./PostUserDocModal";
import { TiTick } from "react-icons/ti";
import { errors } from "../../../data/errors";
import { GrValidate } from "react-icons/gr";
import { BsPatchQuestion } from "react-icons/bs";
import { deleteUserDocument } from "../../../store/features/UserSlice";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getUserDocuments,
  postUserDocument,
} from "../../../store/features/AdminSlice";
import { ColorRing } from "react-loader-spinner";

function EditUserPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [modalOn, setModalOn] = useState(false);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [userData, setUserData] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [document, setDocument] = useState("");
  const [loading, setLoading] = useState("");
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  let bodyFormData = new FormData();
  bodyFormData.append("UserId", id);
  bodyFormData.append("DocumentId", documentId);
  bodyFormData.append("Document", document);
  function clicked(e, id) {
    e.preventDefault();
    setModalOn(true);
    setDocumentId(id);
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/User/UserDetails?userId=${id}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            },
          }
        );
        setUserData(response.data.data);
      } catch (error) {
        let isEqual = false;
        for (let index = 0; index < errors.length; index++) {
          if (error.response.data.details.errorCodes === errors[index].id) {
           isEqual = true;
            return toast.error(errors[index].content);
          } else if (error.response.data.details.errorCodes === 999) {
             isEqual = true;
            return toast.error(error.response.data.details.message);
          } else {
            isEqual = false;
          }
        }
        if (!isEqual) {
          toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
        }
      }
    };
    fetchUserData();
  }, [id, jwtTokenRedux,dispatch]);

  useEffect(() => {
    dispatch(getUserDocuments({ jwt: JSON.parse(jwtTokenRedux), userId: id }));
  }, [reducerValue, jwtTokenRedux, dispatch, id]);
  
  const userDocuments = useSelector(
    (state) => state.AdminSlice.getUserDocuments
  );

  const getDefaults = async (e) => {
    e.preventDefault();
    try {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_BASE_URL}/Admin/User/AddDefaultDocuments?userId=${id}`,
        headers: {
          Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
          accept: "*/*",
        },
      });
      forceUpdate();
    } catch (error) {
      let isEqual = false;
      for (let index = 0; index < errors.length; index++) {
        if (error.response.data.details.errorCodes === errors[index].id) {
          isEqual = true;
          return toast.error(errors[index].content);
        } else if (error.response.data.details.errorCodes === 999) {
          isEqual = true;
          return toast.error(error.response.data.details.message);
        } else {
          isEqual = false;
        }
      }
      if (!isEqual) {
        toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
      }
    }
  };

  const handleUpload = () => {
    Swal.fire({
      title: "Belge yüklensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          setLoading(true);
          try {
            await dispatch(
              postUserDocument({
                jwt: JSON.parse(jwtTokenRedux),
                bodyFormData: bodyFormData,
              })
            );
            dispatch(
              getUserDocuments({ jwt: JSON.parse(jwtTokenRedux), userId: id })
            );
            toast.success("İşlem başarılı!");
            setLoading(false);
          } catch (response) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (
                response.response.data.details.errorCodes === errors[index].id
              ) {
              isEqual = true;
                return toast.error(errors[index].content);
              } else if (response.response.data.details.errorCodes === 999) {
               isEqual = true;
                return toast.error(response.response.data.details.message);
              } else {
               isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        submit();
      }
    });
  };

  const userDocDisplay = (e, path, name) => {
    Swal.fire({
      text: name,
      imageUrl: path,
      width: "60%",
      imageWidth: 800,
      imageHeight: 1000,
      imageAlt: 'belge-örneği',
      confirmButtonText: "Tamam"
    })
  };

  const handleDelete = (e, id, userId) => {
    e.preventDefault();
    Swal.fire({
      title: "Silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          try {
            await dispatch(
              deleteUserDocument({
                jwt: JSON.parse(jwtTokenRedux),
                documentId: id,
                userId: userId,
              })
            );
            toast.success("Silindi!");
            forceUpdate();
          } catch (error) {
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
               isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === 999) {
                isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        submit();
      }
    });
  };

  return (
    <div className="relative">
      <div className="text-center mb-10 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-[#2196f3] font-bold">
          Kullanıcı Bilgileri
        </h1>
      </div>

      <div className="flex justify-center flex-wrap px-3 gap-5 mb-5">
        <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none ">
            <NavLink to={`/adminpanel/kullanicilar`}>
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border border-gray-200 bg-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Geri
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2459/2459427.png"
                  alt="plus "
                  className="h-5 w-5 inline-flex ml-2"
                />
              </button>
            </NavLink>
          </div>
        </div>
        <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto sm:flex-none ">
            <NavLink
              to={`/adminpanel/kullanicilar/duzenle/kullanici-ayarlari/${id}`}
            >
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border border-gray-200 bg-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Kullanıcı Ayarları
                <img
                  src="https://cdn-icons-png.flaticon.com/512/1769/1769328.png"
                  alt="plus "
                  className="h-5 w-5 inline-flex ml-2"
                />
              </button>
            </NavLink>
          </div>
        </div>
        <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto ">
            <Link to={`/adminpanel/kullanicilar/siparisgecmisi/${id}`}>
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border border-gray-200 bg-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Sipariş Geçmişi
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2822/2822682.png"
                  alt="plus"
                  className="h-5 w-5 inline-flex ml-2"
                />
              </button>
            </Link>
          </div>
        </div>
        <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto  ">
            <Link to={`/adminpanel/kullanicilar/kullanicihareketleri/${id}`}>
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border border-gray-200 bg-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Kullanıcı Hareketleri
                <img
                  src="https://cdn-icons-png.flaticon.com/512/4289/4289598.png"
                  alt="plus "
                  className="h-5 w-5 inline-flex ml-2"
                />
              </button>
            </Link>
          </div>
        </div>

        <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto sm:mx-0">
          <div className="mt-4 sm:mt-0 sm:ml-auto  ">
            <Link to={`/adminpanel/kullanicilar/duzenle/${id}/roller`}>
              <button
                type="button"
                className="items-center rounded-lg shadow-xl border border-gray-200 bg-white w-64 px-4 py-3.5 text-md font-medium hover:bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                Kullanıcı Rolleri
                <img
                  //todo: image değişecek
                  src="https://cdn-icons-png.flaticon.com/512/4289/4289598.png"
                  alt="plus "
                  className="h-5 w-5 inline-flex ml-2"
                />
              </button>
            </Link>
          </div>
        </div>

        
      </div>
      {!!userData &&
        userData?.map((user) => (
          <div className="user-info">
            {user.isConfirmed ? (
              <span className="text-sm mt-2 font-semibold tracking-wide text-green-500 flex items-center align-middle gap-x-2">
                <GrValidate className="mb-0" /> Onaylanmış hesap
              </span>
            ) : (
              <span className="text-sm mt-2 font-semibold tracking-wide text-red-500 flex items-center align-middle gap-x-2">
                <BsPatchQuestion className="mb-0" /> Onaylanmamış hesap! Kullanıcı ayarlarından kimlik
                numarasını kontrol edip bilgileri onaylayınız.
              </span>
            )}

            <div className="mt-5 border-t border-gray-200">
              <dl className="divide-y divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">İsim</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{user.firstName}</span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Soyisim</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{user.lastName}</span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    T.C. Kimlik No
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{user.identityNumber}</span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Kullanıcı Adı
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{user.userName}</span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Mail Adresi
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{user.email}</span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Telefon Numarası
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{user.phoneNumber}</span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Şehir</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{user.city}</span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Kursları
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-shrink-0">
                      <NavLink
                        type="button"
                        to={`/adminpanel/kullanicilar/duzenle/kursekle/${id}`}
                        className="text-sm px-2 py-1 m-1 rounded-md bg-gray-500 shadow-md text-white hover:bg-blue-200 hover:text-black"
                      >
                        Kursları Görüntüle
                      </NavLink>
                    </span>
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Belgeler
                  </dt>
                  <div className="grid-col">
                    <button
                      onClick={(e) => getDefaults(e)}
                      className="text-sm py-1 px-2 m-1 rounded-md bg-gray-500 shadow-md text-white hover:bg-blue-200 hover:text-black"
                    >
                      Belgeleri Getir
                    </button>
                  </div>

                  {!!userDocuments &&
                    userDocuments?.map((doc) => (
                      <dd
                        key={doc.id}
                        className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 "
                      >
                        <ul
                          className="divide-y divide-gray-200 rounded-md border border-gray-200"
                        >
                          <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                            <span className="mr-2">
                              {doc.path === "" ? (
                                ""
                              ) : (
                                <TiTick className="text-green-400" />
                              )}
                            </span>
                            <div className="flex w-0 flex-1 items-center">
                              <PaperClipIcon
                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-2 w-0 flex-1 truncate">
                                {doc.document.name}
                              </span>
                            </div>
                            <div className="ml-4 flex flex-shrink-0 space-x-2">
                              <button
                                onClick={(e) => userDocDisplay(e, doc.path, doc.document.name)}
                                type="button"
                                className="rounded-md bg-white text-[#2196f3] hover:text-blue-700"
                              >
                                Aç
                              </button>
                              <span
                                className="text-gray-300"
                                aria-hidden="true"
                              >
                                |
                              </span>
                              <button
                                onClick={(e) => clicked(e, doc.document.id)}
                                type="button"
                                className="rounded-md bg-white text-[#2196f3] hover:text-blue-700"
                              >
                                Yükle
                              </button>

                              <ToastContainer />
                              <span
                                className="text-gray-300"
                                aria-hidden="true"
                              >
                                |
                              </span>
                              <button
                                onClick={(e) =>
                                  handleDelete(e, doc.document.id, doc.userId)
                                }
                                type="button"
                                className="rounded-md bg-white font-medium text-[#2196f3] hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
                              >
                                Sil
                              </button>
                            </div>
                          </li>
                        </ul>
                      </dd>
                    ))}
                </div>
              </dl>
            </div>
            {loading && (
              <div className="grid place-content-center shadow bg-grey-light text-center mt-3">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClassName="blocks-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
                <div className="text-sm text-center"> Yükleniyor...</div>
              </div>
            )}
          </div>
        ))}

      <EditUserPageButtons userData={userData} />

      {modalOn && (
        <PostUserDocModal
          setDocument={setDocument}
          setModalOn={setModalOn}
          action={handleUpload}
        />
      )}
    </div>
  );
}

export default EditUserPage;
