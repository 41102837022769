import React from "react";
import LoginComp from "../components/Login/LoginComp";
import { Helmet } from "react-helmet-async";

const Login = ({ checkAdmin, checkUser }) => {
  return (
    <>
      <Helmet>
        <title>Giriş| Unvan Eğitim Kurumları</title>
        <meta
          data-react-helmet="true"
          name="description"
          content="Unvan Eğitim Kurumları Giriş Sayfası. Öğrenci/Eğitmen Paneli için kullanıcı adı ve şifrenizi yazın. Üniversite veya MEB onaylı sertifikalı eğitimlerimizle ihtiyaç duyduğunuz becerileri öğrenin ve kariyerinizi ilerletin. Kurslarımız, iş dünyasının taleplerine uygun olarak tasarlanmıştır ve sektördeki uzmanlar tarafından verilmektedir. İşletme, dijital pazarlama, programlama ve daha birçok alanda sertifika programlarımıza göz atın ve kariyerinizi güçlendirin."
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content="giriş yap, kullanıcı giriş, eğitmen giriş, öğrenci giriş, üniversite sertifikalı eğitimler, meb sertifikalı eğitimler,,kariyer eğitimleri, sertifikalı kurslar, işletme eğitimi, dijital pazarlama kursları, programlama eğitimleri, sektörel eğitimler"
        />
        <meta
          data-react-helmet="true"
          name="copyright"
          content="© 2023 Unvan Eğitim Kurumları. Tüm hakları saklıdır. Web sitemizde yer alan tüm içerikler, telif hakkı kanunlarıyla korunmaktadır. İzinsiz kullanımı yasaktır."
        />
      </Helmet>
      <LoginComp checkAdmin={checkAdmin} checkUser={checkUser} />
    </>
  );
};

export default Login;
