import React, { useEffect, useState } from "react";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  getUserCertificate,
  getUserProfile,
} from "../../../store/features/UserSlice";

const UsersCertificate = () => {
  // const [userCertificates, setUserCertificates] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile(JSON.parse(jwtTokenRedux)));
  }, []);

  useEffect(() => {
    const fetchUserCertificates = async () => {
      try {
        dispatch(getUserCertificate(JSON.parse(jwtTokenRedux)));
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserCertificates();
  }, []);
  const userCertificates = useSelector(
    (state) => state.userSlice.usersCertificate
  );
  const CertificateContent = () => {
    if (userCertificates.length === 0) {
      return (
        <>
          <div className="bg-white">
            <div className="mx-auto max-w-7xl py-16 px-6 lg:px-8">
              <div className="overflow-hidden rounded-lg bg-[#142850] shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
                <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                  <div className="lg:self-center">
                    <h2 className="text-white">
                      <span className="block text-lg">
                        Henüz bir sertifikanız yok.
                      </span>
                      <span className="block font-bold text-2xl">
                        Kariyerinzi geliştirmeye bugün başlayın!
                      </span>
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-indigo-200">
                      Yetkin olduğunuz alanlarda yeteneklerinizi belgeleyin ya
                      da ilk defa eğitim alacağınız alanlar için videoları
                      izleyin. Eğitim sonrası sınav sonucunuza göre
                      sertifikanızı alın!
                    </p>
                    <Link
                      to="/kullanicipaneli/kurslarim"
                      className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-[#142850] shadow hover:bg-blue-200"
                    >
                      Kurslarıma Git
                    </Link>
                  </div>
                </div>
                <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
                  <img
                    className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                    src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                    alt="App screenshot"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {isLoading ? (
            <h1>Yükleniyor...</h1>
          ) : (
            !!userCertificates &&
            userCertificates?.map((item) => (
              <>
                {item.path === null ? (
                  <div
                    key={item.id}
                    className="h-40 w-full grid grid-cols-6 p-3 gap-3 mb-3 border-b border-gray-200"
                  >
                    <div className="col-span-1 ">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/3172/3172183.png"
                        alt=""
                        className="h-28 mx-auto mobileimage hover:scale-110"
                      />
                    </div>
                    <div className="col-span-5  p-3 ">
                      <div className="text-2xl font-bold text-[#142850]">
                        {item.course.name}
                      </div>
                      <p className="text-sm tracking-wide">
                        Sertifikanız kursu tamamladığınızda yüklenecektir.
                      </p>
                      <div className="mt-3 flex items-center cursor-pointer text-gray-500">
                        <div className="flex items-center bg-blue-200 rounded-md p-1">
                          <p className="text-sm mr-2 mb-0">İncele</p>
                          <AiOutlineEyeInvisible size={28} color="#142850" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={item.id}
                    className="h-40 w-full grid grid-cols-6 p-3 gap-3 mb-3 border-b border-gray-200"
                  >
                    <div className="col-span-1 ">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/8681/8681457.png"
                        alt=""
                        className="h-32 mx-auto mobileimage hover:scale-110"
                      />
                    </div>

                    <div className="col-span-5  p-3 ">
                      <div className="text-2xl font-bold text-[#142850]">
                        {item.course.name}
                      </div>
                      <Link to={`/kullanicipaneli/sertifikalarim-${item.id}`}>
                        <div className="mt-3 flex items-center cursor-pointer">
                          <div className="flex items-center hover:bg-blue-200 rounded-md p-1">
                            <p className="text-sm mr-2 mb-0 hover:text-red-600">
                              İncele
                            </p>
                            <AiFillEye size={28} color="#142850" />
                          </div>
                        </div>
                      </Link>
                      <div className="text-gray-400 mt-3">
                        Veriliş Tarihi: {item.created}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))
          )}
        </>
      );
    }
  };
  return (
    <>
      <div className="w-[calc(100%-3.73rem)] ">
        <div className="2xl:container mx-auto space-y-3 py-3 ">
          <div className="mx-auto  bg-white rounded-xl px-1.5">
            <div className=" bg-[#142850] rounded-lg mb-6">
              <p className="text-white pl-5 text-3xl py-1  font-bold	text-center">
                Sertifikalarım
              </p>
            </div>
            <div className="flex mt-5">
            <NavLink
              to={`/kullanicipaneli/anasayfa`}
              className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
            >
              <lord-icon
                src="https://cdn.lordicon.com/zfxxckbp.json"
                trigger="loop"
                colors="primary:#4be1ec,secondary:#cb5eee"
              ></lord-icon>
              Geri
            </NavLink>
          </div>
            <div className="sm:flex sm:items-center mb-2 col-span-12 md:col-span-6  mx-auto">
              <div className="mt-4 mx-auto sm:flex ">
                <Link to={"/kullanicipaneli/kurslarim"}>
                  <button
                    type="button"
                    className="items-center rounded-lg shadow-xl border border-gray-200 bg-[#142850] w-64 px-2 py-2 text-md font-medium hover:bg-blue-100 text-white focus:z-10 hover:text-[#142850] focus:ring-2 focus:ring-blue-500"
                  >
                    Kurslarıma Git
                  </button>
                </Link>
              </div>
            </div>
            <CertificateContent />
            <hr className="w-full mb-5" />
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersCertificate;
