import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../data/errors";

function EditAgreements() {
  const { id } = useParams();
  const notifyError = () => toast.error("İşlem başarısız");
  const [name, setName] = useState("");
  const [info, setInfo] = useState("");
  const [version, setVersion] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const postingData = {
    name: name,
    data: info,
    version: version,
    isMandatory: true,
  };
  useEffect(() => {
    const fetchAgreements = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/Agreement/${id}`,
          {
            headers: {
              accept: "text/plain",
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            },
          }
        );
        setName(response.data.data[0].name);
        setInfo(response.data.data[0].data);
        setVersion(response.data.data[0].version);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAgreements();
  }, []);

  const editAgreement = () => {
    Swal.fire({
      title: "Sözleşme güncellensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          setLoading(true);
          try {
            await axios({
              method: "patch",
              url: `${process.env.REACT_APP_BASE_URL}/Agreement?id=${id}`,
              data: postingData,
              headers: {
                ContentType: "application/json-patch+json",
                accept: "text/plain",
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
              },
            });
            setLoading(false);
            Swal.fire("Güncellendi!");
            navigate("/adminpanel/sozlesmeler");
          } catch (error) {
            setLoading(false);
            let isEqual =false;
            for (let index = 0; index < errors.length; index++) {
              if (error.response.data.details.errorCodes === errors[index].id) {
                let isEqual = true;
                return toast.error(errors[index].content);
              } else if(error.response.data.details.errorCodes === 999) {
                let isEqual= true;
                return toast.error(error.response.data.details.message);
              } 
              else {
                let isEqual = false;
              }
            }
            if (!isEqual) {
             toast.error("Bilinmeyen bir hata oluştu! Bizimle iletişime geçin.");
            }
          }
        };
        submit();
      }
    });
  };

  return (
    <div>
      <div className="w-full min-h-[90vh] grid gap-x-20 gap-y-20 justify-items-center">
        <div className="w-2/3 items-start m-10 shadow-lg border-2 rounded-lg p-5">
          {!loading && (
            <form>
              <h2 className="text-blue-900 text-left font-semibold mb-5 text-2xl">
                Sözleşme Düzenle
              </h2>

              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                <label
                  htmlFor="name"
                  className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                >
                  BAŞLIK
                </label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text"
                  name="Tittle"
                  id=""
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                />
              </div>

              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                <label
                  htmlFor="name"
                  className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                >
                  Versiyon
                </label>
                <input
                  onChange={(e) => setVersion(e.target.value)}
                  value={version}
                  type="text"
                  name="version"
                  id=""
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                />
              </div>

              <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
                <h2 className="text-base font-medium tracking-tight text-gray-600 mb-2">
                  İçerik
                </h2>
                <CKEditor
                  data={info}
                  value={info}
                  editor={Editor}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setInfo(data);
                  }}
                />
              </div>
            </form>
          )}
          {loading && (
            <div className="grid place-content-center shadow bg-grey-light text-center mt-3 text-md">
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
              Yükleniyor...
            </div>
          )}

          <button
            onClick={editAgreement}
            type="submit"
            className="inline-flex shadow-xl items-center mx-auto rounded-md border-2 bg-blue-900 
            px-4 py-2 m-5 text-sm font-medium text-white hover:text-blue-900 hover:bg-blue-300 focus:outline-none 
            focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
          >
            Ekle
          </button>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default EditAgreements;
