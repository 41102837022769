

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { addCourseDocument,getCourseDocuments} from "../../../../store/features/AdminSlice";
import { errors } from "../../../../data/errors";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2/dist/sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import AddDocumentToCourseModal from "./AddDocumentModal";

function EducatorUpdateCourseDocuments() {
    
    const { id }  = useParams();
    const dispatch = useDispatch();
    const courseId = id;
    const jwtToken = useSelector((state) => state.login.accessToken);
    const [document, setDocument] = useState("");
    const [courseDoc, setCourseDoc] = useState("");
    const [modalOn, setModalOn] = useState(false);
    const [loading, setLoading] = useState(false);
    const refresh = () => window.location.reload(true);
    let bodyFormData = new FormData();
    bodyFormData.append("CourseId", courseId);
    bodyFormData.append("Documents", document);
    useEffect(() => {
      dispatch(getCourseDocuments({ jwt: JSON.parse(jwtToken), courseId }));
    }, []);
  
    const courseDocument = useSelector(
      (state) => state.AdminSlice.getCourseDocuments
    );
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      Swal.fire({
        title: "Döküman kursa eklensin mi?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Vazgeç",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet",
      }).then((result) => {
        if (result.isConfirmed) {
          const submit = async () => {
            setLoading(true);
            try {
              await dispatch(
                addCourseDocument({
                  jwt: JSON.parse(jwtToken),
                  bodyFormData: bodyFormData,
                })
              );
              setLoading(false);
              toast.success("Eklendi!");
            } catch (error) {
              setLoading(false);
              let isEqual = false;
              for (let index = 0; index < errors.length; index++) {
                if (error.response.data.details.errorCodes === errors[index].id) {
                  let isEqual = true;
                  return toast.error(errors[index].content);
                } else if (error.response.data.details.errorCodes === 999) {
                  let isEqual = true;
                  return toast.error(error.response.data.details.message);
                } else {
                  let isEqual = false;
                }
              }
              if (!isEqual) {
                toast.error("Bilinmeyen hata. Bizimle iletişime geçiniz. ");
              }
            }
          };
          submit();
        }
      });
      // refresh();
    };
  
    const handleClick = (e, doc) => {
      e.preventDefault();
      setCourseDoc(doc);
      setModalOn(true);
    }
    return (
    
        <>
        <div className="flex mb-5 mt-5 ml-8 md:ml-0">
                <NavLink
                to={`/egitmenpanel/kurslarim/duzenle/${id}`}
                className="flex text-lg items-center text-[#2196f3] hover:underline border-2 shadow-sm rounded-md pr-1"
                >
                <lord-icon
                    src="https://cdn.lordicon.com/zfxxckbp.json"
                    trigger="loop"
                    colors="primary:#4be1ec,secondary:#cb5eee"
                ></lord-icon>
                Geri
                </NavLink>
            </div>

            <div className="text-center mb-5 border-b">
        <h1 className="text-3xl md:text-4xl px-5 py-4 text-black font-bold">
          Kurs Dökümanları
        </h1>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 mt-10">
        <label
          htmlFor="pdf"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Döküman Ekle
        </label>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-medium text-gray-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-gray-500 focus-within:ring-offset-2 hover:text-blue-500"
                >
                  <span>Dosya yükle ya da sürükleyip bırakın</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="file:hidden text-xs pl-36"
                    onChange={(e) => setDocument(e.target.files[0])}
                  />
                </label>
              </div>
              <p className="text-xs text-gray-500">PNG, JPG, PDF</p>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="grid place-content-center shadow bg-grey-light text-center mt-3 text-md">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
          Yükleniyor...
        </div>
      )}

      <div className="flex justify-center mt-5 mb-10">
        <button
          onClick={(e) => handleSubmit(e)}
          className="items-center rounded-lg shadow-xl border ml-3 border-gray-300 hover:bg-[#2196f3] hover:text-white w-64 px-4 py-3.5 text-md font-medium bg-blue-100 text-gray-800 focus:z-10 focus:border-blue-500 focus:outline-none ring-1"
        >
          Yükle
        </button>
        <ToastContainer />
      </div>

      <div className="mx-auto py-5 px-4 sm:px-6 lg:px-8">
        <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
          {courseDocument && courseDocument?.map((doc) => (
            <li 
            key={doc.id}
            className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
              <div className="flex w-0 flex-1 items-center">
              <PaperClipIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <span className="ml-2 w-0 flex-1 truncate">
                {doc.documentPath.slice(58,80)}.pdf
              </span>
            </div>
           
            <div className="ml-4 flex flex-shrink-0 space-x-2">
              <button
               onClick={(e) => handleClick(e, doc)}
                type="button"
                className="rounded-md bg-white text-[#2196f3] hover:text-blue-700"
              >
                Aç
              </button>
              <span className="text-gray-300" aria-hidden="true">
                |
              </span>
              <button
                type="button"
                className="rounded-md bg-white font-medium text-[#2196f3] hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                Sil
              </button>
            </div>
          </li>
          ))}
        </ul>
      </div>
      {modalOn && (
        <AddDocumentToCourseModal
        setModalOn={setModalOn}
        courseDoc={courseDoc}
        />
      )}
    </>
    )
}

export default EducatorUpdateCourseDocuments