import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errors } from "../../../data/errors";

function EditSubCats() {
  const { id } = useParams();
  const [mainID, setMainID] = useState("");
  const [mainCategory, setMainCategory] = useState("");
  const [subCatName, setSubCatName] = useState("");
  const [image, setImage] = useState("");
  const [mainName, setMainName] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  let bodyFormData = new FormData();
  bodyFormData.append("Name", subCatName);
  bodyFormData.append("MainCategoryId", mainID);
  bodyFormData.append("Image", image);

  useEffect(() => {
    const fetchMainCategories = async () => {
      try {
        const mains = await axios(
          `${process.env.REACT_APP_BASE_URL}/MainCategory`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
            },
          }
        );
        setMainCategory(mains.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMainCategories();
  }, []);

  const handleMain = (e) => {
    const getMainId = e.target.value;
    setMainID(getMainId);
  };
  useEffect(() => {
    const fetchSubCats = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_BASE_URL}/SubCategory/${id}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
              accept: "text/plain",
            },
          }
        );
        setSubCatName(response.data.data[0].name);
        setMainName(response.data.data[0].mainCategoryName);
        setImage(response.data.data[0].imagePath);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubCats();
  }, []);

  const handleSubmit = () => {
    Swal.fire({
      title: "Kategori güncellensin mi?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Vazgeç",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
    }).then((result) => {
      if (result.isConfirmed) {
        const submit = async () => {
          setLoading(true);
          try {
            await axios({
              method: "patch",
              url: `${process.env.REACT_APP_BASE_URL}/SubCategory?id=${id}`,
              data: bodyFormData,
              headers: {
                "Content-Type": "multipart/form-data",
                accept: "text/plain",
                Authorization: `Bearer ${JSON.parse(jwtTokenRedux)}`,
              },
            });
            setLoading(false);
            Swal.fire("Güncellendi!");
            navigate("/adminpanel/kategoriler");
          } catch (error) {
            setLoading(false);
            let isEqual = false;
            for (let index = 0; index < errors.length; index++) {
              if (
                error.response.data.details.errorCodes === errors[index].id
              ) {
                let isEqual = true;
                return toast.error(errors[index].content);
              } else if (error.response.data.details.errorCodes === 999) {
                let isEqual = true;
                return toast.error(error.response.data.details.message);
              } else {
                let isEqual = false;
              }
            }
            if (!isEqual) {
              toast.error(
                "Bilinmeyen bir hata oluştu! Bizimle iletişime geçin."
              );
            }
          }
        };
        submit();
      }
    });
  };

  return (
    <div>
      <div className="w-full h-64 grid gap-x-20 gap-y-20 justify-items-center">
        <div className="w-2/3 items-start m-10 shadow-lg border-2 rounded-lg p-5">
          <form>
            <h2 className="text-blue-900 text-left font-semibold mb-5 text-2xl">
              Düzenle
            </h2>

            <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
              <label
                htmlFor="name"
                className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
              >
                ANA KATEGORİ
              </label>
              <select
                onChange={(e) => handleMain(e)}
                value={mainID}
                className="form-select block w-full p-1 text-gray-900 sm:text-sm"
              >
                <option className="text-gray-500">{mainName}</option>
                {mainCategory.data?.map((maincategory) => (
                  <option value={maincategory.id}>{maincategory.name}</option>
                ))}
              </select>
            </div>

            <div className="relative rounded-md border mb-10 border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
              <label
                htmlFor="name"
                className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
              >
                ADI
              </label>
              <input
                onChange={(e) => setSubCatName(e.target.value)}
                value={subCatName}
                type="text"
                name="Tittle"
                id=""
                className="block w-full p-1 text-gray-900 sm:text-sm"
              />
            </div>
            {!loading && (
              <div className="relative grid justify-center rounded-md mb-10 border-gray-300 px-3 py-2">
                <img src={image} alt="ategori-görseli" className="h-80" />
                <input
                  onChange={(e) => setImage(e.target.files[0])}
                  type="file"
                  name="imageFile"
                  id="image"
                  className="block w-full p-1 text-gray-900 sm:text-sm"
                />
              </div>
            )}
            {loading && (
              <div className="grid place-content-center shadow bg-grey-light text-center mt-3 text-md">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
                Yükleniyor...
              </div>
            )}
          </form>

          <button
            onClick={handleSubmit}
            type="submit"
            className="inline-flex items-center mx-auto rounded-md border border-transparent bg-blue-100 
        px-4 py-2 m-5 text-sm font-medium text-blue-900 hover:bg-indigo-200 focus:outline-none 
        focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
          >
            Paylaş
          </button>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default EditSubCats;
