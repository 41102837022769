import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import { useEffect, useLayoutEffect, useState } from "react";

//instructor starts
import InstructorAddCourse from "./components/Dashboards/EducatorDashboard/Courses/AddNewCourse";
import EducatorPanel from "./components/Dashboards/EducatorDashboard/EducatorPanel";
import EducatorMainPart from "./components/Dashboards/EducatorDashboard/EducatorMainPart";
import EducatorCourses from "./components/Dashboards/EducatorDashboard/Courses/EducatorCourses";
import EducatorCourseComments from "./components/Dashboards/EducatorDashboard/CourseComments/EducatorCourseComments";
import EducatorStudents from "./components/Dashboards/EducatorDashboard/EducatorStudents/EducatorStudents";
import EducatorAnnouncments from "./components/Dashboards/EducatorDashboard/Announcments/EducatorAnnouncments";
import EducatorProfile from "./components/Dashboards/EducatorDashboard/Profile/EducatorProfile";
import EducatorEditUser from "./components/Dashboards/EducatorDashboard/EducatorStudents/EducatorEditUser";
import EducatorCourseLessons from "./components/Dashboards/EducatorDashboard/Courses/EducatorCourseLessons";
import EducatorCourseUpdate from "./components/Dashboards/EducatorDashboard/Courses/EducatorCourseUpdate";
import EducatorUpdateCourseDocuments from "./components/Dashboards/EducatorDashboard/Courses/EducatorUpdateCourseDocumnets";
import EducatorUpdateCourseInfo from "./components/Dashboards/EducatorDashboard/Courses/EducatorUpdateCourseInfo";
import EducatorUpdateCourseAddLesson from "./components/Dashboards/EducatorDashboard/Courses/EducatorCourseAddLesson";
import EducatorCourseUpdateLesson from "./components/Dashboards/EducatorDashboard/Courses/EducatorCourseUpdateLesson";

import EducatorAddAnnouncement from "./components/Dashboards/EducatorDashboard/Announcments/EducatorAddAnnouncement";
import EducatorUpdateAnnouncement from "./components/Dashboards/EducatorDashboard/Announcments/EducatorUpdateAnnouncement";

//instructor ends

//Admin starts
import AdminPanel from "./components/Dashboards/AdminDashboard/AdminPanel";
import AdminCourses from "./components/Dashboards/AdminDashboard/AdminCourses";
import AdminAnnouncement from "./components/Dashboards/AdminDashboard/AdminAnnouncement";
import MainPart from "./components/Dashboards/AdminDashboard/MainPart";
import AddAnnouncment from "./components/Dashboards/AdminDashboard/AddAnnouncment";
import EditAnnouncment from "./components/Dashboards/AdminDashboard/EditAnnouncment";
import AddCourse from "./components/Dashboards/AdminDashboard/AddCourse";
import AddMainCat from "./components/Dashboards/AdminDashboard/AddMainCat";
import AddSubCats from "./components/Dashboards/AdminDashboard/AddSubCats";
import AdminAgreements from "./components/Dashboards/AdminDashboard/AdminAgreements";
import AddAgreements from "./components/Dashboards/AdminDashboard/AddAgreements";
import AdminCategories from "./components/Dashboards/AdminDashboard/AdminCategories";
import EditCourse from "./components/Dashboards/AdminDashboard/EditCourse";
import EditMainCategories from "./components/Dashboards/AdminDashboard/EditMainCategories";
import EditSubCats from "./components/Dashboards/AdminDashboard/EditSubCats";
import AdminVideos from "./components/Dashboards/AdminDashboard/AdminVideos";
import EditAgreements from "./components/Dashboards/AdminDashboard/EditAgreements";
import AdminProfile from "./components/Dashboards/AdminDashboard/AdminProfile";
import AdminComments from "./components/Dashboards/AdminDashboard/AdminComments";
import AdminPayment from "./components/Dashboards/AdminDashboard/AdminPayment";
import AdminShopping from "./components/Dashboards/AdminDashboard/AdminShopping";
import AdminRoles from "./components/Dashboards/AdminDashboard/AdminRoles";
import AdminSettings from "./components/Dashboards/AdminDashboard/AdminSettings";
import AdminUsers from "./components/Dashboards/AdminDashboard/AdminUsers";
import EditUserPage from "./components/Dashboards/AdminDashboard/EditUserPage";
import UserRoles from "./components/Dashboards/AdminDashboard/AdminUserRoles";
import AddUserRole from "./components/Dashboards/AdminDashboard/AdminAddRoleToUser";
import EditUserCourse from "./components/Dashboards/AdminDashboard/EditUserCourse";
import AddUser from "./components/Dashboards/AdminDashboard/AddUser";
import AdminCoursePage from "./components/Dashboards/AdminDashboard/AdminCoursePage";
import AddVideos from "./components/Dashboards/AdminDashboard/AddVideos";
import AddUserCourse from "./components/Dashboards/AdminDashboard/AddUserCourse";
import AdminDocument from "./components/Dashboards/AdminDashboard/AdminDocument";
import AddDocument from "./components/Dashboards/AdminDashboard/AddDocument";
import DocumentsById from "./components/Dashboards/AdminDashboard/DocumentsById";
import AddDocumentToUser from "./components/Dashboards/AdminDashboard/AddDocumentToUser";
import AdminUserOrderHistory from "./components/Dashboards/AdminDashboard/AdminUserOrderHistory";
import AdminUserLogs from "./components/Dashboards/AdminDashboard/AdminUserLogs";
//import NotConfirmRegister from "./components/Login/NotConfirmRegister";
import EditUserSettings from "./components/Dashboards/AdminDashboard/EditUserSettings";
import AddLesson from "./components/Dashboards/AdminDashboard/AddLesson";
import LessonUpdate from "./components/Dashboards/AdminDashboard/LessonUpdate";
import AdminSSS from "./components/Dashboards/AdminDashboard/AdminSSS";
import AddSSS from "./components/Dashboards/AdminDashboard/AddSSS";
import AddRole from "./components/Dashboards/AdminDashboard/AddRole";
import AdminLessons from "./components/Dashboards/AdminDashboard/AdminLessons";
import EditLessons from "./components/Dashboards/AdminDashboard/EditLessons";
import EditCourseInfo from "./components/Dashboards/AdminDashboard/EditCourseInfo";
import AddDocumentToCourse from "./components/Dashboards/AdminDashboard/AddDocumentToCourse";
import AdminGroups from "./components/Dashboards/AdminDashboard/AdminGroups";
import AdminGroupsCoursePage from "./components/Dashboards/AdminDashboard/AdminGroupsCoursePage";
import AddNewGroup from "./components/Dashboards/AdminDashboard/AddNewGroup";
import InstructorOperations from "./components/Dashboards/AdminDashboard/Instructor/InstructorOperations";
import Instructors from "./components/Dashboards/AdminDashboard/Instructor/Instructors";
import InstructorRequests from "./components/Dashboards/AdminDashboard/Instructor/InstructorRequests";
import InstructorCourses from "./components/Dashboards/AdminDashboard/Instructor/InstructorCourses";
import UserCertificateDetails from "./components/Dashboards/AdminDashboard/UserCertificateDetails";
import AdminExams from "./components/Dashboards/AdminDashboard/AdminExams";

//admin ends

//user starts
import UsersLesson from "./components/Dashboards/UserDashboard/UsersLesson";
import UsersDocuments from "./components/Dashboards/UserDashboard/UsersDocuments";
import UsersSettings from "./components/Dashboards/UserDashboard/UsersSettings";
import UsersOrderHistory from "./components/Dashboards/UserDashboard/UsersOrderHistory";
import SingleCerificate from "./components/Dashboards/UserDashboard/SingleCerificate";
import UsersCertificate from "./components/Dashboards/UserDashboard/UsersCertificate";
import UsersComment from "./components/Dashboards/UserDashboard/UsersComment";
import UserPostComment from "./components/Dashboards/UserDashboard/UserPostComment";
import UsersCourse from "./components/Dashboards/UserDashboard/UsersCourse";
import UserHome from "./components/Dashboards/UserDashboard/UserHome";
import UserInstructorRequest from "./components/Dashboards/UserDashboard/InstructorRequest";
import CourseDocuments from "./components/Dashboards/UserDashboard/CourseDocuments";
import UserExams from "./components/Dashboards/UserDashboard/UserExams";
import ExamPage from "./components/Dashboards/UserDashboard/ExamPage";
import UserDashboard from "./pages/UserDashboard";
//user ends

//uncategorized
import { addToken, checkIfAuthorized } from "./store/features/LoginSlice";
import { useDispatch, useSelector } from "react-redux";

import EducatorEditCourse from "./components/Dashboards/EducatorDashboard/Courses/EducatorEditCourse";
import EducatorLessons from "./components/Dashboards/EducatorDashboard/Lessons/EducatorLessons";
import EditSeo from "./components/Dashboards/AdminDashboard/EditSeo";
import { Helmet, HelmetProvider } from "react-helmet-async";
import UserCourseExams from "./components/Dashboards/UserDashboard/UserCourseExams";
import AddQToExam from "./components/Dashboards/AdminDashboard/AddQToExam";
import UpdateExam from "./components/Dashboards/AdminDashboard/UpdateExam";

function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  const dispatch = useDispatch();

  dispatch(addToken());
  const jwtTokenRedux = useSelector((state) => state.login.accessToken);
  const [checkAdmin, setCheckAdmin] = useState([]);
  const [checkUser, setCheckUser] = useState([]);
  const [checkInstructor, setCheckInstructor] = useState([]);
  const babaFonk = async (e) => {
    let decoded = await jwt_decode(JSON.parse(jwtTokenRedux));

    const decodedJwt = decoded.roles.split(",");
    setCheckAdmin(decodedJwt?.filter((plan) => plan.includes("Admin")));
    setCheckUser(decodedJwt?.filter((plan) => plan.includes("User")));
    setCheckInstructor(decodedJwt?.filter((plan) => plan.includes("Eğitmen")));
  };
  useEffect(() => {
    babaFonk();
  }, [jwtTokenRedux]);

  useEffect(() => {
    dispatch(checkIfAuthorized(JSON.parse(jwtTokenRedux)));
  }, [!jwtTokenRedux]);

  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <Wrapper>
            <Helmet>
              {/* <title>Unvan Eğitim Kurumları</title>
              <meta
                data-react-helmet="true"
                name="description"
                content="Unvan Eğitim Kurslarında Üniversite ya da MEB onaylı sertifikalı eğitimlerimizle kariyerinizi ilerletin. İhtiyaç duyduğunuz becerileri öğrenin ve sertifikanızı alarak işverenlerin dikkatini çekin."
              />
              <meta
                data-react-helmet="true"
                name="keywords"
                content="meb onaylı sertifika, üniversite onaylı sertifika, sertifikalı eğitim, kariyer gelişimi, işletme eğitimi, dijital pazarlama eğitimi, programlama eğitimi, bilgisayar işletmenliği, eğitim, eğitici, sertifika, uzaktan eğitim"
              />
              <meta
                data-react-helmet="true"
                name="copyright"
                content="© 2023 Unvan Eğitim Kurumları. Tüm hakları saklıdır. Web sitemizde yer alan tüm içerikler, telif hakkı kanunlarıyla korunmaktadır. İzinsiz kullanımı yasaktır."
              /> */}
            </Helmet>
            <Header
              setCheckInstructor={setCheckInstructor}
              setCheckAdmin={setCheckAdmin}
              setCheckUser={setCheckUser}
              checkAdmin={checkAdmin}
              checkInstructor={checkInstructor}
            />
            {/* <Navbar
              setCheckAdmin={setCheckAdmin}
              setCheckUser={setCheckUser}
              checkAdmin={checkAdmin}
            /> */}
            <Routes basename="/">
              <Route path="/" element={<Home checkAdmin={checkAdmin} checkUser={checkUser}/>} />
              {/* <Route path="/tumkurslar" element={<AllCourses />} /> */}

              {/* ANA KURS ve ANA KATEGORİ ve ALT KATEGORİ */}
              {/* <Route path="/kurslar" element={<MainCourses />} />
              <Route
                path="/:subCourseName/:subCourseId"
                element={<SubCourseCategory />}
              />
              <Route
                path="/:subCourseName/:sub/:courseName/:courseId"
                element={<Courses />}
              /> */}

              {/* END */}

              {/* SIKÇA SORULAN SORULAR */}
              {/* <Route path="/sss" element={<SideMenu />} />
              <Route path="/sss/:id" element={<FirstSub />} /> */}

              {/* END */}
              {/* <Route path="/kurumsal" element={<Corporate />} />
              <Route path="/iletisim" element={<Contact />} />
              <Route path="/duyurular" element={<AnnouncePage />} />
              <Route path="/duyurular/:name/:id" element={<ReadMore />} /> */}
              <Route
                path="/login"
                element={
                  <Login checkAdmin={checkAdmin} checkUser={checkUser} />
                }
              />
              {/* <Route path="/sifremiunuttum" element={<ForgotPassword />} />
              <Route path="/sifremidegistir" element={<ChangePassword />} /> */}
              {/* <Route path="/duyurular/ekle" element={<AddAnnouncment />} />
              <Route path="/duyurular/düzenle" element={<EditAnnouncment />} />
              <Route path="/hahaha" element={<CourseListCopy />} /> */}

              {/* ADMİN PANEL */}
              <Route
                exact
                path="/adminpanel"
                element={
                  checkAdmin == "Admin" ? (
                    <AdminPanel
                      checkAdmin={checkAdmin}
                      setCheckAdmin={setCheckAdmin}
                      setCheckUser={setCheckUser}
                      setCheckInstructor={setCheckInstructor}
                    />
                  ) : (
                    <Login />
                  )
                }
              >
                <Route path="" element={<MainPart />} />
                <Route path="kurslar" element={<AdminCourses />} />
                <Route path="yorumlar" element={<AdminComments />} />
                <Route path="roller" element={<AdminRoles />} />
                <Route path="roller/ekle" element={<AddRole />} />
                <Route path="ayarlar" element={<AdminSettings />} />
                <Route path="kullanicilar" element={<AdminUsers />} />
                <Route
                  path="kullanicilar/siparisgecmisi/:id"
                  element={<AdminUserOrderHistory />}
                />
                <Route
                  path="kullanicilar/kullanicihareketleri/:id"
                  element={<AdminUserLogs />}
                />
                <Route
                  path="kullanicilar/duzenle/:id"
                  element={<EditUserPage />}
                />
                <Route
                  path="kullanicilar/duzenle/:id/roller"
                  element={<UserRoles />}
                />
                <Route
                  path="kullanicilar/duzenle/:id/roller/add"
                  element={<AddUserRole />}
                />
                <Route
                  path="kullanicilar/duzenle/kursekle/:id"
                  element={<EditUserCourse />}
                />
                <Route
                  path="kullanicilar/duzenle/kursekle/:id/detaylar/:courseId"
                  element={<UserCertificateDetails />}
                />
                <Route
                  path="kullanicilar/duzenle/kullaniciya-kursekle/:id"
                  element={<AddUserCourse />}
                />

                <Route
                  path="kullanicilar/duzenle/kullanici-ayarlari/:id"
                  element={
                    checkUser == "User" ? <EditUserSettings /> : <Login />
                  }
                />
                <Route path="kullanicilar/ekle" element={<AddUser />} />
                <Route path="evraklar" element={<AdminDocument />} />
                <Route path="evraklar/ekle" element={<AddDocument />} />
                <Route path="evraklar/:id" element={<DocumentsById />} />
                {/* <Route path="admin" element={<AdminProfile />} /> */}
                <Route path="kategoriler" element={<AdminCategories />} />
                <Route path="anakategoriler/ekle" element={<AddMainCat />} />
                <Route
                  path="anakategoriler/duzenle/:id"
                  element={<EditMainCategories />}
                />
                <Route path="altkategoriler/ekle" element={<AddSubCats />} />
                <Route
                  path="altkategoriler/duzenle/:id"
                  element={<EditSubCats />}
                />
                <Route path="sozlesmeler" element={<AdminAgreements />} />
                <Route path="sozlesmeler/ekle" element={<AddAgreements />} />
                <Route
                  path="sozlesmeler/duzenle/:id"
                  element={<EditAgreements />}
                />
                <Route path="duyurular" element={<AdminAnnouncement />} />
                <Route path="videolar" element={<AdminVideos />} />
                <Route path="videolar/ekle" element={<AddVideos />} />
                <Route path="alisveris" element={<AdminShopping />} />
                <Route path="odemeler" element={<AdminPayment />} />
                <Route path="duyurular/ekle" element={<AddAnnouncment />} />
                <Route
                  path="duyurular/duzenle/:id"
                  element={<EditAnnouncment />}
                />
                <Route path="kurslar/ekle" element={<AddCourse />} />
                <Route
                  path="kurslar/incele/:id"
                  element={<AdminCoursePage />}
                />
                <Route path="kurslar/sinavlar/:id" element={<AdminExams />} />
                <Route
                  path="kurslar/sinav-duzenle/:id/:examId"
                  element={<UpdateExam />}
                />
                <Route
                  path="kurslar/sinavlar/:id/sorular/:examId"
                  element={<AddQToExam />}
                />
                <Route path="kurslar/dersler/:id" element={<AddLesson />} />
                <Route
                  path="kurslar/dersler/düzenle/:id"
                  element={<LessonUpdate />}
                />
                <Route path="kurslar/duzenle/:id" element={<EditCourse />} />
                <Route
                  path="kurslar/duzenle/kurs-bilgilerini-duzenle/:id"
                  element={<EditCourseInfo />}
                />
                <Route
                  path="kurslar/duzenle/seo-bilgilerini-duzenle/:id"
                  element={<EditSeo />}
                />
                <Route
                  path="kurslar/duzenle/dokuman-ekle/:id"
                  element={<AddDocumentToCourse />}
                />
                <Route
                  path="kurslar/duzenle/evrak-ekle/:id"
                  element={<AddDocumentToUser />}
                />
                <Route path="sss" element={<AdminSSS />} />
                <Route path="sss/ekle" element={<AddSSS />} />
                <Route path="dersler" element={<AdminLessons />} />
                <Route path="dersler/duzenle/:id" element={<EditLessons />} />
                <Route path="gruplar" element={<AdminGroups />} />
                <Route
                  path="gruplar/:name/:id"
                  element={<AdminGroupsCoursePage />}
                />
                <Route
                  path="gruplar/:name/:id/yeni-ekle"
                  element={<AddNewGroup />}
                />

                <Route
                  path="egitmen-islemleri"
                  element={<InstructorOperations />}
                />
                <Route path="egitmenler" element={<Instructors />} />
                <Route
                  path="egitmen-istekleri"
                  element={<InstructorRequests />}
                />
                <Route
                  path="egitmen-kurslari"
                  element={<InstructorCourses />}
                />
              </Route>
              {/* ADMİN PANEL ENDS */}

              {/* EGİTMEN PANEL */}

              <Route
                exact
                path="/egitmenpanel"
                element={
                  checkInstructor == "Eğitmen" ? (
                    <EducatorPanel
                      checkInstructor={checkInstructor}
                      setCheckAdmin={setCheckInstructor}
                      setCheckUser={setCheckUser}
                      setCheckInstructor={setCheckInstructor}
                    />
                  ) : (
                    <Login />
                  )
                }
              >
                <Route path="" element={<EducatorMainPart />} />
                <Route path="kurslarim" element={<EducatorCourses />} />
                <Route
                  path="kurslarim/duzenle/:id"
                  element={<EducatorEditCourse />}
                />
                <Route
                  path="kurslarim/yenikurs"
                  element={<InstructorAddCourse />}
                />
                <Route
                  path="kursyorumlari"
                  element={<EducatorCourseComments />}
                />
                <Route path="ogrencilerim" element={<EducatorStudents />} />
                <Route path="duyurular" element={<EducatorAnnouncments />} />
                <Route path="dersler" element={<EducatorLessons />} />
                <Route path="profil" element={<EducatorProfile />} />

                <Route
                  path="ogrencilerim/duzenle/:id"
                  element={<EducatorEditUser />}
                />
                <Route
                  path="ogrencilerim/duzenle/:id"
                  element={<EducatorEditUser />}
                />
                <Route
                  path="kurslarim/:id/dersler"
                  element={<EducatorCourseLessons />}
                />

                <Route
                  path="kurslarim/:id/dersler/ekle"
                  element={<EducatorUpdateCourseAddLesson />}
                />

                <Route
                  path="kurslarim/:id/dersler/düzenle/:lessonId"
                  element={<EducatorCourseUpdateLesson />}
                />

                <Route
                  path="kurslarim/duzenle/:id"
                  element={<EducatorCourseUpdate />}
                />
                <Route
                  path="kurslarim/duzenle/:id/dokumanlar"
                  element={<EducatorUpdateCourseDocuments />}
                />

                <Route
                  path="kurslarim/duzenle/:id/bilgiler"
                  element={<EducatorUpdateCourseInfo />}
                />

                <Route
                  path="duyurular/ekle"
                  element={<EducatorAddAnnouncement />}
                />

                <Route
                  path="duyurular/duzenle/:id"
                  element={<EducatorUpdateAnnouncement />}
                />
              </Route>
              {/* EĞİTMEN PANEL ENDS */}

              {/* KULLANICI PANELİ */}
              <Route
                path="/kullanicipaneli"
                element={
                  checkUser == "User" ? (
                    <UserDashboard
                      checkAdmin={checkAdmin}
                      checkUser={checkUser}
                      setCheckAdmin={setCheckAdmin}
                      setCheckUser={setCheckUser}
                      setCheckInstructor={setCheckInstructor}
                    />
                  ) : (
                    <Login />
                  )
                }
              >
                <Route path="anasayfa" element={<UserHome />} />
                <Route path="kurslarim" element={<UsersCourse />} />
                <Route
                  path="kurslarim/yorumyap/:id"
                  element={<UserPostComment />}
                />
                <Route path="kurslarim/:name/:id" element={<UsersLesson />} />
                <Route path="yorumlarim" element={<UsersComment />} />
                <Route path="sertifikalarim" element={<UsersCertificate />} />
                <Route
                  path="sertifikalarim-:id"
                  element={<SingleCerificate />}
                />
                <Route path="siparisgecmisi" element={<UsersOrderHistory />} />
                <Route path="ayarlarim" element={<UsersSettings />} />
                <Route path="evraklarim" element={<UsersDocuments />} />
                <Route path="kurs-dokumanlari" element={<CourseDocuments />} />
                <Route path="sinavlar" element={<UserExams />} />
                <Route
                  path="sinav/:name/:courseId"
                  element={<UserCourseExams />}
                />
                <Route
                  path="sinav/:name/:courseId/:examId"
                  element={<ExamPage />}
                />
                <Route
                  path="egitmen-istegi"
                  element={<UserInstructorRequest />}
                />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
         
          </Wrapper>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
